import alpha from "./theme/alpha";
import { colorsAsHex } from "./theme/colors";
import iconSize from "./theme/iconSize";
import spaces from "./theme/spaces";
import typography from "./theme/typography";
import zIndex from "./theme/zIndex";
import { ratios } from "./theme/aspectRatio";

/**
 * The XXL theme object.
 * @param {object} colors - The colors object for the XXL theme is deprecated
 * use import colors directly from `@xxl/theme` instead.
 */
const xxlTheme = {
  /**
   * @deprecated use "@xxl/theme"
   */
  colors: colorsAsHex,
  ...{ iconSize, spaces, typography, zIndex, alpha, ratios },
};
const componentMaxWidth = 1440;
const containerMaxWidth = 1332;
const containerDesktopOffset = 100;
const containerMaxWidthPx = `${containerMaxWidth}px`;

// eslint-disable-next-line import/no-unused-modules
export {
  componentMaxWidth,
  containerDesktopOffset,
  containerMaxWidth,
  containerMaxWidthPx,
  xxlTheme,
};
