import { QueryClientProvider } from "@/components/Providers/QueryClientProvider";
import { QuickShop } from "@/components/QuickShop/QuickShop";
import HeadMetaTags from "@/components/withLayout/HeadMetaTags/HeadMetaTags";
import type { LayoutProps } from "@/utils/apis/layout-props";
import { type Theme, ThemeProvider } from "@mui/material/styles";
import { hasNoValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { StatusCodes } from "http-status-codes";
import isEmpty from "lodash/isEmpty";
import type { AppProps as NextAppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CartContextProvider } from "react-app/src/components/Cart/CartContextProvider";
import { ApiClientsProvider } from "react-app/src/components/ContextProviders/ApiClientsProvider";
import { TrackingContextProvider } from "react-app/src/components/ContextProviders/TrackingContextProvider";
import { TranslationsProvider } from "react-app/src/components/ContextProviders/TranslationsProvider";
import { SnackBarWrapper } from "react-app/src/components/SnackBar";
import {
  getAccount,
  type UserAccountProps,
} from "react-app/src/components/UserDetails/UserAPI";
import { UserDataContext } from "react-app/src/contexts/UserData/UserDataContext";
import type { EcomSiteUidLegacy, HeaderConfig } from "react-app/src/global";
import { Layout } from "../components/Layout/Layout";
import { EnvironmentDataProvider } from "../components/Providers/EnvironmentDataProvider";
import type { XXLAppData } from "../global";
import "../styles/global.scss";
import type { DeviceType } from "../utils/app-page-helper";
import { getMuiTheme } from "../utils/app-page-helper";
import { getPageType } from "../utils/common-page-data/common-page-data";
import { initNextJsGiosg } from "../utils/giosg-init";
import { initGtm } from "../utils/gtm";
import { initKindlyChatbot } from "../utils/kindly-chatbot";
import { ThirdPartyScripts } from "@/components/ThirdPartyScripts";

const { NOT_FOUND } = StatusCodes;

const PAGE_TYPES_WITH_REWARDS_BANNER = [
  "checkout",
  "order-confirmation",
  "storeFinder",
  "guides",
  "campaignHub",
  "error",
];

const PAGE_TYPES_WITH_LIMITED_HEADER = ["checkout"];

const PAGE_TYPES_WITH_HIDDEN_BOTTOM_MARGIN = ["checkout"];

if (process.env.NODE_ENV === "development") {
  log.error(
    "React is running in strict mode, please read more about different development behaviour here: https://react.dev/reference/react/StrictMode"
  );
}

const LOGOUT_PATHNAME = "logout";
const muiTheme = new Map<DeviceType, Theme>();

function getMuiThemeInternal(
  deviceType: DeviceType,
  param2: EcomSiteUidLegacy
): Theme {
  if (!muiTheme.has(deviceType)) {
    const theme = getMuiTheme(deviceType, param2); // Generate the theme only if it doesn't exist
    muiTheme.set(deviceType, theme); // Cache the theme for the deviceType
  }
  return muiTheme.get(deviceType) as Theme; // Return the cached theme
}

if (process.env.NODE_ENV === "development") {
  log.error(
    "React is running in strict mode, please read more about different development behaviour here: https://react.dev/reference/react/StrictMode"
  );
}

export type InitialProps = {
  campaignHubUrl: string;
  deviceType: DeviceType;
  isTeamsales: boolean;
  layoutProps: LayoutProps;
};

const XXLApp = ({
  Component,
  pageProps,
}: NextAppProps<XXLAppData | { statusCode?: number }>) => {
  const router = useRouter();
  const { pathname } = router;
  const isLogoutPage = pathname.includes(LOGOUT_PATHNAME);

  if (
    isLogoutPage ||
    pageProps.statusCode === NOT_FOUND ||
    !("environmentData" in pageProps)
  ) {
    return (
      <>
        <HeadMetaTags />
        <Component {...pageProps} />
      </>
    );
  }

  const {
    campaignHubUrl,
    deviceType,
    environmentData,
    giosg,
    isTeamsales,
    layoutProps,
    logRocketApiId = "",
    searchQuery,
    serverGtmScriptUrl = "",
    specificKindlyChatApiKey,
    translations,
  } = pageProps;
  const theme = getMuiThemeInternal(deviceType, environmentData.siteUid);
  const { megaMenuContent, headerContent, infoMessage, footerContent } =
    layoutProps;
  const pageType = getPageType(pathname);
  const [showQuickShop, setShowQuickshop] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserAccountProps | undefined>();

  const getUserData = async (graphqlUrl: string) => {
    try {
      const response = await getAccount(graphqlUrl);
      setUserData(response.data?.account ?? undefined);
    } catch (error) {
      log.error("Cannot fetch user data");
      log.debug("Error while fetching user data", error);
    }
  };

  /**
   * Make sure we user correct market and environment in local development
   */
  useEffect(() => {
    const { origin } = window.location;
    if (isEmpty(environmentData)) {
      return;
    }
    const { frontEndServerUrl } = environmentData;
    if (origin.includes("localhost") && frontEndServerUrl !== origin) {
      throw Error(
        `Please check url, seems that market and/or environment differ from parameters that server was started with.\n\nServer started with: ${frontEndServerUrl}`
      );
    }
  }, [environmentData]);

  useEffect(() => {
    if (pageType === "account" && isEmpty(userData)) {
      void getUserData(
        environmentData.configuration.amplifyConfig.aws_appsync_graphqlEndpoint
      );
    }
  }, [
    environmentData.configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    pageType,
    userData,
  ]);

  useEffect(() => {
    if (isEmpty(environmentData)) {
      return;
    }
    const {
      featureToggles: {
        toggle_kindly_chatbot,
        toggle_quick_shop,
        toggle_load_gtm_immediately,
      },
      gtmId,
      kindlyChatbotKey,
      gitHash,
    } = environmentData;

    setShowQuickshop(toggle_quick_shop);

    //GTM
    initGtm(gtmId, serverGtmScriptUrl, toggle_load_gtm_immediately);

    // init LogRocket
    const isPreProdEnv = environmentData.frontEndServerUrl.includes("pre.");
    const shouldEnableLogRocket =
      logRocketApiId.trim() !== "" &&
      environmentData.featureToggles.toggle_log_rocket &&
      !isPreProdEnv &&
      hasNoValue(window.Cypress);

    if (shouldEnableLogRocket) {
      void import("../utils/third-party/logrocket").then(
        ({ default: initializeLogrocket }) => {
          initializeLogrocket({
            logRocketApiId,
            gitHash,
          });
          return;
        }
      );
    }

    // KINDLY CHATBOT INIT - Temporary mapping for soft launch.
    // nb router.pathname will have eg /customerservice and not the localised value since it's a middleware rewrite
    const whitelistedPaths = [
      "/account",
      "/checkout",
      "/order-confirmation",
      "/customerservice",
      "/faq",
    ];
    const startHiddenPaths = ["/order-confirmation"];

    const isLandingPage = document.location.pathname === "/";

    const isWhitelisted =
      specificKindlyChatApiKey !== undefined ||
      isLandingPage ||
      whitelistedPaths.some((p) => router.pathname.includes(p));
    const shouldStartHidden = startHiddenPaths.some((p) =>
      router.pathname.includes(p)
    );

    if (toggle_kindly_chatbot && isWhitelisted && !isTeamsales) {
      initKindlyChatbot({
        kindlyChatbotKey: specificKindlyChatApiKey ?? kindlyChatbotKey,
        shouldStartHidden,
      });
    } else if (giosg !== undefined) {
      const { giosgEnabled, giosgId } = giosg;
      if (giosgEnabled === true) {
        initNextJsGiosg(giosgId);
      }
    }
  }, [
    environmentData,
    giosg,
    isTeamsales,
    logRocketApiId,
    pathname,
    router.pathname,
    serverGtmScriptUrl,
    specificKindlyChatApiKey,
  ]);

  const { headerLogo, headerLinks, campaignHubLink } = headerContent;
  const headerConfig: HeaderConfig = {
    logoUrl: headerLogo?.url ?? "",
    logoAlt: headerLogo?.alt ?? "",
    headerLinks: headerLinks ?? [],
    isTeamsales,
    campaignHubUrl,
    campaignHubLink: campaignHubLink,
    showHeaderLinks: !isTeamsales,
  };

  const shouldShowRewardsBanner =
    PAGE_TYPES_WITH_REWARDS_BANNER.includes(pageType);
  const shouldShowLimitedHeader =
    PAGE_TYPES_WITH_LIMITED_HEADER.includes(pageType);
  const shouldHideBottomMargin =
    PAGE_TYPES_WITH_HIDDEN_BOTTOM_MARGIN.includes(pageType);

  return (
    <>
      {isNotNullOrUndefined(environmentData) && (
        <ThirdPartyScripts environmentData={environmentData} />
      )}
      <ThemeProvider theme={theme}>
        <EnvironmentDataProvider environmentData={environmentData}>
          <ApiClientsProvider>
            <TrackingContextProvider>
              <TranslationsProvider nextJsTranslations={translations}>
                <UserDataContext.Provider value={userData}>
                  <QueryClientProvider>
                    <CartContextProvider isTeamsales={isTeamsales}>
                      <HeadMetaTags />
                      <SnackBarWrapper />
                      {showQuickShop && <QuickShop />}
                      <Layout
                        footerContent={footerContent}
                        megaMenuContent={megaMenuContent}
                        headerConfig={headerConfig}
                        infoMessage={infoMessage}
                        searchQuery={searchQuery}
                        shouldShowLimitedHeader={shouldShowLimitedHeader}
                        shouldShowRewardsBanner={shouldShowRewardsBanner}
                        shouldHideBottomMargin={shouldHideBottomMargin}
                      >
                        <Component {...pageProps} />
                      </Layout>
                    </CartContextProvider>
                  </QueryClientProvider>
                </UserDataContext.Provider>
              </TranslationsProvider>
            </TrackingContextProvider>
          </ApiClientsProvider>
        </EnvironmentDataProvider>
      </ThemeProvider>
    </>
  );
};

export default XXLApp;
