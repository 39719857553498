import type { ContentApi } from "@xxl/content-api";
import type { CustomersApi } from "@xxl/customers-api";
import type { DeliverystreamApi } from "@xxl/deliverystream-api";
import type { PricesApi } from "@xxl/frontend-api";
import type { PimApi } from "@xxl/pim-api";
import type { SearchApi as ProductConfiguratorSearchApi } from "@xxl/product-configurator-api";
import type { ProductConfiguratorApi } from "@xxl/product-configurator-api-library";
import type { ElevateApi, ProductApi } from "@xxl/product-search-api";
import type { SearchApi } from "@xxl/search-api";
import { createContext, useContext } from "react";
import type { nextFrontendApi } from "../../api/NextFrontendApi";

export type ApiClients = {
  contentApi: ContentApi;
  customersApi: CustomersApi;
  deliveryStreamApi: DeliverystreamApi;
  elevateApi: ElevateApi<unknown>;
  elevateClusterApi: ElevateApi<unknown>;
  nextFrontendApi: typeof nextFrontendApi;
  pimApi: PimApi;
  pricesApi: PricesApi;
  productApi: ProductApi;
  productConfiguratorApi: ProductConfiguratorApi<unknown>;
  productConfiguratorSearchApi: ProductConfiguratorSearchApi;
  searchApi: SearchApi;
};

export const ApiClientsContext = createContext<ApiClients | undefined>(
  undefined
);

export const useApiClients = () => {
  const context = useContext(ApiClientsContext);

  if (context === undefined) {
    throw Error("useApiClients must be used within its provider.");
  }

  return context;
};
