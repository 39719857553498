import type { Trackers } from "../../../contexts/Tracking";
import type { ProductImpressionTrackingData } from "../../../utils/Tracking";
import type { CartItem, DisplayCart } from "../Api/types";
import { isEgPriceData } from "./utils";

export const handleTrackingAddProductToCart = ({
  ean,
  name,
  priceType,
  salesPrice,
  sizeCode,
  styleCode,
  productListName,
  trackers,
  brand,
  category,
  ticket,
}: {
  ean: string;
  name: string;
  priceType: string;
  productListName: string;
  salesPrice: number;
  sizeCode: string;
  styleCode: string;
  trackers: Trackers;
  brand?: string;
  category?: string;
  ticket?: string;
}): void => {
  trackers.sendAddToCartEvent({
    product: {
      name,
      priceType,
      brand,
      category,
      price: salesPrice.toString(),
    },
    list: productListName,
    deliveryStreamProduct: {
      id: styleCode,
      size: sizeCode,
      ean,
      entryNumber: 0,
      brand,
      productPrice: salesPrice,
    },
    ticket,
  });
};

type TrackingProductData = {
  title: string;
  price: string;
  priceType: string;
  brand?: string;
  category?: string;
};

export const handleTrackingIncreaseQuantity = (
  item: TrackingProductData,
  productListName: string,
  trackers: Trackers
): void => {
  trackers.sendAddToCartEvent({
    product: {
      brand: item.brand,
      category: item.category,
      name: item.title,
      price: item.price.toString(),
      priceType: item.priceType.toString(),
    },
    list: productListName,
  });
};

export const handleTrackingRemoveProductFromCart = (
  item: TrackingProductData,
  trackers: Trackers
): void => {
  trackers.sendRemoveFromCartEvent({
    brand: item.brand,
    category: item.category,
    name: item.title,
    price: item.price.toString(),
    priceType: item.priceType,
  });
};

export const handleTrackingClickProductInCart = (
  item: TrackingProductData,
  list: string,
  trackers: Trackers
): void => {
  trackers.sendProductClickInCartEvent({
    product: {
      brand: item.brand,
      category: item.category,
      name: item.title,
      price: item.price.toString(),
      priceType: item.priceType,
    },
    list,
  });
};

const _isLineValid = (
  line: Record<string, unknown>
): line is { amount: number; productCode: string; price: number } =>
  line.amount !== undefined &&
  line.productCode !== undefined &&
  line.price !== undefined;

const getPrice = (item: CartItem): number | undefined =>
  isEgPriceData(item.displayPrice)
    ? item.displayPrice.value
    : item.displayPrice?.salesPrice;

const getCheckoutProducts = (
  cartData: DisplayCart
): ProductImpressionTrackingData[] => {
  return cartData.items.map((item, index) => ({
    name: item.title,
    id: String(item.selectedStyle),
    price: String(getPrice(item)),
    brand: String(item.brand),
    variant: String(item.selectedStyle),
    list: "Checkout Summary",
    quantity: item.quantity ?? 1,
    position: index,
  }));
};

export const handleTrackingCartUpdatedInCheckout = (
  cartData: DisplayCart,
  trackers: Trackers
) => {
  if (cartData.totalAmount > 0) {
    trackers.sendCheckoutEvent({
      products: getCheckoutProducts(cartData),
      step: 1,
      deliveryStreamCheckout: {
        totalPrice: {
          value: cartData.totalAmount,
        },
        lines: cartData.items
          .map((line) => ({
            amount: line.quantity,
            productCode: line.productCode,
            price: getPrice(line),
          }))
          .filter(_isLineValid),
      },
    });
  }
};
