import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Text } from "@/react-components/Text/Text";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery/useXxlMediaQuery";
import type { ProductData, VariantData } from "@xxl/product-search-api";
import {
  getCurrentStoreIdFromCookie,
  getHighestCollectableStockStatusForAnySize,
  getHighestCollectableStockStatusFromStores,
  getInitCollectableStockStatus,
  getOnlineStockStatus,
  getOnlineStockStatusOfVariants,
  getPreferredStores,
  getStoresWithCollectableStockCount,
  getStoresWithCollectableStockCountForAnySize,
} from "../helpers";
import { useSortedStoresQuery } from "../queries/useSortedStoresQuery";
import { StockLevels } from "../types";
import {
  CncAvailability,
  CncAvailabilityInitialStockLevels,
  CncExcludedFromClickAndCollect,
} from "./CncAvailability/CncAvailability";
import { OnlineAvailability } from "./OnlineAvailability/OnlineAvailability";

const { spaces } = xxlTheme;

type Props = {
  isExcludedFromClickAndCollect: boolean;
  isOnlinePurchaseAllowed: boolean;
  productType: ProductData["type"];
  selectedVariant?: VariantData;
  variants: VariantData[];
  toggleCncDialog: () => void;
  toggleVariantSelectDrawer: () => void;
};
export const AvailabilityPanel = ({
  isExcludedFromClickAndCollect,
  isOnlinePurchaseAllowed,
  variants,
  productType,
  selectedVariant,
  toggleCncDialog,
  toggleVariantSelectDrawer,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const { data: sortedStores = [], isFetchedAfterMount } =
    useSortedStoresQuery();
  const preferredStores = getPreferredStores(
    sortedStores,
    getCurrentStoreIdFromCookie()
  );
  const hideCnc =
    ["PRODUCT_MULTIPACK", "BUNDLE_MULTIPACK"].includes(productType) ||
    (isFetchedAfterMount && sortedStores.length === 0);

  const computeOnlineStockStatus = () => {
    if (selectedVariant !== undefined) {
      return (
        getOnlineStockStatus(selectedVariant.availability) ??
        StockLevels.OUT_OF_STOCK
      );
    }
    return getOnlineStockStatusOfVariants(variants);
  };

  return (
    <>
      <XxlStack
        width={"100%"}
        gridTemplateColumns={"max-content 1fr"}
        display={!isMobile ? "grid" : "flex"}
        flexWrap={!isMobile ? "nowrap" : "wrap"}
        gap={spaces.smallRegular}
      >
        <XxlStack>
          <Text typography="baseBold">
            {t("product.details.clickcollect.stock.status.online")}:
          </Text>
          <OnlineAvailability
            stockStatus={computeOnlineStockStatus()}
            isPurchaseAllowed={isOnlinePurchaseAllowed}
          />
        </XxlStack>
        {!hideCnc && (
          <XxlStack width={"fit-content"}>
            <Text typography="baseBold">
              {t("product.details.clickcollect.stock.status.cc")}:
            </Text>
            {isExcludedFromClickAndCollect && (
              <CncExcludedFromClickAndCollect />
            )}
            {selectedVariant === undefined &&
              isExcludedFromClickAndCollect === false &&
              preferredStores.length === 0 && (
                <CncAvailabilityInitialStockLevels
                  initPosStockStatus={getInitCollectableStockStatus(variants)}
                  onBtnClick={toggleVariantSelectDrawer}
                />
              )}
            {selectedVariant === undefined &&
              isExcludedFromClickAndCollect === false &&
              preferredStores.length > 0 && (
                <CncAvailability
                  onBtnClick={toggleVariantSelectDrawer}
                  preferredStores={preferredStores}
                  stores={sortedStores}
                  storesWithCollectableStockCount={getStoresWithCollectableStockCountForAnySize(
                    sortedStores.map(({ id }) => id),
                    variants
                  )}
                  stockStatus={getHighestCollectableStockStatusForAnySize(
                    preferredStores.map(({ id }) => id),
                    variants
                  )}
                />
              )}
            {selectedVariant !== undefined &&
              isExcludedFromClickAndCollect === false && (
                <CncAvailability
                  onBtnClick={toggleCncDialog}
                  preferredStores={preferredStores}
                  stores={sortedStores}
                  storesWithCollectableStockCount={getStoresWithCollectableStockCount(
                    selectedVariant.availability
                  )}
                  stockStatus={getHighestCollectableStockStatusFromStores(
                    (preferredStores.length > 0
                      ? preferredStores
                      : sortedStores
                    ).map(({ id }) => id),
                    selectedVariant.availability
                  )}
                />
              )}
          </XxlStack>
        )}
      </XxlStack>
    </>
  );
};
