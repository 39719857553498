import { NextImage } from "@/components/common/NextImage/NextImage";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { Text } from "react-app/src/components/Text";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { StyledHeading } from "./styles";
import { NextLink } from "@/react-components/NextComponentsStubs/NextLink";

const BRAND_IMAGE = {
  WIDTH: 30,
  HEIGHT: {
    MOBILE: 22,
    DESKTOP: 30,
  },
  QUALITY: 100,
} as const;

type Props = {
  baseProductName: string;
  brandName: string;
  brandUrl: string;
  brandLogoUrl: string;
};

const _ProductHeading = ({
  baseProductName,
  brandName,
  brandUrl,
  brandLogoUrl,
}: Props) => {
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const imageHeight = isLaptop
    ? BRAND_IMAGE.HEIGHT.DESKTOP
    : BRAND_IMAGE.HEIGHT.MOBILE;
  const shouldIncludeBrandUrl = brandUrl !== "";
  const shouldShowBrandLogo =
    brandUrl !== "" && brandName !== "" && brandLogoUrl !== "";

  if (shouldShowBrandLogo) {
    return (
      <StyledHeading>
        <NextLink
          href={brandUrl}
          style={{ width: "fit-content", height: `${imageHeight}px` }}
        >
          <NextImage
            src={brandLogoUrl}
            priority={true}
            alt={brandName}
            quality={BRAND_IMAGE.QUALITY}
            padding={1}
            width={BRAND_IMAGE.WIDTH}
            height={imageHeight}
            style={{ width: "auto", height: "100%", filter: "grayscale(1)" }}
            sizes="(max-width: 600px) 50px, 100px, (max-width: 1280px) 50px, 100px"
          />
        </NextLink>
        {baseProductName}
      </StyledHeading>
    );
  }

  if (shouldIncludeBrandUrl) {
    return (
      <StyledHeading>
        <NextLink href={brandUrl}>
          <Text typography="baseBold">{brandName}</Text>
          {baseProductName}
        </NextLink>
      </StyledHeading>
    );
  }

  return (
    <StyledHeading>
      <Text typography="baseBold">{brandName}</Text>
      {baseProductName}
    </StyledHeading>
  );
};

export const ProductHeading = withErrorBoundary(_ProductHeading);
