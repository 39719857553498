import { getElevateApiConfiguration } from "@/utils/environment-variables";
import { ProductApi } from "@xxl/product-search-api";
import { serverSideApiInstanceFactory } from "../../shared/helpers";

let productApi: ProductApi | undefined;

export const getProductApiInstance = () =>
  serverSideApiInstanceFactory(() => {
    const { baseURL } = getElevateApiConfiguration(false);
    productApi = new ProductApi({ baseUrl: baseURL });
    return productApi;
  }, productApi);
