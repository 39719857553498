import type { Customer } from "@xxl/deliverystream-api";
import { log } from "@xxl/logging-utils";
import { isAxiosError } from "axios";
import { getAccount } from "../../components/UserDetails/UserAPI";

export const getCustomerDetails = async (
  isLoggedIn: boolean,
  graphQLEndpointUrl: string
): Promise<Customer> => {
  if (!isLoggedIn) {
    return {};
  }

  try {
    const { data } = await getAccount(graphQLEndpointUrl);
    return {
      email: data?.account?.email,
      firstName: data?.account?.firstName,
      lastName: data?.account?.lastName,
      phoneNumber: data?.account?.mobilePhone,
      memberNumber: data?.account?.memberNumber ?? undefined,
    };
  } catch (error) {
    log.error(
      "Could not fetch customer details",
      ...(isAxiosError(error) ? [error.message, error.config?.url] : [error])
    );
    return {};
  }
};
