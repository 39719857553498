import { QUERY_KEYS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import {
  hasNoValue,
  hasValue,
  isNotUndefined,
  isNullOrUndefined,
} from "@xxl/common-utils";
import { toValidStoreOrUndefined } from "react-app/src/utils/Stores/stores-helper";

export const useStoreDetailsQuery = (storeId?: string) => {
  const {
    data: { siteUid },
  } = useSharedData();
  const { contentApi } = useApiClients();

  const getStore = async () => {
    if (hasNoValue(storeId)) {
      throw new Error(
        `${QUERY_KEYS.STORE_DETAILS} + :Store ID is undefined or empty. Expected a non-empty string. Please try again.`
      );
    }
    const {
      data: { result = [] },
    } = await contentApi.getStore(legacySiteUidToSiteUid(siteUid), storeId);

    const [store] = result.map(toValidStoreOrUndefined).filter(isNotUndefined);

    if (isNullOrUndefined(store)) {
      throw new Error(
        `Failed to fetch store data. Store ID: ${storeId}. Please try again.`
      );
    }
    return store;
  };

  return useQuery({
    enabled: hasValue(storeId),
    queryFn: getStore,
    queryKey: [QUERY_KEYS.STORE_DETAILS, storeId],
    staleTime: Infinity,
  });
};
