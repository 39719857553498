import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import type { QuickShopData } from "react-app/src/global";
import * as XxlEvent from "react-app/src/utils/xxl-event";
import { MiniElevatePdp } from "../MiniPdp/MiniElevatePdp";

export const QuickShop = () => {
  const [productCode, setProductCode] = useState<string>("");

  useEffect(() => {
    const eventHandler = (event: CustomEvent<QuickShopData>) => {
      const {
        productCode: selectedProductCode,
        ticket: _ticket,
        additionalSales: _additionalSales,
      } = event.detail;
      setProductCode(selectedProductCode);
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_SET_QUICKSHOP_PRODUCTCODE,
      eventHandler as EventListener
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_SET_QUICKSHOP_PRODUCTCODE,
        eventHandler as EventListener
      );
    };
  }, [productCode, setProductCode]);

  if (isEmpty(productCode)) {
    return null;
  }

  return (
    <div data-testid="quickshop-mini-pdp">
      <MiniElevatePdp
        handleClose={() => setProductCode("")}
        isOpen={!isEmpty(productCode)}
        isQuickShop={true}
        productCode={productCode}
      />
    </div>
  );
};
