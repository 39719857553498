import type { PdpState } from "@/react-components/ProductDetailsPage/ProductDetailsPage.state";
import type { CustomersApi } from "@xxl/customers-api";
import { log } from "@xxl/logging-utils";
import { StockStatus, type StockStatusType } from "@xxl/product-search-api";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import type { EcomSiteUidLegacy } from "react-app/src/global";
import { getAccessToken } from "react-app/src/graphql/getAuthData";
import { legacySiteUidToSiteUid } from "react-app/src/utils/xxl-shared-data";
import stringFormat from "string-format";

export const postNotificationRequest = async (
  customersApi: CustomersApi,
  siteUid: EcomSiteUidLegacy,
  articleNumber: string,
  ean: string
) => {
  try {
    const accessToken = await getAccessToken();

    if (accessToken === undefined) {
      log.error("Could not fetch auth data.");
      return;
    }

    void (await customersApi.postProductNotification(
      legacySiteUidToSiteUid(siteUid),
      accessToken,
      {
        articleNumber,
        ean,
      }
    ));
  } catch (error) {
    log.error("Could not set size reminder.", error);
  }
};

export const getStoreTranslationText = (
  t: Translate,
  storeName: string,
  nrOfStoresWithStock: number,
  storeStatus: StockStatusType,
  preferredStoresCount: number,
  pdpState: PdpState
) => {
  if (preferredStoresCount >= 2) {
    switch (storeStatus) {
      case StockStatus.INSTOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.available.store.multi.preferredstores"
        );
      case StockStatus.OUTOFSTOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.multi.preferredstores"
        );
      case StockStatus.LOWSTOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstores"
        );
    }
  }

  if (preferredStoresCount === 1) {
    switch (storeStatus) {
      case StockStatus.OUTOFSTOCK:
        return nrOfStoresWithStock === 0
          ? t(
              "product.details.clickcollect.stock.status.not.available.in.store"
            )
          : stringFormat(
              t(
                "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.single.preferredstore"
              ),
              storeName,
              nrOfStoresWithStock.toString()
            );
      case StockStatus.INSTOCK:
        return stringFormat(
          t(
            "product.details.clickcollect.sizeselect.store.stock.status.available.store.multi.preferredstore"
          ),
          storeName,
          nrOfStoresWithStock.toString(),
          pdpState.clickAndCollectPickupReadyHours.toString()
        );
      case StockStatus.LOWSTOCK:
        return stringFormat(
          t(
            "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstore"
          ),
          storeName,
          nrOfStoresWithStock.toString(),
          pdpState.clickAndCollectPickupReadyHours.toString()
        );
    }
  }

  return stringFormat(
    t(
      storeStatus === StockStatus.INSTOCK
        ? "product.details.clickcollect.stock.status.available.store"
        : "product.details.clickcollect.stock.status.available.few.store"
    ),
    storeName,
    "0", // not used, but needed for consistency in args
    pdpState.clickAndCollectPickupReadyHours.toString()
  );
};
