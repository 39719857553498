import {
  coordinatesDistance,
  requestUserCoordinates,
} from "@/react-utils/xxl-geolocation";
import type { Store } from "react-app/src/utils/Stores/stores-helper";
import type {
  AvailabilityData,
  StockStatusType,
} from "@xxl/product-search-api";
import { getPreferredStoreIds } from "../helpers";
import { StockLevels, type StoreWithStock } from "../types";

export type CncInfo = {
  pickupTimeMessage: string;
  specialOpeningHoursMessage: string;
};

export const getDistanceFromUserLocation = (
  userCoordinates: GeolocationPosition,
  location: {
    latitude: number;
    longitude: number;
  }
) => {
  const { latitude, longitude } = location;
  const distanceFromUserLocation = coordinatesDistance.get(
    userCoordinates.coords.latitude,
    userCoordinates.coords.longitude,
    latitude,
    longitude
  );
  return distanceFromUserLocation;
};

export const sortDataByGeolocation = <T extends Store>(
  stores: T[],
  userCoordinates: GeolocationPosition
): T[] => {
  return stores.sort((storeA, storeB) => {
    const locationA = storeA.location;
    const locationB = storeB.location;

    if (locationA === null || locationB === null) {
      return 0;
    }

    const distanceToA = getDistanceFromUserLocation(userCoordinates, locationA);
    const distanceToB = getDistanceFromUserLocation(userCoordinates, locationB);

    if (distanceToA === undefined || distanceToB === undefined) {
      return 0;
    }

    return distanceToA - distanceToB;
  });
};

export const sortDataAlphabetically = (stores?: Store[]) =>
  stores?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

export const hasStock = ({ stockLevel }: StoreWithStock): boolean =>
  stockLevel === StockLevels.IN_STOCK || stockLevel === StockLevels.LOW_STOCK;

export const isStoreDifferentThanSelected = (
  storeFromCart: Store | undefined,
  selectedStoreId: string | undefined
) => storeFromCart !== undefined && storeFromCart.id !== selectedStoreId;

export const getSortedStoresList = async (
  stores: Store[]
): Promise<Store[]> => {
  try {
    const userCoordinates = await requestUserCoordinates();
    return sortDataByGeolocation(stores, userCoordinates);
  } catch (_error) {
    return sortDataAlphabetically(stores);
  }
};

export const storeHasStock = ({ collectableStockLevel }: StoreWithStock) =>
  collectableStockLevel !== StockLevels.OUT_OF_STOCK;

export type StoreListItem = {
  name: string;
  stockStatus: StockStatusType;
  storeId: string;
};
export const getStoreStockList = ({
  availabilityData,
  excludeOutOfStock = false,
  limit = 100,
  stores,
}: {
  availabilityData?: AvailabilityData[];
  excludeOutOfStock?: boolean;
  limit?: number;
  stores: Store[];
}) => {
  if (availabilityData === undefined) {
    throw new Error("availabilityData not found");
  }
  const psIds = getPreferredStoreIds();
  const preferredStores = stores.filter((s) => psIds.includes(s.id));
  const resultsMap = new Map<string, StoreListItem>();

  for (const store of [...preferredStores, ...stores]) {
    const storeStockStatus =
      availabilityData.find((a) => a.key === store.id)?.stockStatus ??
      StockLevels.OUT_OF_STOCK;

    if (
      excludeOutOfStock &&
      storeStockStatus === StockLevels.OUT_OF_STOCK &&
      !preferredStores.includes(store)
    ) {
      continue;
    }
    if (resultsMap.size >= limit) {
      break;
    }

    resultsMap.set(store.id, {
      storeId: store.id,
      name: store.name,
      stockStatus: storeStockStatus,
    });
  }
  return Array.from(resultsMap.values());
};
