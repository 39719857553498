import React, { useState } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  CART_CONTENT_EDITING_LOCK,
  UPDATE_CART_PRICE_AND_OFFERS,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  useCartContext,
} from "../CartState";
import AnimateHeight from "react-animate-height";
import { updateCheckoutSnippet } from "../Services/updateCartQuantity";

import {
  Wrapper,
  HeadingButton,
  OffersList,
  HeadingButtonAnimationWrapper,
  ErrorWrapper,
  OfferItem,
  OfferItemWrapper,
  CarouselWrapper,
} from "../Styles/PersonalOffers.styled";
import {
  ERROR_RESPONSE_STATUS,
  SUCCESS_RESPONSE_STATUS,
  toggleOfferInCart,
} from "../Api/CartAPI";
import type { PersonalOffer } from "../Api/types";
import { useSharedData } from "../../../contexts/SharedData";
import { CaretIcon } from "../../Common/Icons/Caret";
import { PersonalOfferItem } from "./PersonalOfferItem";
import { Slider } from "../../Common/Slider";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { log } from "@xxl/logging-utils";

export const PersonalOffers = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { state, dispatch } = useCartContext();
  const { t } = useTranslations();
  const {
    data: { configuration },
  } = useSharedData();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  if (
    state.displayCart?.personalOffers === undefined ||
    state.displayCart.personalOffers.length === 0
  ) {
    return null;
  }

  const handleExpandButtonClick = () => {
    setIsExpanded(!isExpanded);
    setIsAnimating(true);
  };

  const handleToggleOfferButtonClick = async (offer: PersonalOffer) => {
    const { aws_appsync_graphqlEndpoint, aws_appsync_apiKey } =
      configuration.amplifyConfig;
    try {
      dispatch({
        type: CART_CONTENT_EDITING_LOCK,
      });
      if (isKlarnaCheckoutSnippetLoaded()) {
        window._klarnaCheckout((api) => {
          api.suspend({
            autoResume: {
              enabled: false,
            },
          });
        });
      }
      if (isWalley(state.paymentProvider)) {
        window.walley?.checkout.api.suspend();
      }
      const response = await toggleOfferInCart(
        offer,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      const { status, data } = response;
      if (
        status === SUCCESS_RESPONSE_STATUS &&
        data.data?.toggleOfferInCart !== undefined
      ) {
        if (hasErrors) {
          setHasErrors(false);
        }
        const { offers, totals, hasDiscounts, items } =
          data.data.toggleOfferInCart;
        dispatch({
          type: UPDATE_CART_PRICE_AND_OFFERS,
          payload: {
            personalOffers: offers,
            totalDiscounts: totals.cartDiscountTotalFormattedPrice,
            cartDiscountTotals: totals.cartDiscountTotals,
            hasDiscounts: hasDiscounts,
            totalAmount: totals.salesTotalAmount,
            totalPrice: totals.salesTotalFormattedPrice,
            totalAmountExcludingShipping: totals.salesTotalAmount,
            paymentTotalAmount: totals.paymentTotals.totalAmount,
            newPrices: items,
          },
        });
      } else if (status === ERROR_RESPONSE_STATUS) {
        setHasErrors(true);
        log.error("Personal offer cannot be applied", data.errors);
      }
    } catch (err) {
      log.error("Cannot toggle activation of personal offer", err);
    } finally {
      void updateCheckoutSnippet(
        dispatch,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey,
        state.customerTypeOptions,
        state.customerType
      );
    }
  };

  return (
    <Wrapper isLoading={state.isCartContentLocked}>
      <HeadingButton type="button" onClick={handleExpandButtonClick}>
        {t("personal.offers.heading")} (
        {state.displayCart.personalOffers.length})
        <CaretIcon direction={isExpanded ? "up" : "down"} />
        <HeadingButtonAnimationWrapper
          isAnimating={isAnimating}
          onAnimationEnd={() => {
            setIsAnimating(false);
          }}
        />
      </HeadingButton>
      <AnimateHeight height={isExpanded ? "auto" : 0}>
        {hasErrors && (
          <ErrorWrapper>{t("personal.offers.graphql.error")}</ErrorWrapper>
        )}
        {state.displayCart.personalOffers.length > 0 && (
          <>
            {isMobile ? (
              <CarouselWrapper>
                <Slider
                  items={state.displayCart.personalOffers.map((item, index) => (
                    <OfferItemWrapper key={`personal-offer-${index}`}>
                      <PersonalOfferItem
                        item={item}
                        isCartContentLocked={state.isCartContentLocked}
                        handleToggleOfferButtonClick={
                          handleToggleOfferButtonClick
                        }
                      />
                    </OfferItemWrapper>
                  ))}
                  slidesConfig={{
                    perView: isMobile ? 2 : 3,
                    spacing: 8,
                  }}
                />
              </CarouselWrapper>
            ) : (
              <OffersList>
                {state.displayCart.personalOffers.map((item, index) => (
                  <OfferItem key={`personal-offer-${index}`}>
                    <PersonalOfferItem
                      item={item}
                      isCartContentLocked={state.isCartContentLocked}
                      handleToggleOfferButtonClick={
                        handleToggleOfferButtonClick
                      }
                    />
                  </OfferItem>
                ))}
              </OffersList>
            )}
          </>
        )}
      </AnimateHeight>
    </Wrapper>
  );
};
