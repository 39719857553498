import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text/Text";
import { addXXLEventListener } from "@/react-utils/xxl-event";
import type { AvailabilityData, VariantData } from "@xxl/product-search-api";
import { useEffect, useState } from "react";
import { useStoresQuery } from "../queries/useStoresQuery";
import { StockLevels } from "../types";
import { StoreStockDialog } from "./StoreStockDialog/StoreStockDialog";
import { NotInStockMessageStyled } from "./StoreStockNotInStockMessage";
import { StoreStockLevelElement } from "./StoreStockStatus";
import {
  StoreStockChooseProductSizeMessage,
  StoreStockHeader,
  StoreStockListItem,
  StoreStockListStyled,
  StoreStockSeeAllStoresButton,
} from "./StoreStockStyles";
import { getStoreStockList } from "./helper";

const { spaces } = xxlTheme;
const STORE_DISPLAY_COUNT = 3;

const getAvailabilityStockStatus = (storeId: string, ad: AvailabilityData[]) =>
  ad.find((a) => a.key === storeId)?.stockStatus ?? StockLevels.OUT_OF_STOCK;

const useToggleDialogEvent = (cb: () => void) => {
  useEffect(() => {
    addXXLEventListener("OPEN_STORE_STOCK_MODAL", cb);
    return () => removeEventListener("OPEN_STORE_STOCK_MODAL", cb);
  }, [cb]);
};

const StoreStockHeading = ({ text }: { text: string }) => {
  return (
    <StoreStockHeader>
      <Text typography="baseBold" role="heading" aria-level={2}>
        {text}
      </Text>
    </StoreStockHeader>
  );
};
const SelectVariantMessage = ({
  headingText,
  bodyText,
}: {
  headingText: string;
  bodyText: string;
}) => (
  <XxlStack>
    <StoreStockHeading text={headingText} />
    <StoreStockChooseProductSizeMessage>
      {bodyText}
    </StoreStockChooseProductSizeMessage>
  </XxlStack>
);

type Props = {
  selectedVariant?: VariantData;
};
export const StoreStock = ({ selectedVariant }: Props) => {
  const { t } = useTranslations();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  useToggleDialogEvent(toggleDialog);

  const { data: storesData = [], isError } = useStoresQuery();

  if (isError) {
    return <Text>{t("general.error")}</Text>;
  }

  if (selectedVariant === undefined || storesData.length === 0) {
    return (
      <SelectVariantMessage
        headingText={t("product.details.storestock.status")}
        bodyText={t("product.details.storestock.choose.product")}
      />
    );
  }

  const storeStockList = getStoreStockList({
    stores: storesData,
    availabilityData: selectedVariant.availability,
    excludeOutOfStock: true,
  }).slice(0, STORE_DISPLAY_COUNT);

  const shouldShowStoreStock = storeStockList.length > 0;

  return (
    <XxlStack>
      <StoreStockHeading text={t("product.details.storestock.status")} />
      {shouldShowStoreStock ? (
        <XxlStack gap={spaces.smallRegular}>
          <StoreStockListStyled>
            {storeStockList.map(({ storeId, name }) => (
              <StoreStockListItem key={storeId}>
                {name}
                <StoreStockLevelElement
                  stockLevel={getAvailabilityStockStatus(
                    storeId,
                    selectedVariant.availability
                  )}
                />
              </StoreStockListItem>
            ))}
          </StoreStockListStyled>
          <StoreStockSeeAllStoresButton
            onClick={toggleDialog}
            data-testid="showAllStores"
          >
            <Icon name="Store" size={20} />
            <Text>{t("product.details.storestock.status.all")}</Text>
          </StoreStockSeeAllStoresButton>
        </XxlStack>
      ) : (
        <NotInStockMessageStyled
          testId="storestock-out-all"
          messageText={t("product.details.storestock.out.all")}
        />
      )}
      <StoreStockDialog
        storeListItems={getStoreStockList({
          stores: storesData,
          availabilityData: selectedVariant.availability,
          excludeOutOfStock: false,
        })}
        isDialogOpen={isDialogOpen}
        toggleDialog={toggleDialog}
      />
    </XxlStack>
  );
};
