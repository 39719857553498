import styled from "@emotion/styled/macro";
import * as React from "react";
import { getSignUpImageUrl } from "../../../utils/xxl-shared-data";
import { DialogBox } from "../../DialogBox";
import { SignUpForm } from "./SignUpForm/SignUpForm";
import { SignUpSuccess } from "./SignUpSuccess/SignUpSuccess";
import { CallToAction } from "../SharedComponents/CallToAction";
import { isCartPage, isTeamsalesClubPage } from "../../../utils/xxl-page-type";
import { needToCompleteProfile } from "../Login/Login.helper";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { Root } from "react-dom/client";
import {
  dispatchEvent,
  XXL_SIGNUP_CLOSE,
} from "../../../../../react-app/src/utils/xxl-event";
import { windowAccess } from "../../../utils/Window";
import { MQ } from "../../../styles/helpers";
import { useSession } from "../../../hooks/useSession";
import { SignupPhone } from "./SignupPhone";
import { CustomerFound } from "./CustomerFound";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${MQ("tablet")} {
    flex-direction: row;
  }
`;

type SignUpModalProps = {
  rootElement?: Root;
  initialViewState?: SignupViewStates;
};

export enum SignupViewStates {
  SIGN_UP,
  SIGN_UP_SUCCESSFUL,
  SIGN_UP_PHONE,
  SIGN_UP_USER_FOUND,
  SIGN_UP_USER_NOT_FOUND,
}

const SignUpModal: React.FunctionComponent<SignUpModalProps> = ({
  rootElement,
  initialViewState = SignupViewStates.SIGN_UP,
}) => {
  const { t } = useTranslations();
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const [viewState, setViewState] =
    React.useState<SignupViewStates>(initialViewState);
  const [mobilePhone, setMobilePhone] = React.useState<string>("");
  const handleClose = () => {
    if (viewState === SignupViewStates.SIGN_UP_SUCCESSFUL && isCartPage()) {
      window.location.reload();
    }
    rootElement?.unmount();
    dispatchEvent(XXL_SIGNUP_CLOSE);
  };
  const handleSuccess = () => {
    if (
      windowAccess()._sharedData.isTeamsales &&
      isTeamsalesClubPage() &&
      needToCompleteProfile()
    ) {
      // Success info modal covered update user info modal, so as long as TS is not rewritten to React it's better to just hide this modal with success info
      handleClose();
      window.Account.getMoreUserInfo();
    } else {
      setViewState(SignupViewStates.SIGN_UP_SUCCESSFUL);
    }
  };

  const backgroundUrl = getSignUpImageUrl() ?? "";

  return (
    <DialogBox
      dialogBoxSize={"md"}
      isDialogBoxOpen={true}
      handleDialogBoxClosing={handleClose}
      hasPadding={false}
      hideDefaultCloseButton={true}
    >
      <Container>
        <CallToAction {...{ backgroundUrl }} />
        {viewState === SignupViewStates.SIGN_UP && (
          <SignUpForm
            isInModal={true}
            successfulSignUpCallback={handleSuccess}
            onClose={handleClose}
            handleDialogBoxClosing={handleClose}
            signUpTranslations={{
              formHeader: t("reward.success.account.create"),
              terms: t("reward.signup.terms.label"),
              register: t("reward.signup.register"),
              existing: t("reward.success.account.existing"),
              buttonText: t("login.form.login.button"),
            }}
          />
        )}
        {viewState === SignupViewStates.SIGN_UP_USER_FOUND && (
          <CustomerFound handleDialogBoxClosing={handleClose} />
        )}
        {viewState === SignupViewStates.SIGN_UP_USER_NOT_FOUND && (
          <SignUpForm
            userData={{ phoneNumber: mobilePhone }}
            descriptionTextComponent={
              <p>{t("reward.signup.email.not.found")}</p>
            }
            isInModal={true}
            successfulSignUpCallback={handleSuccess}
            onClose={handleClose}
            handleDialogBoxClosing={handleClose}
            signUpTranslations={{
              formHeader: t("reward.success.account.create"),
              terms: t("reward.signup.terms.label"),
              register: t("reward.signup.register"),
              existing: t("reward.success.account.existing"),
              buttonText: t("login.form.login.button"),
            }}
          />
        )}
        {viewState === SignupViewStates.SIGN_UP_PHONE && (
          <SignupPhone
            setMobilePhone={setMobilePhone}
            setViewState={setViewState}
            handleDialogBoxClosing={handleClose}
          />
        )}
        {viewState === SignupViewStates.SIGN_UP_SUCCESSFUL && (
          <SignUpSuccess
            handleDialogBoxClosing={handleClose}
            signUpSuccessTranslations={{
              header: t("reward.success.header"),
              info: t("reward.success.info"),
              buttonText: t("club.register.form.button.xxlpage"),
            }}
            failedLoginTranslation={
              isLoggedIn ? undefined : t("reward.signup.login.error")
            }
          />
        )}
      </Container>
    </DialogBox>
  );
};

export { SignUpModal };
