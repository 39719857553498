/**
 * Z-Index values for the application.
 * The list is in order that the z-indices will appear.
 * If you need something between a header and a modal, add it to the list
 * between those two.
 * @example ["body", "MY_THING", "modal"]
 */
const zIndexOrder = [
  "body",
  "image-banner",
  "cta-slider",
  "filterBar",
  "navigation",
  "backToTopButton",
  "header",
  "kindly-chat",
  "modal",
] as const;

type ZIndexValues = (typeof zIndexOrder)[number];
type ZIndexRecord = Record<ZIndexValues, number>;

/**
 * The base z-index value to start at 100 to keep in line with existing cookie banners ect.
 */
const BASE_Z_INDEX = 100;

const zIndexes = zIndexOrder.reduce(
  (acc: ZIndexRecord, current: ZIndexValues, index: number) => {
    acc[current] = index + BASE_Z_INDEX;
    return acc;
  },
  {} as ZIndexRecord
);

export default zIndexes;
