import zIndex from "@/react-app/styles/theme/zIndex";
import { getEnvVar } from "@/utils/environment-variables";
import { log } from "@xxl/logging-utils";
import { CustomWindowEvents } from "react-app/src/components/Common/util";
import {
  getCustomerKey as getCustomerKeyFromCookie,
  getSessionKey,
  isFunctionalCookieEnabled,
} from "react-app/src/utils/Cookie/XXLCookie";
import {
  type InjectedScript,
  injectScript,
} from "react-app/src/utils/xxl-load-script";

const getCustomerKey = (): string => {
  const key = getCustomerKeyFromCookie();
  if (key === null) {
    log.debug("customerKey is null");
    return getSessionKey();
  }
  return key;
};

const initKindlyChatbot = ({
  kindlyChatbotKey,
  shouldStartHidden,
}: {
  kindlyChatbotKey: string;
  shouldStartHidden: boolean;
}): void => {
  if (typeof window === "undefined") {
    return;
  }

  const scriptData: InjectedScript = {
    src: "https://chat.kindlycdn.com/kindly-chat.js",
    id: "kindly-chat",
    dataParams: [
      {
        name: "data-shadow-dom",
        value: "1",
      },
      {
        name: "data-bot-key",
        value: kindlyChatbotKey,
      },
    ],
    defer: true,
  };

  window.kindlyOptions = {
    zIndex: zIndex["kindly-chat"],
    context: {
      "ve:sessionKey": getSessionKey(),
      "ve:customerKey": getCustomerKey(),
    },
    position: { bottom: "74px" }, // default is 16px
    ...(shouldStartHidden && { bubbleHidden: true }),
  };

  window.addEventListener(
    CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
    () => {
      if (isFunctionalCookieEnabled()) {
        injectScript(scriptData);
      }
    }
  );
};

const isKindlyEnabledOnPdp = (
  productCategories: string[],
  toggle_kindly_pdp_on_category_codes: string
): boolean => {
  const kindlyCategories = toggle_kindly_pdp_on_category_codes
    .split(",")
    .map((item) => item.trim())
    .filter(Boolean);
  return (
    productCategories.find((categoryCode) =>
      kindlyCategories.includes(categoryCode)
    ) !== undefined
  );
};

const getProductChatApiKey = (): string =>
  getEnvVar("CONFIG_SITE_KINDLYCHATBOTKEY_PRODUCTCHAT", "");

export { getProductChatApiKey, initKindlyChatbot, isKindlyEnabledOnPdp };
