import { useMediaQuery } from "@mui/material";
import { resolutionSizes20 } from "../../config";

export const BREAKPOINTS = {
  mobile: "mobile",
  tablet: "tablet",
  laptop: "laptop",
  desktop: "desktop",
  bigDesktop: "bigDesktop",
} as const;

export const useBreakpoint = () => {
  const isMobile = useMediaQuery(
    `(max-width: ${resolutionSizes20.tablet - 1}px)`
  ); // max-width: 767
  const isTablet = useMediaQuery(
    `(max-width: ${resolutionSizes20.laptop - 1}px)`
  ); // max-width: 1024
  const isLaptop = useMediaQuery(
    `(max-width: ${resolutionSizes20.desktop - 1}px)`
  ); // max-width: 1279
  const isLaptopAndDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.laptop}px)`
  ); // min-width: 1024
  const isDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.desktop}px)`
  ); // min-width: 1280
  const isBigDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.bigDesktop}px)`
  ); // min-width: 1440

  if (isMobile) {
    return BREAKPOINTS.mobile;
  } else if (isTablet && !isLaptopAndDesktop) {
    return BREAKPOINTS.tablet;
  } else if (isLaptop && !isDesktop) {
    return BREAKPOINTS.laptop;
  } else if (isDesktop && !isBigDesktop) {
    return BREAKPOINTS.desktop;
  } else {
    return BREAKPOINTS.bigDesktop;
  }
};
