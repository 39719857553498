import React from "react";
import { isWalley, useCartContext } from "../CartState";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { setCartTitle } from "../Services/setCartTitle";
import { useMediaQuery } from "@mui/material";
import { mobileMediaQuery } from "../../../utils/xxl-screen";
import { CartProductsColumn, Title } from "../Styles/CartProducts.styled";
import { ProductsList } from "./ProductsList";
import { RewardBox } from "./RewardBox";
import { TotalPrice } from "./TotalPrice";
import type { CartContentProps } from "../CartContent";
import { TeamAdminBox } from "./TeamAdminBox";
import { useSession } from "../../../hooks/useSession";
import { PersonalOffers } from "./PersonalOffers";
import { Coupons } from "./Coupons";
import { GiftcardPayment } from "./GiftcardPayment";

export const CartProducts: React.FunctionComponent<CartContentProps> = ({
  isTeamAdmin,
  disableCheckoutCoupons,
}) => {
  const { state } = useCartContext();
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { t } = useTranslations();
  const productsLength =
    state.displayCart !== undefined ? state.displayCart.totalItems : 0;

  const cartTitle = setCartTitle(
    t(isMobile ? "cart.products.title.short" : "cart.products.title"),
    productsLength
  );
  return (
    <CartProductsColumn isLocked={state.isCartContentLocked}>
      <Title>{cartTitle}</Title>
      <ProductsList />
      {isTeamAdmin && <TeamAdminBox />}
      {!isLoggedIn && !isWalley(state.paymentProvider) && <RewardBox />}
      {isWalley(state.paymentProvider) && <GiftcardPayment />}
      {state.displayCart?.personalOffers !== undefined && <PersonalOffers />}
      {disableCheckoutCoupons === false && <Coupons />}
      <TotalPrice />
    </CartProductsColumn>
  );
};
