import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery/useXxlMediaQuery";
import type { Store as StoreDetails } from "react-app/src/utils/Stores/stores-helper";
import type { CncStockListItem } from "../helpers";
import { getPreferredStoreIds } from "../helpers";
import { StoreListDesktop } from "./StoreListDesktop";
import { StoreListMobile } from "./StoreListMobile";

type Props = {
  cncStockList: CncStockListItem[];
  handleStoreSelect: (id: string) => void;
  selectedStoreId?: string;
  selectedStoreDetails?: StoreDetails;
};

export const StoreListComponent = ({
  cncStockList,
  handleStoreSelect,
  selectedStoreId,
  selectedStoreDetails,
}: Props) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const preferredStoreIds = getPreferredStoreIds();
  const preferredStores = cncStockList.filter((store) =>
    preferredStoreIds.includes(store.storeId)
  );
  const remainingStores = cncStockList.filter(
    (store) => !preferredStoreIds.includes(store.storeId)
  );

  if (isMobile === true) {
    return (
      <StoreListMobile
        preferredStores={preferredStores}
        remainingStores={remainingStores}
        handleStoreSelect={handleStoreSelect}
        selectedStoreId={selectedStoreId}
        selectedStoreDetails={selectedStoreDetails}
      />
    );
  }

  return (
    <StoreListDesktop
      preferredStores={preferredStores}
      remainingStores={remainingStores}
      handleStoreSelect={handleStoreSelect}
      selectedStoreId={selectedStoreId}
    />
  );
};
