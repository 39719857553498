import { isNotNullOrUndefined } from "@xxl/common-utils";
import { Star } from "@xxl/icons";
import React from "react";
import { ExpandableDescription } from "react-app/src/components/Common/ExpandableDescription/ExpandableDescription";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import type { PriceDataV2 } from "react-app/src/components/Product/PriceWithLabels/PriceWithLabels";
import { PriceWithLabels } from "react-app/src/components/Product/PriceWithLabels/PriceWithLabels";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  RecommendationBanner,
  ServiceBrandName,
  ServiceDescription,
  ServiceElement,
  ServiceIconNameAndPrice,
  ServiceName,
} from "../ServiceProduct.styled";

export type ServiceProductDumbProps = {
  brandName: string;
  categoryCode: string;
  description: string;
  isRecommended: boolean;
  isSelected: boolean;
  name: string;
  priceData: PriceDataV2;
  PurchaseDetails: React.ReactNode;
};

export const ServiceProductDumb: React.FunctionComponent<
  ServiceProductDumbProps
> = ({
  brandName,
  description,
  isRecommended,
  isSelected,
  name,
  priceData,
  PurchaseDetails,
}) => {
  const { t } = useTranslations();

  return (
    <ServiceElement className={isSelected ? "is-selected" : undefined}>
      {isRecommended ? (
        <RecommendationBanner>
          {t("services.popup.recommended.service.label")}
          <Star />
        </RecommendationBanner>
      ) : null}
      <ServiceIconNameAndPrice>
        <XxlStack alignSelf={"baseline"}>
          <ServiceBrandName data-testid="service-brand-name">
            {brandName}
          </ServiceBrandName>
          <ServiceName data-testid="service-name">{name}</ServiceName>
        </XxlStack>
        <PriceWithLabels
          version={2}
          priceData={priceData}
          selectedColumnsNumber={6}
          isCompact={true}
          productType={"SERVICE"}
        />
      </ServiceIconNameAndPrice>
      {isNotNullOrUndefined(description) && (
        <ServiceDescription>
          <ExpandableDescription
            description={description}
            descriptionTestId="service-description"
            numberOfLinesToShow={3}
            showMoreButtonTestId="service-show-more-button"
          />
        </ServiceDescription>
      )}
      {PurchaseDetails}
    </ServiceElement>
  );
};
