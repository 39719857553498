import isArray from "lodash/isArray";
import { type FormError } from "../../components/Reward/SignUp/SignUpForm/SignUpFormHelper";
import type { TrackingEvent } from "../../global";
import type { GTMOrderConfirmationProps } from "./get-order-confirmation-event";
import type {
  AnalyticsContext,
  ProductTrackingData,
  TrackCheckoutProductsProp,
  TrackExchangeOrderEvent,
  TrackFavoriteProductProps,
  TrackFavoritesConfirmationClickProps,
  TrackFavoritesSharedLinkGetProps,
  TrackFilterSelectProps,
  TrackMultipackBundleListProps,
  TrackProductClickProps,
  TrackProductImpressionsProps,
  TrackProductListProps,
  TrackProductViewProps,
  TrackSortChangeProps,
} from "./types";

const EventNames = {
  ADD_ON_ADDED_TO_CART: "addOnAddedToCart",
  ADD_ON_INFORMATION_PDP: "addOnInformationPDP",
  ADD_ON_READ_MORE_CART: "addOnReadMoreCart",
  ADD_ON_REMOVED_CART: "addOnRemovedCart",
  ADD_ON_REMOVED_PDP: "addOnRemovedPDP",
  ADD_TO_CART: "addToCart",
  ADD_MULTIBUY_BUNDLE_TO_CART: "addMultipackBundleToCart",
  CHECKOUT: "checkout",
  CROSS_SALES_CLOSE: "crossSales.close",
  CROSS_SALES_CHECKOUT: "crossSales.checkout",
  ECOMMERCE_ADD_ON_CLOSE_ACCESSORY_CART: "ecommerce.addOnCloseAccessoryCart",
  ECOMMERCE_ADD_ON_CLOSE_PDP: "ecommerce.addOnClosePDP", //not used?
  ECOMMERCE_ADD_ON_CONTINUE_SHOPPING: "ecommerce.addOnContinueShopping",
  ECOMMERCE_ADD_ON_EDIT_CART: "ecommerce.addOnEditCart",
  ECOMMERCE_ADD_ON_GO_TO_CART: "ecommerce.addOnGoToCart", //not used?
  ECOMMERCE_ADD_ON_TRIGGERED: "ecommerce.addOnTriggered",
  ECOMMERCE_CLICK_GO_TO_CHECKOUT: "ecommerce.clickGoToCheckout",
  ECOMMERCE_CLOSE_MINI_CART: "ecommerce.closeMiniCart",
  PRODUCT_CLICK: "productClick",
  PRODUCT_CLICK_IN_CART: "productClickInCart",
  PRODUCT_IMPRESSIONS: "productImpressions",
  PRODUCT_VIEW: "productView",
  REMOVE_FROM_CART: "removeFromCart",
  SIGNUP_ERROR: "signupError",
  SIGNUP_SUCCESS: "signupSuccess",
  SELECT_FILTER: "selectFilter",
  SEARCH_PAGE_VIEW: "searchPageView",
  CAMPAIGN_PAGE_VIEW: "campaignPageView",
  SELECT_SORT: "selectSort",
  //favorites
  ADD_TO_FAVORITES: "addToFavorites",
  CLICK_FAVORITES_CONF_BANNER: "clickFavoritesConfirmationBanner",
  REMOVE_FROM_FAVORITES: "removeFromFavorites",
  SHARE_FAVORITES_LINK: "sharedFavoritesLinkGet",
  EXCHANGE_ORDER: "exchangeOrder",
  PURCHASE_ORDER: "purchase",
} as const;

type EventName = (typeof EventNames)[keyof typeof EventNames];

const pushGTMEvent = (event: TrackingEvent): void => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push(event);
  }
};

const setEnhancedConversionData = ({
  sha256_email_address,
}: GTMOrderConfirmationProps): void => {
  if (typeof window !== "undefined" && sha256_email_address !== undefined) {
    window.ecommerce = { sha256_email_address };
  }
};

const sendEvent = (event: EventName, ecommerce?: Record<string, unknown>) =>
  pushGTMEvent({ event, ecommerce });

class Analytics {
  constructor(private readonly context: AnalyticsContext) {}

  sendAddToCartEvent = ({ product, list }: TrackProductListProps): void => {
    const products = isArray(product) ? product : [product];

    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      add: {
        actionField: { list },
        products,
      },
    };
    sendEvent(EventNames.ADD_TO_CART, ecommerce);
  };

  sendAddMultipackBundleEvent = ({
    bundle,
    list,
  }: TrackMultipackBundleListProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      add: {
        actionField: { list },
        products: [bundle],
      },
    };
    sendEvent(EventNames.ADD_MULTIBUY_BUNDLE_TO_CART, ecommerce);
  };

  sendAddServiceToCartEvent = ({
    product,
    list,
  }: TrackProductListProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      add: {
        actionField: { list },
        products: [product],
      },
    };
    sendEvent(EventNames.ADD_ON_ADDED_TO_CART, ecommerce);
  };

  sendAddToFavoritesEvent = ({
    list,
    product,
  }: TrackFavoriteProductProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      pageType: this.context.pageType,
      add: {
        actionField: { list },
        products: [product],
      },
    };
    sendEvent(EventNames.ADD_TO_FAVORITES, ecommerce);
  };

  sendRemoveFromFavoritesEvent = ({
    list,
    product,
  }: TrackFavoriteProductProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      pageType: this.context.pageType,
      add: {
        actionField: { list },
        products: [product],
      },
    };
    sendEvent(EventNames.REMOVE_FROM_FAVORITES, ecommerce);
  };

  sendFavoritesConfirmationClickEvent = ({
    list,
  }: TrackFavoritesConfirmationClickProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      pageType: this.context.pageType,
      click: {
        actionField: { list },
      },
    };
    sendEvent(EventNames.CLICK_FAVORITES_CONF_BANNER, ecommerce);
  };

  sendSharedFavoritesLinkGetEvent = ({
    link,
    products,
    list,
  }: TrackFavoritesSharedLinkGetProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      pageType: this.context.pageType,
      click: {
        actionField: { list },
        products,
        link,
      },
    };
    sendEvent(EventNames.SHARE_FAVORITES_LINK, ecommerce);
  };

  sendProductImpressionsEvent = ({
    products: impressions,
  }: TrackProductImpressionsProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      impressions,
    };
    sendEvent(EventNames.PRODUCT_IMPRESSIONS, ecommerce);
  };

  sendCrossSalesCloseEvent = (): void => {
    sendEvent(EventNames.CROSS_SALES_CLOSE);
  };

  sendCrossSalesCheckoutEvent = (): void => {
    sendEvent(EventNames.CROSS_SALES_CHECKOUT);
  };

  sendAddOnContinueShoppingEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_ADD_ON_CONTINUE_SHOPPING);
  };

  sendAddOnEditCartEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_ADD_ON_EDIT_CART);
  };

  sendAddOnCloseAccessoryCartEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_ADD_ON_CLOSE_ACCESSORY_CART);
  };

  sendClickGoToCheckoutEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_CLICK_GO_TO_CHECKOUT);
  };

  sendCloseMiniCartEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_CLOSE_MINI_CART);
  };

  sendReadMoreServicePDPClickEvent = (label: string): void => {
    sendEvent(EventNames.ADD_ON_INFORMATION_PDP, { label });
  };

  sendReadMoreServiceCartClickEvent = (label: string): void => {
    sendEvent(EventNames.ADD_ON_READ_MORE_CART, { label });
  };

  sendOpenServicesPDPEvent = (): void => {
    sendEvent(EventNames.ECOMMERCE_ADD_ON_TRIGGERED);
  };

  sendProductClickInCartEvent = ({
    product,
    list,
  }: TrackProductListProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      click: {
        actionField: { list },
        products: [product],
      },
    };
    sendEvent(EventNames.PRODUCT_CLICK_IN_CART, ecommerce);
  };

  sendRemoveFromCartEvent = (product: ProductTrackingData): void => {
    const ecommerce = {
      remove: {
        products: [product],
      },
    };
    sendEvent(EventNames.REMOVE_FROM_CART, ecommerce);
  };

  sendRemoveServiceOnCartEvent = ({
    product,
    list: label,
  }: TrackProductListProps): void => {
    const ecommerce = {
      remove: {
        products: [product],
        label,
      },
    };
    sendEvent(EventNames.ADD_ON_REMOVED_CART, ecommerce);
  };

  sendRemoveServiceOnPDPEvent = (product: ProductTrackingData): void => {
    const ecommerce = {
      remove: {
        products: [product],
      },
    };
    sendEvent(EventNames.ADD_ON_REMOVED_PDP, ecommerce);
  };

  sendSignupSuccessEvent = (): void => {
    sendEvent(EventNames.SIGNUP_SUCCESS);
  };

  sendSignupErrorEvent = (errors: FormError[] = []): void => {
    errors.forEach(({ message }) =>
      pushGTMEvent({
        event: EventNames.SIGNUP_ERROR,
        message,
      })
    );
  };
  sendSelectFilterEvent = ({
    isFilterActive,
    filters,
  }: TrackFilterSelectProps): void => {
    const ecommerce = {
      [isFilterActive ? "add" : "remove"]: {
        filters,
      },
    };
    sendEvent(EventNames.SELECT_FILTER, ecommerce);
  };

  sendSearchPageViewEvent = (side: string): void => {
    pushGTMEvent({ event: EventNames.SEARCH_PAGE_VIEW, side });
  };

  sendCampaignPageViewEvent = (side: string): void => {
    pushGTMEvent({ event: EventNames.CAMPAIGN_PAGE_VIEW, side });
  };

  sendSortChangeEvent = ({
    category,
    searchQuery,
    value,
  }: TrackSortChangeProps): void => {
    const ecommerce = {
      sort: [
        {
          value,
          ...(category !== undefined && category !== "" && { category }),
          ...(searchQuery !== undefined &&
            searchQuery !== "" && { searchQuery }),
        },
      ],
    };
    sendEvent(EventNames.SELECT_SORT, ecommerce);
  };

  sendProductClickEvent = ({
    list,
    product,
    eventCallback,
  }: TrackProductClickProps): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      click: {
        actionField: { list },
        products: [product],
      },
    };

    pushGTMEvent({ event: EventNames.PRODUCT_CLICK, ecommerce, eventCallback });
  };

  sendCheckoutEvent = ({ step, products }: TrackCheckoutProductsProp): void => {
    const ecommerce = {
      currencyCode: this.context.siteCurrency,
      checkout: {
        actionField: { step },
        products,
      },
    };
    sendEvent(EventNames.CHECKOUT, ecommerce);
  };

  sendProductViewEvent = ({
    product,
    listName,
  }: TrackProductViewProps): void => {
    const ecommerce = {
      detail: {
        actionField: {
          optionalListValue: listName,
        },
        products: [product],
      },
    };
    sendEvent(EventNames.PRODUCT_VIEW, ecommerce);
  };

  sendExchangeOrderEvent = ({ products }: TrackExchangeOrderEvent): void => {
    const ecommerce = {
      detail: {
        products,
      },
    };
    sendEvent(EventNames.EXCHANGE_ORDER, ecommerce);
  };

  sendPurchaseOrderEvent = (gtmProps: GTMOrderConfirmationProps): void => {
    sendEvent(EventNames.PURCHASE_ORDER, gtmProps);
    setEnhancedConversionData(gtmProps);
  };
}

export { Analytics as GoogleAnalytics };
