import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import { getSiteUid } from "../environment-variables";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

const fetchTranslationsInternal = async (): Promise<NextJsTranslations> => {
  const {
    data: { result = [] },
  } = await Apis.getInstance().contentApi.getTranslations(getSiteUid(), "web");
  return result.reduce<NextJsTranslations>((acc, { key, value }) => {
    if (typeof key !== "string" || typeof value !== "string") {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const fetchTranslations = memoize(
  fetchTranslationsInternal,
  new Keyv({ namespace: KeyvCacheNamespace.NEXTJS_TRANSLATIONS }),
  KeyvDefaultTtl
);
