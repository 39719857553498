import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { isAxiosError } from "axios";
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  PreviewData,
} from "next";
import type { ParsedUrlQuery } from "querystring";
import { pagePaths, PageType } from "react-app/src/constants";
import type { FeatureToggles } from "react-app/src/global";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import type { ToggleCookie } from "../../components/XXLDynamicToggle/types";
import type { EnvironmentData, XXLAppData } from "../../global";
import {
  getEnvironmentData,
  getTranslationsContent,
} from "../../utils/common-content";
import type { GetServerSidePropsCallback } from "../apis/layout-props";
import { getLayoutPropsMemoized } from "../apis/layout-props";
import { setCookies } from "../apis/product-search/elevate/search-helper";
import { getDeviceTypeFromHeaders } from "../app-page-helper";
import { sanitizeErrorIfAxiosErrorForLog } from "../axios-error-logs";
import { isBot } from "../custom-headers";
import {
  getEnvironmentVariables,
  getEnvVar,
  getSiteUid,
  isLocal,
  isNotLocal,
  isTeamsales,
} from "../environment-variables";
import { getXXLToggleCookieFromRequest } from "../page-helper";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";
import { getSsmConfigParameters } from "../ssm-parameters";
import type { IncomingMessageWithCookies } from "../types";

interface SharedPageData {
  environmentData: Omit<EnvironmentData, "pageType">;
  ecoOnlineApiKey: string;
  ecoOnlineUrl: string;
  giosg: {
    giosgEnabled: boolean;
    giosgId: string;
  };
  headerCode: string;
  logRocketApiId: string;
  serverGtmScriptUrl: string;
  translations: NextJsTranslations;
}

interface PageData extends SharedPageData {
  environmentData: EnvironmentData;
  isBot: boolean;
}

export const getPageType = (pathName?: string): PageType => {
  const {
    category,
    product,
    checkout,
    orderConfirmationPage,
    brandIndex,
    storeFinder,
    guides,
    campaignHub,
    error,
    account,
    service,
  } = pagePaths;
  if (isNotNullOrUndefined(pathName)) {
    if (pathName.includes(category)) {
      return PageType.CATEGORY;
    }
    if (pathName.includes(product)) {
      return PageType.PRODUCT;
    }
    if (pathName.includes(checkout)) {
      return PageType.CHECKOUT;
    }
    if (pathName.includes(orderConfirmationPage)) {
      return PageType.ORDER_CONFIRMATION;
    }
    if (pathName.includes(brandIndex)) {
      return PageType.BRAND_INDEX;
    }
    if (pathName.includes(storeFinder)) {
      return PageType.STORE_FINDER;
    }
    if (guides.find((item) => pathName.includes(item)) !== undefined) {
      return PageType.GUIDES;
    }
    if (pathName.includes(campaignHub)) {
      if (pathName.split(campaignHub)[1].length === 0) {
        return PageType.CAMPAIGN_HUB;
      } else {
        return PageType.CAMPAIGN;
      }
    }
    if (pathName.includes(error)) {
      return PageType.ERROR;
    }
    if (pathName.includes(account)) {
      return PageType.ACCOUNT;
    }
    if (pathName.includes(service)) {
      return PageType.SERVICE;
    }
  }

  return PageType.HOME;
};

const getSharedPageData = async (): Promise<SharedPageData> => {
  const siteUid = getSiteUid();
  const environmentVariables = getEnvironmentVariables(process.env.ENV_SITE);
  const getTranslationsContentPromise = getTranslationsContent(
    environmentVariables.TOGGLE_TEAMSALES
  );

  const [translations, ssmParams] = await Promise.all([
    getTranslationsContentPromise,
    getSsmConfigParameters(siteUid),
  ]);

  const {
    cookieVersion,
    ecoOnlineApiKey,
    ecoOnlineUrl,
    giosg,
    logRocketApiId,
    serverGtmScriptUrl,
  } = ssmParams;

  const environmentData = await getEnvironmentData({
    cookieVersion,
    environmentVariables,
    isDevelopment: isLocal(),
    siteUid,
  });

  return {
    environmentData,
    ecoOnlineApiKey,
    ecoOnlineUrl,
    giosg,
    headerCode: environmentVariables.CONFIG_SITE_CONTENT_HEADERCODE,
    logRocketApiId,
    serverGtmScriptUrl,
    translations,
  };
};

const dynamicCookieToFeatureToggles = (
  dynamicToggles: ToggleCookie
): FeatureToggles =>
  Object.keys(dynamicToggles).reduce((toggles, name) => {
    return {
      ...toggles,
      ...{
        [name]: dynamicToggles[name][0] === "true",
      },
    };
  }, {} as FeatureToggles);

const getDynamicCookieFeatureToggles = (
  req: IncomingMessageWithCookies
): FeatureToggles | undefined => {
  const featureTogglesFromCookie =
    process.env.ENV_TYPE !== "prod" ? getXXLToggleCookieFromRequest(req) : null;
  const dynamicCookieFeatureToggles =
    featureTogglesFromCookie !== null
      ? dynamicCookieToFeatureToggles(featureTogglesFromCookie)
      : undefined;
  return dynamicCookieFeatureToggles;
};

const getPageData = (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  sharedData: SharedPageData
): PageData => {
  const { req } = context;
  const { headers, url } = req;
  const pageType: PageType = getPageType(url);

  if (typeof url !== "string") {
    throw TypeError(`Expected page url to be string, got ${typeof url}`);
  }

  return {
    ...sharedData,
    environmentData: {
      ...sharedData.environmentData,
      featureToggles: {
        ...sharedData.environmentData.featureToggles,
        ...getDynamicCookieFeatureToggles(req),
      },
      pageType,
    },
    isBot: isBot(headers),
  };
};

const getCommonData = memoize(
  getSharedPageData,
  new Keyv({ namespace: KeyvCacheNamespace.COMMON_CONTENT }),
  KeyvDefaultTtl
);

const withPageData =
  <T = undefined>(
    cb: GetServerSidePropsCallback<XXLAppData, T>,
    additionalData?: T | undefined
  ): GetServerSideProps<XXLAppData> =>
  async (context) => {
    try {
      const { req, res } = context;
      const { cookies } = req;
      const deviceType = getDeviceTypeFromHeaders(req.headers);

      const { customerKey, sessionKey } = await setCookies({
        cookies,
        req,
        res,
      });
      const commonData = await getCommonData();
      const pageData = getPageData(context, commonData);
      const layoutProps = await getLayoutPropsMemoized();

      return await cb(
        context,
        {
          ...pageData,
          campaignHubUrl: getEnvVar("REQUEST_MAPPING_CAMPAIGNHUBPAGE"),
          deviceType,
          isTeamsales: isTeamsales(),
          layoutProps,
        },
        {
          customerKey,
          sessionKey,
        },
        additionalData
      );
    } catch (error) {
      const message = "Unhandled error on page";
      log.error(
        message,
        ...sanitizeErrorIfAxiosErrorForLog({ error, full: true })
      );
      if (isAxiosError(error) && isNotLocal()) {
        throw Error(message);
      } else {
        throw error as Error;
      }
    }
  };

export { getCommonData, getDynamicCookieFeatureToggles, withPageData };
