import dynamic from "next/dynamic";
import type { ReactNode } from "react";

const ClientSideRenderingWrapper = dynamic(
  () => import("./ClientSideRenderingWrapper"),
  { ssr: false }
);

/**
 * Avoid hydration errors by next/dynamic ssr: false
 * https://nextjs.org/docs/messages/react-hydration-error#solution-2-disabling-ssr-on-specific-components
 */
export const CSR = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <ClientSideRenderingWrapper>{children}</ClientSideRenderingWrapper>
);
