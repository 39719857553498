import styled from "@emotion/styled/macro";
import { Text } from "react-app/src/components/Text";
import spaces from "@/react-app/styles/theme/spaces";
import { MQ } from "@/react-app/styles/helpers";

export const Container = styled(Text)<{
  inDrawer: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spaces.mini};
  margin-top: ${spaces.mini};

  ${({ inDrawer }) =>
    inDrawer
      ? ""
      : `${MQ("smallTablet")} {
    flex-direction: row;
    align-items: flex-end;
  }`};
`;
