import type { AxiosError } from "axios";
import { Formik } from "formik";
import type { MouseEvent } from "react";
import * as React from "react";
import { Button } from "../../../Common";
import {
  FormHeaderInModal,
  InputContainer,
  SignUpErrorMessage,
  SignUpFormInput,
  SignUpFormLabel,
} from "../../SignUp/SignUpForm/SignupForm.styled";
import {
  ForgotPasswordWrapper,
  FormContainer,
  RegisterContainer,
} from "./LoginForm.styled";
import { fields, initialValues, validationSchema } from "./LoginFormHelper";
import type { FormData as LoginFormData } from "./LoginFormHelper";
import { getClubName } from "../Login.helper";
import Axios from "axios";
import { isTeamsalesClubPage } from "../../../../utils/xxl-page-type";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import { useSharedData } from "../../../../contexts/SharedData";
import { ButtonStyledAsLink } from "../../../../styled";
import { XxlButton } from "../../../Common/XxlButton/XxlButton";
import type { UserDataProps } from "../Login";
import type { TranslationKey } from "../../../../translations";
import { isEmpty, isNullOrUndefined } from "@xxl/common-utils";

type LoginFormProps = {
  onClickForgotPassword: (event: MouseEvent) => void;
  onClickRegister: (event: MouseEvent) => void;
  onSuccessfulLogin: () => Promise<void>;
  handleDialogBoxClosing: () => void;
  goToOTP: () => void;
  onIncompleteAccount: ({
    email,
    password,
    firstName,
    lastName,
  }: UserDataProps) => void;
};

type ErrorInfo = {
  message?: TranslationKey;
  errorCode?: "INCOMPLETE_MEMBER_DATA" | TranslationKey;
  data: {
    firstName?: string;
    lastName?: string;
  };
};

type ErrorResponse = {
  errors: ErrorInfo[];
};

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  onClickForgotPassword,
  onClickRegister,
  onSuccessfulLogin,
  handleDialogBoxClosing,
  onIncompleteAccount,
  goToOTP,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isDataIncorrect, setIsDataIncorrect] = React.useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const { t } = useTranslations();
  const schema = validationSchema(t);
  const {
    isTeamsales,
    featureToggles: { toggle_otp_login },
  } = useSharedData().data;
  const OTPMode = isDataIncorrect && toggle_otp_login;

  const onSubmit = async (formData: LoginFormData) => {
    setErrorMessage(null);
    const postData = new FormData();
    const { username, password } = formData;
    postData.append("username", username);
    postData.append("password", password);
    if (isTeamsalesClubPage()) {
      postData.append("club", getClubName());
    }
    setisSubmitting(true);
    await Axios.post("/login", postData)
      .then(onSuccessfulLogin)
      .catch((error: AxiosError<ErrorResponse | undefined>) => {
        const { response } = error;
        if (
          isNullOrUndefined(response?.data) ||
          isEmpty(response.data.errors)
        ) {
          setErrorMessage(t("reward.signup.submit.error"));
          return;
        }
        const { message, errorCode, data } = response.data.errors[0];
        setIsDataIncorrect(true);
        if (errorCode === "INCOMPLETE_MEMBER_DATA") {
          onIncompleteAccount({
            email: formData.username,
            password: formData.password,
            firstName: data.firstName,
            lastName: data.lastName,
          });
        } else {
          const errorKey = errorCode ?? message ?? "reward.signup.submit.error";
          setErrorMessage(t(errorKey));
        }
      })
      .finally(() => {
        setisSubmitting(false);
      });
  };

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
      }}
      validationSchema={schema}
      validateOnChange={true}
      validateOnMount={true}
    >
      {({ errors, handleBlur, handleChange, values, isValid, touched }) => (
        <FormContainer inProgress={isSubmitting}>
          <DialogBoxHeaderWrapper>
            <FormHeaderInModal>{t("login.form.title")}</FormHeaderInModal>
            <DialogCloseButton
              handleDialogBoxClosing={handleDialogBoxClosing}
            />
          </DialogBoxHeaderWrapper>
          {errorMessage !== null && (
            <SignUpErrorMessage>
              <div>
                {errorMessage}
                {OTPMode && `. ${t("login.form.incorrect.data.error")}`}
              </div>
            </SignUpErrorMessage>
          )}
          {fields.map(
            ({
              autoCompleteToken,
              fieldName,
              inputMode,
              labelTranslationKey,
              placeholderTranslationKey,
              type,
            }) => (
              <InputContainer key={fieldName}>
                <SignUpFormLabel htmlFor={fieldName} required={true}>
                  {t(labelTranslationKey)}
                </SignUpFormLabel>
                <SignUpFormInput
                  id={fieldName}
                  inputMode={inputMode}
                  name={fieldName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t(placeholderTranslationKey)}
                  type={type}
                  value={values[fieldName]}
                  autoComplete={autoCompleteToken}
                />
                {fieldName === "username" && !isTeamsales && (
                  <ForgotPasswordWrapper style={{ marginBottom: 0 }}>
                    <ButtonStyledAsLink
                      type="button"
                      data-testid="otp-login-link"
                      onClick={() => {
                        goToOTP();
                      }}
                    >
                      {t("login.form.otp.button")}
                    </ButtonStyledAsLink>
                  </ForgotPasswordWrapper>
                )}

                {errors[fieldName] !== undefined && touched[fieldName] && (
                  <SignUpErrorMessage>{errors[fieldName]}</SignUpErrorMessage>
                )}
              </InputContainer>
            )
          )}
          <ForgotPasswordWrapper>
            <ButtonStyledAsLink
              type="button"
              data-testid="forgot-pw-link"
              onClick={onClickForgotPassword}
            >
              {t("login.form.forgot.password")}
            </ButtonStyledAsLink>
          </ForgotPasswordWrapper>

          <XxlButton
            data-testid="form-login-button"
            type="submit"
            variant="primary"
            size="small"
            loading={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              void onSubmit(values);
            }}
            disabled={!isValid}
          >
            {t("login.form.login.button")}
          </XxlButton>

          <RegisterContainer>
            <Button
              data-testid="create-new-user-button"
              onClick={onClickRegister}
              className="button button--small button--secondary button--outlined button--full-width"
            >
              {t("login.form.register.button")}
            </Button>
          </RegisterContainer>
        </FormContainer>
      )}
    </Formik>
  );
};
