import { resolutionSizes } from "./config";
import { color } from "@xxl/theme";
import "@emotion/react";

const themeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      sm: resolutionSizes.mobile,
      md: resolutionSizes.tablet,
      lg: resolutionSizes.laptop,
      xl: resolutionSizes.desktop,
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: "0",
        minHeight: "500px",
        minWidth: "320px",
      },
    },
  },
  palette: {
    primary: {
      main: color.green.hex,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  typography: {
    fontFamily: "XXLSans, sans-serif",
    letterSpacing: "-0.009375rem",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "XXLSans";
          src: url("../public/fonts/XXLSans-Regular.woff2") format("woff2");
          font-display: swap;
        }

        @font-face {
          font-family: "XXLSans medium";
          src: url("../public/fonts/XXLSans-Medium.woff2") format("woff2");
          font-display: swap;
        }

        @font-face {
          font-family: "XXLSans bold";
          src: url("../public/fonts/XXLSans-Bold.woff2") format("woff2");
          font-display: swap;
        }
      `,
    },
  },
};

export { themeConfig };
