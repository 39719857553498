import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text/Text";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import { Message } from "./Message";

export const AddToCartWarning = ({
  addToCartError,
}: {
  addToCartError: string;
}) => {
  return (
    <Message backgroundColor={colorsAsCssVariable.xxlWarning}>
      <Icon name="Info" />
      <Text typography="baseBold" data-testid="product-add-to-cart-error">
        {addToCartError}
      </Text>
    </Message>
  );
};
