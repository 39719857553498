import { isNotNullOrUndefined } from "@xxl/common-utils";
import { createContext, useContext } from "react";
import {
  type CustomerType,
  PaymentProvider,
} from "../../generated/graphql-code-generator";
import type { TrackOrderConfirmationData as CheckoutOrderData } from "../../utils/Tracking";
import { windowAccess } from "../../utils/Window";
import {
  CartController,
  UpdateCartTotalsController,
  getCartItemsCount,
} from "./Api/CartController";
import type {
  CartItem,
  CartPaymentTotalsData,
  CartResponseData,
  DisplayCart,
  PaymentProviderData,
  PersonalOffer,
} from "./Api/types";
import { DEFAULT_PAYMENT_PROVIDER } from "./constants";
import {
  type PriceUpdateItem,
  updateCartItemsWithNewPrices,
} from "./utils/updatePrices";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const CART_REQUEST_SUCCESS = "CART_REQUEST_SUCCESS";
export const UPDATE_CART_CONTENT = "UPDATE_CART_CONTENT";
export const CART_EDITION_START = "CART_EDITION_START";
export const CART_EDITION_STOP = "CART_EDITION_STOP";
export const LIST_HEIGHT_CHANGE = "LIST_HEIGHT_CHANGE";
export const HAS_MINI_CART = "HAS_MINI_CART";
export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT";
export const PRODUCT_NOT_ENOUGH_QUANTITY = "PRODUCT_NOT_ENOUGH_QUANTITY";
export const REMOVE_NOT_ENOUGH_QUANTITY = "REMOVE_NOT_ENOUGH_QUANTITY";
export const SET_CART_COUNT = "SET_CART_COUNT";
export const ENABLE_CART_API_REQUEST = "ENABLE_CART_API_REQUEST";
export const CART_CONTENT_EDITING_LOCK = "CART_CONTENT_EDITING_LOCK";
export const CART_CONTENT_EDITING_UNLOCK = "CART_CONTENT_EDITING_UNLOCK";
export const COLLECT_WARNING_DISPLAY = "COLLECT_WARNING_DISPLAY";
export const COLLECT_WARNING_CLEAR = "COLLECT_WARNING_CLEAR";
export const COLLECT_SUCCESS = "COLLECT_SUCCESS";
export const COLLECT_SUCCESS_CLEAR = "COLLECT_SUCCESS_CLEAR";
export const UPDATE_CART_PRICE_AND_OFFERS = "UPDATE_CART_PRICE_AND_OFFERS";
export const UPDATE_CART_ERRORS = "UPDATE_CART_ERRORS";
export const UPDATE_PAYMENT_PROVIDER = "UPDATE_PAYMENT_PROVIDER";
export const UPDATE_WALLEY_SNIPPET = "UPDATE_WALLEY_SNIPPET";
export const UPDATE_CUSTOMER_TYPE = "UPDATE_CUSTOMER_TYPE";
export const UPDATE_CUSTOMER_TYPE_OPTIONS = "UPDATE_CUSTOMER_TYPE_OPTIONS";
export const UPDATE_PAYMENT_VALUES = "UPDATE_PAYMENT_VALUES";
export const UPDATE_PAYMENT_SNIPPET_HTML = "UPDATE_PAYMENT_SNIPPET_HTML";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const UPDATE_SHIPMENT_COST = "UPDATE_SHIPMENT_COST";
export const UPDATE_CART_ID = "UPDATE_CART_ID";
export const SET_INITIAL_PAYMENT_PROVIDER = "SET_INITIAL_PAYMENT_PROVIDER";
export const SET_SHOULD_RELOAD_ON_ERROR = "SET_SHOULD_RELOAD_ON_ERROR";
export const UPDATE_CHECKOUT_ORDER_DATA = "UPDATE_CHECKOUT_ORDER_DATA";

type State = {
  hasMiniCart: boolean;
  isAddingToCart: boolean;
  isCartContentLocked: boolean;
  isCartEditing: boolean;
  isLoading: boolean;
  isOnlyMiniCartCount: boolean;
  listHeightChange: boolean;
  miniCartCounter: null | number;
  updateCartContent: boolean;
  updateShipmentCost: boolean;
  cart?: CartResponseData;
  displayCart?: DisplayCart;
  isCollectWarningDisplayed?: boolean;
  isCollectSuccess?: boolean;
  notEnoughQuantityEntryNumber?: number;
  paymentProvider?: PaymentProvider;
  customerType?: CustomerType;
  customerTypeOptions: CustomerType[];
  updatePaymentSnippet: boolean;
  paymentSnippetHtml?: string;
  isAddToCartSuccess?: boolean;
  cartId?: string;
  isTeamsales: boolean;
  shouldReloadOnError: boolean;
  checkoutOrderData?: CheckoutOrderData;
};

type StateStruct = {
  current: State;
  prev?: State;
};

export type Action =
  | {
      type: typeof CART_REQUEST_SUCCESS;
      payload: {
        data: CartResponseData;
      };
    }
  | {
      type: typeof UPDATE_CART_CONTENT;
    }
  | {
      type: typeof CART_EDITION_START;
    }
  | {
      type: typeof CART_EDITION_STOP;
    }
  | {
      type: typeof LIST_HEIGHT_CHANGE;
    }
  | {
      type: typeof HAS_MINI_CART;
    }
  | {
      type: typeof UPDATE_CART_COUNT;
    }
  | {
      type: typeof REMOVE_NOT_ENOUGH_QUANTITY;
    }
  | {
      type: typeof PRODUCT_NOT_ENOUGH_QUANTITY;
      payload: number;
    }
  | {
      type: typeof SET_CART_COUNT;
      payload: { count: number };
    }
  | {
      type: typeof ENABLE_CART_API_REQUEST;
    }
  | {
      type: typeof CART_CONTENT_EDITING_LOCK;
    }
  | {
      type: typeof CART_CONTENT_EDITING_UNLOCK;
    }
  | {
      type: typeof COLLECT_WARNING_DISPLAY;
    }
  | {
      type: typeof COLLECT_WARNING_CLEAR;
    }
  | {
      type: typeof COLLECT_SUCCESS;
    }
  | {
      type: typeof COLLECT_SUCCESS_CLEAR;
    }
  | {
      type: typeof UPDATE_CART_PRICE_AND_OFFERS;
      payload: {
        personalOffers: PersonalOffer[];
        totalDiscounts: string;
        cartDiscountTotals: NonNullable<
          NonNullable<CartResponseData["data"]>["cart"]
        >["totals"]["cartDiscountTotals"];
        hasDiscounts: boolean;
        totalAmount: number;
        totalPrice: string;
        totalAmountExcludingShipping: number;
        paymentTotalAmount: number;
        newPrices?: PriceUpdateItem[];
      };
    }
  | {
      type: typeof UPDATE_CART_ERRORS;
      payload: Pick<DisplayCart, "items" | "errors">;
    }
  | {
      type: typeof ADD_TO_CART;
    }
  | {
      type: typeof ADD_TO_CART_SUCCESS;
      payload: {
        cartId: string;
      };
    }
  | {
      type: typeof UPDATE_PAYMENT_PROVIDER;
      payload: PaymentProviderData;
    }
  | {
      type: typeof UPDATE_WALLEY_SNIPPET;
      payload: boolean;
    }
  | {
      type: typeof UPDATE_CUSTOMER_TYPE;
      payload: CustomerType;
    }
  | {
      type: typeof UPDATE_CART_ID;
      payload: string | undefined;
    }
  | {
      type: typeof UPDATE_CUSTOMER_TYPE_OPTIONS;
      payload: CustomerType[];
    }
  | {
      type: typeof UPDATE_PAYMENT_SNIPPET_HTML;
      payload: string;
    }
  | {
      type: typeof UPDATE_SHIPMENT_COST;
      payload: boolean;
    }
  | {
      type: typeof SET_INITIAL_PAYMENT_PROVIDER;
      payload: boolean;
    }
  | {
      type: typeof SET_SHOULD_RELOAD_ON_ERROR;
      payload: boolean;
    }
  | {
      type: typeof UPDATE_CART_ITEMS;
      payload: CartItem[];
    }
  | {
      type: typeof UPDATE_PAYMENT_VALUES;
      payload: CartPaymentTotalsData;
    }
  | {
      type: typeof UPDATE_CHECKOUT_ORDER_DATA;
      payload: CheckoutOrderData | undefined;
    };

type DefaultValue = {
  state: State;
  dispatch: (action: Action) => void;
};

const initialStaticState: State = {
  customerTypeOptions: [] as CustomerType[],
  hasMiniCart: false,
  isAddingToCart: false,
  isCartContentLocked: false,
  isCartEditing: false,
  isLoading: true,
  isOnlyMiniCartCount: true,
  listHeightChange: true,
  miniCartCounter: null,
  notEnoughQuantityEntryNumber: undefined,
  updateCartContent: true,
  updatePaymentSnippet: false,
  updateShipmentCost: false,
  isCollectSuccess: false,
  isAddToCartSuccess: false,
  isTeamsales: false,
  shouldReloadOnError: false,
  paymentProvider: DEFAULT_PAYMENT_PROVIDER,
} as const;

export const getInitialState = (isTeamsales?: boolean): StateStruct => {
  return {
    current: {
      ...initialStaticState,
      ...(isTeamsales !== undefined
        ? {
            isTeamsales,
          }
        : undefined),
    },
  };
};

const reducerInternal = (state: State, action: Action): State => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        isAddingToCart: true,
      };
    }
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        isAddingToCart: false,
        isCartEditing: false,
        isAddToCartSuccess: true,
        cartId: action.payload.cartId,
      };
    }
    case CART_REQUEST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        cart: data,
        isAddingToCart: false,
        isLoading: false,
        updateCartContent: false,
        displayCart: CartController(data),
        miniCartCounter: getCartItemsCount(data),
        isCollectWarningDisplayed: undefined,
      };
    }
    case UPDATE_PAYMENT_VALUES: {
      if (state.displayCart !== undefined) {
        return {
          ...state,
          displayCart: UpdateCartTotalsController(
            action.payload,
            state.displayCart
          ),
        };
      }
      return state;
    }
    case UPDATE_CART_CONTENT:
    case UPDATE_CART_COUNT:
      return {
        ...state,
        updateCartContent: true,
      };
    case CART_EDITION_START:
      return {
        ...state,
        isCartEditing: true,
      };
    case CART_EDITION_STOP:
      return {
        ...state,
        isAddingToCart: false,
        isCartEditing: false,
      };
    case COLLECT_SUCCESS:
      return {
        ...state,
        isCollectSuccess: true,
        isAddingToCart: false,
        isCartEditing: false,
      };
    case COLLECT_SUCCESS_CLEAR:
      return {
        ...state,
        isCollectSuccess: false,
        isAddToCartSuccess: false,
      };
    case LIST_HEIGHT_CHANGE:
      return {
        ...state,
        listHeightChange: !state.listHeightChange,
      };
    case HAS_MINI_CART:
      return {
        ...state,
        hasMiniCart: true,
      };
    case PRODUCT_NOT_ENOUGH_QUANTITY:
      return {
        ...state,
        notEnoughQuantityEntryNumber: action.payload,
      };
    case REMOVE_NOT_ENOUGH_QUANTITY:
      return {
        ...state,
        notEnoughQuantityEntryNumber: undefined,
      };
    case SET_CART_COUNT:
      return {
        ...state,
        miniCartCounter: action.payload.count,
      };
    case ENABLE_CART_API_REQUEST:
      return {
        ...state,
        isOnlyMiniCartCount: false,
      };
    case CART_CONTENT_EDITING_LOCK:
      return {
        ...state,
        isCartContentLocked: true,
      };
    case CART_CONTENT_EDITING_UNLOCK:
      return {
        ...state,
        isCartContentLocked: false,
      };
    case COLLECT_WARNING_DISPLAY:
      return {
        ...state,
        isCollectWarningDisplayed: true,
      };
    case COLLECT_WARNING_CLEAR:
      return {
        ...state,
        isCollectWarningDisplayed: undefined,
      };
    case UPDATE_PAYMENT_PROVIDER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_WALLEY_SNIPPET:
      return {
        ...state,
        updatePaymentSnippet: action.payload,
      };
    case UPDATE_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload,
      };
    case UPDATE_CUSTOMER_TYPE_OPTIONS:
      return {
        ...state,
        customerTypeOptions: action.payload,
      };
    case UPDATE_PAYMENT_SNIPPET_HTML: {
      const actionHTML = new DOMParser().parseFromString(
        action.payload,
        "text/html"
      );
      if (isNotNullOrUndefined(actionHTML)) {
        const actionScript = actionHTML.querySelector("script");
        if (isNotNullOrUndefined(actionScript)) {
          const { token } = actionScript.dataset;
          if (isNotNullOrUndefined(state.paymentSnippetHtml)) {
            const stateHTML = new DOMParser().parseFromString(
              state.paymentSnippetHtml,
              "text/html"
            );
            if (isNotNullOrUndefined(stateHTML)) {
              const stateScript = stateHTML.querySelector("script");
              if (isNotNullOrUndefined(stateScript)) {
                const { token: stateToken } = stateScript.dataset;
                if (token === stateToken) {
                  return state;
                }
              }
            }
          }
        }
      }
      return {
        ...state,
        updateShipmentCost:
          isNotNullOrUndefined(state.paymentSnippetHtml) &&
          state.paymentSnippetHtml !== action.payload,
        paymentSnippetHtml: action.payload,
      };
    }
    case UPDATE_CART_ITEMS: {
      if (isNotNullOrUndefined(state.displayCart)) {
        return {
          ...state,
          displayCart: {
            ...state.displayCart,
            items: action.payload,
          },
        };
      }
      return state;
    }
    case UPDATE_CART_ID:
      return {
        ...state,
        cartId: action.payload,
      };
    case UPDATE_SHIPMENT_COST:
      return {
        ...state,
        updateShipmentCost: action.payload,
      };
    case SET_INITIAL_PAYMENT_PROVIDER:
      return {
        ...state,
        paymentProvider: action.payload
          ? PaymentProvider.WALLEY
          : PaymentProvider.KLARNA,
      };
    case SET_SHOULD_RELOAD_ON_ERROR:
      return {
        ...state,
        shouldReloadOnError: action.payload,
      };
    case UPDATE_CART_PRICE_AND_OFFERS: {
      if (state.displayCart !== undefined) {
        return {
          ...state,
          displayCart: {
            ...state.displayCart,
            totalAmount: action.payload.totalAmount,
            totalPrice: action.payload.totalPrice,
            personalOffers: action.payload.personalOffers,
            totalDiscounts: action.payload.totalDiscounts,
            hasDiscounts: action.payload.hasDiscounts,
            totalAmountExcludingShipping:
              action.payload.totalAmountExcludingShipping,
            paymentTotalAmount: action.payload.paymentTotalAmount,
            ...(action.payload.newPrices !== undefined && {
              items: updateCartItemsWithNewPrices(
                state.cart?.data?.cart,
                action.payload.newPrices
              ),
            }),
          },
          ...(isNotNullOrUndefined(state.cart) && {
            cart: {
              ...state.cart,
              ...(isNotNullOrUndefined(state.cart.data) && {
                data: {
                  ...state.cart.data,
                  ...(isNotNullOrUndefined(state.cart.data.cart) && {
                    cart: {
                      ...state.cart.data.cart,
                      ...(isNotNullOrUndefined(state.cart.data.cart.totals) && {
                        totals: {
                          ...state.cart.data.cart.totals,
                          cartDiscountTotals: action.payload.cartDiscountTotals,
                        },
                      }),
                    },
                  }),
                },
              }),
            },
          }),
        };
      }
      return state;
    }
    case UPDATE_CART_ERRORS: {
      if (state.displayCart !== undefined) {
        return {
          ...state,
          displayCart: {
            ...state.displayCart,
            items: action.payload.items,
            errors: action.payload.errors,
          },
        };
      }
      return state;
    }
    case UPDATE_CHECKOUT_ORDER_DATA: {
      return {
        ...state,
        checkoutOrderData: action.payload,
      };
    }
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: reducerInternal(stateStruct.current, action),
  };
};

export const CartContext = createContext<DefaultValue>({
  dispatch: (): void => {
    return;
  },
  state: initialStaticState,
});

export const useCartContext = (): DefaultValue => useContext(CartContext);

export const isKlarnaCheckoutSnippetLoaded = (): boolean =>
  typeof windowAccess()._klarnaCheckout === "function";

export const isWalley = (
  paymentProvider: PaymentProvider | undefined
): boolean => paymentProvider === PaymentProvider.WALLEY;
