import {
  CartItemLoaderAnimation,
  CartItemLoaderWrapper,
  type CartItemLoaderWrapperProps,
} from "../Styles/CartItemLoader.styled";
import { ITEM_LOADER_TEST_ID } from "../constants";

interface CartItemLoaderProps extends CartItemLoaderWrapperProps {
  "aria-label"?: string;
}

export const CartItemLoader = ({
  isLoading,
  "aria-label": ariaLabel = "Loading",
}: CartItemLoaderProps) => {
  return (
    <CartItemLoaderWrapper
      isLoading={isLoading}
      role="status"
      aria-label={ariaLabel}
      aria-live="polite"
      data-testid={ITEM_LOADER_TEST_ID}
    >
      <CartItemLoaderAnimation>
        <div></div>
        <div></div>
        <div></div>
      </CartItemLoaderAnimation>
    </CartItemLoaderWrapper>
  );
};
