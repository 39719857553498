import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { XxlStack } from "@/react-components/Common/XxlStack";
import type { Store } from "react-app/src/utils/Stores/stores-helper";
import { Skeleton } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { StockStatus } from "@xxl/search-api";
import { useStockStatusTranslation } from "../../hooks/useStockStatusTranslation";
import { AvailabilityDisplay } from "../AvailabilityDisplay/AvailabilityDisplay";

type CncAvailabilityProps = {
  onBtnClick: () => void;
  stores: Store[];
  preferredStores?: Store[];
  storesWithCollectableStockCount: number;
  stockStatus: StockStatus;
};

const testId = "availability-pos";

export const CncExcludedFromClickAndCollect = () => {
  const { t } = useTranslations();
  return (
    <AvailabilityDisplay
      id={testId}
      stockStatus={null}
      text={t("product.details.clickcollect.service.not.available")}
    />
  );
};

export const CncAvailabilityInitialStockLevels = ({
  initPosStockStatus,
  onBtnClick,
}: {
  initPosStockStatus: StockStatus;
  onBtnClick: () => void;
}) => {
  const { t } = useTranslations();
  const translationKey =
    initPosStockStatus === "OUTOFSTOCK"
      ? "product.details.clickcollect.service.not.available"
      : (`product.details.storestock.${initPosStockStatus === "INSTOCK" ? "in.stock" : "low"}` as const);

  return (
    <AvailabilityDisplay
      id={testId}
      onBtnClick={onBtnClick}
      text={t(translationKey)}
      stockStatus={initPosStockStatus}
    />
  );
};

/**
 * Displays collectable stock status for a product.
 */
export const CncAvailability = ({
  onBtnClick,
  preferredStores,
  stores,
  storesWithCollectableStockCount,
  stockStatus,
}: CncAvailabilityProps) => {
  const { t } = useTranslations();
  const { storeFn } = useStockStatusTranslation();

  const getTranslation = (nrOfStores: number) => {
    if (nrOfStores > 1) {
      return t({
        key: "product.details.clickcollect.available.stores.other",
        messageArguments: [nrOfStores.toString()],
      });
    }
    if (nrOfStores === 1) {
      return t({
        key: "product.details.clickcollect.available.stores.one",
        messageArguments: [nrOfStores.toString()],
      });
    }
    return t("product.details.clickcollect.not.available");
  };

  if (stores.length === 0) {
    return (
      <XxlStack my={"12px"}>
        <Skeleton />
      </XxlStack>
    );
  }

  if (isNotNullOrUndefined(preferredStores) && preferredStores.length > 0) {
    const translation = storeFn({
      isMultiPreferredStores: preferredStores.length > 1,
      storeName: preferredStores[0].name,
      storesWithCollectableStockCount,
      highestCollectableStockStatus: stockStatus,
    });

    return (
      <AvailabilityDisplay
        id={testId}
        onBtnClick={
          storesWithCollectableStockCount > 0 ? onBtnClick : () => null
        }
        stockStatus={stockStatus}
        text={translation}
      />
    );
  }

  return (
    <AvailabilityDisplay
      id={testId}
      onBtnClick={storesWithCollectableStockCount > 0 ? onBtnClick : () => null}
      stockStatus={stockStatus}
      text={getTranslation(storesWithCollectableStockCount)}
    />
  );
};
