import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "@/react-app/styled";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { useState, type ChangeEvent, type ChangeEventHandler } from "react";
import { Dialog } from "../StoreStock/Dialog/Dialog";
import {
  BodyContainer,
  FormWrapper,
  StoreInput,
  StoreSelectionWrapper,
} from "../StoreStock/StoreStockDialog/StoreStockDialogStyles";
import type { CncStockListItem } from "../helpers";
import { getCurrentStoreIdFromCookie } from "../helpers";
import { useStoreDetailsQuery } from "../queries/useStoreDetailsQuery";
import { StoreInCartDialog } from "./StoreInCartDialog";
import { StoreListComponent } from "./StoreListComponent";
import { DialogBtn, DialogFooter } from "./styles";
import { StockLevels } from "../types";

const DEFAULT_FILTER_KEY = "";

const getCurrentStoreForPickup = (s: CncStockListItem[]) => {
  const storeId = getCurrentStoreIdFromCookie();
  return s.find((store) => store.storeId === storeId);
};

const filterCncStockList = ({
  cncStockList,
  filterKey = DEFAULT_FILTER_KEY,
  collectableStockOnly,
}: {
  cncStockList: CncStockListItem[];
  filterKey: string;
  collectableStockOnly: boolean;
}) => {
  if (filterKey === DEFAULT_FILTER_KEY && !collectableStockOnly) {
    return cncStockList;
  }
  return cncStockList.filter((store) => {
    const isNameMatch = store.name
      .toLocaleLowerCase()
      .includes(filterKey.toLocaleLowerCase());
    const isStockMatch = collectableStockOnly
      ? store.collectableStockStatus !== StockLevels.OUT_OF_STOCK
      : true;
    return isNameMatch && isStockMatch;
  });
};

type Props = {
  onAtcClick: (args: { storeId?: string }) => void;
  isDialogOpen: boolean;
  toggleDialog: () => void;
  cncStockList: CncStockListItem[];
};

export const CncDialog = ({
  onAtcClick,
  isDialogOpen,
  toggleDialog,
  cncStockList,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const [onlyShowStoresWithStock, setOnlyShowStoresWithStock] = useState(false);
  const [filterKey, setFilterKey] = useState(DEFAULT_FILTER_KEY);
  const [selectedStoreId, setSelectedStoreId] = useState<string>();
  const cartStoreId = getCurrentStoreForPickup(cncStockList)?.storeId;
  const storeForPickup = cncStockList.find((s) => s.storeId === cartStoreId);
  const selectedStore =
    cncStockList.find((s) => s.storeId === selectedStoreId) ?? storeForPickup;
  const { data: selectedStoreDetails } = useStoreDetailsQuery(
    selectedStore?.storeId
  );
  const filteredCncStockList = filterCncStockList({
    cncStockList,
    filterKey,
    collectableStockOnly: onlyShowStoresWithStock,
  });

  const cncBtnDisabledConditionsArray = [
    selectedStoreId === undefined,
    storeForPickup?.storeId !== undefined &&
      storeForPickup.storeId !== selectedStoreId,
    selectedStore?.collectableStockStatus === StockLevels.OUT_OF_STOCK,
  ];
  const isAddToCartBtnDisabled = cncBtnDisabledConditionsArray.some(Boolean);
  const shouldShowStoreInCartDialog =
    isDialogOpen && storeForPickup !== undefined;

  const handleStoreSelect = (id: string) => setSelectedStoreId(id);
  const handleFilterInStock = (event: ChangeEvent<HTMLInputElement>) =>
    setOnlyShowStoresWithStock(event.target.checked);

  const onFilterInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setFilterKey(target.value);
  };

  const handleAddToCartClick = (storeId?: string) => {
    if (storeId === undefined) {
      return;
    }
    onAtcClick({ storeId });
    toggleDialog();
  };

  return (
    <>
      <StoreInCartDialog
        isOpen={isDialogOpen && shouldShowStoreInCartDialog}
        onClose={toggleDialog}
        isMobile={isMobile}
        storeName={storeForPickup?.name ?? ""}
        onAtcClick={() => {
          onAtcClick({ storeId: storeForPickup?.storeId });
          toggleDialog();
        }}
        collectableStockLevel={
          storeForPickup?.collectableStockStatus ?? StockLevels.OUT_OF_STOCK
        }
        storeStockLevel={
          storeForPickup?.storeStockStatus ?? StockLevels.OUT_OF_STOCK
        }
      />

      <Dialog
        open={isDialogOpen && !shouldShowStoreInCartDialog}
        onClose={toggleDialog}
        heading={t("product.details.clickcollect.title")}
        fullScreen={isMobile}
      >
        <BodyContainer>
          <FormWrapper>
            <StoreInput
              data-testid="store-stock-search-input"
              onChange={onFilterInputChange}
              value={filterKey}
              disabled={false}
              placeholder={t("storeselect.search.placeholder")}
            />
            <StyledCheckbox
              testId="store-stock-show-instock"
              handleChange={handleFilterInStock}
              label={t("product.details.storestock.showall")}
            />
            <StoreSelectionWrapper>
              <StoreListComponent
                cncStockList={filteredCncStockList}
                handleStoreSelect={handleStoreSelect}
                selectedStoreId={selectedStoreId}
                selectedStoreDetails={selectedStoreDetails}
              />
            </StoreSelectionWrapper>
          </FormWrapper>
        </BodyContainer>
        <DialogFooter>
          <XxlStack
            direction={"row"}
            width={"100%"}
            gap={"12px"}
            justifyContent={"end"}
          >
            {!isMobile && (
              <DialogBtn
                size="large"
                variant="outlined"
                onClick={toggleDialog}
                data-testid="store-stock-action-btn"
              >
                {t("general.cancel")}
              </DialogBtn>
            )}
            <DialogBtn
              size="large"
              onClick={() => handleAddToCartClick(selectedStoreId)}
              disabled={isAddToCartBtnDisabled}
              data-testid="store-stock-action-btn"
            >
              {t("product.details.add.to.cart.label")}
            </DialogBtn>
          </XxlStack>
        </DialogFooter>
      </Dialog>
    </>
  );
};
