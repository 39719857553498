import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { PriceWrapper } from "react-app/src/components/Cart/Styles/ProductItem.styled";
import {
  Description,
  ReadDescriptionButton as ExpandButton,
} from "react-app/src/components/Common/ExpandableDescription/ExpandableDescription.styled";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors, spaces, typography } = xxlTheme;

const serviceProductCardInnerPadding = spaces.smallRegular;
const serviceProductCardIconSize = spaces.smallLarge;

export const ServiceElement = styled.li`
  border: 1px solid ${colors.xxlLightGrey};
  display: flex;
  flex-direction: column;
  padding-bottom: ${serviceProductCardInnerPadding};
  &.is-selected,
  &:has(input[type="checkbox"]:checked) {
    border-color: ${colors.xxlGreen};
  }

  ${() => {
    if ("next" in window) {
      // https://xxlsports.atlassian.net/browse/XD-11583
      return "";
    }
    return css`
      ${Description} {
        margin: ${serviceProductCardInnerPadding};
        margin-top: 0;
      }

      ${ExpandButton} {
        margin: 0 auto 0 ${serviceProductCardInnerPadding};
      }
    `;
  }}
`;

export const RecommendationBanner = styled.div`
  ${typographyToCss(typography.smallBold)};
  background-color: ${colors.xxlWebGrey};
  color: ${colors.xxlWhite};
  height: ${spaces.biggerBig};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${serviceProductCardInnerPadding};

  & svg {
    font-size: ${serviceProductCardIconSize};
    color: ${colors.xxlGreen};
  }
`;

export const ServiceIconNameAndPrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.micro};
  color: ${colors.xxlWebBlack};
  padding: ${serviceProductCardInnerPadding};
  justify-content: space-between;

  ${() => {
    if ("next" in window) {
      // https://xxlsports.atlassian.net/browse/XD-11583
      return "";
    }
    return css`
      ${PriceWrapper} {
        margin-left: auto;
        margin-bottom: -${spaces.mini};
      }
    `;
  }}
`;

export const ServiceBrandName = styled.span`
  ${typographyToCss(typography.smallBold)};
`;

export const ServiceName = styled.span`
  ${typographyToCss(typography.smallBold)};
`;

export const ServiceDescription = styled.div`
  padding: 0 ${spaces.smallRegular} ${spaces.smallRegular};
`;

export const PurchaseDetails = styled.section`
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
`;
