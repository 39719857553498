import React from "react";
import { Li, Ul } from "./styles";
import type { TranslationKey } from "@/react-app/translations";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { Icon } from "@/react-components/Icon";
import { Text } from "@/react-components/Text";

type Props = {
  brandName: string;
  code: string;
  name: string;
  price: string;
  serviceTextList: TranslationKey[];
  children?: React.ReactNode;
};

export const ServiceProductsListItem = ({
  brandName,
  code,
  name,
  price,
  serviceTextList,
  children,
}: Props) => {
  const { t } = useTranslations();

  return (
    <>
      <XxlStack
        direction="row"
        justifyContent="space-between"
        p={xxlTheme.spaces.smallRegular}
        bgcolor={xxlTheme.colors.xxlWebGrey}
      >
        <Text size="small" typography="baseMedium" color="white">
          {t("services.popup.recommended.service.label")}
        </Text>
        <Icon name="Star" color="green" />
      </XxlStack>
      <XxlStack
        data-name={name}
        data-id={code}
        data-brand={brandName}
        data-price={price}
        data-variant={code}
        bgcolor={xxlTheme.colors.xxlLightGreen}
        px={xxlTheme.spaces.mini}
        py={xxlTheme.spaces.large}
      >
        <XxlStack alignItems={"center"} gap={"6px"}>
          <Text size="small" typography="baseMedium">
            {name}
          </Text>
          <Text sizeCustom={40} typography="baseBold">
            {price}
          </Text>
        </XxlStack>
        <XxlStack gap={"6px"} pt={"24px"} pb={"32px"}>
          <Text typography="baseMedium">
            {t("services.popup.usp.heading")}:
          </Text>
          <Ul>
            {serviceTextList.map((item, index) => (
              <Li key={`${index}-${item}`}>
                <Icon name="Check" />
                <Text size="small">{t(item)}</Text>
              </Li>
            ))}
          </Ul>
        </XxlStack>
        {children}
      </XxlStack>
    </>
  );
};
