import * as React from "react";
import {
  colorNameToColorTheme,
  type ColorTheme,
  getBackgroundColorFromColorTheme,
  getForegroundColorFromColorTheme,
} from "../product-helper";
import { Label, RibbonContent, RibbonWrapper } from "./NewRibbon.styled";

export type RibbonDirection = "left" | "right";

type BaseProps = {
  label?: string;
  direction?: RibbonDirection;
  className?: string;
  dataTestid?: string;
};

type WithColorTheme = BaseProps & {
  variant: "WithColorTheme";
  colorTheme?: ColorTheme;
};

type WithColorName = BaseProps & {
  variant: "WithColorName";
  colorName?: string;
};
type NewRibbonProps = WithColorName | WithColorTheme;

const NewRibbon = (props: NewRibbonProps) => {
  const { label, className = "", direction, dataTestid = "new-ribbon" } = props;

  const foregroundColor =
    props.variant === "WithColorTheme"
      ? getForegroundColorFromColorTheme(props.colorTheme ?? {})
      : colorNameToColorTheme(props.colorName ?? "").textColor;
  const backgroundColor =
    props.variant === "WithColorTheme"
      ? getBackgroundColorFromColorTheme(props.colorTheme ?? {})
      : colorNameToColorTheme(props.colorName ?? "").backgroundColor;

  if (label === undefined) {
    return null;
  }

  return (
    <RibbonWrapper
      backgroundColor={backgroundColor}
      direction={direction}
      className={className}
      data-testid={dataTestid}
    >
      <RibbonContent
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
        direction={direction}
      >
        <Label>{label}</Label>
      </RibbonContent>
    </RibbonWrapper>
  );
};

export { NewRibbon };
