import { hasNoValue, hasValue } from "@xxl/common-utils";
import { PriceType } from "@xxl/product-search-api";
import React from "react";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { ProductType } from "../../../utils/data-types";
import type { PriceData } from "../../../utils/ProductData/product-card-data-helper";
import { OtherPrice } from "../../PriceDisplays/PriceDisplays.styled";
import {
  CurrentPrice,
  OriginalPrice,
  PriceWrapper,
  SalesPriceDisclaimer,
} from "../Product.styled";

type PriceWithLabelsV2Props = {
  selectedColumnsNumber: number;
  priceData: {
    selling: PriceData;
    alternate?: PriceData;
    cheapest?: PriceData;
    type?: PriceType;
  };
  productType: ProductType;
  isMultiPackProduct?: boolean;
  productCode?: string;
};

const getLabel = (priceData: PriceData, t: Translate) => {
  const { labelType } = priceData;

  if (labelType === "TranslatedLabel") {
    return priceData.label;
  }

  if (labelType === "TranslationKey") {
    return t(priceData.translationKey);
  }

  return undefined;
};

const getSellingPriceLabel = ({
  priceData,
  translations,
}: {
  priceData: PriceWithLabelsV2Props["priceData"];
  translations: Translate;
}) => {
  const isRewardsProduct = priceData.type === PriceType.REWARD;
  const isCampaignProduct = priceData.type === PriceType.CAMPAIGN;
  const sellingPriceDisclaimer = getLabel(priceData.selling, translations);

  const shouldShowSellingPriceDisclaimer =
    (!isRewardsProduct && !isCampaignProduct) ||
    //The condition below may not be necessary after the migration from Sanity campaigns.
    (isCampaignProduct &&
      hasValue(sellingPriceDisclaimer) &&
      hasNoValue(priceData.cheapest));

  if (shouldShowSellingPriceDisclaimer) {
    return sellingPriceDisclaimer;
  }
  return undefined;
};

const PriceWithLabelsV2 = ({
  selectedColumnsNumber,
  priceData,
  productType,
}: PriceWithLabelsV2Props) => {
  const { t: translations } = useTranslations();

  const { alternate, selling } = priceData;
  const hasUndeductedRewardDiscount =
    hasValue(alternate) && alternate.value < selling.value;
  const hasDiscountedSellingPrice =
    hasValue(alternate) && alternate.value > selling.value;

  const alternateLabel = hasValue(alternate)
    ? getLabel(alternate, translations)
    : undefined;

  const sellingPriceLabel = getSellingPriceLabel({
    priceData,
    translations,
  });

  return (
    <PriceWrapper data-testid="new-product-card-price-display">
      <CurrentPrice
        data-testid="current-price"
        columnAmount={selectedColumnsNumber}
        isDiscountPrice={hasDiscountedSellingPrice}
      >
        {selling.valueFormatted}
      </CurrentPrice>
      {productType === "SERVICE" ? null : (
        <OriginalPrice columnAmount={selectedColumnsNumber}>
          {hasValue(alternate) ? (
            <>
              {hasValue(alternateLabel) && <span>{alternateLabel}</span>}
              <OtherPrice
                hasLowerProductConfiguratorPriceThanSalesPrice={
                  false // TODO: https://xxlsports.atlassian.net/browse/XD-16321
                }
                hasUndeductedRewardDiscount={hasUndeductedRewardDiscount}
                isInvertedPrice={hasUndeductedRewardDiscount}
              >
                {alternate.valueFormatted}
              </OtherPrice>
            </>
          ) : null}
        </OriginalPrice>
      )}

      {hasValue(sellingPriceLabel) ? (
        <SalesPriceDisclaimer title={sellingPriceLabel}>
          {sellingPriceLabel}
        </SalesPriceDisclaimer>
      ) : (
        <SalesPriceDisclaimer />
      )}
    </PriceWrapper>
  );
};

export { PriceWithLabelsV2 };
