import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { type DeviceProps } from "@/react-app/global";
import spaces from "@/react-app/styles/theme/spaces";
import { Skeleton, SkeletonWrapper } from "@/react-components/Common";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text/Text";
import type { Store as StoreWithId } from "react-app/src/utils/Stores/stores-helper";
import { StoreFinderLink } from "./StoreFinderLink";
import { StoreStockSelectedDetailsMapLink } from "./StoreStockMapLink";
import { StoreStockOpeningHours } from "./StoreStockOpeningHours";

type Props = DeviceProps & {
  store?: StoreWithId;
  isLoading?: boolean;
};

const StoreStockSelectedDetailsSection = ({
  store,
  isMobile,
  isLoading = false,
}: Props) => {
  const { t } = useTranslations();
  const internalIsLoading = isLoading || store === undefined;

  if (internalIsLoading) {
    return (
      <XxlStack width={"40%"}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </XxlStack>
    );
  }

  return (
    <XxlStack gap={"12px"}>
      <SkeletonWrapper isLoading={internalIsLoading}>
        <StoreStockSelectedDetailsMapLink
          selectedStoreDetails={store}
          isMobile={isMobile}
        >
          <XxlStack direction={"row"} gap={spaces.micro} alignItems={"center"}>
            <Icon
              name="Link"
              size={12}
              transform={{ translateX: 0, translateY: 1 }}
            />
            <Text typography="baseMedium">
              {t("product.details.storestock.status.google.maps.link")}
            </Text>
          </XxlStack>
        </StoreStockSelectedDetailsMapLink>
        <StoreStockOpeningHours store={store} />
        <StoreFinderLink store={store} />
      </SkeletonWrapper>
    </XxlStack>
  );
};

export { StoreStockSelectedDetailsSection };
