import { hasValue } from "@xxl/common-utils";
import type { BaseProductData } from "@xxl/product-search-api";

const hasObjectValues = (item: object) => Object.values(item).every(hasValue);

const uniqueByArticleNumber = (
  accumulator: BaseProductData[],
  currentValue: BaseProductData
) => {
  if (
    accumulator.some(
      ({ articleNumber }) => articleNumber === currentValue.articleNumber
    )
  ) {
    return accumulator;
  }
  return [...accumulator, currentValue];
};

export { hasObjectValues, uniqueByArticleNumber };
