import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ImageLoaderProps } from "next/image";
import { IMAGE_BASE_SOURCE_URL } from "react-app/src/constants";

const IMAGE_QUALITY_DEFAULT = 75;
const IMAGE_PADDING_DEFAULT = "0";
const BACKGROUND_COLOR = "#efefef";

type HotSpot = { x: number; y: number };

const sanitizeBaseUrl = (s: string) => s.split("?")[0]; // Removes any query params from the baseUrl before adding the new params
const isFilespinImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.filespin);
const isSanityImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.sanity);
const hasFocalpoint = (hotspot: HotSpot | undefined) =>
  isNotNullOrUndefined(hotspot) &&
  isNotNullOrUndefined(hotspot.x) &&
  isNotNullOrUndefined(hotspot.y);

const addFocalpointParameters = (
  focalPoints: HotSpot,
  params: URLSearchParams
): void => {
  const focalParams = {
    fit: "crop",
    crop: "focalpoint",
    "fp-x": focalPoints.x.toString(),
    "fp-y": focalPoints.y.toString(),
  };

  Object.entries(focalParams).forEach(([key, value]) => params.set(key, value));
};

export type XXLImageLoaderProps = {
  backgroundColor?: string;
  hotspot?: HotSpot;
  height?: number;
  width?: number;
  getHeight?: (width: number) => number | undefined;
  padding?: number;
  trim?: number; // 0-10
};

const getGetHeight = ({
  suppliedGetHeight,
  containerHeight,
  containerWidth,
}: {
  suppliedGetHeight: XXLImageLoaderProps["getHeight"];
  containerHeight: XXLImageLoaderProps["height"];
  containerWidth: XXLImageLoaderProps["width"];
}): Required<XXLImageLoaderProps>["getHeight"] => {
  if (suppliedGetHeight !== undefined) {
    return suppliedGetHeight;
  }
  if (containerHeight !== undefined && containerWidth !== undefined) {
    return (srcWidth: number) =>
      Math.round((srcWidth * containerHeight) / containerWidth);
  }
  return () => undefined;
};

export const imageLoader =
  ({
    hotspot,
    backgroundColor = BACKGROUND_COLOR,
    getHeight: suppliedGetHeight,
    height: containerHeight,
    width: containerWidth,
    padding,
    trim,
  }: XXLImageLoaderProps) =>
  ({
    quality = IMAGE_QUALITY_DEFAULT,
    src,
    width,
  }: ImageLoaderProps): string => {
    const qualityParam = quality.toString();
    const params = new URLSearchParams({
      quality: qualityParam,
      ...(backgroundColor !== "transparent" && {
        bgcolor: backgroundColor.replace("#", ""),
      }),
      pad: padding !== undefined ? padding.toString() : IMAGE_PADDING_DEFAULT,
    });
    const getHeight = getGetHeight({
      suppliedGetHeight,
      containerHeight,
      containerWidth,
    });

    if (isSanityImageUrl(src)) {
      params.set("auto", "format");
      params.set("fit", "fill");
      const h = getHeight(width);
      if (h !== undefined) {
        params.set("h", h.toString());
      }
      params.set("w", width.toString());

      // Only FileSPin uses quality, Sanity uses q
      params.delete("quality");
      params.set("q", qualityParam);
    }

    if (isFilespinImageUrl(src)) {
      params.set("resize", `${width},${getHeight(width) ?? width}`);

      if (isNotNullOrUndefined(trim)) {
        params.set("trim", `${trim}`);
      }
    }

    if (params.get("pad") === IMAGE_PADDING_DEFAULT) {
      params.delete("pad");
    }

    if (hotspot !== undefined && hasFocalpoint(hotspot)) {
      addFocalpointParameters(hotspot, params);
    }

    return `${sanitizeBaseUrl(src)}?${params.toString()}`;
  };
