import { QUERY_KEYS } from "@/react-app/constants";
import { requestUserCoordinates } from "@/react-utils/xxl-geolocation";
import { useQuery } from "@tanstack/react-query";
import { sortDataAlphabetically, sortDataByGeolocation } from "../StoreStock";
import { useStoresQuery } from "./useStoresQuery";

export const useSortedStoresQuery = () => {
  const { data: storesData } = useStoresQuery();
  const sortStores = async () => {
    if (storesData === undefined) {
      return Promise.reject([]);
    }
    try {
      const userCoordinates = await requestUserCoordinates();
      return sortDataByGeolocation(storesData, userCoordinates);
    } catch (_error) {
      return Promise.resolve(sortDataAlphabetically(storesData));
    }
  };

  return useQuery({
    queryKey: [QUERY_KEYS.STORES_SORTED],
    queryFn: sortStores,
    enabled: storesData !== undefined && storesData.length > 0,
    staleTime: Infinity,
  });
};
