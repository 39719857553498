import { useMediaQuery } from "@mui/material";
import { Info } from "@xxl/icons";
import type { ButtonHTMLAttributes, ReactNode } from "react";
import { useState } from "react";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { laptopMediaQuery } from "../../utils/xxl-screen";
import { XxlButton } from "../Common/XxlButton";
import { DialogBox } from "../DialogBox";
import { Drawer } from "../Drawer";
import { DisclaimerInfoHeader } from "./Components/Header";
import { Title } from "./Components/HeaderTitle";
import MainContent from "./Components/MainContent";
import { DisclaimerInfoButton, Footer } from "./DisclaimerInformation.styled";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

const { spaces } = xxlTheme;

type DisclaimerInformationProps = {
  color: string;
  buttonText: string;
  iconSize?: number;
  title: string;
  children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type DisclaimerInformation = {
  buttonColor: string;
  description: string;
};

/**
 * @description React component with button and pop-up to be used inside banners and carousels
 **/
export const DisclaimerInformation = ({
  color,
  buttonText,
  iconSize = 18,
  title,
  children,
  ...buttonProps
}: DisclaimerInformationProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const { t } = useTranslations();

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <DisclaimerInfoButton
        onClick={onOpen}
        aria-label={t("disclaimer.info.button")}
        {...buttonProps}
      >
        <Info fontSize={iconSize} color={color} />
      </DisclaimerInfoButton>

      {!isLaptopSize ? (
        <Drawer
          heading={<Title title={title} />}
          onClose={onClose}
          open={isOpen}
          closeButtonText="Close"
          customPadding={`0 ${spaces.smallRegular}`}
          closeButton={false}
        >
          <MainContent>{children}</MainContent>
          <footer>
            <XxlButton
              variant="secondary"
              onClick={onClose}
              style={{
                marginBottom: spaces.smallRegular,
                marginTop: spaces.big,
              }}
            >
              {buttonText}
            </XxlButton>
          </footer>
        </Drawer>
      ) : (
        <DialogBox
          isDialogBoxOpen={isOpen}
          handleDialogBoxClosing={onClose}
          dialogBoxSize="sm"
        >
          <DisclaimerInfoHeader title={title} onClose={onClose} />
          <MainContent>{children}</MainContent>
          <Footer>
            <XxlButton
              variant="secondary"
              onClick={onClose}
              style={{
                width: "122px",
                marginTop: spaces.big,
                height: "50px",
              }}
            >
              {buttonText}
            </XxlButton>
          </Footer>
        </DialogBox>
      )}
    </>
  );
};
