import type { StockStatusEnum } from "../../../enums";
import * as React from "react";
import { OptionNodeStockStatus } from "./OptionNodeStockStatus";
import { SizeLabel } from "./SizeLabel";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

type OptionNodeProps = {
  itemName: string;
  productCode?: string;
  productEan?: string;
  stockStatus?: StockStatusEnum;
};

export const OptionNode: React.FC<OptionNodeProps> = ({
  itemName = "",
  productCode,
  productEan,
  stockStatus,
}) => {
  const { t } = useTranslations();
  return (
    <>
      <span
        data-select-name={itemName}
        data-product-code={productCode}
        data-product-ean={productEan}
      >
        <SizeLabel size={itemName} ean={productEan ?? null} t={t} />
      </span>
      <OptionNodeStockStatus stockStatus={stockStatus} />
    </>
  );
};
