import React from "react";
import { useClient } from "../../hooks/useClient/useClient";
import { Snowfall } from "react-snowfall";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";

const svgHeart =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCI+PHBhdGggZD0iTTUwIDgwczMwLTIwIDMwLTQwYzAtMTEtOS0yMC0yMC0yMC04IDAtMTUgNS0xOCAxMi0zLTctMTAtMTItMTgtMTItMTEgMC0yMCA5LTIwIDIwIDAgMjAgMzAgNDAgMzAgNDB6IiBmaWxsPSJyZWQiLz48L3N2Zz4=";

const Snow = () => {
  const isClient = useClient();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isTablet = useXxlMediaQuery("MobileAndTabletMediaQuery");
  const now = new Date();
  const isValentine = now.getMonth() === 1 && now.getDate() === 14;

  const images = [];
  if (isClient && isValentine) {
    const heart = document.createElement("img");
    heart.src = svgHeart;
    images.push(heart);
  }

  return isClient ? (
    isValentine ? (
      <Snowfall
        radius={[10, 20]}
        snowflakeCount={isMobile ? 8 : isTablet ? 16 : 24}
        speed={[0.0001, 0.001]}
        wind={[-0.01, 0.01]}
        images={images}
      />
    ) : (
      <Snowfall
        radius={[0.1, 0.6]}
        snowflakeCount={isMobile ? 50 : isTablet ? 75 : 100}
        speed={[0.01, 0.03]}
        wind={[-0.5, -0.3]}
      />
    )
  ) : null;
};

export default React.memo(Snow);
