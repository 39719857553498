import styled from "@emotion/styled/macro";
import Image from "next/image";
import { Text } from "react-app/src/components/Text";
import { resolutionSizes } from "react-app/src/config";
import { MQ, outlineForTabs } from "react-app/src/styles/helpers";
import { containerMaxWidth, xxlTheme } from "react-app/src/styles/xxl-theme";

const { spaces, colors } = xxlTheme;

export const FooterWrapper = styled.footer`
  overflow: hidden;
  background-color: ${colors.xxlWebBlack};
  color: ${colors.xxlWhite};
`;

export const FooterColumnHeading = styled(Text)`
  margin: 0 0 14px;
  padding: 0;
  font-size: 20px;
  text-transform: none;
  color: ${colors.xxlWhite};

  ${MQ("tablet")} {
    margin-bottom: ${spaces.smallRegular};
    font-size: 34px;
  }
`;

export const InnerFooter = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spaces.regular} ${spaces.smallRegular};
  align-items: start;
  padding: 34px 0;
  text-align: left;
  width: 100%;
  max-width: calc(100% - 24px);
  margin-right: auto;
  margin-left: auto;

  ${MQ("tablet")} {
    grid-template-columns: repeat(12, 1fr);
    gap: ${spaces.smallRegular};
    margin-top: 54px;
    margin-bottom: 54px;
    padding: 0;
    row-gap: ${spaces.smallRegular};
    max-width: ${containerMaxWidth}px;
  }
`;

export const HeadingWrapper = styled.figure`
  grid-column: 1;
  margin: 0 0 ${spaces.regular};

  ${MQ("tablet")} {
    grid-column: 1 / span 6;
    margin: calc(-1 * ${spaces.smallRegular}) 0 ${spaces.giant}; // Minus top to compensate for space above text
  }
`;

export const Heading = styled(Image)`
  width: 250px;

  ${MQ("tablet")} {
    width: 300px;
  }
  ${MQ("laptop")} {
    width: unset;
  }
`;

export const ToTop = styled.a`
  color: ${colors.xxlLightGrey};
  font-size: 10px;
  display: flex;
  flex-direction: column;
  grid-row: 5;
  grid-column: 2;
  align-items: center;
  align-self: flex-end;

  &:hover {
    color: ${colors.xxlGreen};
  }
  &:focus-visible {
    color: ${colors.xxlGreen};
    outline: 0;
  }
  &:focus-visible svg {
    color: ${colors.xxlGreen};
    ${outlineForTabs("1px", colors.xxlGreen, "5px")};
  }

  svg,
  [data-inline-svg] {
    width: 50px;
    height: 42px;
    margin-bottom: ${spaces.mini};
    color: ${colors.xxlGreen};

    ${MQ("tablet")} {
      width: 72px;
      height: 61px;
    }
  }

  ${MQ("tablet")} {
    grid-row: 1;
    grid-column: 12;
    align-self: stretch;
  }
`;

export const Service = styled.div`
  grid-row: auto;
  grid-column: 1 / span 2;
  word-break: break-word;

  ${MQ("tablet")} {
    grid-row: 2;
    grid-column: 7 / span 3;
  }
`;
export const About = styled.div`
  grid-row: auto;
  grid-column: 1 / span 2;
  margin-bottom: ${spaces.big};
  word-break: break-word;

  ${MQ("tablet")} {
    grid-row: 2;
    grid-column: 10 / span 3;
    margin-bottom: 0;
  }
`;

export const SocialListColumn = styled.div`
  display: flex;
  grid-row: auto;
  grid-column: 1 / span 2;
  align-items: flex-end;
  align-self: stretch;

  ${MQ("tablet")} {
    grid-row: 2;
    grid-column: 1 / span 4;
    margin-bottom: 108px;
    margin-left: ${spaces.mini};
  }
`;

export const SocialList = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;

  ${MQ("tablet")} {
    margin: 0 0 ${spaces.regular};
  }
`;
export const SocialListItem = styled.li`
  margin-right: ${spaces.regular};

  a {
    display: flex;

    &:hover {
      cursor: pointer;
    }
    &:focus-visible {
      ${outlineForTabs("1px", colors.xxlGreen, "5px")};
    }

    svg,
    [data-inline-svg] {
      width: 23px;
      height: 23px;
      color: ${colors.xxlWhite};
    }

    svg,
    [data-inline-svg] {
      vertical-align: middle;
    }
  }
`;

export const SiteLogos = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 5;
  grid-column: 1;
  align-self: end;
  justify-content: flex-end;

  p {
    color: ${colors.xxlGrey};
    font-size: 10px;
    margin: 0;
    padding: 0;

    ${MQ("tablet")} {
      font-size: 12px;
    }
  }

  figure {
    margin: 0;
    padding: 0;
    margin-bottom: ${spaces.mini};
  }

  a {
    display: inline-block;
  }
  a:focus-visible {
    ${outlineForTabs("1px", colors.xxlGreen, "5px")};
  }

  img {
    display: inline-block;
    width: auto;
    height: 26px;

    ${MQ("tablet")} {
      height: 36px;
    }
  }

  ${MQ("tablet")} {
    grid-row: 2;
    grid-column: 1 / span 6;
  }
`;

export const SiteLogosInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${resolutionSizes.tablet}px) {
    flex-direction: column-reverse;
  }
`;

export const ThirdPartyLogos = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;

  a:focus-visible img {
    ${outlineForTabs("1px", colors.xxlGreen, "5px")};
  }

  @media (max-width: ${resolutionSizes.tablet}px) {
    flex-direction: column;
    height: initial;
    padding-bottom: 18px;
  }
`;

export const ThirdPartyLogo = styled.div`
  height: 36px;
  margin-left: ${spaces.large};
  padding-left: ${spaces.large};
  border-left: 1px solid ${colors.xxlLightGrey};

  figure {
    display: inline-block;
    margin-bottom: 14px;
    vertical-align: middle;
    margin: 0;
    padding: 0;

    @media (max-width: ${resolutionSizes.tablet}px) {
      margin-bottom: 21px;

      &:last-child {
        img {
          margin-right: 0;
        }
      }
    }
  }

  a:focus-visible {
    outline: 0;
  }

  img {
    display: inline-block;
    max-width: 80px;
    height: 36px;
    margin-right: ${spaces.large};
    object-fit: contain;

    @media (max-width: ${resolutionSizes.tablet}px) {
      max-width: 50px;
    }
  }

  @media (max-width: ${resolutionSizes.tablet}px) {
    order: 1;
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    padding-left: 0;
    border: none;
  }
`;
