import React, { useEffect, useState } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import {
  BodyContainer,
  CloseButton,
  DrawerContainer,
  DrawerHeading,
  NonStickyHeading,
  StickyFooterContainer,
  StickyHeading,
} from "./Drawer.styles";
import { laptopMediaQuery } from "../../utils/xxl-screen";
import { XxlButton } from "../Common/XxlButton/XxlButton";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Icon } from "../Icon/Icon";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";

const DRAWER_ANCHOR = {
  MOBILE: "bottom",
  DESKTOP: "right",
} as const;

type Props = {
  onClose: () => void;
  isLoading?: boolean;
  heading: string | React.ReactNode;
  closeButtonText?: string;
  open: boolean;
  children: React.ReactNode;
  closeButton?: boolean;
  customPadding?: string;
};

const useKeepMounted = (open: boolean) => {
  const [km, setKm] = useState<boolean | undefined>();
  useEffect(() => {
    if (open === true) {
      setKm(true);
    }
  }, [open]);
  return km;
};

export const Drawer = ({
  open,
  onClose,
  isLoading = false,
  closeButtonText,
  heading,
  children,
  closeButton = true,
  customPadding,
}: Props) => {
  const isLaptopSize = useXxlMediaQuery(laptopMediaQuery);
  const { t } = useTranslations();
  const keepMounted = useKeepMounted(open);

  const closeButtonLabel = closeButtonText ?? t("sorts.close.button.text");

  const drawerAnchor = isLaptopSize
    ? DRAWER_ANCHOR.DESKTOP
    : DRAWER_ANCHOR.MOBILE;

  return (
    <MuiDrawer
      anchor={drawerAnchor}
      open={open}
      onClose={onClose}
      keepMounted={keepMounted}
    >
      <DrawerContainer mobile={!isLaptopSize}>
        {typeof heading === "string" ? (
          <StickyHeading>
            <DrawerHeading>{heading}</DrawerHeading>
            <CloseButton
              onClick={onClose}
              aria-label={t("size.guide.close.button")}
            >
              <Icon size={18} name="CloseIconSlim" />
            </CloseButton>
          </StickyHeading>
        ) : (
          <NonStickyHeading>
            {heading}
            <CloseButton
              onClick={onClose}
              aria-label={t("size.guide.close.button")}
            >
              <Icon size={18} name="CloseIconSlim" />
            </CloseButton>
          </NonStickyHeading>
        )}
        <BodyContainer customPadding={customPadding}>{children}</BodyContainer>
        {closeButton && (
          <StickyFooterContainer>
            <XxlButton
              loading={isLoading}
              variant="secondary"
              onClick={onClose}
            >
              {closeButtonLabel}
            </XxlButton>
          </StickyFooterContainer>
        )}
      </DrawerContainer>
    </MuiDrawer>
  );
};
