import type { BadgeType } from "@/react-utils/data-types";
import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import {
  isNotEmpty,
  isNotNullOrUndefined,
  type Site,
  type SiteIdentifier,
} from "@xxl/common-utils";
import type {
  CountdownTimerSettings,
  ServicePromotion,
} from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import type {
  ColorTheme,
  ProductType as ProductTypeFromPimApi,
} from "@xxl/pim-api";
import type { ProductType as ProductTypeFromProductSearchApi } from "@xxl/product-search-api";
import isEmpty from "lodash/isEmpty";
import type { Redirect } from "next";
import {
  ERROR_RESPONSE_STATUS,
  SUCCESS_RESPONSE_STATUS,
} from "react-app/src/components/Cart/Api/CartAPI";
import { paymentWidgetQuery } from "react-app/src/components/Cart/Api/graphqlQueries";
import type { ResponseStatus } from "react-app/src/components/Cart/Api/types";
import { MAX_NR_OF_PRODUCT_BADGES } from "react-app/src/constants";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { PaymentWidgetQuery } from "react-app/src/generated/graphql-code-generator";
import {
  callGraphQL,
  type GraphQLResult,
} from "react-app/src/graphql/graphqlApi";
import { getCampaignData } from "react-app/src/hooks/useCampaignDataForPdp/useCampaignDataForPdp.helper";
import { SALES_METHODS } from "react-app/src/hooks/useProductData/constants";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import stringFormat from "string-format";
import { NotFoundResponse } from "../constants";
import { isInternalNextRequest } from "../page-helper";
import { KeyvDefaultTtl } from "../server-side-cache/server-side-cache";

const PDP_SIZE_QUERY_NAME = "size";
const PDP_STYLE_ID_QUERY_NAME = "id";

export type SimpleCampaignData = {
  timer: CountdownTimerSettings | null;
  description: string | null;
  title: string;
  slug: string;
};

type PaymentWidgetResponseData = GraphQLResult<PaymentWidgetQuery>;

type PaymentWidgetResponse = {
  status: ResponseStatus;
  data: PaymentWidgetResponseData;
};

const getSimpleCampaignData = async (
  campaignCode: string,
  siteId: SiteIdentifier
): Promise<SimpleCampaignData | null> => {
  const {
    data: { result = [] },
  } = await Apis.getInstance().contentApi.getDigitalCampaignPage(
    siteId,
    campaignCode
  );
  const [data] = result;
  return isEmpty(data) ? null : getCampaignData(data);
};

const getSimpleCampaignDataMemoized = memoize(
  getSimpleCampaignData,
  new Keyv(),
  {
    key: (campaignCode: string) => `campaign-data-${campaignCode}`,
    ...KeyvDefaultTtl,
  }
);

const DEFAULT_COLOR_THEME: ColorTheme = {
  backgroundColor: colorsAsCssVariable.xxlWhite,
  foregroundColor: colorsAsCssVariable.xxlBlack,
} as const;

const createColorTheme = (
  theme: Partial<ColorTheme> = DEFAULT_COLOR_THEME
) => ({
  background: theme.backgroundColor ?? DEFAULT_COLOR_THEME.backgroundColor,
  foreground: theme.foregroundColor ?? DEFAULT_COLOR_THEME.foregroundColor,
});

const getPaymentWidgetData = async (
  siteId: Site,
  graphQlEndpoint: string,
  graphQlApiKey: string
): Promise<PaymentWidgetResponse> => {
  const queryResponse = await callGraphQL<PaymentWidgetQuery>(
    {
      query: paymentWidgetQuery,
      variables: {
        input: {
          site: siteId,
        },
      },
      forceAnonymous: true,
    },
    graphQlEndpoint,
    graphQlApiKey
  );

  return {
    status:
      queryResponse.data?.paymentWidget !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

const filterServicePromotions = (
  servicePromotions: ServicePromotion[],
  productCategoryCodes: string[]
) =>
  servicePromotions.filter(({ categoryCodes = [] }) =>
    categoryCodes.some((code) => productCategoryCodes.includes(code))
  );

const isBadgeTypeExtended = (badge: unknown): badge is BadgeType =>
  ["ONLINE", "STORE"].includes(badge as BadgeType);

const createBadges = (
  badges: BadgeType[],
  availability: string[],
  salesMethod?: string
): BadgeType[] => {
  const isBelowMaxBadgeThreshold = badges.length < MAX_NR_OF_PRODUCT_BADGES;
  const shouldAddAvailabilityBadge =
    availability.length === 1 && salesMethod !== SALES_METHODS.PRE_SALE;
  if (isBelowMaxBadgeThreshold && shouldAddAvailabilityBadge) {
    const [singleAvailability] = availability;
    if (!isBadgeTypeExtended(singleAvailability)) {
      log.error("Availability type is incorrect.", { singleAvailability });
      return badges;
    }
    return [...badges, singleAvailability];
  }

  return [...badges].slice(0, 2);
};

const metaData = {
  getTitle: (
    styleOptionsName: string | undefined,
    productName: string,
    category: string
  ) => {
    return styleOptionsName !== undefined
      ? `${productName} - ${category} - ${styleOptionsName} | XXL`
      : `${productName} - ${category} | XXL`;
  },
  useDescription: (productName: string) => {
    const { t } = useTranslations();
    return stringFormat(t("product.details.meta.description"), productName);
  },
};

const getRedirection = ({
  requestUrl,
  productUrl,
  requestProductCode,
  productType,
}: {
  requestProductCode: string;
  requestUrl: string;
  productUrl?: string;
  productType?: ProductTypeFromPimApi | ProductTypeFromProductSearchApi;
}): { redirect: Redirect } | null => {
  if (productUrl === undefined) {
    throw Error("productUrl is undefined");
  }

  // Next.js fast refresh returns some internal url
  // Display multipack bundles on PDP as if they were standalone product
  if (
    isInternalNextRequest(requestUrl) ||
    (productType !== undefined &&
      ["PRODUCT_MULTIPACK", "BUNDLE_MULTIPACK"].includes(productType))
  ) {
    return null;
  }

  const [requestRelativeUrl, requestQueryString] = requestUrl.split("?");
  const [productRelativeUrl, productQueryString] = productUrl.split("?");
  const productUrlSearchParams = new URLSearchParams(productQueryString);
  const productUrlProductCode =
    productUrlSearchParams.get(PDP_STYLE_ID_QUERY_NAME) ?? requestProductCode;

  if (
    requestRelativeUrl === productRelativeUrl &&
    requestProductCode === productUrlProductCode
  ) {
    return null;
  }
  const requestUrlSearchParams = new URLSearchParams(requestQueryString);
  requestUrlSearchParams.delete(PDP_STYLE_ID_QUERY_NAME);
  for (const [paramKey, paramValue] of requestUrlSearchParams.entries()) {
    productUrlSearchParams.set(paramKey, paramValue);
  }
  const queryParameters = productUrlSearchParams.toString();

  return {
    redirect: {
      destination: `${productRelativeUrl}${
        isNotEmpty(queryParameters) ? "?" + queryParameters : ""
      }`,
      permanent: true,
    },
  };
};

const getSizeRedirect = ({
  sizeCode,
  styleUrl,
  requestUrl,
}: {
  sizeCode: string;
  styleUrl?: string;
  requestUrl: string;
}): { redirect: Redirect } | typeof NotFoundResponse => {
  if (styleUrl === undefined) {
    log.error("Style URL is undefined.");
    return NotFoundResponse;
  }

  const [_requestRelativeUrl, requestQueryString] = requestUrl.split("?");
  const hasQueryString =
    isNotNullOrUndefined(requestQueryString) && requestQueryString.length > 0;
  const baseUrl = styleUrl.includes(`${PDP_SIZE_QUERY_NAME}=`)
    ? styleUrl
    : `${styleUrl}?${PDP_SIZE_QUERY_NAME}=${sizeCode}`;
  return {
    redirect: {
      destination: `${baseUrl}${
        hasQueryString ? `&${requestQueryString}` : ""
      }`,
      permanent: true,
    },
  };
};

export {
  createBadges,
  createColorTheme,
  filterServicePromotions,
  getPaymentWidgetData,
  getRedirection,
  getSimpleCampaignDataMemoized,
  getSizeRedirect,
  metaData,
  PDP_SIZE_QUERY_NAME,
};
