import { QUERY_KEYS } from "@/react-app/constants";
import { useAmplifyConfig } from "@/react-hooks/useAmplifyConfig";
import { useQuery } from "@tanstack/react-query";
import { CreateClientApi } from "../PdpBody/Reviews/api/client";

export const useSizeRecommendationQuery = (articleNr: string) => {
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();
  const api = CreateClientApi(aws_appsync_apiKey, aws_appsync_graphqlEndpoint);

  const fetchSizeRecommendation = async () =>
    await api.ReviewService.GetSizeRating(articleNr);

  return useQuery({
    queryKey: [QUERY_KEYS.SIZE_RECOMMENDATION],
    queryFn: fetchSizeRecommendation,
  });
};
