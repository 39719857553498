import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { SizeLabel } from "@/react-components/Common/SelectBox/SizeLabel";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text/Text";
import { useBreakpoint } from "@/react-hooks/useBreakpoint/useBreakpoint";
import type { VariantData } from "@xxl/product-search-api";
import React from "react";
import {
  getCurrentStoreIdFromCookie,
  getHighestCollectableStockStatusFromStores,
  getOnlineStockStatus,
  getPreferredStores,
  getStoresWithCollectableStockCount,
} from "../../helpers";
import { useStockStatusTranslation } from "../../hooks/useStockStatusTranslation";
import { useStoresQuery } from "../../queries/useStoresQuery";
import { CollectableStoreStockStatusComponent } from "./CollectableStoreStockStatusComponent";
import { RemindMeComponent } from "./RemindMeComponent";
import { StockStatusIndicator, VariantLi } from "./styles";

type TonRemindMeClick = (
  evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  code: string,
  sizeCode: string
) => void;
type Props = {
  variant: VariantData;
  onClick: () => void;
  onRemindMeClick: TonRemindMeClick;
  hasReminder: boolean;
  isExcludedFromClickAndCollect?: boolean;
};

export const VariantListItem = ({
  variant,
  onClick,
  onRemindMeClick,
  hasReminder,
  isExcludedFromClickAndCollect = true,
}: Props) => {
  const { t } = useTranslations();
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";
  const { data: storeData = [], isLoading } = useStoresQuery();
  const onlineStockStatus =
    getOnlineStockStatus(variant.availability) ?? "OUTOFSTOCK";

  /** Online stock status */
  const { onlineFn } = useStockStatusTranslation();
  const onlineStatusText = onlineFn(onlineStockStatus);

  /** In store stock status */
  const preferredStores = getPreferredStores(
    storeData,
    getCurrentStoreIdFromCookie()
  );
  const shouldShowStoreStock =
    preferredStores.length > 0 && !isExcludedFromClickAndCollect;

  const storesWithCollectableStockCount = getStoresWithCollectableStockCount(
    variant.availability
  );
  const highestCollectableStockStatus =
    getHighestCollectableStockStatusFromStores(
      preferredStores.map((ps) => ps.id),
      variant.availability
    );

  return (
    <VariantLi
      aria-label={variant.label}
      data-testid={`size-${variant.label}`}
      onClick={onClick}
    >
      <Text
        style={{
          alignSelf: "start",
          paddingTop: xxlTheme.spaces.micro,
        }}
      >
        <SizeLabel size={variant.label} ean={variant.code} t={t} />
      </Text>
      <XxlStack>
        <XxlStack direction={"row"} alignItems={"center"}>
          <StockStatusIndicator stockStatus={onlineStockStatus} />
          <Text typography={isMobile ? "small" : "base"}>
            {onlineStatusText}
          </Text>
          <RemindMeComponent
            onClick={(evt) =>
              onRemindMeClick(evt, variant.code, variant.sizeCode.split("_")[0])
            }
            hasReminder={hasReminder}
            shouldBePossibleToAddReminder={true}
            shouldShowRemindMe={onlineStockStatus === "OUTOFSTOCK"}
          >
            <Text
              typography={isMobile ? "smallMedium" : "baseMedium"}
              underline={true}
            >
              {t(hasReminder ? "general.reminder.added" : "general.remind.me")}
            </Text>
          </RemindMeComponent>
        </XxlStack>
        {shouldShowStoreStock && (
          <CollectableStoreStockStatusComponent
            isLoading={isLoading}
            isMultiPreferredStores={preferredStores.length > 1}
            preferredStoreName={preferredStores[0].name}
            highestCollectableStockStatus={highestCollectableStockStatus}
            preferredStores={preferredStores}
            storesWithCollectableStockCount={storesWithCollectableStockCount}
          />
        )}
      </XxlStack>
    </VariantLi>
  );
};
