import { isEmpty } from "@xxl/common-utils";
import ReactHtmlParser from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

export const SanitizedHtmlComponent = ({
  text,
}: {
  text?: string;
}): JSX.Element | undefined => {
  if (isEmpty(text)) {
    return;
  }
  return (
    <ErrorBoundary>{ReactHtmlParser(DOMPurify.sanitize(text))}</ErrorBoundary>
  );
};
