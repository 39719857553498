import { isNotNullOrUndefined } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import React, { createRef, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { FormErrorMessage } from "../../../styled";
import { KeyCode } from "../../../utils/xxl-keyboard-code";
import { ErrorMessageWrapper } from "../../ExpertReviewRadioButtons/ExpertReviewRadioButtons.styled";
import {
  Validity,
  Arrow,
  Item,
  Label,
  SelectedItem,
  TextBox,
  UlAsListBox,
  Wrapper,
} from "./SelectBox.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../translations";
import { useSharedData } from "../../../contexts/SharedData";
import { SizeLabel } from "./SizeLabel";

export type SelectBoxOption<T> = {
  key: string | number;
  name: React.ReactNode | string;
  value: T | null;
};

type SelectBoxProps<T> = {
  disabled?: boolean;
  errorMessage?: string;
  hideErrorMessage?: boolean;
  id: string;
  initialValue?: SelectBoxOption<T> | null;
  isLoading?: boolean;
  isMandatory?: boolean;
  label?: string;
  maxHeight?: string;
  onClose?: () => void;
  onSelect: (selectedItem: SelectBoxOption<T>) => void;
  onTouched?: () => void;
  options: SelectBoxOption<T>[];
  placeholder: string;
  shouldScrollViewToFocusOptionsList?: boolean;
  separateNthFromBottom?: number;
  separateNthFromTop?: number;
  shouldClearValue?: boolean;
  unit?: string;
  validity?: Validity;
};

type OptionProps<T> = {
  isFocused: boolean;
  isSelected: boolean;
  onKeyDown: (event: React.KeyboardEvent, option: SelectBoxOption<T>) => void;
  onSelect: (option: SelectBoxOption<T>) => void;
  option: SelectBoxOption<T>;
  unit?: string;
};
function Option<T>(
  props: React.PropsWithChildren<OptionProps<T>>
): JSX.Element {
  const { isFocused, isSelected, onKeyDown, onSelect, option, unit } = props;
  const optionRef = React.createRef<HTMLLIElement>();
  const { t } = useTranslations();
  const {
    featureToggles: { toggle_products_as_package_quantity },
  } = useSharedData().data;

  React.useEffect(() => {
    if (isFocused) {
      optionRef.current?.focus();
    }
  }, [isFocused, optionRef]);

  const ean =
    typeof option.value === "object" &&
    option.value !== null &&
    "ean" in option.value &&
    typeof option.value.ean === "string"
      ? option.value.ean
      : null;

  return (
    <Item
      aria-selected={isSelected}
      id={option.key.toString()}
      key={option.key}
      onClick={(): void => {
        onSelect(option);
      }}
      onKeyDown={(event): void => {
        onKeyDown(event, option);
      }}
      ref={optionRef}
      role="option"
      tabIndex={0}
      data-testid="option"
    >
      {typeof option.name === "string" ? (
        <SizeLabel
          unit={unit}
          size={option.name}
          ean={ean}
          t={t}
          toggle_products_as_package_quantity={
            toggle_products_as_package_quantity
          }
        />
      ) : (
        option.name
      )}
    </Item>
  );
}

export function SelectBox<T>(
  props: React.PropsWithChildren<SelectBoxProps<T>>
): JSX.Element {
  const {
    disabled = false,
    errorMessage,
    hideErrorMessage = false,
    id,
    initialValue,
    isLoading = false,
    isMandatory = false,
    label,
    maxHeight = "211px",
    onClose = noop,
    onSelect,
    onTouched = noop,
    options,
    placeholder,
    shouldScrollViewToFocusOptionsList = false,
    separateNthFromBottom,
    separateNthFromTop,
    shouldClearValue = false,
    unit,
    validity,
  } = props;
  const buttonRef = createRef<HTMLParagraphElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<SelectBoxOption<T> | null>(initialValue ?? null);
  const [focusedOption, setFocusedOption] = useState<SelectBoxOption<T> | null>(
    null
  );
  const listRef = createRef<HTMLUListElement>();
  const labelId = `${id}-label`;
  const { t } = useTranslations();

  const handleSelect = (option: SelectBoxOption<T>): void => {
    setSelectedOption(
      isNotNullOrUndefined(unit)
        ? {
            ...option,
            name: t(`product.unit.amount.${unit}` as TranslationKey),
          }
        : option
    );
    setIsOpen(!isOpen);
    onSelect(option);
  };

  const handleArrowUp = (activeOption: SelectBoxOption<T> | null): void => {
    if (isNotNullOrUndefined(activeOption)) {
      const activeOptionIndex: number = options.findIndex(
        (option) => option.key === activeOption.key
      );
      const indexDecreasedByOne = activeOptionIndex - 1;
      const previousOptionIndex =
        indexDecreasedByOne !== -1 ? indexDecreasedByOne : options.length - 1;
      const previousOption = options[previousOptionIndex];
      setFocusedOption(previousOption);
    } else {
      const lastItemIndex = options.length - 1;
      setFocusedOption(options[lastItemIndex]);
    }
  };

  const handleArrowDown = (activeOption: SelectBoxOption<T>): void => {
    const activeOptionIndex: number = options.findIndex(
      (option) => option.key === activeOption.key
    );
    const nextOptionIndex = (activeOptionIndex + 1) % options.length;
    const nextOption = options[nextOptionIndex];
    setFocusedOption(nextOption);
  };

  const handleKeyDownOnButton = (event: React.KeyboardEvent): void => {
    const { key } = event;
    onTouched();

    switch (key) {
      case KeyCode.ARROW_DOWN: {
        if (isNotNullOrUndefined(selectedOption)) {
          handleArrowDown(selectedOption);
        } else {
          setFocusedOption(options[0]);
        }
        break;
      }
      case KeyCode.ARROW_UP: {
        handleArrowUp(selectedOption);
        break;
      }
      case KeyCode.ESCAPE: {
        isOpen && event.stopPropagation();
        setFocusedOption(null);
        setIsOpen(false);
        break;
      }
      case KeyCode.SPACE: {
        if (isNotNullOrUndefined(selectedOption)) {
          setFocusedOption(selectedOption);
        }
        setIsOpen(true);

        break;
      }
      case KeyCode.TAB: {
        setIsOpen(false);
        break;
      }
    }
  };

  const handleKeyDownOnOption = (
    event: React.KeyboardEvent,
    option: SelectBoxOption<T>
  ): void => {
    const key = event.key;

    switch (key) {
      case KeyCode.ARROW_DOWN: {
        if (isNotNullOrUndefined(focusedOption)) {
          handleArrowDown(focusedOption);
        }
        break;
      }
      case KeyCode.ARROW_UP: {
        handleArrowUp(focusedOption);
        break;
      }
      case KeyCode.ENTER:
      case KeyCode.SPACE: {
        handleSelect(option);
        setFocusedOption(null);
        setIsOpen(false);
        buttonRef.current?.focus();
        break;
      }
      case KeyCode.TAB:
      case KeyCode.ESCAPE: {
        event.stopPropagation();
        setFocusedOption(null);
        setIsOpen(false);
        buttonRef.current?.focus();
        break;
      }
    }
  };

  const handleOnClick = (): void => {
    onTouched();
    setIsOpen(!isOpen);
    setIsTouched(true);

    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    setSelectedOption(shouldClearValue ? null : selectedOption);
  }, [shouldClearValue, selectedOption]);

  useEffect(() => {
    if (isNotNullOrUndefined(initialValue)) {
      setSelectedOption(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (
      shouldScrollViewToFocusOptionsList &&
      isOpen &&
      listRef.current !== null
    ) {
      listRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, shouldScrollViewToFocusOptionsList]);

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={(): void => {
          setIsOpen(false);
          if (isOpen) {
            onClose();
          }
        }}
      >
        <Wrapper
          isOpen={isOpen}
          validity={
            isTouched && validity === Validity.PENDING
              ? Validity.PENDING_ACTIVE
              : validity
          }
          isLoading={isLoading}
          isDisabled={disabled}
        >
          {isNotNullOrUndefined(label) && (
            <Label
              htmlFor={id}
              id={labelId}
              isMandatory={isMandatory}
              isDisabled={disabled}
              onClick={(): void => {
                buttonRef.current?.click();
              }}
            >
              {label}
            </Label>
          )}
          <TextBox
            aria-haspopup="listbox"
            id={id}
            data-testid={id}
            onClick={handleOnClick}
            onKeyDown={(event): void => {
              handleKeyDownOnButton(event);
            }}
            ref={buttonRef}
            role="button"
            tabIndex={0}
            hasError={!isEmpty(errorMessage)}
            isDisabled={disabled}
          >
            <SelectedItem>
              {isNotNullOrUndefined(selectedOption)
                ? selectedOption.name
                : placeholder}
            </SelectedItem>
            <Arrow direction={isOpen ? "up" : "down"} />
          </TextBox>
          <UlAsListBox
            aria-activedescendant={
              isNotNullOrUndefined(focusedOption)
                ? focusedOption.key.toString()
                : ""
            }
            aria-labelledby={labelId}
            isOpen={isOpen}
            role="listbox"
            tabIndex={-1}
            maxHeight={maxHeight}
            ref={listRef}
            separateNthFromBottom={separateNthFromBottom}
            separateNthFromTop={separateNthFromTop}
          >
            {options.map((option) => {
              return (
                <Option
                  isFocused={focusedOption?.key === option.key}
                  isSelected={Boolean(
                    isNotNullOrUndefined(selectedOption) &&
                      selectedOption.key === option.key
                  )}
                  key={option.key}
                  option={option}
                  onKeyDown={handleKeyDownOnOption}
                  onSelect={handleSelect}
                  unit={unit}
                />
              );
            })}
          </UlAsListBox>
        </Wrapper>
      </OutsideClickHandler>
      {!hideErrorMessage && !isEmpty(errorMessage) && (
        <ErrorMessageWrapper>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </ErrorMessageWrapper>
      )}
    </>
  );
}
