import { useApiClients } from "@/react-app/contexts/ApiClients";
import type { CartItemType } from "@/react-app/generated/graphql-code-generator";
import { useAddToCart } from "@/react-hooks/useAddToCart/useAddToCart";
import { useElevateRequestData } from "@/react-hooks/useElevateRequestData/useElevateRequestData";
import { useMediaQuery } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { log } from "@xxl/logging-utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isAxiosError } from "axios";
import {
  ShadowBottom,
  ShadowTop,
} from "next-js-app/src/components/common/ServiceProducts/ServiceProductsWrapper.styled";
import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { mobileAndTabletMediaQuery } from "../../../utils/xxl-screen";
import { useCartContext } from "../CartState";
import {
  DesktopListHeightInteger,
  List,
  ListWrapper,
  ScrollWrapper,
  ShadowsContainer,
  ShowMoreButton,
} from "../Styles/ProductsList.styled";
import { ProductItem } from "./ProductItem";

type ProductsListProps = {
  isMiniCart?: boolean;
  miniCartListHeight?: number;
};

export const ProductsList: React.FunctionComponent<ProductsListProps> = ({
  isMiniCart,
  miniCartListHeight,
}) => {
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const { t } = useTranslations();
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false);
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const [listViewportHeight, setListViewportHeight] = useState(0);
  const listRef = useRef<HTMLUListElement | null>(null);
  const listViewportRef = useRef<HTMLDivElement | null>(null);
  const [isListToggled, setIsListToggled] = useState(false);
  const [showMoreDisplay, setShowMoreDisplay] = useState(false);
  const [mobileVisibleListIndex, setMobileVisibleListIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const { productApi } = useApiClients();
  const {
    state: { displayCart, isCartContentLocked, listHeightChange },
  } = useCartContext();
  const { handleAdditionalSales, ServicesComponent } = useAddToCart({
    shouldShowCrossSales: false,
    shouldShowServices: true,
  });
  const { getBaseQuery } = useElevateRequestData();
  const { mutate: mutateService } = useMutation({
    mutationFn: productApi.getProductData,
  });

  const productsList = displayCart !== undefined ? displayCart.items : [];

  useEffect(() => {
    const setListScroll = () => {
      const listElement = listRef.current;
      const listViewportElement = listViewportRef.current;
      if (
        listHeight === 0 &&
        listElement !== null &&
        listElement.offsetHeight !== 0
      ) {
        setListHeight(listElement.offsetHeight);
      }
      if (
        listViewportHeight === 0 &&
        listViewportElement !== null &&
        listViewportElement.offsetHeight !== 0
      ) {
        setListViewportHeight(listViewportElement.offsetHeight);
      }
    };
    if (!isMobileOrTablet || isMiniCart === true) {
      setTimeout(() => {
        setListScroll();
      }, 0);
    }
  }, [
    listRef,
    listHeight,
    listViewportHeight,
    isMobileOrTablet,
    listViewportRef,
    isMiniCart,
  ]);

  useEffect(() => {
    if (scrollPosition + listViewportHeight >= listHeight) {
      setIsBottomShadowVisible(false);
    } else if (
      listHeight > listViewportHeight &&
      scrollPosition + listViewportHeight < listHeight
    ) {
      setIsBottomShadowVisible(true);
    }
    setIsTopShadowVisible(scrollPosition > 0);
    if (
      miniCartListHeight !== undefined
        ? listHeight < miniCartListHeight
        : listHeight < DesktopListHeightInteger
    ) {
      setIsTopShadowVisible(false);
      setIsBottomShadowVisible(false);
    }
  }, [
    scrollPosition,
    listViewportHeight,
    listHeight,
    listViewportRef,
    listRef,
    miniCartListHeight,
  ]);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement !== null && listElement.offsetHeight !== 0) {
      setListHeight(listElement.offsetHeight);
    }
  }, [listHeightChange]);

  const hasFirstProductServices =
    productsList.length === 0
      ? false
      : (productsList[0].productServices !== undefined &&
          productsList[0].productServices.length > 0) ||
        productsList[0].teamBundleSettings !== undefined;

  const shouldShowMobileButton =
    (productsList.length > 1 && hasFirstProductServices) ||
    productsList.length > 2;

  useEffect(() => {
    if (isMobileOrTablet && shouldShowMobileButton) {
      setMobileVisibleListIndex(hasFirstProductServices ? 0 : 1);
    }
  }, [hasFirstProductServices, isMobileOrTablet, shouldShowMobileButton]);

  useEffect(() => {
    setShowMoreDisplay(shouldShowMobileButton);
  }, [shouldShowMobileButton]);

  useEffect(() => {
    setIsEditing(isCartContentLocked);
  }, [isCartContentLocked]);

  const toggleHandler = () => {
    setIsListToggled(!isListToggled);
  };

  const onServiceEditClick = ({
    parentProductCode,
    parentItemId,
    serviceItemEan,
    serviceItemId,
  }: {
    parentProductCode: string;
    parentItemId: {
      id: number;
      type: CartItemType;
    };
    serviceItemEan: string;
    serviceItemId: {
      id: number;
      type: CartItemType;
    };
  }) => {
    mutateService(
      {
        ...getBaseQuery(),
        productCode: parentProductCode,
        clientId: "cart-service-update",
      },
      {
        onSuccess: (data) => {
          const { additionalSales, categoryBreadcrumbs } = data ?? {};
          if (additionalSales === undefined) {
            return;
          }

          void handleAdditionalSales({
            variant: "serviceEdit",
            categoryCode: categoryBreadcrumbs?.at(1)?.name ?? "",
            cartEntryItemId: parentItemId,
            additionalSales,
            preSelectedService: {
              ean: serviceItemEan,
              itemId: serviceItemId,
            },
          });
        },
        onError: (error) => {
          log.error(
            "Could not fetch services.",
            ...(isAxiosError(error)
              ? [error.message, error.config?.url]
              : [error])
          );
        },
      }
    );
  };

  if (productsList.length <= 0) {
    return null;
  }

  return (
    <>
      <ListWrapper ref={listViewportRef} isEditing={isEditing}>
        {isMobileOrTablet && isMiniCart !== true ? (
          <>
            {showMoreDisplay ? (
              <>
                <List>
                  {productsList.map((item, index) => {
                    if (index <= mobileVisibleListIndex) {
                      return (
                        <ProductItem
                          key={`product-item-${index}`}
                          item={item}
                          data-testid="mini-cart-product-item"
                          onServiceEditClick={onServiceEditClick}
                        />
                      );
                    }
                    return null;
                  })}
                  <AnimateHeight height={isListToggled ? "auto" : 0}>
                    {productsList.map((item, index) => {
                      if (index <= mobileVisibleListIndex) {
                        return null;
                      }
                      return (
                        <ProductItem
                          key={`product-item-${index}`}
                          item={item}
                          data-testid="mini-cart-product-item"
                          onServiceEditClick={onServiceEditClick}
                        />
                      );
                    })}
                  </AnimateHeight>
                </List>
                <ShowMoreButton
                  type="button"
                  isToggled={isListToggled}
                  onClick={toggleHandler}
                >
                  <span>
                    {isListToggled
                      ? t("cart.summary.show.less")
                      : t("cart.summary.show.more")}
                  </span>
                  <svg>
                    <use href="#arrow-down" xlinkHref="#arrow-down" />
                  </svg>
                </ShowMoreButton>
              </>
            ) : (
              <List>
                {productsList.map(({ accessories = [], ...item }, index) => (
                  <>
                    <ProductItem
                      key={`product-item-${index}`}
                      item={item}
                      data-testid="mini-cart-product-item"
                      onServiceEditClick={onServiceEditClick}
                    />

                    {accessories.map((accessory) => (
                      <ProductItem
                        key={accessory.entryNumber}
                        item={accessory}
                        data-testid="mini-cart-product-item"
                        onServiceEditClick={onServiceEditClick}
                      />
                    ))}
                  </>
                ))}
              </List>
            )}
          </>
        ) : (
          <>
            <ShadowsContainer maxListHeight={miniCartListHeight}>
              <ShadowTop isVisible={isTopShadowVisible} />
              <ShadowBottom isVisible={isBottomShadowVisible} />
            </ShadowsContainer>
            <ScrollWrapper
              onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
                const target = event.target as HTMLDivElement;
                setScrollPosition(target.scrollTop);
              }}
              maxListHeight={miniCartListHeight}
            >
              <List ref={listRef}>
                {productsList.map(({ accessories = [], ...item }) => (
                  <React.Fragment
                    key={`product-item-${item.entryNumber}-${item.type}`}
                  >
                    <ProductItem
                      item={item}
                      data-testid="mini-cart-product-item"
                      onServiceEditClick={onServiceEditClick}
                    />
                    {accessories.map((accessory) => (
                      <ProductItem
                        key={accessory.entryNumber}
                        item={accessory}
                        data-testid="mini-cart-product-item"
                        onServiceEditClick={onServiceEditClick}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </List>
            </ScrollWrapper>
          </>
        )}
      </ListWrapper>
      {ServicesComponent}
    </>
  );
};
