import type { Action } from "@/next-js-app/components/ConfiguratorForProductBundles/ConfiguratorState/ConfiguratorState.types";
import type {
  Placement,
  PlacementWithSelectedSize,
} from "@/next-js-app/components/ConfiguratorForProductBundles/Placements/Placement/types";
import type { AmplifyConfig } from "@/next-js-app/global";
import { hasNoValue } from "@xxl/common-utils";
import { color } from "@xxl/theme";
import groupBy from "lodash/groupBy";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";
import type { Dispatch } from "react";
import { addAccessoriesToCart as addAccessoriesToCartGraphQLCall } from "../../components/Cart/Api/CartAccessoriesAPI";
import { addBundleForProductConfiguratorToCart } from "../../components/Cart/Api/CartBundleAPI";
import type { GenericGraphQLError } from "../../components/Cart/Api/types";
import {
  hasOutOfStockError,
  isOutOfStockError,
} from "../../components/Cart/cart-page-helper";
import type { Configuration as UserSetting } from "../../components/Product/ConfigurableProduct/ConfigurationModal/ConfigurationForm/types";
import { QUANTITY_ONE } from "../../constants";
import type { CartItemIdInput } from "../../generated/graphql-code-generator";
import type { ProductTrackingData } from "../../utils/Tracking";
import { dispatchEvent } from "../../utils/xxl-event";
const { webBlack, white } = color;

const mapSelectedPlacementToObjectWithEanAndQuantity = ({
  selectedEan,
}: PlacementWithSelectedSize) => ({
  ean: selectedEan,
  quantity: QUANTITY_ONE,
});

const getListOfEanCodesForUserSettings = (
  selectedConfigurations: UserSetting
) =>
  selectedConfigurations.configurations
    .map(({ ean }) => ({ ean }))
    .filter((conf): conf is { ean: string } => typeof conf.ean === "string");

const getErrorMessageProps = (errors: GenericGraphQLError[]) => ({
  msgs: [
    { background: webBlack.hex, textColor: white.hex, key: "general.error" },
    {
      background: white.hex,
      textColor: webBlack.hex,
      key: hasOutOfStockError(errors)
        ? "product.details.add.to.cart.not.enough.stock"
        : "product.details.add.to.cart.failure",
    },
  ],
});

const handleAddToCartError = ({
  dispatch,
  errors,
}: {
  dispatch: Dispatch<Action>;
  errors: GenericGraphQLError[];
}) => {
  dispatchEvent("xxl-show-message", getErrorMessageProps(errors));
  const eans = errors
    .filter(isOutOfStockError)
    .flatMap(
      ({ errorInfo: { eanCodesRelatedToError } }) => eanCodesRelatedToError
    );
  dispatch({
    payload: { eans },
    type: "UPDATE_STOCK_STATUS_ON_PLACEMENTS",
  });
};

export const getGtmEventData = (
  bundleProducts: PlacementWithSelectedSize[]
) => {
  const eventData = bundleProducts.flatMap((product) => {
    const { productData, productCode } = product;

    const obj: ProductTrackingData = {
      brand: productData.brand?.name,
      category: productData.categoryCode,
      id: productCode,
      name: productData.baseProductName ?? "",
      price: productData.priceDisplay.salesPriceFormatted,
    };

    return obj;
  });

  return eventData;
};

export type AddBundleToCartArgs = {
  amplifyConfig: AmplifyConfig;
  bundleProducts: PlacementWithSelectedSize[];
  dispatch: Dispatch<Action>;
  selectedConfiguration: UserSetting;
  templateId: string;
};

export const addBundleProductsToCart = async ({
  amplifyConfig,
  bundleProducts,
  dispatch,
  selectedConfiguration,
  templateId,
}: AddBundleToCartArgs) => {
  const {
    data: { data, errors = [] },
    status,
  } = await addBundleForProductConfiguratorToCart(
    templateId,
    bundleProducts.map(mapSelectedPlacementToObjectWithEanAndQuantity),
    getListOfEanCodesForUserSettings(selectedConfiguration),
    amplifyConfig.aws_appsync_graphqlEndpoint,
    amplifyConfig.aws_appsync_apiKey
  );
  if (status === "ERROR") {
    handleAddToCartError({ dispatch, errors: errors as GenericGraphQLError[] });
    throw Error("Error adding products to cart");
  }
  const { itemsCount, totalPriceAsInteger } =
    data?.addBundleProductsToCart.totals ?? {};
  if (typeof itemsCount !== "number") {
    throw Error("ItemsCount is not a number");
  }
  if (typeof totalPriceAsInteger !== "number") {
    throw Error("totalPriceAsInteger is not a number");
  }
  const { items = [] } = data?.addBundleProductsToCart ?? {};
  if (items.length === 0) {
    throw Error("Items array is empty");
  }
  dispatchEvent("XXL_SET_CART_COUNT", {
    count: itemsCount,
  });

  const filteredItems = items.filter((item) => item.templateId === templateId);
  const [{ itemId, ean }] = sortBy(filteredItems, [
    ({ itemId }) => itemId.id,
  ]).reverse();
  return { ean, itemId, totalPriceAsInteger };
};

export type AddAccessoriesToCartArgs = {
  accessories: PlacementWithSelectedSize[];
  amplifyConfig: AmplifyConfig;
  dispatch: Dispatch<Action>;
  itemId: CartItemIdInput;
  templateId: string;
};

export const addAccessoriesToCart = async ({
  accessories,
  amplifyConfig,
  dispatch,
  itemId: parentId,
  templateId,
}: AddAccessoriesToCartArgs) => {
  const {
    data: { data, errors = [] },
    status,
  } = await addAccessoriesToCartGraphQLCall(
    Object.entries(groupBy(accessories, "selectedEan")).map(
      ([ean, listOfAccessories]) => ({
        ean,
        parentId,
        quantity: listOfAccessories.length,
      })
    ),
    templateId,
    amplifyConfig.aws_appsync_graphqlEndpoint,
    amplifyConfig.aws_appsync_apiKey
  );
  if (status === "ERROR") {
    handleAddToCartError({ dispatch, errors: errors as GenericGraphQLError[] });
    throw Error("Error adding accessories to cart");
  }
  if (hasNoValue(data)) {
    throw Error("Data is undefined/null");
  }
  if (!("addAccessoriesToCartItems" in data)) {
    throw Error("addAccessoriesToCartItems is missing in data");
  }
  const { itemsCount } = data.addAccessoriesToCartItems.totals;
  if (typeof itemsCount !== "number") {
    throw Error("itemsCount is not a number");
  }
  dispatchEvent("XXL_SET_CART_COUNT", { count: itemsCount });
};

const hasSelectedSize = (
  placement: Placement
): placement is PlacementWithSelectedSize => "selectedEan" in placement;

export const extractAccessoriesAndBundleProductsAndFirstBundleProduct = (
  placements: Placement[]
): [
  PlacementWithSelectedSize[],
  PlacementWithSelectedSize[],
  PlacementWithSelectedSize | undefined,
] => {
  const selectedPlacements = placements.filter(hasSelectedSize);
  const [_accessories, _bundleProducts] = partition(
    selectedPlacements,
    ({ type }) => type === "accessory"
  );
  const [_firstBundleProduct] = _bundleProducts;
  return [_accessories, _bundleProducts, _firstBundleProduct];
};
