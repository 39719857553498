import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ReactNode } from "react";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../translations";
import { SanitizedHtmlComponent } from "react-app/src/components/Common";

const translateSizeLabel = ({
  ean,
  t,
}: {
  ean: string;
  t: Translate;
}): string | null => {
  const key = `product.size.label.${ean}` as const;
  const translatedSize = t(key);
  return translatedSize !== key ? translatedSize : null;
};

const getSizeLabel = ({
  unit,
  ean,
  size,
  t,
  toggle_products_as_package_quantity = true,
}: {
  unit?: string;
  ean: string | null;
  size: string;
  t: Translate;
  toggle_products_as_package_quantity?: boolean;
}): string => {
  if (toggle_products_as_package_quantity && isNotNullOrUndefined(unit)) {
    return t(`product.unit.amount.${unit}` as TranslationKey);
  }
  const translatedSize = ean !== null ? translateSizeLabel({ ean, t }) : null;
  return translatedSize !== null ? translatedSize : size;
};

export const SizeLabel = (input: {
  unit?: string;
  ean: string | null;
  size: string;
  t: Translate;
  toggle_products_as_package_quantity?: boolean;
}): ReactNode => {
  const label = getSizeLabel(input);
  return (
    <>
      <SanitizedHtmlComponent text={label} />
    </>
  );
};
