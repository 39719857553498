import type { ServiceDescription } from "@xxl/content-api";

export const getDescriptionByCategory = ({
  category,
  listOfServiceDescriptions,
}: {
  category: string;
  listOfServiceDescriptions: ServiceDescription[];
}) =>
  listOfServiceDescriptions.find(({ categories }) =>
    categories?.find(({ categoryCode }) => categoryCode === category)
  ) ?? {};
