import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors, spaces } = xxlTheme;

export const ErrorsWrapper = styled.div`
  padding: ${spaces.smallRegular};
  margin: 0 ${spaces.smallRegular};
  background-color: ${colors.xxlXmasRed};
  color: ${colors.xxlWhite};

  ${MQ("muiTablet")} {
    margin: 0 ${spaces.large};
  }
`;

export const ErrorsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ErrorItem = styled.li`
  margin: ${spaces.mini} 0;
  ${typographyToCss({
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  })};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
