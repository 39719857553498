import { hasValue, isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTrackingDataFromURL } from "next-js-app/src/hooks/useTrackingDataFromURL";
// eslint-disable-next-line import/no-extraneous-dependencies
import { additionalSalesToServiceProducts } from "next-js-app/src/components/common/ServiceProducts/ServiceProducts.helper";
import { useCallback } from "react";
import {
  addCollectableProductsToCart,
  addToCart as addProductToCart,
  SUCCESS_RESPONSE_STATUS,
} from "../../components/Cart/Api/CartAPI";
import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  CART_EDITION_START,
  CART_EDITION_STOP,
  COLLECT_SUCCESS,
  COLLECT_WARNING_CLEAR,
  COLLECT_WARNING_DISPLAY,
  SET_CART_COUNT,
  useCartContext,
} from "../../components/Cart/CartState";
import { handleTrackingAddProductToCart } from "../../components/Cart/Services/tracking";
import type { ProductAddedToCartData } from "../../components/CrossSales";
import { additionalSalesToCrossSalesProducts } from "../../components/CrossSales";
import { PIECE_UNIT } from "../../components/Product/product-helper";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import type {
  CartItemIdInput,
  CollectStoreWarningType,
} from "../../generated/graphql-code-generator";
import type { AdditionalSales } from "../../global";
import { toProductCardDataFromBase } from "../../utils/ProductData/product-card-data-helper";
import { useProductRecommendations } from "../useProductRecommendations/useProductRecommendations";
import type {
  AddAccessoriesToCartArgs,
  AddBundleToCartArgs,
} from "./useAddToCart.helper";
import {
  addAccessoriesToCart,
  addBundleProductsToCart,
  extractAccessoriesAndBundleProductsAndFirstBundleProduct,
  getGtmEventData,
} from "./useAddToCart.helper";
import { useCrossSales } from "./useCrossSales/useCrossSales";
import { useServices } from "./useServices/useServices";

export type AddToCartArgs = {
  brandName: string;
  categoryCode: string;
  categoryCodes?: string[];
  eanCode: string;
  imageUrl: string;
  priceType: string;
  productName: string;
  quantity: number;
  salesPrice: number;
  salesPriceFormatted: string;
  size: {
    code: string;
    name: string;
  };
  styleCode: string;
  ticket: string;
  additionalSales?: AdditionalSales;
  productListName?: string;
  storeId?: string;
  unit?: string;
};

type ClickAndCollectWarning = {
  stock: number;
  type: CollectStoreWarningType;
};

type HandleAdditionalSalesArgs =
  | {
      variant: "addToCart";
      categoryCode: string;
      categoryCodes?: string[];
      cartEntryItemId: CartItemIdInput;
      clickAndCollectWarning: ClickAndCollectWarning | null;
      productData: ProductAddedToCartData;
      additionalSales?: AdditionalSales;
      ean?: string;
      isClickAndCollect?: boolean;
    }
  | {
      variant: "serviceEdit";
      categoryCode: string;
      cartEntryItemId: CartItemIdInput;
      preSelectedService: {
        ean: string;
        itemId: CartItemIdInput;
      };
      additionalSales?: AdditionalSales;
    };

const useAddToCart = (args?: {
  shouldShowServices: boolean;
  shouldShowCrossSales: boolean;
}) => {
  const {
    state: { miniCartCounter },
    dispatch,
  } = useCartContext();
  const { shouldShowCrossSales = true, shouldShowServices = true } = args ?? {};
  const {
    data: {
      configuration,
      featureToggles: {
        toggle_products_as_package_quantity,
        toggle_service_cnc_category_codes,
      },
    },
  } = useSharedData();
  const trackers = useTracking();
  const { getAddToCartRecs, getAdditionalSalesProducts } =
    useProductRecommendations();
  const { openCrossSales, CrossSalesComponent } = useCrossSales();
  const { openServices, ServicesComponent } = useServices();
  const { gtmProductListName } = useTrackingDataFromURL();

  const handleAdditionalSales = useCallback(
    async (additionalSalesArgs: HandleAdditionalSalesArgs) => {
      const { additionalSales, categoryCode, cartEntryItemId, variant } =
        additionalSalesArgs;
      const isClickAndCollect =
        variant === "addToCart" &&
        additionalSalesArgs.isClickAndCollect === true;
      const clickAndCollectWarning =
        variant === "addToCart"
          ? additionalSalesArgs.clickAndCollectWarning
          : null;
      const productData =
        variant === "addToCart" ? additionalSalesArgs.productData : null;
      const ean =
        (variant === "addToCart" ? additionalSalesArgs.ean : null) ?? null;

      if (
        isClickAndCollect &&
        !toggle_service_cnc_category_codes
          .split(",")
          .some((cncCategoryCode) =>
            (additionalSalesArgs.categoryCodes ?? []).includes(
              cncCategoryCode.trim()
            )
          )
      ) {
        return;
      }

      try {
        const [
          additionalSalesProductsResult,
          addToCartRecommendationProductsResult,
        ] = await Promise.allSettled([
          getAdditionalSalesProducts(additionalSales),
          !isClickAndCollect && isNotEmpty(ean) ? getAddToCartRecs(ean) : null,
        ]);
        const additionalSalesProducts =
          additionalSalesProductsResult.status === "fulfilled"
            ? additionalSalesProductsResult.value
            : null;
        const addToCartRecommendationProducts =
          addToCartRecommendationProductsResult.status === "fulfilled"
            ? addToCartRecommendationProductsResult.value
            : null;
        const _serviceProducts = additionalSalesToServiceProducts(
          additionalSalesProducts,
          additionalSales?.crossSalesProductIds ?? []
        );
        const _crossSalesProducts = additionalSalesToCrossSalesProducts(
          additionalSalesProducts,
          (addToCartRecommendationProducts?.baseProducts ?? []).map(
            toProductCardDataFromBase
          )
        );

        if (hasValue(_serviceProducts) && shouldShowServices) {
          openServices({
            parentCartId: cartEntryItemId,
            categoryId: categoryCode,
            serviceProducts: _serviceProducts,
            preSelectedService:
              variant === "serviceEdit"
                ? additionalSalesArgs.preSelectedService
                : undefined,
            onClose: () => {
              if (
                shouldShowCrossSales &&
                !isClickAndCollect &&
                productData !== null
              ) {
                openCrossSales({
                  clickAndCollectWarning,
                  crossSalesProducts: _crossSalesProducts,
                  productData,
                });
              }
            },
          });

          return;
        }
        if (
          shouldShowCrossSales &&
          variant === "addToCart" &&
          !(additionalSalesArgs.isClickAndCollect ?? false) &&
          _crossSalesProducts.length > 0
        ) {
          openCrossSales({
            clickAndCollectWarning: additionalSalesArgs.clickAndCollectWarning,
            crossSalesProducts: _crossSalesProducts,
            productData: additionalSalesArgs.productData,
          });
        }

        return;
      } catch (error) {
        log.error("Failed to fetch additional products.", error);

        return;
      }
    },
    [
      getAddToCartRecs,
      getAdditionalSalesProducts,
      openCrossSales,
      openServices,
      shouldShowCrossSales,
      shouldShowServices,
      toggle_service_cnc_category_codes,
    ]
  );

  const addToCart = useCallback(
    async ({
      additionalSales,
      brandName,
      categoryCode,
      categoryCodes,
      eanCode,
      imageUrl,
      priceType,
      productListName,
      productName,
      quantity,
      salesPrice,
      salesPriceFormatted,
      size,
      storeId,
      styleCode,
      ticket,
      unit,
    }: AddToCartArgs) => {
      dispatch({
        type: ADD_TO_CART,
      });

      try {
        dispatch({
          type: CART_EDITION_START,
        });

        const isClickAndCollect = storeId !== undefined;
        const unitWithPackagesToggleValue =
          toggle_products_as_package_quantity && isNotNullOrUndefined(unit)
            ? unit
            : PIECE_UNIT;

        const response = isClickAndCollect
          ? await addCollectableProductsToCart(
              eanCode,
              quantity,
              storeId,
              configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
              configuration.amplifyConfig.aws_appsync_apiKey
            )
          : await addProductToCart(
              [{ ean: eanCode, quantity, unit: unitWithPackagesToggleValue }],
              configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
              configuration.amplifyConfig.aws_appsync_apiKey
            );
        const { status, data } = response;
        const responseData =
          isNotNullOrUndefined(data.data) && "addProductsToCart" in data.data
            ? data.data.addProductsToCart
            : data.data?.addCollectableProductsToCart;
        const itemsCount = responseData?.totals.itemsCount ?? 0;

        if (
          status === SUCCESS_RESPONSE_STATUS &&
          itemsCount !== miniCartCounter &&
          isNotNullOrUndefined(responseData)
        ) {
          const collectWarning =
            responseData.items.reduce(
              (acc, { collectStore }) => {
                const { warning } = collectStore ?? {};
                if (isNotNullOrUndefined(warning)) {
                  acc = warning;
                }

                return acc;
              },
              null as ClickAndCollectWarning | null
            ) ?? null;

          dispatch({
            type: COLLECT_WARNING_CLEAR,
          });

          if (collectWarning !== null && isClickAndCollect) {
            dispatch({
              type: COLLECT_WARNING_DISPLAY,
            });
          }

          const addedItem = responseData.items.findLast(
            ({ ean }) => ean === eanCode
          );

          if (addedItem !== undefined) {
            handleTrackingAddProductToCart({
              brand: brandName,
              category: categoryCode,
              ean: eanCode,
              name: productName,
              priceType,
              productListName: productListName ?? "NO_LIST",
              salesPrice,
              sizeCode: size.code,
              styleCode,
              ticket,
              trackers,
            });

            void handleAdditionalSales({
              variant: "addToCart",
              additionalSales,
              categoryCode,
              categoryCodes,
              cartEntryItemId: addedItem.itemId,
              clickAndCollectWarning: collectWarning,
              ean: eanCode,
              productData: {
                brand: brandName,
                imageUrl,
                name: productName,
                salesPrice,
                salesPriceFormatted,
                size: size.name,
              },
              isClickAndCollect,
            });
          }

          dispatch({
            type: SET_CART_COUNT,
            payload: { count: itemsCount },
          });
          if (isClickAndCollect) {
            dispatch({
              type: COLLECT_SUCCESS,
            });
          } else {
            dispatch({
              type: ADD_TO_CART_SUCCESS,
              payload: {
                cartId: responseData.id.id,
              },
            });
          }
        }

        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        dispatch({
          type: CART_EDITION_STOP,
        });
      }
    },
    [
      configuration.amplifyConfig.aws_appsync_apiKey,
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      dispatch,
      handleAdditionalSales,
      miniCartCounter,
      toggle_products_as_package_quantity,
      trackers,
    ]
  );

  const addBundleToCart = useCallback(
    async ({
      addAccessoriesToCartData,
      addBundleToCartData,
      additionalSales,
    }: {
      addBundleToCartData: AddBundleToCartArgs;
      addAccessoriesToCartData?: Omit<AddAccessoriesToCartArgs, "itemId">;
      additionalSales?: AdditionalSales;
    }) => {
      const { itemId, totalPriceAsInteger } =
        await addBundleProductsToCart(addBundleToCartData);

      const eventData = getGtmEventData(addBundleToCartData.bundleProducts);
      trackers.sendAddToCartEvent({
        product: eventData,
        list: gtmProductListName ?? "",
      });

      if (addAccessoriesToCartData !== undefined) {
        await addAccessoriesToCart({ ...addAccessoriesToCartData, itemId });
      }

      if (additionalSales !== undefined) {
        const [_accessories, _bundleProducts, firstBundleProduct] =
          extractAccessoriesAndBundleProductsAndFirstBundleProduct(
            addBundleToCartData.bundleProducts
          );

        if (firstBundleProduct === undefined) {
          return;
        }

        const {
          brand,
          categoryCode,
          name,
          primaryImage,
          selectedSize = "",
        } = firstBundleProduct.productData;
        void handleAdditionalSales({
          variant: "addToCart",
          additionalSales,
          clickAndCollectWarning: null,
          categoryCode,
          cartEntryItemId: itemId,
          ean: "",
          productData: {
            brand: brand?.name ?? "",
            imageUrl: primaryImage ?? "",
            name: name ?? "",
            salesPrice: totalPriceAsInteger,
            salesPriceFormatted: totalPriceAsInteger.toString(),
            size: selectedSize,
          },
          isClickAndCollect: false,
        });
      }
    },
    [handleAdditionalSales, trackers]
  );

  return {
    addBundleToCart,
    addToCart,
    CrossSalesComponent,
    handleAdditionalSales,
    ServicesComponent,
  };
};

export { useAddToCart };
