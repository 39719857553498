import { SanitizedHtmlComponent } from "@/react-components/Common";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type { PhotoshootModelData } from "@xxl/product-search-api";
import { useState } from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Drawer } from "react-app/src/components/Drawer";
import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import stringFormat from "string-format";
import { useSizeGuidesQuery } from "../queries/useSizeGuidesQuery";
import { Container } from "./SizeGuide.styled";
import { Button, SectionHeading } from "./styles";

type Props = {
  brandCode?: string;
  categoryCodes?: string[];
  photoshoot?: PhotoshootModelData | null;
  useFallback?: boolean;
  inDrawer?: boolean;
};

export const SizeGuide = ({
  brandCode = "",
  categoryCodes = [""],
  photoshoot,
  useFallback = false,
  inDrawer = false,
}: Props) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const { height: modelHeight, size: modelSize } = photoshoot ?? {};
  const hasModelInfo = hasValue(modelHeight) && hasValue(modelSize);

  const { data: sgData, isLoading } = useSizeGuidesQuery(
    brandCode,
    categoryCodes
  );
  const hasSizeGuide = isLoading || isNotNullOrUndefined(sgData);

  if (!hasSizeGuide && !hasModelInfo) {
    return useFallback ? (
      <Container inDrawer={inDrawer}>
        <XxlStack>&nbsp;</XxlStack>
      </Container>
    ) : null;
  }

  return (
    <>
      <Container inDrawer={inDrawer}>
        {hasSizeGuide && (
          <XxlStack direction="row" justifyContent="flex-end">
            <Button
              onClick={toggleIsOpen}
              data-testid="size-guide-button"
              style={{ padding: 0 }}
            >
              <Text fontFamily="medium">{t("product.details.size.guide")}</Text>
            </Button>
          </XxlStack>
        )}
        {hasModelInfo && (
          <Text>
            {stringFormat(
              t("product.details.model.size"),
              modelHeight.toString(),
              modelSize.toUpperCase()
            )}
          </Text>
        )}
      </Container>
      {hasSizeGuide && (
        <Drawer
          open={isOpen}
          heading={t("product.details.size.guide")}
          onClose={toggleIsOpen}
          customPadding={xxlTheme.spaces.smallRegular}
        >
          <SectionHeading>
            <SanitizedHtmlComponent text={sgData?.content} />
          </SectionHeading>
        </Drawer>
      )}
    </>
  );
};
