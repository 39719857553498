import { EntitySortingParameterCustomNameEnum } from "@xxl/search-api";

export const ACTION_QUERY_PARAMETER = "action";
export const SEARCH_QUERY_PARAMETER = "query";
export const CATEGORY_PATH_QUERY_PARAMETER = "categoryPath";
export const FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER = "showAll";
export const MINIMUM_QUERY_LENGTH = 2;
export const PAGE_QUERY_PARAMETER = "pages";
export const SORT_QUERY_PARAMETER = "sort";
export const INITIAL_PAGE_NUMBER = 0;
export const IS_FETCHING_PRODUCTS = "IS_FETCHING_PRODUCTS";
export const DEFAULT_PAGE_SIZE = 36;
export const DEFAULT_SEARCH_SORT =
  EntitySortingParameterCustomNameEnum.relevance;
export const DEFAULT_CAMPAIGN_SORT =
  EntitySortingParameterCustomNameEnum.campaignPage;
export const COOKIE_LOAD_SUCCESS = "COOKIE_LOAD_SUCCESS";
export const SEARCH_REQUEST_SUCCESS = "SEARCH_REQUEST_SUCCESS";
export const SEARCH_REQUEST_FAILED = "SEARCH_REQUEST_FAILED";
export const DO_FALLBACK_SEARCH = "DO_FALLBACK_SEARCH";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const SET_PAGE = "SET_PAGE";
export const CHANGE_SORT = "CHANGE_SORT";
export const UPDATE_SORTS_FOR_LOGIN_STATUS = "UPDATE_SORTS_FOR_LOGIN_STATUS";
export const ADD_SELECTED_DISTINCT_FILTER = "ADD_SELECTED_DISTINCT_FILTER";
export const TOGGLE_CATEGORY_FILTER = "TOGGLE_CATEGORY_FILTER";
export const SET_HIDDEN_PRODUCT_ITEMS_NUMBER =
  "SET_HIDDEN_PRODUCT_ITEMS_NUMBER";
export const REMOVE_SELECTED_DISTINCT_FILTER =
  "REMOVE_SELECTED_DISTINCT_FILTER";
export const REMOVE_ALL_FILTERS = "REMOVE_ALL_FILTERS";
export const REMOVE_ALL_ATTRIBUTE_FILTERS = "REMOVE_ALL_ATTRIBUTE_FILTERS";
export const RESET_PAGE = "RESET_PAGE";
export const FINAL_SEARCH_FAILED = "FINAL_SEARCH_FAILED";
export const CAMPAIGN_PAGE_REQUEST_SUCCESS = "CAMPAIGN_PAGE_REQUEST_SUCCESS";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const FACET_CATEGORY_ID = "category";
export const CAMPAIGN_ATTRIBUTE_NAME = "campaignId";
export const CAMPAIGN_LOOP_ATTRIBUTE_NAME = "campaignId_string";
export const CATEGORY_CONTENT_REQUEST_SUCCESS =
  "CATEGORY_CONTENT_REQUEST_SUCCESS";
export const CATEGORY_GUIDES_REQUEST_SUCCESS =
  "CATEGORY_GUIDES_REQUEST_SUCCESS";
export const PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS =
  "PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS";
export const CATEGORY_INFO_REQUEST_SUCCESS = "CATEGORY_INFO_REQUEST_SUCCESS";
export const CATEGORY_DATA_REQUEST_SUCCESS = "CATEGORY_DATA_REQUEST_SUCCESS";
export const SHOW_ALL_CATEGORIES = "SHOW_ALL_CATEGORIES";
export const SHOW_ALL_CATEGORIES_CODE = "all";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const HISTORY_NAVIGATE_BACK = "HISTORY_NAVIGATE_BACK";
export const BRAND_ATTRIBUTE_NAME_FOR_ELEVATE = "brand";
export const BRAND_ATTRIBUTE_NAME_FOR_LOOP = "brandName";
export const BRAND_ATTRIBUTE_NAMES_FOR_SOLR = [
  "brandName_fi_string",
  "brandName_no_string",
  "brandName_sv_string",
];
export const BRAND_CODE_ATTRIBUTE_NAME_FOR_LOOP = "brandCode";
export const BRAND_CODE_ATTRIBUTE_NAME_FOR_SOLR = "brandCode_string";
export const CATEGORY_ATTRIBUTE_NAME = "category_string_mv";
export const BRAND_DATA_REQUEST_SUCCESS = "BRAND_DATA_REQUEST_SUCCESS";
export const BRAND_FACET_ATTRIBUTE_NAME = "brandName";
export const CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_LOOP = "campaignLabels";
export const CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_SOLR =
  "campaignLabels_string_mv";
export const CATEGORY_FACET_ATTRIBUTE_NAME = "facetCategory";
export const USER_FACET_ATTRIBUTE_NAME = "allClassCategoriesUser";
export const CENSHARE_USER_FACET_ATTRIBUTE_NAME =
  "pim_mandatory_User_string_mv";
export const COLOR_FACET_ATTRIBUTE_NAME = "color"; // Elevate
export const SIZE_FACET_ATTRIBUTE_NAME = "size";
export const PRICE_FACET_ATTRIBUTE_NAME = "price";
export const PRICE_VALUE_FACET_ATTRIBUTE_NAME = "priceValue";
export const STYLE_FACET_ATTRIBUTE_NAME = "style"; // Loop/Solr
export const TABS_CREATE_SUCCESS = "TABS_CREATE_SUCCESS";
export const TABS_CHANGE = "TABS_CHANGE";
export const SET_SEARCH_RESULT_PAGE = "SET_SEARCH_RESULT_PAGE";
export const SET_BRANDS_CONTENT = "SET_BRANDS_CONTENT";
export const SET_GUIDES_CONTENT = "SET_GUIDES_CONTENT";
export const SET_STORES_CONTENT = "SET_STORES_CONTENT";
export const SET_FAQ_CONTENT = "SET_FAQ_CONTENT";
export const SET_SEARCH_SUGGESTIONS = "SET_SEARCH_SUGGESTIONS";

export const TEAMSALES_HOST_NAME = "teamsales";
export const WAREHOUSE_ATTRIBUTE_NAME = "warehouse_string_mv";
export const MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME =
  "multiChannelAvailability_string_mv";

export const BRANDS_CATEGORY_CODE = "BRANDS_CATEGORY_CODE";
export const CUSTOM_CATEGORY_CODE = "CUSTOM_CATEGORY_CODE";
export const CUSTOMER_SERVICE_CATEGORY_CODE = "CUSTOMER_SERVICE_CATEGORY_CODE";
export const GIFTCARD_CATEGORY_CODE = "GIFTCARD_CATEGORY_CODE";
export const GUIDE_CATEGORY_CODE = "GUIDE_CATEGORY_CODE";
export const NAVIGATE_BACK_CODE = "NAVIGATE_BACK_CODE";
export const NEWS_CATEGORY_CODE = "NEWS_CATEGORY_CODE";
export const REWARD_CATEGORY_CODE = "REWARD_CATEGORY_CODE";
export const SHOWMORE_CATEGORY_CODE = "SHOWMORE_CATEGORY_CODE";
export const TEAMSALES_CATEGORY_CODE = "TEAMSALES_CATEGORY_CODE";
export const WORKSHOP_CATEGORY_CODE = "WORKSHOP_CATEGORY_CODE";
export const SHOW_ADDITIONAL_VIEW = "SHOW_ADDITIONAL_VIEW";
export const LANDING_PAGE_CODE = "LANDING_PAGE_CODE";

export const QUERY_PARAMETERS = [
  SEARCH_QUERY_PARAMETER,
  PAGE_QUERY_PARAMETER,
  SORT_QUERY_PARAMETER,
];
export const CATEGORY_ROUTE_PREFIX_LETTER = "c";
export const CATEGORY_ROUTE_PREFIX = `/${CATEGORY_ROUTE_PREFIX_LETTER}/`;
export const QUERY_PARAMETER_ACTIONS = {
  ScrollToProductList: "scrollToProductList",
};
export const SEARCH_ROOT_ID = "js-react-search";
export const UPDATE_RANGE_FILTER = "UPDATE_RANGE_FILTER";
export const LOOP_API_VERSION = "V3";

export const UTM_QUERY_PARAM_PREFIX = "utm_";
export const GTM_QUERY_PARAM_PREFIX = "gtm";
export const GCLID_QUERY_PARAM_PREFIX = "gclid";
const VTRID_QUERY_PARAM_PREFIX = "vtid";
const ECLUB_QUERY_PARAM_PREFIX = "eclub";
const FBCLID_QUERY_PARAM_PREFIX = "fbclid";
export const SYMPLIFY_PREVIEW_MODE_PREFIX = "sgpreviewmode";
export const SYMPLIFY_VARIANT_ID_PREFIX = "varid";
const LOGROCKET_RECORD_PARAM = "record";

export const WHITELISTED_QUERY_PARAMS = [
  UTM_QUERY_PARAM_PREFIX,
  GTM_QUERY_PARAM_PREFIX,
  GCLID_QUERY_PARAM_PREFIX,
  VTRID_QUERY_PARAM_PREFIX,
  ECLUB_QUERY_PARAM_PREFIX,
  FBCLID_QUERY_PARAM_PREFIX,
  SYMPLIFY_PREVIEW_MODE_PREFIX,
  SYMPLIFY_VARIANT_ID_PREFIX,
  LOGROCKET_RECORD_PARAM,
];
