import { QUERY_KEYS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { log } from "@xxl/logging-utils";

type UseProductTooltipsQueryParams = {
  isEnabled: boolean;
};

export const useProductTooltipsQuery = ({
  isEnabled = false,
}: UseProductTooltipsQueryParams) => {
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();

  const fetchProductTooltips = async () => {
    try {
      const {
        data: { result = [] },
      } = await contentApi.getProductTooltips(legacySiteUidToSiteUid(siteUid));
      return result;
    } catch (error) {
      log.error("Unable to fetch product tooltips");
      log.debug("Unable to fetch product tooltips: ", error);
      return [];
    }
  };

  return useQuery({
    enabled: isEnabled,
    queryFn: fetchProductTooltips,
    queryKey: [QUERY_KEYS.PRODUCT_TOOLTIPS],
    staleTime: Infinity,
  });
};
