import React from "react";
import styled from "@emotion/styled/macro";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { hasValue } from "@xxl/common-utils";

const { div, img } = styled;

const Wrapper = div<{
  minHeight?: string;
}>`
  text-align: center;
  align-content: center; 
  min-height: ${({ minHeight }) => (hasValue(minHeight) ? minHeight : "160px")};
`;

const Img = img`
  max-width: 200px;
  height: auto;
`;

const XXLLoader = ({ minHeight }: { minHeight?: string }) => {
  const { t } = useTranslations();

  return (
    <Wrapper minHeight={minHeight}>
      <picture>
        <Img
          src="/static/images/XXL-loader.gif"
          alt={t("page.loading")}
          width={200}
          height={160}
        />
      </picture>
    </Wrapper>
  );
};

export { XXLLoader };
