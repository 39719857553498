import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text/Text";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery/useXxlMediaQuery";
import type { StockStatusType } from "@xxl/product-search-api";
import { color } from "@xxl/theme";
import { AnimatePresence, motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import React from "react";
import { StockStatusIndicatorComponent } from "./StockStatusIndicatorComponent/StockStatusIndicatorComponent";
import { StoreDetailsSectionComponent } from "./StoreDetailsSectionComponent/StoreDetailsSectionComponent";

const TRANSITION = { duration: 0.2, ease: [0.65, 0.05, 0.36, 1] };
const ACCORDION_BORDER = `1px solid ${color.mediumGray.cssVariable}`;
const MIN_STORE_DETAILS_HEIGHT = "98px";
const MOBILE_BUFFER_HEIGHT = "12px";
const DESKTOP_BUFFER_HEIGHT = "24px";

const getAnimatedBufferHeight = (isMobile: boolean, isOpen: boolean) =>
  isMobile
    ? isOpen
      ? MOBILE_BUFFER_HEIGHT
      : "0"
    : isOpen
      ? DESKTOP_BUFFER_HEIGHT
      : "0";

type Index = number | false;

const StoreListAccodionContext = React.createContext<
  | {
      expandedIdx: Index;
      setExpanded: (idx: Index) => void;
    }
  | undefined
>(undefined);

const Accordion = ({ children }: PropsWithChildren) => {
  const [expandedIdx, setExpanded] = React.useState<Index>(false);

  return (
    <StoreListAccodionContext.Provider value={{ expandedIdx, setExpanded }}>
      {children}
    </StoreListAccodionContext.Provider>
  );
};

const useStoreListAccordion = () => {
  const context = React.useContext(StoreListAccodionContext);
  if (context === undefined) {
    throw new Error(
      "useStoreListAccordion must be used within a StoreListAccordion"
    );
  }
  return context;
};

const Body = ({ idx, storeId }: { idx: Index; storeId: string }) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const { expandedIdx } = useStoreListAccordion();
  const isOpen = idx === expandedIdx;

  return (
    <>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", overflow: "hidden" },
              collapsed: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={TRANSITION}
          >
            <motion.div style={{ minHeight: MIN_STORE_DETAILS_HEIGHT }}>
              <StoreDetailsSectionComponent
                storeId={storeId}
                isMobile={isMobile}
              />
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>

      <motion.div
        animate={{ height: getAnimatedBufferHeight(isMobile, isOpen) }}
        style={{
          borderBottom: ACCORDION_BORDER,
        }}
      />
    </>
  );
};

const Heading = ({
  idx,
  storeName,
  stockStatus,
  generalAddressAndHoursText,
}: {
  idx: number;
  storeName: string;
  stockStatus: StockStatusType;
  generalAddressAndHoursText: string;
}) => {
  const { expandedIdx, setExpanded } = useStoreListAccordion();
  const isOpen = idx === expandedIdx;
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  if (!isMobile) {
    return (
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : idx)}
      >
        <XxlStack
          display={"grid"}
          gridTemplateColumns={"1fr 1fr 1fr"}
          direction={"row"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          py={"24px"}
          style={{ cursor: "pointer" }}
        >
          <Text typography={isOpen ? "baseBold" : "base"}>{storeName}</Text>
          <StockStatusIndicatorComponent stockStatus={stockStatus} />
          <XxlStack direction={"row"} gap={"4px"}>
            {generalAddressAndHoursText}
            <motion.div
              animate={{ rotate: isOpen ? "180deg" : "0" }}
              transition={TRANSITION}
              style={{ maxHeight: "fit-content" }}
            >
              <Icon name="CaretDown" />
            </motion.div>
          </XxlStack>
        </XxlStack>
      </motion.header>
    );
  }

  return (
    <motion.header
      initial={false}
      onClick={() => setExpanded(isOpen ? false : idx)}
    >
      <XxlStack py={"12px"} gap={"6px"}>
        <Text typography={isOpen ? "baseBold" : "base"}>{storeName}</Text>
        <StockStatusIndicatorComponent stockStatus={stockStatus} />
      </XxlStack>
      <XxlStack
        direction={"row"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        pb={"12px"}
      >
        {generalAddressAndHoursText}
        <motion.div
          animate={{ rotate: isOpen ? "180deg" : "0" }}
          transition={TRANSITION}
          style={{ maxHeight: "fit-content" }}
        >
          <Icon name="CaretDown" />
        </motion.div>
      </XxlStack>
    </motion.header>
  );
};

export const StoreListComponent = {
  Accordion,
  Body,
  Heading,
};
