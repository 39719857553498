import { isNotNullOrUndefined, isNullOrUndefined } from "@xxl/common-utils";
import type {
  ColourThemeData as ColorThemeDefault,
  ProductData as ProductDataDefault,
} from "@xxl/frontend-api";
import { PriceDisplayDataTypeEnum } from "@xxl/frontend-api";
import type { V3CategoryData } from "@xxl/pim-api";
import type { ProductData as ProductDataConfigurator } from "@xxl/product-configurator-api";
import type { CampaignBadgeData } from "@xxl/product-search-api";
import type {
  ColorTheme as ColorThemeSearch,
  ProductData as ProductDataSearch,
} from "@xxl/search-api";
import { color } from "@xxl/theme";
import isEmpty from "lodash/isEmpty";
import type { Trackers } from "../../contexts/Tracking";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import type { ListProduct, ProductMetaData } from "../../global";
import type { GetFrontendPriceDisplayDataProps } from "../../utils/PriceDisplay/types";
import { getClassificationsFromUsps } from "../../utils/ProductAttributes/attributes-helper";
import type { ProductCardDataV2 } from "../../utils/ProductData/product-card-data-helper";
import type {
  Filter,
  PriceDisplayVariant,
  ProductType,
  StockStatus,
} from "../../utils/data-types";
import { getNumericMonthDay } from "../../utils/xxl-format-date";
import { formatPrice } from "../../utils/xxl-price-format";
import { SIZE_FACET_ATTRIBUTE_NAME } from "../Search/Constants";
import { isDistinctFacetParameter } from "../Search/UrlPath";
import { compare } from "./SizeAttributeComparator";
import { PERSONALIZED_PRODUCT_LIST_QUERY_NAME } from "./constants";
import type { ProductProps } from "./types";

export type ColorTheme = ColorThemeSearch | ColorThemeDefault;
export type CombinedProductData =
  | ProductDataSearch
  | ProductDataDefault
  | ProductDataConfigurator;

export const PIECE_UNIT = "piece";
export const BOX_UNIT = "box";
export const PACKAGE_UNIT = "package";

type Theme = {
  backgroundColor?: string;
  foregroundColor?: string;
  name?: string;
};
/**
 * Helper function to return color theme that differs in search and frontend api
 */
export const getColorTheme = (priceDisplay?: {
  colorTheme?: Theme;
  colourTheme?: Theme;
}): ColorTheme | undefined => {
  if (!isNotNullOrUndefined(priceDisplay)) {
    return undefined;
  }
  if ("colourTheme" in priceDisplay) {
    return priceDisplay.colourTheme;
  }
  if ("colorTheme" in priceDisplay) {
    return priceDisplay.colorTheme;
  }
  return undefined;
};

/**
 * Helper function to return foreground color that differ in search and frontend api
 */
export const getForegroundColorFromColorTheme = (
  colorTheme: ColorTheme
): string | undefined => {
  if ("foregroundColor" in colorTheme) {
    return colorTheme.foregroundColor;
  }
  if ("foregroundColour" in colorTheme) {
    return colorTheme.foregroundColour;
  }
  return undefined;
};

/**
 * Helper function to return background color that differ in search and frontend api
 */
export const getBackgroundColorFromColorTheme = (
  colorTheme: ColorTheme
): string | undefined => {
  if ("backgroundColor" in colorTheme) {
    return colorTheme.backgroundColor;
  }
  if ("backgroundColour" in colorTheme) {
    return colorTheme.backgroundColour;
  }
  return undefined;
};

type RetrieveProductUrlWithPreselectedSizeProps = {
  baseUrl: string;
  selectedFilters: Filter[];
  productUrl: string;
  sizeOptions?: {
    size?: string;
  }[];
};
export const retrieveProductUrlWithPreselectedSize = ({
  baseUrl,
  selectedFilters,
  productUrl,
  sizeOptions,
}: RetrieveProductUrlWithPreselectedSizeProps): URL => {
  const resultUrl = new URL(productUrl, baseUrl);

  if (selectedFilters.length > 0) {
    const sizeFilter = selectedFilters.find(
      (filter) => filter.attributeName === SIZE_FACET_ATTRIBUTE_NAME
    );

    if (sizeFilter !== undefined && sizeFilter.selected !== undefined) {
      const sizeFilter = selectedFilters
        .filter(isDistinctFacetParameter)
        .find((f) => f.attributeName === SIZE_FACET_ATTRIBUTE_NAME);
      if (sizeFilter === undefined) {
        throw Error(
          "[retrieveProductUrlWithPreselectedSize] Size filter is undefined."
        );
      }

      const selectedSizeFilters = sizeFilter.selected?.map(String) ?? [];

      if (selectedSizeFilters.length > 0) {
        const sortedSizeFilters = selectedSizeFilters.sort((size1, size2) =>
          compare(size1, size2)
        );

        const productSizes = sizeOptions?.map((sizeOption) => sizeOption.size);

        const firstSizeFilter =
          productSizes !== undefined
            ? sortedSizeFilters.find((size) => productSizes.includes(size))
            : sortedSizeFilters[0];

        if (firstSizeFilter !== undefined) {
          resultUrl.searchParams.set(
            "preselectedSize",
            firstSizeFilter.toString()
          );
        }
      }
    }
  }

  return resultUrl;
};

type GetOptionalHrefProps = {
  personalizedProductListName?: string;
  product?: {
    url?: string;
    sizeOptions?: {
      size?: string;
    }[];
  };
  selectedFilters: Filter[];
};
export const getOptionalRelativeHref = ({
  personalizedProductListName,
  product,
  selectedFilters,
}: GetOptionalHrefProps): string | undefined => {
  const { url: productUrl, sizeOptions } = product ?? {};
  if (productUrl === undefined) {
    return;
  }

  const dummyBase = "https://dummy";
  const url = retrieveProductUrlWithPreselectedSize({
    baseUrl: dummyBase,
    productUrl,
    selectedFilters,
    sizeOptions,
  });

  if (personalizedProductListName !== undefined) {
    url.searchParams.append(
      PERSONALIZED_PRODUCT_LIST_QUERY_NAME,
      personalizedProductListName
    );
  }

  return url.href.replace(dummyBase, "");
};

export const stockStatusBulletColor = (level: StockStatus): string => {
  switch (level) {
    case "INSTOCK":
      return color.green.hex;
    case "LOWSTOCK":
      return color.amber.hex;
    case "OUTOFSTOCK":
      return color.red.hex;
    default:
      return "";
  }
};

export const getStockStatusLabel = (
  level: StockStatus,
  t: Translate
): string => {
  switch (level) {
    case "INSTOCK":
      return t("product.details.storestock.in.stock");
    case "LOWSTOCK":
      return t("product.details.storestock.low");
    default:
      return t("product.details.storestock.out");
  }
};

const getCategoryId = ({
  breadcrumbs = [],
}: {
  breadcrumbs: V3CategoryData[];
}) => {
  if (breadcrumbs.length === 0) {
    throw Error("Empty breadcrumbs array");
  }
  const { code } =
    breadcrumbs
      .filter(
        (breadcrumb): breadcrumb is { code: string } => "code" in breadcrumb
      )
      .at(-1) ?? {};
  if (code === undefined) {
    throw Error("Missing categoryId");
  }
  return code;
};

type GetHighlightedLabelProps = {
  priceDisplay?: {
    endDate?: string;
    label?: string;
    startDate?: string;
    type?: PriceDisplayVariant;
  };
  siteDefaultLanguage: string;
};

function getHighlightedLabel({
  priceDisplay,
  siteDefaultLanguage,
}: GetHighlightedLabelProps): string | undefined {
  if (priceDisplay === undefined || isEmpty(priceDisplay.label)) {
    return;
  }

  const isOnlyAvailableOnline =
    priceDisplay.type === PriceDisplayDataTypeEnum.ONLY_AVAILABLE_ONLINE;
  if (isOnlyAvailableOnline) {
    return;
  }
  priceDisplay.type;
  const hasStartAndEndDate =
    "startDate" in priceDisplay &&
    priceDisplay.startDate !== undefined &&
    priceDisplay.endDate !== undefined;

  return hasStartAndEndDate
    ? `${priceDisplay.label ?? ""} ${getNumericMonthDay(
        priceDisplay.startDate as string,
        siteDefaultLanguage
      )} - ${getNumericMonthDay(
        priceDisplay.endDate as string,
        siteDefaultLanguage
      )}`
    : priceDisplay.label;
}

const getAvailableColorsText = ({
  numberOfAvailableColors,
  t,
}: {
  numberOfAvailableColors: number;
  t: Translate;
}) =>
  `${numberOfAvailableColors} ${t(
    numberOfAvailableColors > 1 ? "product.colors" : "product.color"
  )}`;

type Units =
  | {
      code?: string;
      visible?: boolean;
    }[]
  | undefined;

const isSoldInPackages = (units: Units): boolean =>
  isNotNullOrUndefined(units) &&
  units.length > 1 &&
  units.find(
    (item) => item.code === PIECE_UNIT && String(item.visible) === "false"
  ) !== undefined;

type PriceDisplay = {
  cheapestPrice?: number;
  cheapestPriceFormatted?: string;
  colorTheme?: {
    backgroundColor?: string;
    foregroundColor?: string;
    name?: string;
  };
  colourTheme?: {
    backgroundColour?: string;
    foregroundColour?: string;
    name?: string;
  };
  iconUrl?: string;
  invertedPrice?: number;
  invertedPriceFormatted?: string;
  label?: string;
  latestPrice?: number;
  latestPriceFormatted?: string;
  otherPrice?: string;
  otherPriceDisclaimer?: string;
  previousPrice?: number;
  previousPriceFormatted?: string;
  priceSplash?: string;
  salesPrice?: number;
  salesPriceDisclaimer?: string;
  salesPriceFormatted?: string;
  type?: PriceDisplayVariant;
};

type MultiBuyPrices = PriceDisplay & {
  unit?: string;
};

type MultiBuyUnit = {
  code?: string;
  conversions?: {
    operationValue?: number;
    conversionTo?: string;
  }[];
};

const getPackagePriceBoughtSeparately = (
  multibuyPrices: MultiBuyPrices[],
  units: MultiBuyUnit[]
) => {
  const unitSalesPrice = multibuyPrices.find(
    ({ unit }) => unit === PIECE_UNIT
  )?.salesPrice;
  const packageUnitConversion = units
    .find((item) => item.code === PACKAGE_UNIT || item.code === BOX_UNIT)
    ?.conversions?.find(
      ({ conversionTo }) => conversionTo === PIECE_UNIT
    )?.operationValue;
  if (unitSalesPrice === undefined || packageUnitConversion === undefined) {
    return undefined;
  }
  const priceBoughtSeparately = unitSalesPrice * packageUnitConversion;
  return {
    priceBoughtSeparately,
    priceBoughtSeparatelyFormatted: formatPrice(priceBoughtSeparately),
  };
};

const getPriceDisplaysForPackages = (
  priceDisplay?: PriceDisplay & {
    multibuyPrices?: MultiBuyPrices[];
  },
  units?: MultiBuyUnit[]
): PriceDisplay | undefined => {
  const unitBox = units?.find(
    (item) => item.code === BOX_UNIT || item.code === PACKAGE_UNIT
  );
  if (
    isNotNullOrUndefined(priceDisplay) &&
    isNotNullOrUndefined(priceDisplay.multibuyPrices) &&
    isNotNullOrUndefined(unitBox) &&
    isNotNullOrUndefined(units)
  ) {
    const boxPrice = priceDisplay.multibuyPrices.find(
      ({ unit }) => unit === unitBox.code
    );
    if (isNotNullOrUndefined(boxPrice)) {
      const boxPriceBoughtSeparately = getPackagePriceBoughtSeparately(
        priceDisplay.multibuyPrices,
        units
      );
      return {
        ...priceDisplay,
        salesPrice: boxPrice.salesPrice ?? priceDisplay.salesPrice,
        salesPriceFormatted:
          boxPrice.salesPriceFormatted ?? priceDisplay.salesPriceFormatted,
        latestPrice: boxPrice.latestPrice ?? priceDisplay.latestPrice,
        latestPriceFormatted:
          boxPrice.latestPriceFormatted ?? priceDisplay.latestPriceFormatted,
        previousPrice:
          boxPrice.previousPrice ??
          priceDisplay.previousPrice ??
          boxPriceBoughtSeparately?.priceBoughtSeparately,
        previousPriceFormatted:
          boxPrice.previousPriceFormatted ??
          priceDisplay.previousPriceFormatted ??
          boxPriceBoughtSeparately?.priceBoughtSeparatelyFormatted,
        cheapestPrice: boxPrice.cheapestPrice ?? priceDisplay.cheapestPrice,
        cheapestPriceFormatted:
          boxPrice.cheapestPriceFormatted ??
          priceDisplay.cheapestPriceFormatted,
        invertedPrice: boxPrice.invertedPrice ?? priceDisplay.invertedPrice,
        invertedPriceFormatted:
          boxPrice.invertedPriceFormatted ??
          priceDisplay.invertedPriceFormatted,
      };
    }
  }
  return priceDisplay;
};

const getPackageUnitName = (units?: { code?: string }[]): string => {
  if (isSoldInPackages(units)) {
    return (
      units?.find(({ code }) => code === BOX_UNIT || code === PACKAGE_UNIT)
        ?.code ?? BOX_UNIT
    );
  }
  return PACKAGE_UNIT;
};

export const trackProductClick = (
  trackers: Trackers,
  product: {
    brand?: {
      name?: string;
    };
    categoryName?: string;
    code?: string;
    name?: string;
    priceDisplay?: {
      salesPriceFormatted?: string;
    };
    style?: string;
  },
  productMetaData: ProductMetaData,
  listName?: string,
  ticket?: string
): void => {
  const { list, position, pageType } = productMetaData;
  const category = "categoryName" in product ? product.categoryName : undefined;
  const variant = "style" in product ? product.style : undefined;
  const price = product.priceDisplay?.salesPriceFormatted ?? "0";

  const gtmProductData: ListProduct = {
    name: product.name ?? "",
    id: product.code ?? "",
    brand: product.brand?.name ?? "",
    category,
    price,
    variant,
    list,
    position,
    pageType,
  };

  const eventProps = {
    product: gtmProductData,
    list: listName ?? "",
    ticket,
  };
  trackers.sendProductClickEvent(eventProps);
};

const transformSizeIdToStyle = (productCode: string): string => {
  const lowerCaseProductCode = productCode.toLowerCase();
  return lowerCaseProductCode.includes("size")
    ? `${lowerCaseProductCode.split("size")[0]}Style`
    : productCode;
};

const toProductCardData = ({
  product,
  isLoggedIn,
  t,
  ticket,
  type,
}: {
  product: CombinedProductData;
  isLoggedIn: boolean;
  t: Translate;
  ticket: string;
  type?: string;
}): ProductProps["product"] => {
  const multiChannelAvailability =
    "multiChannelAvailability" in product
      ? product.multiChannelAvailability
      : undefined;
  const usp = "usp" in product ? product.usp : null;

  return {
    ...product,
    ...{
      brandName: product.brand?.name,
      multiChannelAvailability: multiChannelAvailability?.map((value) =>
        value === "STORE" ? "STORE" : "ONLINE"
      ),
      ticket,
      type: type === undefined ? "NORMAL" : (type as ProductType),
      ...(isNotNullOrUndefined(usp) && {
        usps: getClassificationsFromUsps(usp, isLoggedIn, t),
      }),
    },
  };
};

type CommonGetPriceDataArgs = {
  productType: ProductType;
  showPackagePrice: boolean;
  siteDefaultLanguage: string;
  toggleProductsAsPackageQuantity: boolean;
  units: Units;
};

type GetPriceDataArgs =
  | ({
      version: 1;
      priceDisplay: GetFrontendPriceDisplayDataProps["priceDisplay"] & {
        colorTheme?: Theme;
        colourTheme?: Theme;
      };
    } & CommonGetPriceDataArgs)
  | ({
      version: 2;
      priceData: ProductCardDataV2["price"];
      campaignHighlightedLabel?: CampaignBadgeData;
      campaignRibbon?: CampaignBadgeData;
    } & CommonGetPriceDataArgs);

const getPriceData = (
  args: GetPriceDataArgs
): {
  colorTheme: ColorTheme;
  highlightedLabel: string | undefined;
  priceSplash: string | undefined;
  colorThemeName?: string;
} | null => {
  const {
    productType,
    showPackagePrice,
    siteDefaultLanguage,
    toggleProductsAsPackageQuantity,
    version,
    units,
  } = args;
  const isMultipack = ["PRODUCT_MULTIPACK", "BUNDLE_MULTIPACK"].includes(
    productType
  );
  const showPackagePriceValues = showPackagePrice || isMultipack;
  const isPackageSell = isSoldInPackages(units) || isMultipack;

  if (version === 1) {
    const { priceDisplay } = args;
    const { priceSplash } = priceDisplay;
    const priceDisplayWithPackages =
      isPackageSell && toggleProductsAsPackageQuantity && showPackagePriceValues
        ? getPriceDisplaysForPackages(priceDisplay, units)
        : priceDisplay;

    if (
      isNullOrUndefined(priceDisplayWithPackages) ||
      isNullOrUndefined(priceDisplay)
    ) {
      return null;
    }

    const highlightedLabel = getHighlightedLabel({
      priceDisplay: priceDisplayWithPackages,
      siteDefaultLanguage,
    });
    const colorTheme = getColorTheme(priceDisplay) ?? {};

    return {
      colorTheme,
      highlightedLabel,
      priceSplash,
    };
  }

  const { campaignRibbon, campaignHighlightedLabel } = args;

  return {
    colorTheme: {
      backgroundColor:
        campaignRibbon?.content?.backgroundColor ??
        campaignHighlightedLabel?.content?.backgroundColor,
      foregroundColor:
        campaignRibbon?.content?.frontColor ??
        campaignHighlightedLabel?.content?.frontColor,
      name: campaignHighlightedLabel?.content?.name,
    },
    highlightedLabel: campaignHighlightedLabel?.content?.text,
    priceSplash: campaignRibbon?.content?.text,
  };
};

const getProductType = (product: CombinedProductData): ProductType => {
  const ProductType =
    "ProductType" in product ? product.ProductType : undefined;
  return (("productType" in product ? product.productType : ProductType) ??
    "NORMAL") as ProductType;
};

type ImageSize = { w: number; h: number };

const IMAGE_SIZES = {
  ONE: {
    w: 351,
    h: 344,
  },
  TWO: {
    w: 215,
    h: 298,
  },
  THREE: {
    w: 324,
    h: 380,
  },
};

const MAPPING_IMAGE_SIZES = {
  mobile: {
    1: IMAGE_SIZES.ONE,
    2: IMAGE_SIZES.TWO,
  },
  desktop: {
    4: IMAGE_SIZES.THREE,
    6: IMAGE_SIZES.TWO,
  },
};

const getProductImageSizes = <T extends keyof typeof MAPPING_IMAGE_SIZES>({
  device,
  columnAmount,
}: {
  device: T;
  columnAmount: keyof (typeof MAPPING_IMAGE_SIZES)[T];
}) => {
  const defaultValue = IMAGE_SIZES.TWO;
  const value = MAPPING_IMAGE_SIZES[device][columnAmount] as
    | ImageSize
    | undefined;

  return value ?? defaultValue;
};

const colorNameToColorTheme = (colorThemeName: string) => {
  const entries = Object.entries(color);

  for (const [_key, value] of entries) {
    if (value.name.toLowerCase() === colorThemeName.toLocaleLowerCase()) {
      return {
        backgroundColor: value.hex,
        textColor: value.fontColor,
      };
    }
  }

  return {
    backgroundColor: color.green.hex,
    textColor: color.green.fontColor,
  };
};

export {
  colorNameToColorTheme,
  getAvailableColorsText,
  getCategoryId,
  getHighlightedLabel,
  getPackagePriceBoughtSeparately,
  getPackageUnitName,
  getPriceData,
  getPriceDisplaysForPackages,
  getProductImageSizes,
  getProductType,
  isSoldInPackages,
  toProductCardData,
  transformSizeIdToStyle,
};
