import styled from "@emotion/styled/macro";
import { colorsAsCssVariable as colors } from "../styles/theme/colors";

export const Label = styled.label`
  font-family: var(--font-family-regular);
  color: ${colors.xxlWebBlack};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: var(--space-small-regular);
`;
