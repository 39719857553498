/**
 * Instructions in root/README.md ## Feature toggles
 */

export const namesOfBooleanToggles = [
  "checkout_psp_walley",
  "giftcards_psp_walley",
  "return_replacement_psp_walley",
  "toggle_cacheable_campaign_search_query",
  "toggle_campaign_page",
  "toggle_christmas_mode",
  "toggle_click_and_collect",
  "toggle_click_and_collect_KCO",
  "toggle_click_and_collect_for_goods_only_in_store",
  "toggle_click_and_collect_quantity",
  "toggle_complete_profile_points",
  "toggle_cross_sales",
  "toggle_delivery_widget",
  "toggle_disable_checkout_coupons",
  "toggle_elevate_categories",
  "toggle_elevate_cluster_landing_page",
  "toggle_elevate_cluster_search",
  "toggle_elevate_favorites",
  "toggle_elevate_pdp",
  "toggle_elevate_quickshop_pdp",
  "toggle_exit_austria",
  "toggle_fitstation",
  "toggle_free_delivery_widget",
  "toggle_ga_ssr",
  "toggle_kindly_chatbot",
  "toggle_klarna_osm_configuration_externalized",
  "toggle_load_gtm_immediately",
  "toggle_left_column_page_filters",
  "toggle_log_rocket",
  "toggle_lowest_price",
  "toggle_new_guides",
  "toggle_otp_login",
  "toggle_paperless_returns",
  "toggle_personal_shoewall",
  "toggle_plp_buying_guides",
  "toggle_plp_one_card_content_component",
  "toggle_prisjakt",
  "toggle_products_as_package_quantity",
  "toggle_quick_shop",
  "toggle_rate_and_review_section",
  "toggle_ratings_and_reviews",
  "toggle_react_cat_search",
  "toggle_return_fee",
  "toggle_returns_digital_only",
  "toggle_reward_page",
  "toggle_rewards",
  "toggle_seo_product_archival_page",
  "toggle_social_security_number",
  "toggle_sticky_filter",
  "toggle_triggerbee",
  "toggle_videoly",
  "toggle_vipps_signup",
  "toggle_challenges",
  "toggle_employee_discount",
  "toggle_campaign_hub_v2",
  "toggle_order_cancellation",
  "toggle_force_member_price_display",
] as const;

export type BooleanSsmToggles = {
  [key in (typeof namesOfBooleanToggles)[number]]: boolean;
};

export const namesOfStringToggles = [
  "toggle_eco_online_on_category_codes",
  "toggle_kindly_pdp_on_category_codes",
  "toggle_service_cnc_category_codes",
] as const;

export type StringSsmToggles = {
  [key in (typeof namesOfStringToggles)[number]]: string;
};
