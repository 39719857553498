import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import text from "./UspBar.typography";

const { colors, spaces } = xxlTheme;

export const UspWrapper = styled.div`
  width: 100%;
  height: 28px;
  background-color: ${colors.xxlGreen};
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;

  ${MQ("muiTablet")} {
    padding: 0 ${spaces.large};
  }
`;

export const Item = styled.li`
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-left: ${spaces.large};
  text-align: left;
  width: 100%;
  justify-content: center;

  &:first-of-type {
    margin-left: 0;
  }

  ${MQ("tablet")} {
    width: auto;
    justify-content: flex-start;
  }

  & svg {
    display: inline-block;
    width: ${spaces.smallRegular};
    height: ${spaces.smallRegular};
    margin-right: ${spaces.micro};
    vertical-align: middle;
  }
`;

const inheritedLink = `
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-decoration: underline;
`;

export const Text = styled.span`
  ${typographyToCss(text.usp)};
  color: ${colors.xxlWebBlack};
  a {
    ${inheritedLink}
  }
`;

export const MoreText = styled(Text)`
  margin-left: ${spaces.line};

  a {
    ${inheritedLink}
  }
`;
