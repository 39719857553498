import type { PaymentWidgetQuery } from "react-app/src/generated/graphql-code-generator";

export type PaymentWidgetData = Pick<
  PaymentWidgetQuery,
  "paymentWidget"
>["paymentWidget"];

export const KeyvCacheNamespace = {
  BRANDS_CONTENT: "brands-content",
  BRAND_INDEX_CONTENT: "brand-index-content",
  CAMPAIGN_HUB_20_CONTENT: "campaign-hub-2.0-content",
  CAMPAIGN_HUB_CONTENT: "campaign-hub-content",
  CATEGORY_CONTENT: "category-content",
  CHALLENGES_CONTENT: "challenges-content",
  COMMON_CONTENT: "common-content",
  CONTENT_PRODUCT_LISTING_SMALL_BANNERS: "content-product-listing-banners",
  CONTENT_PRODUCT_LISTING_SMALL_BANNERS_AS_MAP:
    "content-product-listing-banners-as-map",
  DIGITAL_CAMPAIGN_CONTENT: "digital-campaign-content",
  FOOTER_CONTENT: "footer-content",
  GAME_CONTENT: "game-content",
  GUIDES_CONTENT: "guides-content",
  GUIDE_HUB_CONTENT: "guide-hub-content",
  HEADER_CONTENT: "header-content",
  HOME_PAGE_20_CONTENT: "home-page-2.0-content",
  INFO_MESSAGES_CONTENT: "info-messages-content",
  LAYOUT_CONTENT: "layout-content",
  LONG_TAIL_DATA: "long-tail-data",
  MEGA_MENU_CONTENT: "mega-menu-content",
  NEXTJS_TRANSLATIONS: "nextjs-translations",
  PIM_CAMPAIGN_CATEGORY: "pim-campaign-category",
  RELATED_GUIDES: "related-guides",
  REWARDS_CONTENT: "rewards-content",
  SHOP_IN_SHOP_CONTENT: "shop-in-shop-content",
  SIZE_GUIDES_CONTENT: "size-guides-content",
  SSM_PARAMETERS: "ssm-parameters",
  STORES_CONTENT: "stores-content",
  TRANSLATIONS_CONTENT: "translations-content",
  WORKSHOP_CATEGORY_CONTENT: "workshop-category-content",
  WORKSHOP_HUB_CONTENT: "workshop-hub-content",
  WORKSHOP_SERVICE_CONTENT: "workshop-service-content",
};

export const KeyvDefaultTtl = {
  ttl: 15 * 60 * 1000, // 15 minutes
  staleTtl: 3 * 60 * 1000, // 3 minutes
};

export const KeyvShortTtl = {
  ttl: 5 * 60 * 1000, // 5 minutes
  staleTtl: 1 * 60 * 1000, // 1 minute
};
