import React from "react";
import type { GenericGraphQLError } from "react-app/src/components/Cart/Api/types";
import { GeneralError } from "./GeneralError";
import { ErrorsList, ErrorsWrapper } from "./GeneralErrors.styled";

type GeneralErrorsListProps = {
  errors: GenericGraphQLError[];
};

export const GeneralErrorsList: React.FunctionComponent<
  GeneralErrorsListProps
> = ({ errors }) =>
  errors.length === 0 ? null : (
    <ErrorsWrapper>
      <ErrorsList>
        {errors.map((item, index) => (
          <GeneralError key={`error-${index}`} item={item} />
        ))}
      </ErrorsList>
    </ErrorsWrapper>
  );
