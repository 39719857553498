import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { ArrowUp } from "@xxl/icons";
import { showStickyHeader } from "react-app/src/utils/xxl-toggle-sticky-header";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { MQ } from "react-app/src/styles/helpers";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

export const Y_OFFSET = 500;
const ARROW_UP_SIZE = 33;

const { zIndex } = xxlTheme;

const BackButtonWrapper = styled.div`
  position: fixed;
  bottom: 240px;
  right: 0;
  height: 44px;
  z-index: ${zIndex["backToTopButton"]};
  ${MQ("desktop")} {
    right: -6px;
  }
`;

const BackToTopButton = ({ isHeaderInView }: { isHeaderInView: boolean }) => {
  const { isReactApp } = useSharedData();
  const { t } = useTranslations();
  const isDesktop = useXxlMediaQuery("DesktopMediaQuery");
  const [isVisible, setIsVisible] = useState(false);
  const showButton = !isHeaderInView || (isReactApp && isVisible);

  useEffect(() => {
    // TODO - remove toggleVisibility on scroll when all pages are migrated to Next.js
    const toggleVisibility = () => setIsVisible(window.pageYOffset > Y_OFFSET);
    if (isReactApp) {
      window.addEventListener("scroll", toggleVisibility);
    }
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, [isReactApp]);

  if (!showButton) {
    return null;
  }

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    showStickyHeader();
  };

  return (
    <BackButtonWrapper data-testid="back-to-top-button">
      <XxlButton
        aria-label={t("general.back.to.top")}
        onClick={goToTop}
        size="small"
        variant="secondary"
        style={isDesktop ? { padding: 12 } : { padding: 6 }}
      >
        {isDesktop && <span>{t("general.back.to.top")}</span>}
        <ArrowUp width={ARROW_UP_SIZE} height={ARROW_UP_SIZE} />
      </XxlButton>
    </BackButtonWrapper>
  );
};

export default BackToTopButton;
