import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-app/utils/xxl-shared-data";
import { useEffect, useState } from "react";
import { getDescriptionByCategory } from "./useServiceDescription.helper";

const useServiceDescription = ({ category }: { category: string }) => {
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();
  const [serviceDescription, setServiceDescription] = useState<string>();
  const [serviceTitle, setServiceTitle] = useState<string>();

  useEffect(() => {
    void (async () => {
      const {
        data: { result: listOfServiceDescriptions = [] },
      } = await contentApi.getServiceDescriptions(
        legacySiteUidToSiteUid(siteUid)
      );
      const { content, serviceName } = getDescriptionByCategory({
        category,
        listOfServiceDescriptions,
      });
      setServiceDescription(content);
      setServiceTitle(serviceName);
    })();
  }, [category, contentApi, siteUid]);

  return { serviceDescription, serviceTitle };
};

export { useServiceDescription };
