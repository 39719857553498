import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "@/react-app/translations";
import { assertNever } from "@/react-utils/xxl-assert-never";
import React from "react";
import { StockLevels, type StockLevel } from "../../types";
import { AvailabilityDisplay } from "../AvailabilityDisplay/AvailabilityDisplay";

type OnlineAvailabilityProps = {
  stockStatus: StockLevel;
  isPurchaseAllowed: boolean;
};

const OnlineAvailability: React.FunctionComponent<OnlineAvailabilityProps> = ({
  stockStatus,
  isPurchaseAllowed,
}) => {
  const { t } = useTranslations();

  let translationKey: TranslationKey = "general.available.online.none";

  if (!isPurchaseAllowed) {
    translationKey = "general.product.not.buyable.online";
  } else {
    switch (stockStatus) {
      case StockLevels.IN_STOCK:
        translationKey = "general.available.online.many";
        break;
      case StockLevels.LOW_STOCK:
        translationKey = "general.available.online.few";
        break;
      case StockLevels.OUT_OF_STOCK:
        translationKey = "general.available.online.none";
        break;
      default:
        assertNever(stockStatus);
    }
  }

  return (
    <AvailabilityDisplay
      id="availability-online"
      stockStatus={isPurchaseAllowed ? stockStatus : null}
      text={t(translationKey)}
    />
  );
};

export { OnlineAvailability };
