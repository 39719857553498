import { DEFAULT_PICKUP_READY_HOURS } from "@/react-app/constants";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { color } from "@xxl/theme";
import React from "react";
import { StoreStockStatusIndicator } from "../StoreStock/StoreStockStyles";
import type { CncStockListItem } from "../helpers";
import {
  useTranslationCCStockStatus,
  useTranslationStoreStockStatus,
} from "./helpers";
import { ACCORDION_STYLES } from "./styles";

const AccordionDesktop = ({
  store,
  selectedStoreId,
  handleStoreSelect,
}: {
  store: CncStockListItem;
  selectedStoreId?: string;
  handleStoreSelect: (id: string) => void;
}) => {
  const { t } = useTranslations();
  const tStoreStock = useTranslationStoreStockStatus(store.storeStockStatus);
  const tCollectableStockLevel = useTranslationCCStockStatus(
    store.collectableStockStatus
  );
  const isSelected = selectedStoreId === store.storeId;
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    handleStoreSelect(evt.target.value);
  return (
    <Accordion
      data-testid={`store-${store.storeId}`}
      sx={ACCORDION_STYLES}
      key={store.storeId}
      expanded={selectedStoreId === store.storeId}
    >
      <AccordionSummary sx={{ margin: 0, padding: 0 }}>
        <XxlStack
          flexDirection={"row"}
          display={"grid"}
          gridTemplateColumns={"1fr 1fr"}
          width={"100%"}
        >
          <FormControlLabel
            value={store.storeId}
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
            }}
            control={
              <Radio
                checked={isSelected}
                onChange={handleChange}
                value={store.storeId}
                name="click-and-collect-store-selection"
                inputProps={{ "aria-label": store.storeId }}
                sx={{
                  color: color.gray.hex,
                  paddingTop: 0,
                  display: "flex",
                  alignItems: "start",
                  "&.Mui-checked": {
                    color: color.webBlack.hex,
                  },
                }}
              />
            }
            label={
              <Text
                typography={isSelected ? "baseBold" : "base"}
                style={{
                  margin: 0,
                  paddingTop: xxlTheme.spaces.line,
                }}
              >
                {store.name}
              </Text>
            }
          />
          <XxlStack justifyContent={"end"} gap={"8px"}>
            <XxlStack direction={"row"} alignItems={"center"}>
              <StoreStockStatusIndicator
                stockStatus={store.collectableStockStatus}
              />
              <Text typography="small">{tCollectableStockLevel}</Text>
            </XxlStack>
            <XxlStack direction={"row"} alignItems={"center"}>
              <StoreStockStatusIndicator stockStatus={store.storeStockStatus} />
              <Text typography="small">{tStoreStock}</Text>
            </XxlStack>
          </XxlStack>
        </XxlStack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingX: xxlTheme.spaces.huge,
        }}
      >
        <Text>
          {t({
            key: "product.details.clickcollect.ready.for.pickup",
            messageArguments: [DEFAULT_PICKUP_READY_HOURS.toString()],
          })}
        </Text>
      </AccordionDetails>
    </Accordion>
  );
};

export const StoreListDesktop = ({
  preferredStores,
  remainingStores,
  handleStoreSelect,
  selectedStoreId,
}: {
  preferredStores: CncStockListItem[];
  remainingStores: CncStockListItem[];
  handleStoreSelect: (id: string) => void;
  selectedStoreId?: string;
}) => {
  const { t } = useTranslations();
  return (
    <>
      {preferredStores.length > 0 && (
        <Text typography="baseBold">
          {t("storeselect.selected.stores.header")}
        </Text>
      )}
      <XxlStack gap={"4px"}>
        {preferredStores.map((store) => (
          <AccordionDesktop
            key={store.storeId}
            store={store}
            selectedStoreId={selectedStoreId}
            handleStoreSelect={handleStoreSelect}
          />
        ))}
        <XxlStack height={xxlTheme.spaces.mini} />
        <Text typography="baseBold">
          {t("storeselect.other.stores.header")}
        </Text>
        {remainingStores.map((store) => (
          <AccordionDesktop
            key={store.storeId}
            store={store}
            selectedStoreId={selectedStoreId}
            handleStoreSelect={handleStoreSelect}
          />
        ))}
      </XxlStack>
    </>
  );
};
