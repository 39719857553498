import { log } from "@xxl/logging-utils";
import { AUTH_ENDPOINT } from "../constants";

/**
 * Returns access token for logged-in users, undefined for guests
 */
export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const resp = await fetch(AUTH_ENDPOINT);
    const jsonData = (await resp.json()) as { accessToken?: string };
    const { accessToken } = jsonData;

    return accessToken;
  } catch (err) {
    log.error("Unable to get access token", err);
    return Promise.reject();
  }
};
