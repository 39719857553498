import { hasValue } from "@xxl/common-utils";
import type {
  Location as ApiLocation,
  Store as ApiStore,
} from "@xxl/content-api";

export type Location = Required<ApiLocation>;
export type Store = {
  id: string;
  location: Location | null;
  name: string;
} & Omit<ApiStore, "location">;

const getLocation = (
  location: ApiStore["location"] | null | undefined
): Location | null => {
  const { latitude, longitude } = location ?? {};
  return hasValue(latitude) && hasValue(longitude)
    ? { latitude, longitude }
    : null;
};

export const toValidStoreOrUndefined = ({
  id,
  name,
  location,
  ...store
}: ApiStore): Store | undefined =>
  hasValue(id) && hasValue(name)
    ? {
        id,
        name,
        location: getLocation(location),
        ...store,
      }
    : undefined;
