import type { EcomSiteUid } from "@/react-app/global";
import { siteUidToLanguage } from "@/react-utils/xxl-language";
import { Apis } from "@/utils/api-helper";
import { sanitizeErrorIfAxiosErrorForLog } from "@/utils/axios-error-logs";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isNotUndefined, isNullOrUndefined } from "@xxl/common-utils";
import type { ContentApi, DayOpeningSchedule } from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import { coordinatesDistance } from "react-app/src/utils/xxl-geolocation";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";
import {
  type Store,
  toValidStoreOrUndefined,
} from "react-app/src/utils/Stores/stores-helper";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const fetchStores = async (
  siteId: EcomSiteUid,
  contentApi: ContentApi
): Promise<Store[]> => {
  const {
    data: { result = [] },
  } = await contentApi.getStores(siteId);
  return result
    .map(toValidStoreOrUndefined)
    .filter(isNotUndefined)
    .sort((s1: Store, s2: Store) =>
      s1.name.localeCompare(s2.name, siteUidToLanguage[siteId])
    );
};

export const fetchStoresMemoized = memoize(
  fetchStores,
  new Keyv({ namespace: KeyvCacheNamespace.STORES_CONTENT }),
  KeyvDefaultTtl
);

export const getStores = async (siteId: EcomSiteUid): Promise<Store[]> => {
  try {
    const result = await fetchStoresMemoized(
      siteId,
      Apis.getInstance().contentApi
    );
    return result;
  } catch (error) {
    log.error(
      "Could not fetch stores.",
      ...sanitizeErrorIfAxiosErrorForLog({ error })
    );
    return [];
  }
};

export const getTodayOpeningHours = (store: Store): DayOpeningSchedule => {
  const now = new Date();
  const nowDay = weekday[now.getDay()];
  const nowDate = now.toISOString().split("T")[0];
  const regularHours = store.openingHours?.find(
    (opening) => opening.day === nowDay
  ) ?? { closed: true };
  const specialHours =
    store.specialOpeningHours?.find((opening) => opening.date === nowDate) ??
    {};
  return {
    ...regularHours,
    ...specialHours,
  };
};

const COUNT_OF_NEARBY_STORES = 3;

export const getNearbyStores = (
  { location: selectedStoreLocation }: Store,
  stores: Store[]
): Store[] => {
  if (isNullOrUndefined(selectedStoreLocation)) {
    return [];
  }
  return stores
    .sort((storeA, storeB) => {
      const locationA = storeA.location;
      const locationB = storeB.location;
      const distanceToA = coordinatesDistance.get(
        selectedStoreLocation.latitude,
        selectedStoreLocation.longitude,
        locationA?.latitude,
        locationA?.longitude
      );
      const distanceToB = coordinatesDistance.get(
        selectedStoreLocation.latitude,
        selectedStoreLocation.longitude,
        locationB?.latitude,
        locationB?.longitude
      );
      if (distanceToA === undefined || distanceToB === undefined) {
        return 0;
      }
      return distanceToA - distanceToB;
    })
    .slice(1, COUNT_OF_NEARBY_STORES + 1);
};
