import { PageType } from "react-app/src/constants";
import { GCMInitScript } from "../utils/gcm-init-script";
import type { EnvironmentData } from "../global";
import Head from "next/head";
import { Symplify } from "./Symplify/Symplify";

type ThirdPartyScriptsProps = {
  environmentData: EnvironmentData;
};

export const ThirdPartyScripts = ({
  environmentData,
}: ThirdPartyScriptsProps) => {
  const {
    featureToggles: {
      toggle_load_gtm_immediately,
      toggle_ga_ssr,
      checkout_psp_walley,
    },
    cookieCultureLanguage,
    klarnaPlacementWidget,
    pageType,
    frontEndServerUrl,
    symplifyId,
  } = environmentData;

  const isProduction = !(
    frontEndServerUrl.includes("test") || frontEndServerUrl.includes("dev")
  );

  return (
    <>
      {(toggle_ga_ssr || toggle_load_gtm_immediately) && (
        <Head>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: GCMInitScript() }}
          />
          {toggle_load_gtm_immediately && (
            <script
              id="CookieConsent"
              type="text/javascript"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture={cookieCultureLanguage}
              data-gcm-version="2.0"
            />
          )}
        </Head>
      )}
      {(pageType === PageType.PRODUCT || pageType === PageType.CHECKOUT) && (
        <Head>
          {!checkout_psp_walley && klarnaPlacementWidget !== undefined && (
            <script
              async={true}
              data-environment={isProduction ? "production" : "playground"}
              src=""
              data-client-id={klarnaPlacementWidget.clientId}
              data-consent-src="https://js.klarna.com/web-sdk/v1/klarna.js"
              data-category-consent="cookie_cat_marketing"
            />
          )}
          <script
            async={true}
            src=""
            data-consent-src="https://cdn.pji.nu/b2b/widgets/cheapest/index.js"
            data-category-consent="cookie_cat_marketing"
          />
        </Head>
      )}
      <Symplify id={symplifyId} />
    </>
  );
};
