import type { GraphQLResult } from "@aws-amplify/api-graphql";
import { GraphQLAPI, graphqlOperation } from "@aws-amplify/api-graphql";
import type {
  AggregatedRatingQuery,
  AggregatedRatingQueryVariables,
  ExpertReviewQuery,
  ExpertReviewQueryVariables,
  MarkReviewHelpfulInput,
  MarkReviewHelpfulMutation,
  MarkReviewHelpfulMutationVariables,
  MarkReviewUnhelpfulInput,
  MarkReviewUnhelpfulMutation,
  MarkReviewUnhelpfulMutationVariables,
  ReportReviewInput,
  ReviewsAndAggregatedRatingsQuery,
  ReviewsAndAggregatedRatingsQueryVariables,
  ReviewsQuery,
  ReviewsQueryVariables,
  SizeFitness,
  SubmitReviewMutation,
  SubmitReviewMutationVariables,
  UnmarkReviewHelpfulnessInput,
  UnmarkReviewHelpfulnessMutation,
  UnmarkReviewHelpfulnessMutationVariables,
} from "../../../generated/graphql-code-generator";
import { callGraphQL } from "react-app/src/graphql/graphqlApi";

export type RatingCount = {
  quantity: number;
  rating: RATING_NAME;
};

export type ReviewTranslationData = {
  headline: string;
  language: string;
  sourceLanguage: string;
  text: string;
};

export type Review = {
  displayName: string;
  headline: string;
  helpfulVoteCount: number;
  id: string;
  isArticleRecommended: boolean;
  rating: number;
  creationDate: number;
  sizeFitness?: SizeFitness | null;
  text: string;
  unhelpfulVoteCount: number;
  translations: ReviewTranslationData[];
  verifiedBuyer: boolean;
};

export type ReviewRequestData = {
  articleNumber: string;
  displayName: string;
  email: string;
  headline: string;
  isArticleRecommended: boolean;
  rating: number;
  sizeFitness?: SizeFitness;
  sourceSite: string;
  text: string;
};

export type ReviewsResponseData = {
  nextToken: string | null;
  reviews: Review[];
};

export type ReviewsResponse = {
  reviews: ReviewsResponseData;
};

export type ReviewsAndAggregatedRatingsResponse = {
  reviews: ReviewsResponseData;
  aggregatedRating?: AggregatedRatingResponseData;
};

export type ReviewsAndAggregatedRating = {
  reviews: Review[];
  aggregatedRating: Omit<
    AggregatedRatingResponseData,
    "ratingQuantities"
  > | null;
};

export type AggregatedRatingResponse = {
  aggregatedRating?: AggregatedRatingResponseData;
};

export type AggregatedRatingResponseData = {
  average: number;
  quantity: number;
  recommendationPercentage: number;
  ratingQuantities: RatingCount[];
};

export enum RATING_NAME {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
}

export type ExpertReviewData = {
  id: string;
  text: string;
  store: string;
  title: string;
  author: string;
  modifiedTime: number;
  creationDate: number;
  unhelpfulVoteCount: number;
  helpfulVoteCount: number;
};

export type ExpertReviewResponse = {
  expertReview: ExpertReviewData;
};

type GetExpertReviewProps = {
  siteId: string;
  articleNumber: string;
};

type GetReviewsProps = {
  productId: string;
  nextToken?: string | null;
};

const reviewsQueryFields = /* GraphQL */ `
  fragment reviewsQueryFields on PaginatedReviews {
    nextToken
    reviews {
      creationDate
      displayName
      headline
      helpfulVoteCount
      id
      isArticleRecommended
      rating
      sizeFitness
      text
      verifiedBuyer
      translations {
        headline
        language
        sourceLanguage
        text
      }
      unhelpfulVoteCount
    }
  }
`;

export const getReviews = async ({
  productId,
  nextToken = null,
}: GetReviewsProps): Promise<GraphQLResult<ReviewsQuery>> => {
  const queryInput: ReviewsQueryVariables["filter"] = {
    articleNumber: {
      eq: productId,
    },
  };
  const query = /* GraphQL */ `
    query Reviews($filter: ReviewsFilter!, $limit: Int, $nextToken: String) {
      reviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
        ...reviewsQueryFields
      }
    }

    ${reviewsQueryFields}
  `;
  const limit = 10;
  const variables: ReviewsQueryVariables = {
    filter: queryInput,
    limit,
    nextToken,
  };

  return GraphQLAPI.graphql<ReviewsQuery>(
    graphqlOperation(query, variables)
  ) as Promise<GraphQLResult<ReviewsQuery>>;
};

export const getReviewsAndAggregatedRating = async ({
  productId,
  nextToken = null,
}: GetReviewsProps): Promise<
  GraphQLResult<ReviewsAndAggregatedRatingsQuery>
> => {
  const queryInput = {
    articleNumber: {
      eq: productId,
    },
  };
  const query = /* GraphQL */ `
    query ReviewsAndAggregatedRatings(
      $reviewsFilter: ReviewsFilter!
      $aggregatedRatingsFilter: AggregatedRatingFilter!
      $limit: Int
      $nextToken: String
    ) {
      reviews(filter: $reviewsFilter, limit: $limit, nextToken: $nextToken) {
        ...reviewsQueryFields
      }
      aggregatedRating(filter: $aggregatedRatingsFilter) {
        average
        quantity
        ratingQuantities {
          quantity
          rating
        }
        recommendationPercentage
      }
    }

    ${reviewsQueryFields}
  `;

  const limit = 10;
  const variables: ReviewsAndAggregatedRatingsQueryVariables = {
    reviewsFilter: queryInput,
    aggregatedRatingsFilter: queryInput,
    limit,
    nextToken,
  };

  return GraphQLAPI.graphql<ReviewsAndAggregatedRatingsQuery>(
    graphqlOperation(query, variables)
  ) as Promise<GraphQLResult<ReviewsAndAggregatedRatingsQuery>>;
};

export const submitReviewMutation = /* GraphQL */ `
  mutation SubmitReview($input: SubmitReviewMutationInput!) {
    submitReview(input: $input)
  }
`;

export const submitReview = (
  mutationInput: ReviewRequestData
): Promise<GraphQLResult<SubmitReviewMutation>> => {
  const variables: SubmitReviewMutationVariables = { input: mutationInput };

  return GraphQLAPI.graphql<SubmitReviewMutation>(
    graphqlOperation(submitReviewMutation, variables)
  ) as Promise<GraphQLResult<SubmitReviewMutation>>;
};

export const reportReviewMutation = /* GraphQL */ `
  mutation ReportReview($input: ReportReviewInput!) {
    reportReview(input: $input)
  }
`;

type ReportReviewInputVariables = {
  input: ReportReviewInput;
};

export const reportReview = (
  mutationInput: ReportReviewInput
): Promise<GraphQLResult<ReportReviewInput>> => {
  const variables: ReportReviewInputVariables = { input: mutationInput };

  return GraphQLAPI.graphql<ReportReviewInput>(
    graphqlOperation(reportReview, variables)
  ) as Promise<GraphQLResult<ReportReviewInput>>;
};

const aggregatedRating = /* GraphQL */ `
  query AggregatedRating($filter: AggregatedRatingFilter!) {
    aggregatedRating(filter: $filter) {
      articleNumber
      average
      quantity
      ratingQuantities {
        quantity
        rating
      }
      recommendationPercentage
    }
  }
`;

export const getAggregatedRating = (
  articleNumber: string
): Promise<GraphQLResult<AggregatedRatingQuery>> => {
  const input: AggregatedRatingQueryVariables = {
    filter: {
      articleNumber: {
        eq: articleNumber,
      },
    },
  };

  return GraphQLAPI.graphql<AggregatedRatingQuery>(
    graphqlOperation(aggregatedRating, input)
  ) as Promise<GraphQLResult<AggregatedRatingQuery>>;
};

export const HelpfulnessVotes = {
  HELPFUL: "HELPFUL",
  UNHELPFUL: "UNHELPFUL",
} as const;

export type HelpfulnessVotesType =
  | (typeof HelpfulnessVotes)[keyof typeof HelpfulnessVotes]
  | null;

export const markReviewHelpful = async (
  mutationInput: MarkReviewHelpfulInput,
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
) => {
  const variables: MarkReviewHelpfulMutationVariables = {
    input: mutationInput,
  };
  const query = /* GraphQL */ `
    mutation MarkReviewHelpful($input: MarkReviewHelpfulInput!) {
      markReviewHelpful(input: $input) {
        review {
          id
        }
      }
    }
  `;

  const { errors = [] } = await callGraphQL<MarkReviewHelpfulMutation>(
    {
      query,
      variables,
    },
    aws_appsync_graphqlEndpoint,
    aws_appsync_apiKey
  );
  if (errors.length > 0) {
    throw Error("Something went wrong marking review as helpful");
  }
};
export const markReviewUnhelpful = async (
  mutationInput: MarkReviewUnhelpfulInput,
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
) => {
  const variables: MarkReviewUnhelpfulMutationVariables = {
    input: mutationInput,
  };
  const query = /* GraphQL */ `
    mutation MarkReviewUnhelpful($input: MarkReviewUnhelpfulInput!) {
      markReviewUnhelpful(input: $input) {
        review {
          id
        }
      }
    }
  `;

  const { errors = [] } = await callGraphQL<MarkReviewUnhelpfulMutation>(
    {
      query,
      variables,
    },
    aws_appsync_graphqlEndpoint,
    aws_appsync_apiKey
  );

  if (errors.length > 0) {
    throw Error("Something went wrong marking review as helpful");
  }
};
export const unmarkReviewHelpfulness = async (
  mutationInput: UnmarkReviewHelpfulnessInput,
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
) => {
  const variables: UnmarkReviewHelpfulnessMutationVariables = {
    input: mutationInput,
  };
  const query = /* GraphQL */ `
    mutation UnmarkReviewHelpfulness($input: UnmarkReviewHelpfulnessInput!) {
      unmarkReviewHelpfulness(input: $input) {
        review {
          id
        }
      }
    }
  `;

  const { errors = [] } = await callGraphQL<UnmarkReviewHelpfulnessMutation>(
    {
      query,
      variables,
    },
    aws_appsync_graphqlEndpoint,
    aws_appsync_apiKey
  );

  if (errors.length > 0) {
    throw Error("Something went wrong marking review as helpful");
  }
};

export const getExpertReview = async ({
  articleNumber,
  siteId,
}: GetExpertReviewProps): Promise<GraphQLResult<ExpertReviewQuery>> => {
  const query = /* GraphQL */ `
    query ExpertReview($input: ExpertReviewInput!) {
      expertReview(input: $input) {
        id
        text
        title
        store
        author
        modifiedTime
        creationDate
        helpfulVoteCount
        unhelpfulVoteCount
      }
    }
  `;

  const variables: ExpertReviewQueryVariables = {
    input: { articleNumber, siteId },
  };

  return GraphQLAPI.graphql<ExpertReviewQuery>(
    graphqlOperation(query, variables)
  ) as Promise<GraphQLResult<ExpertReviewQuery>>;
};
