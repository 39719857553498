import type { Localization } from "@mui/material/locale";
import { fiFI, nbNO, svSE } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import mediaQuery from "css-mediaquery";
import type { IncomingHttpHeaders } from "http";
import { resolutionSizes } from "react-app/src/config";
import type { EcomSiteUidLegacy } from "react-app/src/global";
import { themeConfig } from "react-app/src/mui-theme";
import parser from "ua-parser-js";

const deviceTypeToWidth = {
  mobile: "0px",
  desktop: `${resolutionSizes.laptop}px`,
} as const;

type DeviceType = keyof typeof deviceTypeToWidth;

const uidToMuiLanguage: {
  [key in EcomSiteUidLegacy]: Localization;
} = {
  xxl: nbNO,
  "xxl-fi": fiFI,
  "xxl-se": svSE,
};

export const getMuiTheme = (deviceType: DeviceType, uid: EcomSiteUidLegacy) => {
  const ssrMatchMedia = (query: string) => ({
    matches: mediaQuery.match(query, {
      width: deviceTypeToWidth[deviceType],
    }),
  });

  return createTheme(
    {
      ...themeConfig,
      components: {
        ...themeConfig.components,
        MuiUseMediaQuery: {
          defaultProps: {
            ssrMatchMedia,
            noSsr: false,
          },
        },
      },
    },
    uidToMuiLanguage[uid]
  );
};

/**
 * Get device type from CloudFront headers https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/adding-cloudfront-headers.html
 */
export const getDeviceTypeFromHeaders = ({
  "cloudfront-is-mobile-viewer": cloudfrontIsMobileViewer,
  "user-agent": userAgent,
}: IncomingHttpHeaders = {}): DeviceType => {
  if (cloudfrontIsMobileViewer === undefined) {
    // Fallback to ua-parser-js when running server locally
    const { type } = parser(userAgent).device;
    return type === "mobile" ? "mobile" : "desktop";
  }
  return cloudfrontIsMobileViewer.includes("true") ? "mobile" : "desktop";
};

/**
 * Get device type from CloudFront headers in format required by Elevate API
 */
export const getElevateDeviceTypeFromHeaders = (
  headers: IncomingHttpHeaders
) =>
  getDeviceTypeFromHeaders(headers) === "mobile"
    ? ("MOBILE" as const)
    : ("DESKTOP" as const);

export type { DeviceType };
