import { QUERY_KEYS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-app/utils/xxl-shared-data";
import { fetchStoresMemoized } from "@/utils/apis/content-stores";
import { useQuery } from "@tanstack/react-query";

/**
 * Fetches a list of stores, (sorted by name, not location).
 */
export const useStoresQuery = () => {
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();
  const siteId = legacySiteUidToSiteUid(siteUid);

  return useQuery({
    queryKey: [QUERY_KEYS.STORES],
    queryFn: () => fetchStoresMemoized(siteId, contentApi),
    staleTime: Infinity,
  });
};
