import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { StoreListItem } from "../helper";
import { StoreListComponent } from "./StoreListAccordion/StoreListAccordion";
import { StoreSelectionWrapper } from "./StoreStockDialogStyles";

type Props = {
  storeListItems: StoreListItem[];
};
export const StoreList = ({ storeListItems }: Props) => {
  const { t } = useTranslations();
  return (
    <StoreSelectionWrapper>
      <StoreListComponent.Accordion>
        {storeListItems.map(({ storeId, name, stockStatus }, idx) => (
          <>
            <StoreListComponent.Heading
              idx={idx}
              storeName={name}
              stockStatus={stockStatus}
              generalAddressAndHoursText={t(
                "general.address.and.opening.hours"
              )}
              data-testid={`store-list-accordion-heading-${idx}`}
            />
            <StoreListComponent.Body idx={idx} storeId={storeId} />
          </>
        ))}
      </StoreListComponent.Accordion>
    </StoreSelectionWrapper>
  );
};
