import AppBar from "@mui/material/AppBar";
import type { MegaMenuLevelOneLink } from "@xxl/frontend-api";
import { Configuration, MegamenucontentApi } from "@xxl/frontend-api";
import type { MouseEvent } from "react";
import React, { useState } from "react";
import { CategorySection } from "./CategorySection";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { TabButtons } from "./TabButtons.styled";
import { Root } from "./Root.styled";
import { Wrapper as CategoryWrapper } from "./Grid.styled";
import { Wrapper as TabWrapper } from "./Wrapper.styled";
import { TabPanel } from "./TabPanel.styled";
import {
  createClubAssortmentLinksCategoryData,
  createMoreCategoryData,
  getActiveTabLeftOffset,
  getData,
  getParentCategoryCode,
  getFilteredHeaderLinksData,
  getUrl,
  hasSubLinks,
  isCurrentCategory,
  isSelectedLink,
  prepareSections,
} from "./mega-menu-helper";
import { isTouch } from "../../../utils/xxl-screen";
import { getContent } from "./content-section-helper";
import type { tabIndex } from "./mega-menu-helper";
import { Separator, RightAligned } from "./MegaMenu.styled";
import { useSharedData } from "../../../contexts/SharedData";
import { OtherInfo } from "./OtherInfo";
import { TabHeader } from "./TabHeader";
import noop from "lodash/noop";
import { ClubAssortmentLink } from "./ClubAssortmentLink";
import { useSession } from "../../../hooks/useSession";
import { useClubAssortmentLinks } from "../hooks/useClubAssortmentLinks";
import { MAX_NR_OF_LINKS_IN_COLUMN, SPORTS_CATEGORY_CODE } from "./constants";
import { SEOCategoryLinks } from "../../Header/SEOCategoryLinks";
import type { HeaderConfig } from "../../../global";

type MegaMenuProps = {
  campaignHubUrl: string;
  categoryLinks: MegaMenuLevelOneLink[];
  headerLinks: HeaderConfig["headerLinks"];
  isTeamsales?: boolean;
  currentCategoryCode?: string;
  activeTabIndex?: tabIndex;
};

export const MegaMenu: React.FunctionComponent<MegaMenuProps> = ({
  campaignHubUrl,
  categoryLinks,
  currentCategoryCode,
  activeTabIndex = false,
  headerLinks,
  isTeamsales = false,
}) => {
  const { t } = useTranslations();
  const moreText = t("mega.menu.category.more");
  const [tabIndex, setTabIndex] = useState(activeTabIndex);
  const [previousTabIndex, setPreviousTabIndex] = useState(activeTabIndex);
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const { requestMapping, configuration, featureToggles } =
    useSharedData().data;
  const { toggle_christmas_mode } = featureToggles;
  const { isReactApp } = useSharedData();
  const [megaMenuApi] = useState(
    new MegamenucontentApi(new Configuration(configuration.frontendApi))
  );
  const [menuTree, setMenuTree] = useState({
    categoryLinks: prepareSections(categoryLinks, moreText),
  });
  const currentParentCategoryCode = getParentCategoryCode(
    menuTree.categoryLinks,
    currentCategoryCode
  );
  const isTabChange = tabIndex !== false && previousTabIndex !== false;
  const outletIndex = menuTree.categoryLinks.length;
  const campaignOrClubAssortmentIndex = outletIndex + 1;
  const brandsIndex = campaignOrClubAssortmentIndex + 1;
  const moreCategoryIndex = brandsIndex + 1;
  const customerServiceIndex = moreCategoryIndex + 1;
  const storeFinderIndex = customerServiceIndex + 1;
  const [activeTabOffsetLeft, setActiveTabOffsetLeft] = useState<number>();
  const {
    brandsLink,
    customerServiceLink,
    filteredHeaderLinks,
    outletLink,
    storeFinderLink,
  } = getFilteredHeaderLinksData({ headerLinks, requestMapping });
  const moreCategory = createMoreCategoryData(
    moreText,
    filteredHeaderLinks,
    requestMapping
  );
  const isSmallMoreCategory =
    moreCategory.level2Link.category.subCategories.length <=
    MAX_NR_OF_LINKS_IN_COLUMN;
  const clubAssortmentLinks = useClubAssortmentLinks({
    isLoggedIn,
    isTeamsales,
  });
  const clubAssortmentLinksCategory = createClubAssortmentLinksCategoryData(
    t("teamsales.account.nav.assortment"),
    clubAssortmentLinks ?? [],
    requestMapping.clubAssortment
  );

  const handleTabChange = (_e: any, value: tabIndex): void =>
    setTabIndex(value);
  const closeMenu = (): void => setTabIndex(false);

  React.useEffect(() => {
    if (hasSubLinks(categoryLinks)) {
      return;
    }
    void (async (): Promise<void> => {
      const megaMenu = await getData(megaMenuApi);
      setMenuTree({
        categoryLinks: prepareSections(megaMenu.links, moreText),
      });
    })();
  }, [categoryLinks, moreText, setMenuTree, t, megaMenuApi]);

  const handleClick = (event: MouseEvent) => {
    if (isTouch()) {
      event.preventDefault();
    }
  };

  const onHoverTabHeader = (index: number, isSmall = false): void => {
    if (isSmall) {
      setActiveTabOffsetLeft(getActiveTabLeftOffset(index));
    }
    setPreviousTabIndex(tabIndex);
    setTabIndex(index);
  };
  return (
    <Root
      id="desktop-mega-menu"
      onMouseLeave={closeMenu}
      data-testid="desktop-mega-menu"
      transparentBg={toggle_christmas_mode}
    >
      {!isReactApp && (
        <SEOCategoryLinks levelOneLinks={menuTree.categoryLinks} />
      )}
      <AppBar
        position="static"
        elevation={0}
        style={{
          background: toggle_christmas_mode ? "transparent" : "#0f0f0f",
        }}
      >
        <TabButtons value={tabIndex} onChange={handleTabChange}>
          {menuTree.categoryLinks.map(
            (c1: MegaMenuLevelOneLink, index: number) => {
              const label = c1.displayName ?? "";
              const isSelected = isCurrentCategory(
                c1.category?.code,
                currentCategoryCode,
                currentParentCategoryCode,
                menuTree.categoryLinks
              );
              return (
                <TabHeader
                  href={getUrl({ link: c1.category?.url })}
                  index={index}
                  isSelected={isSelected}
                  label={label}
                  onClick={handleClick}
                  onHover={() => onHoverTabHeader(index)}
                  key={index}
                />
              );
            }
          )}
          <Separator />
          {outletLink !== undefined && (
            <TabHeader
              href={outletLink.url}
              index={outletIndex}
              isSelected={isSelectedLink(outletLink.url)}
              label={outletLink.displayName}
              onHover={() => setTabIndex(outletIndex)}
            />
          )}
          {isTeamsales ? (
            <ClubAssortmentLink
              clubAssortmentLinks={clubAssortmentLinks}
              index={campaignOrClubAssortmentIndex}
              onHover={() => onHoverTabHeader(campaignOrClubAssortmentIndex)}
            />
          ) : (
            <TabHeader
              href={campaignHubUrl}
              index={campaignOrClubAssortmentIndex}
              isSelected={isSelectedLink(campaignHubUrl)}
              label={t("campaign.hub.campaigns")}
              onHover={() => setTabIndex(campaignOrClubAssortmentIndex)}
            />
          )}
          {brandsLink !== undefined && (
            <TabHeader
              href={brandsLink.url}
              index={brandsIndex}
              isSelected={isSelectedLink(brandsLink.url)}
              label={brandsLink.displayName}
              onHover={() => setTabIndex(brandsIndex)}
            />
          )}
          <TabHeader
            index={moreCategoryIndex}
            isSelected={moreCategory.categoryCode === currentCategoryCode}
            label={moreCategory.displayName}
            onHover={() =>
              onHoverTabHeader(moreCategoryIndex, isSmallMoreCategory)
            }
          />
          <RightAligned>
            {customerServiceLink !== undefined && (
              <TabHeader
                href={customerServiceLink.url}
                index={customerServiceIndex}
                isSelected={false}
                label={customerServiceLink.displayName}
                onHover={() => setTabIndex(customerServiceIndex)}
              />
            )}
            {storeFinderLink !== undefined && (
              <TabHeader
                href={storeFinderLink.url}
                index={storeFinderIndex}
                isSelected={false}
                label={storeFinderLink.displayName}
                onHover={noop}
                removeRightPadding={true}
              />
            )}
          </RightAligned>
        </TabButtons>
      </AppBar>

      <TabWrapper className="mega-menu-wrapper">
        {menuTree.categoryLinks.map(
          (c1: MegaMenuLevelOneLink, index: number) => {
            const content = getContent(c1, t);
            const active = index === tabIndex;
            const c1Links = c1.links ?? [];

            return (
              <TabPanel
                active={active}
                key={index}
                isTabChange={isTabChange}
                closeMenu={closeMenu}
              >
                {
                  <CategoryWrapper key={`row-${index}`}>
                    <>
                      {c1Links.map((link, linkIndex) => (
                        <CategorySection
                          c2={link}
                          key={linkIndex}
                          limitNrOfLinks={c1Links.length > 3}
                        />
                      ))}
                    </>
                    {c1.category?.code !== SPORTS_CATEGORY_CODE && (
                      <OtherInfo
                        contentData={content}
                        brandLinks={c1.brandLinks}
                      />
                    )}
                  </CategoryWrapper>
                }
              </TabPanel>
            );
          }
        )}
        <TabPanel
          active={moreCategoryIndex === tabIndex}
          isTabChange={isTabChange}
          closeMenu={closeMenu}
          isSmall={isSmallMoreCategory}
          offsetLeft={activeTabOffsetLeft}
        >
          <CategoryWrapper key={`row-${moreCategoryIndex}`} nrOfColumns={1}>
            <CategorySection
              c2={moreCategory.level2Link}
              limitNrOfLinks={false}
            />
          </CategoryWrapper>
        </TabPanel>
        {isTeamsales && (
          <TabPanel
            active={campaignOrClubAssortmentIndex === tabIndex}
            isTabChange={isTabChange}
            closeMenu={closeMenu}
          >
            <CategoryWrapper key={`row-${campaignOrClubAssortmentIndex}`}>
              <CategorySection
                c2={clubAssortmentLinksCategory.level2Link}
                limitNrOfLinks={false}
              />
            </CategoryWrapper>
          </TabPanel>
        )}
      </TabWrapper>
    </Root>
  );
};
