import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { type PriceData, PriceType } from "@xxl/product-search-api";
import { PriceDescriptionWidget } from "react-app/src/components/Product/Product.styled";
import { PriceComparatorWidget } from "../../PriceComparatorWidget";
import type { PriceComparatorWidgetProps } from "../../PriceComparatorWidget/PriceComparatorWidget";
import {
  CurrentPrice,
  OriginalPrice,
  OtherPrice,
  PriceDescription,
  PriceWrapper,
} from "./Price.styled";
import { getLabel, isSoldInPackages } from "./price.helper";

export type PriceProps = Partial<PriceComparatorWidgetProps> & {
  /**@param salesUnit if salesUnit==='box | package', display price description "product.unit.per.box.description" */
  salesUnit?: string;
  priceData: PriceData;
};

export const Price = ({
  config,
  priceData,
  productCode,
  salesUnit,
}: PriceProps) => {
  const { t } = useTranslations();
  const { alternate, selling, cheapest } = priceData;
  const hasAlternatePrice = isNotNullOrUndefined(alternate);
  const altPriceValue = hasAlternatePrice ? alternate.range.min.value : 0;
  const sellingPriceValue = selling.range.min.value;
  const hasUndeductedRewardDiscount =
    hasAlternatePrice && altPriceValue < sellingPriceValue;
  const hasDiscountedSellingPrice =
    hasAlternatePrice && altPriceValue > sellingPriceValue;
  const sellingLabel = getLabel(selling, t);
  const alternateLabel = hasAlternatePrice ? getLabel(alternate, t) : undefined;
  const sellingPriceFormatted = selling.range.min.formatted;
  const alternatePriceFormatted = hasAlternatePrice
    ? alternate.range.min.formatted
    : undefined;
  const displayPriceComparator =
    isNotNullOrUndefined(config) && isNotNullOrUndefined(productCode);
  const displayUnitDescription = isSoldInPackages(salesUnit);
  const shouldShowSellingLabel =
    sellingLabel !== undefined && sellingLabel !== alternateLabel;
  const shouldShowAlternatePrice =
    hasAlternatePrice && alternate.range.min.value !== selling.range.min.value;
  const isCampaignProduct = priceData.type === PriceType.CAMPAIGN;
  const isProductWithLatestPrice =
    priceData.type === PriceType.REWARD || isCampaignProduct;
  const cheapestLatestPrice =
    cheapest !== undefined ? cheapest.range.min.formatted : undefined;
  const shouldShowCheapestLatestPrice =
    isProductWithLatestPrice && cheapestLatestPrice !== undefined;

  return (
    <PriceWrapper data-testid="elevate-price-display">
      <div>
        <CurrentPrice
          data-testid="selling-price"
          isDiscountPrice={
            hasDiscountedSellingPrice || isSoldInPackages(salesUnit)
          }
        >
          {sellingPriceFormatted}
          {displayUnitDescription && (
            <PriceDescriptionWidget isPDP={true}>
              {t("product.unit.per.box.description")}
            </PriceDescriptionWidget>
          )}
        </CurrentPrice>

        {shouldShowSellingLabel &&
          (!isCampaignProduct || !shouldShowCheapestLatestPrice) && (
            <PriceDescription>{sellingLabel}</PriceDescription>
          )}

        {shouldShowAlternatePrice && (
          <OriginalPrice>
            {alternateLabel !== undefined && <span>{alternateLabel}</span>}
            <OtherPrice
              hasUndeductedRewardDiscount={hasUndeductedRewardDiscount}
              isInvertedPrice={hasUndeductedRewardDiscount}
            >
              {alternatePriceFormatted}
            </OtherPrice>
          </OriginalPrice>
        )}
      </div>
      <div>
        {displayPriceComparator && (
          <PriceComparatorWidget productCode={productCode} config={config} />
        )}
      </div>
    </PriceWrapper>
  );
};
