import { isAxiosError } from "axios";
import { sanitizeErrorIfAxiosErrorForLog as sanitizeAxiosError } from "@xxl/security-utils";

/**
 * Simpler shorter version of import { sanitizeErrorIfAxiosErrorForLog } from "@xxl/security-utils";
 */
export const sanitizeErrorIfAxiosErrorForLog = ({
  error,
  full = false,
}: {
  error: unknown;
  full?: boolean;
}) =>
  isAxiosError(error)
    ? full
      ? sanitizeAxiosError(error)
      : [error.message, error.config?.url]
    : [error];
