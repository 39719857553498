import { setStorageItem } from "./LocalStorage/local-storage";

const GEOLOCATION_GRANTED = "geolocationGranted";

const getPosition = (
  options?: PositionOptions
): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    const successCallback = (position: GeolocationPosition): void => {
      setStorageItem(GEOLOCATION_GRANTED, "true");
      resolve(position);
    };
    const errorCallback = (error: GeolocationPositionError): void => {
      localStorage.removeItem(GEOLOCATION_GRANTED);
      reject(error);
    };
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      options
    );
  });
};
/**
 * Request user position
 * @returns {Promise<Position>} Promise object with geolocation position
 */
export const requestUserCoordinates =
  async (): Promise<GeolocationPosition> => {
    if (!navigator.geolocation) {
      throw new Error("Geolocation API not available");
    }
    try {
      const options = {
        maximumAge: 600000, // 10 minutes,
        timeout: 2000,
      };
      return await getPosition(options);
    } catch (positionError) {
      throw new Error("Couldn't get geolocation");
    }
  };

export const coordinatesDistance = ((): {
  get(
    lat1: number | undefined,
    lon1: number | undefined,
    lat2: number | undefined,
    lon2: number | undefined
  ): number | undefined;
} => {
  // Get distance between two coordinates (in km)
  // http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
  const deg2rad = (deg: number): number => {
    return deg * (Math.PI / 180);
  };
  const get = (
    lat1: number | undefined,
    lon1: number | undefined,
    lat2: number | undefined,
    lon2: number | undefined
  ): number | undefined => {
    if (typeof lat2 === "undefined" || typeof lon2 === "undefined") {
      return Number.MAX_VALUE;
    }

    if (typeof lat1 === "undefined" || typeof lon1 === "undefined") {
      return;
    }

    const earthRadius = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = earthRadius * c;
    return d;
  };

  return {
    get,
  };
})();

export const isGeolocationAccessGranted = (): boolean =>
  Boolean(localStorage.getItem(GEOLOCATION_GRANTED));
