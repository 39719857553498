import React from "react";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { Item, MoreText, Text } from "./UspBar.styled";
import { SanitizedHtmlComponent } from "@/react-components/Common";

type UspItemProps = {
  index: 1 | 2 | 3;
};

export const UspItem: React.FunctionComponent<UspItemProps> = ({ index }) => {
  const { t } = useTranslations();
  return (
    <Item data-testid="service-usp-item">
      <svg>
        <use href="#check-usp" xlinkHref="#check-usp" />
      </svg>
      <Text>
        <SanitizedHtmlComponent text={t(`cart.usp${index}`)} />
      </Text>
      <MoreText>
        <SanitizedHtmlComponent text={t(`cart.usp${index}.more`)} />
      </MoreText>
    </Item>
  );
};
