import { hasValue } from "@xxl/common-utils";
import { Close, SearchIcon } from "@xxl/icons";
import { log } from "@xxl/logging-utils";
import debounce from "lodash/debounce";
import type { ChangeEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { showStickyHeader } from "react-app/src/utils/xxl-toggle-sticky-header";
import { useSession } from "../../hooks/useSession";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";
import { getSuggestions, toAutoCompleteData } from "./Api/SuggestsApi";
import { AutoSuggestions } from "./AutoSuggestions";
import { ANIMATE_HEIGHT_DURATION_MS, THROTTLE_TIME_MS } from "./constants";
import { MINIMUM_QUERY_LENGTH } from "./searchBoxHelper";
import {
  SEARCH_BOX_CLEAR_RESULT,
  SEARCH_BOX_REQUEST_SUCCESS,
  useSearchBoxContext,
} from "./SearchBoxState";
import {
  Button,
  Form,
  Input,
  Label,
  Overlay,
  SearchInputWrapper,
  SearchSuggestionsWrapper,
} from "./SearchInput.styled";
import type { SearchInputProps } from "./types";

const shouldShowSuggestions = (query: string) =>
  query.length >= MINIMUM_QUERY_LENGTH;

let latestRequestId = 0;
const generateLatestRequestId = () => (latestRequestId = latestRequestId + 1);

export const SearchInput = ({
  hideSuggestions = false,
  query: initialQuery = "",
}: SearchInputProps) => {
  const { t } = useTranslations();
  const {
    isTeamsales,
    requestMapping,
    siteUid,
    featureToggles: { toggle_force_member_price_display },
  } = useSharedData().data;
  const { elevateApi: apiClient } = useApiClients();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const [query, setQuery] = useState<string>(initialQuery);
  const { state, dispatch } = useSearchBoxContext();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const hasInputFocusOrShouldShowSuggestions = inputFocus || showSuggestions;

  const [stickyHeaderElement, setStickyHeaderElement] =
    useState<HTMLElement | null>(null);
  const [reactHeaderElement, setReactHeaderElement] =
    useState<HTMLElement | null>(null);

  const clearQuery = () => setQuery("");

  const overlayClickHandler = () => setInputFocus(false);

  useEffect(() => {
    if (hideSuggestions) {
      setInputFocus(false);
    }
  }, [hideSuggestions]);

  const fetchSuggestions = useMemo(
    () =>
      debounce(async (queryString: string) => {
        const requestId = generateLatestRequestId();
        try {
          const response = await getSuggestions({
            apiClient,
            isLoggedIn,
            isLaptop,
            isTeamsales,
            query: queryString,
            siteUid,
            toggle_force_member_price_display,
          });

          if (requestId !== latestRequestId) {
            return;
          }

          dispatch({
            type: SEARCH_BOX_REQUEST_SUCCESS,
            payload: toAutoCompleteData(response.data, queryString),
          });
        } catch (err) {
          log.error("Could not get autosuggestions", err);
        }
      }, THROTTLE_TIME_MS),
    [apiClient, dispatch, isLaptop, isLoggedIn, isTeamsales, siteUid]
  );

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuery(value);
    if (shouldShowSuggestions(value)) {
      void fetchSuggestions(value);
      return;
    }
    dispatch({
      type: SEARCH_BOX_CLEAR_RESULT,
    });
  };

  const handleFocus = () => {
    setInputFocus(true);
    if (!shouldShowSuggestions(query) || hasValue(state.items)) {
      return;
    }
    void fetchSuggestions(query);
  };

  useEffect(() => {
    setShowSuggestions(
      shouldShowSuggestions(query) &&
        ((state.items !== undefined && state.items.length > 0) ||
          state.products !== undefined) &&
        inputFocus
    );
  }, [state, query.length, inputFocus]);

  useEffect(() => {
    setStickyHeaderElement(document.getElementById("js-sticky-header"));
    setReactHeaderElement(document.getElementById("js-react-header"));
  }, []);

  useEffect(() => {
    if (showSuggestions) {
      stickyHeaderElement?.classList.toggle("header--non-sticky", true);
      reactHeaderElement?.classList.toggle("header--non-sticky", true);

      window.scrollTo({ top: 0, behavior: "smooth" });
      showStickyHeader();
    }

    return () => {
      stickyHeaderElement?.classList.toggle("header--non-sticky", false);
      reactHeaderElement?.classList.toggle("header--non-sticky", false);
    };
  }, [reactHeaderElement, showSuggestions, stickyHeaderElement]);

  return (
    <SearchSuggestionsWrapper className="search-suggestions-wrapper">
      {hasInputFocusOrShouldShowSuggestions && (
        <Overlay onClick={overlayClickHandler} />
      )}
      <SearchInputWrapper
        className="search-input-wrapper"
        isActive={hasInputFocusOrShouldShowSuggestions}
        onClick={() => inputRef.current?.focus()}
      >
        <Form
          action={requestMapping.search}
          autoComplete="off"
          data-testid="search-auto-suggest"
        >
          <Button
            type="submit"
            tabIndex={-1}
            aria-label={t("header.search.text")}
          >
            <SearchIcon fontSize={24} />
          </Button>
          <Label htmlFor="query">{t("header.search.text")}</Label>
          <Input
            style={{ border: "none" }}
            value={query}
            type="text"
            id="query"
            minLength={MINIMUM_QUERY_LENGTH}
            name="query"
            placeholder={t("header.search.text")}
            ref={inputRef}
            required={true}
            onChange={handleChange}
            onFocus={handleFocus}
            autoComplete="off"
            data-testid="search-auto-suggest-input"
          />
          {query !== "" && (
            <Button
              onClick={clearQuery}
              type="reset"
              aria-label={t("search.bar.reset.button")}
            >
              <Close fontSize={24} />
            </Button>
          )}
        </Form>
      </SearchInputWrapper>

      <AnimateHeight
        height={showSuggestions ? "auto" : 0}
        duration={ANIMATE_HEIGHT_DURATION_MS}
      >
        <AutoSuggestions
          query={query}
          isTwoColumns={state.isTwoColumns}
          items={state.items}
          products={{
            highlightedPhrase: state.products?.highlightedPhrase,
            items: (state.products?.items ?? []).map((product) => {
              const { averageRating, brand, name, url, primaryImage, ticket } =
                product;

              const {
                price: { alternate, selling, cheapest, type },
              } = product;

              return {
                averageRating,
                brand,
                name,
                url,
                price: {
                  selling: {
                    isDiscounted:
                      alternate !== undefined
                        ? alternate.value > selling.value
                        : false,
                    price: selling.valueFormatted,
                    ...(selling.labelType !== "None" && {
                      label:
                        selling.labelType === "TranslatedLabel"
                          ? selling.label
                          : t(selling.translationKey),
                    }),
                  },
                  ...(alternate !== undefined && {
                    alternate: {
                      hasUndeductedDiscount: alternate.value < selling.value,
                      price: alternate.valueFormatted,
                      ...(alternate.labelType !== "None" && {
                        label:
                          alternate.labelType === "TranslatedLabel"
                            ? alternate.label
                            : t(alternate.translationKey),
                      }),
                    },
                  }),
                  ...(cheapest !== undefined && {
                    cheapest: {
                      price: cheapest.valueFormatted,
                    },
                  }),
                  type,
                },
                primaryImage,
                ticket,
              };
            }),
          }}
        />
      </AnimateHeight>
    </SearchSuggestionsWrapper>
  );
};
