import {
  XXL_SESSION_LOGIN,
  XXL_SESSION_LOGOUT,
  addXXLEventListener,
  dispatchEvent,
  removeXXLEventListener,
} from "@/react-utils/xxl-event";
import { useEffect, useState } from "react";
import type { XXLCookie } from "react-app/src/global";
import { cookieNames, getCookieParsed } from "react-app/src/utils/Cookie";

interface SessionState {
  isLoggedIn: boolean;
}
interface useSessionState {
  sessionState: SessionState;
  updateLoginState: (input: { isLoggedIn: boolean }) => void;
}

const getIsLoggedIn = (): SessionState => {
  const { loggedIn = false } =
    (typeof window !== "undefined"
      ? getCookieParsed<XXLCookie>(cookieNames.XXL)
      : undefined) ?? {};
  return { isLoggedIn: loggedIn };
};

export const useSession = (): useSessionState => {
  const [sessionState, setSessionState] =
    useState<SessionState>(getIsLoggedIn());

  useEffect(() => {
    const handleSessionUpdate = (isLoggedIn: boolean) => () => {
      setSessionState((oldState) => ({ ...oldState, isLoggedIn }));
    };
    const handleSessionLogin = handleSessionUpdate(true);
    const handleSessionLogout = handleSessionUpdate(false);

    addXXLEventListener(XXL_SESSION_LOGIN, handleSessionLogin);
    addXXLEventListener(XXL_SESSION_LOGOUT, handleSessionLogout);
    return () => {
      removeXXLEventListener(XXL_SESSION_LOGIN, handleSessionLogin);
      removeXXLEventListener(XXL_SESSION_LOGOUT, handleSessionLogout);
    };
  }, []);

  const updateLoginState = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    dispatchEvent(isLoggedIn ? XXL_SESSION_LOGIN : XXL_SESSION_LOGOUT);
  };

  return { sessionState, updateLoginState };
};
