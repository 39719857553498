import {
  DISCOUNT_CATEGORY_SUFFIX,
  getIsDiscountCategoryCode,
} from "@/react-utils/xxl-category";
import { Apis } from "@/utils/api-helper";
import { getLegacySiteUid, getSiteUid } from "@/utils/environment-variables";
import {
  getTranslations,
  translateMessage,
} from "@/utils/translations/translateMessage";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isEmpty, isNullOrUndefined } from "@xxl/common-utils";
import { type Translation } from "@xxl/content-api";
import type { CategoryData } from "@xxl/frontend-api";
import { KeyvDefaultTtl } from "./server-side-cache/server-side-cache";
import { getProductDiscountCount } from "./strategies/product-count";
export const discountSubCategorySlugTranslationKey =
  "category.page.discounts.subcategory.slug";
const discountsSubcategoryTitleKey =
  "category.page.discounts.subcategory.title";

type DiscountCategoryData = {
  code: string;
  name: string;
  url: string | undefined;
  categoryLevel: number | undefined;
  productCount: number | undefined;
  breadcrumbs: CategoryData["breadcrumbs"];
  subCategories: CategoryData["subCategories"];
  pageTitle?: string;
};

const getDiscountCategoryPageTitle = (
  categoryData: CategoryData,
  translations: Translation[]
): string => {
  const currentDate = new Date();
  const currentMonthName = currentDate
    .toLocaleString("default", {
      month: "long",
    })
    .toLowerCase();
  const currentYear = currentDate.getFullYear().toString();
  return translateMessage(
    {
      key: discountsSubcategoryTitleKey,
      messageArguments: [
        categoryData.name ?? "",
        currentMonthName,
        currentYear,
      ],
      fallback: categoryData.code ?? "" + String(DISCOUNT_CATEGORY_SUFFIX),
    },
    translations
  );
};

export const getDiscountCategoryForCategoryData = (
  categoryData: CategoryData,
  translations: Translation[],
  discountedCategoryProductCount: number
): DiscountCategoryData | null => {
  if (categoryData.code === undefined) {
    return null;
  }
  const productCount = discountedCategoryProductCount;
  if (productCount === 0) {
    return null;
  }

  const slugName = translateMessage(
    discountSubCategorySlugTranslationKey,
    translations
  );
  const name = slugName.charAt(0).toUpperCase() + slugName.slice(1);
  const url = categoryData.url?.replace("/c/", `/${slugName}/c/`);
  const code = categoryData.code + DISCOUNT_CATEGORY_SUFFIX;
  const categoryLevel = (categoryData.categoryLevel ?? 0) + 1;

  return {
    code,
    name,
    url,
    categoryLevel,
    productCount,
    breadcrumbs: [
      ...(categoryData.breadcrumbs ?? []),
      {
        code,
        name,
        url,
        categoryLevel,
      },
    ],
    subCategories: [],
    pageTitle: getDiscountCategoryPageTitle(categoryData, translations),
  };
};

const fetchCategoryData = async (
  categoryCode: string | undefined
): Promise<CategoryData | undefined> => {
  if (isEmpty(categoryCode)) {
    return;
  }
  const isDiscountCategory = getIsDiscountCategoryCode(categoryCode);
  const codeWithoutDiscountSuffix = categoryCode.replace(
    DISCOUNT_CATEGORY_SUFFIX,
    ""
  );
  const [
    {
      data: [categoryData],
    },
    translations,
    productDiscountCount,
  ] = await Promise.all([
    Apis.getInstance().pimApi.getCategories(
      getLegacySiteUid(),
      codeWithoutDiscountSuffix
    ),
    getTranslations(),
    getProductDiscountCount(codeWithoutDiscountSuffix, getSiteUid()),
  ]);
  if (isNullOrUndefined(categoryData)) {
    return;
  }
  const discountCategory = getDiscountCategoryForCategoryData(
    categoryData,
    translations,
    productDiscountCount
  );
  if (discountCategory !== null && !isDiscountCategory) {
    categoryData.subCategories?.push(discountCategory);
  }
  const pageTitle = isDiscountCategory
    ? discountCategory?.pageTitle
    : categoryData.pageTitle ?? categoryData.pageTitle;
  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ code, name, url }) => ({ code, name, url }));
  return {
    code: categoryCode,
    url:
      isDiscountCategory && discountCategory !== null
        ? discountCategory.url
        : categoryData.url,
    subCategories: subCategories,
    categoryLevel: categoryData.categoryLevel,
    breadcrumbs: categoryData.breadcrumbs,
    discountsSubcategory: discountCategory !== null,
    header: categoryData.header,
    pageTitle,
  };
};

export const fetchCategoryDataMemoized = memoize(
  fetchCategoryData,
  new Keyv(),
  {
    key: (categoryCode: string | undefined) =>
      `category-data-${categoryCode ?? ""}`,
    ...KeyvDefaultTtl,
  }
);

export const getSuperCategoryCodes = (category: CategoryData): string[] => {
  const breadcrumbs = category.breadcrumbs ?? [];
  return breadcrumbs
    .slice(1, Math.max(0, breadcrumbs.length - 2))
    .map((breadcrumbCategory: CategoryData) => breadcrumbCategory.code ?? "")
    .filter(Boolean);
};
