import type { ImageData } from "@xxl/frontend-api";
import type { Image } from "@xxl/content-api";
import isUndefined from "lodash/isUndefined";
import { MISSING_PRODUCT_IMAGE_URL } from "../constants";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";

const SANITY_DEFAULT_QUALITY_LEVEL = 75; // Same as FileSpin

const getBaseUrl = (imageData: ImageData | Image): string => {
  if ("baseUrl" in imageData && hasValue(imageData.baseUrl)) {
    return imageData.baseUrl;
  }
  if ("url" in imageData && hasValue(imageData.url)) {
    return imageData.url;
  }
  return "";
};

const getImageUrlFromBaseUrl = (
  baseUrl: string,
  quality: number = SANITY_DEFAULT_QUALITY_LEVEL
) => `${baseUrl}?auto=format&fit=crop&q=${quality}`;

/**
 * Returns an image URL from an ImageData object (Sanity hosted)
 * Automatically applies any provided Hotspot
 * Optionally restricts width
 * @param imageData ImageData|Image
 * @param width? Max width in pixels
 */
//
const getImageUrl = (
  imageData: ImageData | Image,
  width?: number,
  height?: number,
  quality: number = SANITY_DEFAULT_QUALITY_LEVEL
): string => {
  const { hotspot = null } = imageData;
  const baseUrl = getBaseUrl(imageData);
  let url = getImageUrlFromBaseUrl(baseUrl, quality);

  if (!isUndefined(width)) {
    url += `&w=${width}`;
  }
  if (!isUndefined(height)) {
    url += `&h=${height}`;
  }
  if (isNotNullOrUndefined(hotspot)) {
    const { x, y } = hotspot;
    if (!isUndefined(x)) {
      url += `&fp-x=${x}`;
    }
    if (!isUndefined(y)) {
      url += `&fp-y=${y}`;
    }
  }
  return url;
};

const handleImageError = (event: React.InvalidEvent<HTMLImageElement>) => {
  event.target.src = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
  event.target.srcset = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
};

export const getHotspot = (image: Image) =>
  isNotNullOrUndefined(image.hotspot)
    ? {
        x: image.hotspot.x ?? 0,
        y: image.hotspot.y ?? 0,
      }
    : undefined;

export {
  getBaseUrl,
  getImageUrl,
  handleImageError,
  SANITY_DEFAULT_QUALITY_LEVEL,
};
