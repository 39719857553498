//import { PERSONALIZED_PRODUCT_LIST_QUERY_NAME } from "@/react-app/src/components/Product/constants";
import { useEffect, useState } from "react";
import { PERSONALIZED_PRODUCT_LIST_QUERY_NAME } from "react-app/src/components/Product/constants";

export const useTrackingDataFromURL = () => {
  const [gtmProductListName, setGtmProductListName] = useState<string | null>(
    null
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setGtmProductListName(
      searchParams.get(PERSONALIZED_PRODUCT_LIST_QUERY_NAME)
    );
  }, []);

  return {
    gtmProductListName,
  };
};
