import { SERVICE_CODE } from "@/react-utils/Symplify/constants";

const { BIKE, IMBOX } = SERVICE_CODE;

const SERVICE_ITEMS = {
  [IMBOX]: [
    "services.popup.imbox.bullet.1",
    "services.popup.imbox.bullet.2",
    "services.popup.imbox.bullet.3",
    "services.popup.imbox.bullet.4",
    "services.popup.imbox.bullet.5",
    "services.popup.imbox.bullet.6",
  ],
  [BIKE]: [
    "services.popup.bike.bullet.1",
    "services.popup.bike.bullet.2",
    "services.popup.bike.bullet.3",
    "services.popup.bike.bullet.4",
    "services.popup.bike.bullet.5",
  ],
} as const;

export { SERVICE_ITEMS };
