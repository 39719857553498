import type { Nullable } from "@xxl/type-utils";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import type { DeepNonNullable } from "utility-types";

type WithBaseColors = {
  baseColors?: string[];
};
type WithBrands = {
  brands?: string[];
};
type WithCampaigns = {
  campaigns?: string[];
};
type WithCategories = {
  categories?: string[];
};
type WithStyle = {
  styleId: string;
};
type WithUsers = {
  users?: string[];
};
type WithProducts = {
  includedProducts?: string[];
};
type UniqueUpsaleProps = WithCategories & WithStyle;
type UniqueRecentlyViewedProps = WithStyle;
type UniquePersonalizedProps = WithBaseColors &
  WithBrands &
  WithCampaigns &
  WithCategories &
  WithProducts &
  WithUsers;
type UniquePopularityProps = WithBaseColors &
  WithBrands &
  WithCampaigns &
  WithCategories &
  WithUsers;
type UniqueBestsellerProps = WithBaseColors &
  WithBrands &
  WithCampaigns &
  WithCategories &
  WithUsers;
type UniqueCartProps = {
  productIdsInCart: string[];
};

type CommonProps = {
  campaigns?: string[];
  includedProducts?: string[];
  styleId?: string;
};

type UseUniquePropsReturn = Nullable<DeepNonNullable<UniqueUpsaleProps>> &
  Nullable<DeepNonNullable<UniqueRecentlyViewedProps>> &
  Nullable<DeepNonNullable<UniquePersonalizedProps>> &
  Nullable<DeepNonNullable<UniqueBestsellerProps>> &
  Nullable<DeepNonNullable<UniquePopularityProps>> &
  Nullable<DeepNonNullable<UniqueCartProps>>;

export const RecommendationStrategies = {
  bestseller: "bestseller",
  cart: "cart",
  frequently_bought_together: "frequently-bought-together",
  personalized: "personalized",
  popularity: "popularity",
  recently: "recently",
  upsale: "upsale",
} as const;

export type HandlerProps =
  | (CommonProps & {
      strategy: typeof RecommendationStrategies.frequently_bought_together;
      styleId: string;
    })
  | (UniqueCartProps &
      CommonProps & {
        strategy: typeof RecommendationStrategies.cart;
      })
  | (UniqueUpsaleProps &
      CommonProps & { strategy: typeof RecommendationStrategies.upsale })
  | (UniqueRecentlyViewedProps &
      Omit<CommonProps, "includedProducts"> & {
        strategy: typeof RecommendationStrategies.recently;
      })
  | (UniquePersonalizedProps &
      CommonProps & { strategy: typeof RecommendationStrategies.personalized })
  | (UniquePopularityProps &
      CommonProps & { strategy: typeof RecommendationStrategies.popularity })
  | (UniqueBestsellerProps &
      CommonProps & { strategy: typeof RecommendationStrategies.bestseller });

export type RecommendationStrategy =
  (typeof RecommendationStrategies)[keyof typeof RecommendationStrategies];

export const useUniqueProps = (
  props: HandlerProps
): UseUniquePropsReturn | null => {
  const isUpsale = props.strategy === "upsale";
  const isPersonalized = props.strategy === "personalized";
  const isPopularity = props.strategy === "popularity";
  const isBestseller = props.strategy === "bestseller";
  const isCart = props.strategy === "cart";

  return {
    baseColors:
      (isPersonalized || isBestseller || isPopularity) &&
      props.baseColors !== undefined
        ? props.baseColors
        : null,
    brands:
      (isPersonalized || isBestseller || isPopularity) &&
      props.brands !== undefined
        ? props.brands
        : null,
    campaigns:
      (isPersonalized || isBestseller || isPopularity) &&
      props.campaigns !== undefined
        ? props.campaigns
        : null,
    categories:
      (isBestseller || isPersonalized || isPopularity || isUpsale
        ? props.categories
        : null) ?? null,
    productIdsInCart: isCart ? props.productIdsInCart : null,
    includedProducts:
      (isPersonalized || isBestseller || isPopularity) &&
      props.includedProducts !== undefined
        ? props.includedProducts
        : null,
    styleId: (isUpsale ? props.styleId : null) ?? null,
    users:
      (isPersonalized || isBestseller || isPopularity) &&
      props.users !== undefined
        ? props.users
        : null,
  };
};

export const getTitle = (
  strategy: RecommendationStrategy,
  t: Translate
): string | undefined => {
  switch (strategy) {
    case "cart":
      return t("carousel.personalized.title");
    case "upsale":
      return t("carousel.upsale.title");
    case "recently":
      return t("carousel.recently.viewed.title");
    case "personalized":
      return t("carousel.personalized.title");
    case "frequently-bought-together":
      return t("carousel.frequently.bought.together.title");
    default:
      return;
  }
};
