import { Apis } from "@/utils/api-helper";
import type {
  FooterContentResponse,
  Image,
  ImageHotspot,
  Link,
  TitledLinkList,
} from "@xxl/content-api";
import { assertNever } from "react-app/src/utils/xxl-assert-never";
import { getSiteUid } from "../environment-variables";
import memoize from "@keyvhq/memoize";
import Keyv from "@keyvhq/core";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

const socialMediaPlatforms = {
  facebook: "facebook",
  instagram: "instagram",
  youtube: "youtube",
} as const;

type FooterLink = {
  displayName: string;
  url: string;
};

type FooterImage = {
  url: string;
  link: string | null;
};

type FooterLinkList = {
  list: {
    displayName?: string;
    url?: string;
  }[];
  title: string;
};

type FooterLogo = {
  url: string;
  alt: string | null;
  hotspot: ImageHotspot | null;
  link: string | null;
};

type SocialMediaUrls = {
  facebookUrl: string | null;
  instagramUrl: string | null;
  youtubeUrl: string | null;
};

type FooterContent = {
  linkListOne: FooterLinkList | null;
  linkListTwo: FooterLinkList | null;
  logo: FooterLogo | null;
  socialMediaUrls: SocialMediaUrls | null;
  thirdPartyLogos: FooterImage[] | null;
};

function getLink({ displayName, url }: Link): FooterLink {
  if (typeof displayName !== "string" || typeof url !== "string") {
    throw new Error(
      `Expected displayName and url to be defined, got ${typeof displayName} and ${typeof url}.`
    );
  }

  return {
    displayName,
    url,
  };
}

const toLinkList = (linkList?: TitledLinkList): FooterLinkList | null => {
  if (linkList === undefined || (linkList as unknown) === null) {
    return null;
  }
  const { list, title } = linkList;

  if (list === undefined || title === undefined) {
    throw new Error("...");
  }

  return {
    list: list.map(getLink),
    title,
  };
};

/**
 * Returns footer data that has been amended with stricter types.
 * @param response
 * @returns footer data
 */
const getAmendedFooter = ({ result }: FooterContentResponse) => {
  if (result === undefined) {
    throw new TypeError(`[getFooterContent] result is not defined.`);
  }
  const [
    { linkListOne, linkListTwo, logo, socialMediaLinks, thirdPartyLogos },
  ] = result;

  return {
    linkListOne,
    linkListTwo,
    logo,
    socialMediaLinks,
    thirdPartyLogos,
  };
};

const getSocialLinks = (list: Link[]): SocialMediaUrls => {
  const urls: SocialMediaUrls = {
    facebookUrl: null,
    instagramUrl: null,
    youtubeUrl: null,
  };

  list.forEach(({ displayName, url }) => {
    if (typeof displayName !== "string" || typeof url !== "string") {
      throw new Error(
        `Expected displayName and url to be string, got ${typeof displayName} and ${typeof url}.`
      );
    }

    switch (displayName) {
      case socialMediaPlatforms.facebook:
        urls.facebookUrl = url;
        break;
      case socialMediaPlatforms.instagram:
        urls.instagramUrl = url;
        break;
      case socialMediaPlatforms.youtube:
        urls.youtubeUrl = url;
        break;
      default:
        assertNever(displayName as never);
    }
  });

  return urls;
};

function getImage({ link, url }: Image): FooterImage {
  if (typeof url !== "string") {
    throw new Error(`Expected url to be string, got ${typeof url}.`);
  }

  return {
    url,
    link: link ?? null,
  };
}

const toImage = (image?: Image): FooterLogo | null => {
  if (typeof image?.url !== "string") {
    return null;
  }

  const { alt = null, hotspot = null, link = null, url } = image;

  return { url, alt, hotspot, link };
};

const getFooterContentInternal = async (): Promise<FooterContent> => {
  const { data: response } = await Apis.getInstance().contentApi.getFooter(
    getSiteUid(),
    "footer"
  );
  const { linkListOne, linkListTwo, logo, socialMediaLinks, thirdPartyLogos } =
    getAmendedFooter(response);

  return {
    linkListOne: toLinkList(linkListOne),
    linkListTwo: toLinkList(linkListTwo),
    logo: toImage(logo),
    socialMediaUrls:
      socialMediaLinks !== undefined ? getSocialLinks(socialMediaLinks) : null,
    thirdPartyLogos: (thirdPartyLogos ?? []).map(getImage),
  };
};

const getFooterContent = memoize(
  getFooterContentInternal,
  new Keyv({ namespace: KeyvCacheNamespace.FOOTER_CONTENT }),
  KeyvDefaultTtl
);

export { getFooterContent };
export type {
  FooterContent,
  FooterImage,
  FooterLinkList,
  FooterLogo,
  SocialMediaUrls,
};
