import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type {
  InfoMessage as ApiInfoMessage,
  DisclaimerInformation as DisclaimerInformationData,
} from "@xxl/content-api";
import { Apis } from "../api-helper";
import { isDateWithinRange } from "../date-helper";
import { getSiteUid } from "../environment-variables";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

export type InfoMessage = {
  linkText: string;
  linkType: string | null;
  message: string;
  url: string;
  type: string;
  disclaimerInformation?: DisclaimerInformationData;
};

const getInfoMessagesInternal = async (): Promise<ApiInfoMessage[]> => {
  const {
    data: { result = [] },
  } = await Apis.getInstance().contentApi.getInfoMessages(getSiteUid());
  return result;
};

const getInfoMessages = memoize(
  getInfoMessagesInternal,
  new Keyv({ namespace: KeyvCacheNamespace.INFO_MESSAGES_CONTENT }),
  KeyvDefaultTtl
);

const getInfoMessage = async (): Promise<InfoMessage | null> => {
  const result = await getInfoMessages();
  const { linkText, linkType, message, url, type, disclaimerInformation } =
    result.find(isDateWithinRange) ?? {};

  if (
    linkText === undefined ||
    message === undefined ||
    url === undefined ||
    type === undefined
  ) {
    return null;
  }

  return {
    linkText,
    linkType: linkType ?? null,
    message,
    url,
    type,
    ...(disclaimerInformation !== undefined && { disclaimerInformation }),
  };
};

export { getInfoMessage };
