import { postNotificationRequest } from "@/components/ProductDetailsPage/ProductForm/ProductSizeSelector/ProductSizeSelector.helper";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSession } from "@/react-app/hooks/useSession";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { Drawer } from "@/react-components/Drawer";
import { dispatchOpenLoginEvent } from "@/react-utils/xxl-event";
import type { ProductData } from "@xxl/product-search-api";
import type { PropsWithChildren } from "react";
import React from "react";
import { VariantListItem } from "./VariantListItem";
import { VariantRecommendationComponent } from "./VariantRecommendation";
import { VariantUl } from "./styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  heading: string;
  product: ProductData;
  onVariantClick: (code: string) => void;
};

export const VariantSelectDrawer = ({
  isOpen,
  onClose,
  isLoading = false,
  heading,
  product,
  onVariantClick,
}: PropsWithChildren<Props>) => {
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const { siteUid } = useSharedData().data;
  const { customersApi } = useApiClients();

  const [pendingRemindersIds, setPendingRemindersIds] = React.useState<
    string[]
  >([]);
  const [reminderIds, setReminderIds] = React.useState<string[]>([]);

  /**
   * @argument code - the product code "variant.code"
   * @argument sizeCode - the size code "variant.sizeCode" without the style info eg. `2144234`
   */
  const handleRemindMeClick = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    code: string,
    sizeCode: string
  ) => {
    evt.stopPropagation();

    if (!isLoggedIn) {
      dispatchOpenLoginEvent();
      setPendingRemindersIds((prev) => Array.from(new Set([...prev, code])));
      return;
    }

    setReminderIds((prev) => [...prev, code]);
    void postNotificationRequest(customersApi, siteUid, sizeCode, code);
  };

  React.useEffect(() => {
    if (isLoggedIn === true && pendingRemindersIds.length > 0) {
      pendingRemindersIds.forEach((id) => {
        const variant = product.variants.find((v) => v.code === id);

        if (variant === undefined) {
          throw new Error(
            `Variant not found for ID: ${id}. Please check the ID and try again.`
          );
        }
        setReminderIds((prev) => [...prev, id]);
        setPendingRemindersIds((prev) => {
          const temp = new Set(prev);
          temp.delete(id);
          return Array.from(temp);
        });
        void postNotificationRequest(
          customersApi,
          siteUid,
          variant.sizeCode.split("_")[0],
          id
        );
      });
    }
  }, [
    customersApi,
    isLoggedIn,
    siteUid,
    pendingRemindersIds,
    product.variants,
  ]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      heading={heading}
      customPadding={`${xxlTheme.spaces.smallRegular}`}
    >
      <VariantRecommendationComponent
        articleNumber={product.code.split("_")[0]}
        brandCode={product.brand?.code}
        categoryCodes={product.categoryBreadcrumbs.map((c) => c.code)}
        photoshoot={product.photoshootModel}
      />
      <VariantUl>
        {product.variants.map((variant) => (
          <VariantListItem
            key={variant.code}
            onClick={() => onVariantClick(variant.code)}
            hasReminder={reminderIds.includes(variant.code)}
            onRemindMeClick={handleRemindMeClick}
            variant={variant}
            isExcludedFromClickAndCollect={
              product.isExcludedFromClickAndCollect
            }
          />
        ))}
      </VariantUl>
    </Drawer>
  );
};
