import React, { useCallback } from "react";
import type { CrossSalesProps } from "../../../components/CrossSales/CrossSales";
import { CrossSales } from "../../../components/CrossSales/CrossSales";
import { usePortal } from "../../usePortal/usePortal";

const useCrossSales = () => {
  const { showPortal, hidePortal, portal } = usePortal({
    rootElementId: "js-react-cart-cross-sales",
  });
  const openCrossSales = useCallback(
    ({
      clickAndCollectWarning,
      crossSalesProducts,
      onClose,
      productData,
    }: Omit<CrossSalesProps, "onClickClose"> & { onClose?: () => void }) => {
      showPortal(
        <CrossSales
          clickAndCollectWarning={clickAndCollectWarning}
          crossSalesProducts={crossSalesProducts}
          onClickClose={() => {
            hidePortal();
            if (onClose !== undefined) {
              onClose();
            }
          }}
          productData={productData}
        />
      );
    },
    [hidePortal, showPortal]
  );

  return {
    openCrossSales,
    CrossSalesComponent: portal,
  };
};

export { useCrossSales };
