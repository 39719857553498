import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Text } from "@/react-components/Text/Text";
import type { StockStatusType } from "@xxl/product-search-api";
import { useTranslationStoreStockStatus } from "../../../../Cnc/helpers";
import { StoreStockStatusIndicator } from "../../../StoreStockStyles";

export const StockStatusIndicatorComponent = ({
  stockStatus,
}: {
  stockStatus: StockStatusType;
}) => {
  const tStoreStock = useTranslationStoreStockStatus(stockStatus);
  return (
    <XxlStack direction={"row"} alignItems={"center"}>
      <StoreStockStatusIndicator stockStatus={stockStatus} />
      <Text typography="small">{tStoreStock}</Text>
    </XxlStack>
  );
};
