import type { Site } from "@xxl/common-utils";
import type { ProductApi } from "@xxl/product-search-api";
import { clientAndServerApiFunctionFactory } from "../../shared/helpers";
import type { ApiOptions } from "../../shared/types";
import { getProductApiInstance } from "./api-instance-util";

type getProductDiscountCountFunction = InstanceType<
  typeof ProductApi
>["getProductDiscountCount"];

const getProductDiscountCount = async (
  site: Site,
  options: ApiOptions<getProductDiscountCountFunction>
) =>
  clientAndServerApiFunctionFactory(
    options,
    () => getProductApiInstance().getProductDiscountCount
  )(site);

export { getProductDiscountCount };
