import { isNotEmpty } from "@xxl/common-utils";
import type { CampaignBadgeData } from "@xxl/product-search-api";
import {
  useTranslations,
  type Translate,
} from "../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../translations";
import { hexColorToName } from "../../../utils/ProductData/badges-helper";
import { NewRibbon, type RibbonDirection } from "../NewRibbon/NewRibbon";

const getLabel = (badge: CampaignBadgeData, t: Translate) => {
  if (typeof badge.content?.text === "string") {
    return badge.content.text;
  }
  if (typeof badge.content?.scharven === "string") {
    return t(`badge.${badge.content.scharven}` as TranslationKey);
  }
  return null;
};

type BadgeProps = {
  badge: CampaignBadgeData;
  direction?: RibbonDirection;
};

export const CampaignRibbon = ({ badge, direction = "left" }: BadgeProps) => {
  const { t } = useTranslations();

  const colorName =
    badge.content?.name ?? hexColorToName(badge.content?.backgroundColor);
  const label = getLabel(badge, t);
  const shouldRenderLabel = label !== null && isNotEmpty(label);

  return (
    shouldRenderLabel && (
      <NewRibbon
        variant="WithColorName"
        colorName={colorName}
        label={label}
        direction={direction}
      />
    )
  );
};
