import { getAccessToken } from "@/react-app/graphql/getAuthData";
import styled from "@emotion/styled/macro";
import { Dialog, Drawer } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { CloseButton } from "react-app/src/components/Drawer/Drawer.styles";
import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useFeatureToggles } from "react-app/src/hooks/useFeatureToggle";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { MQ } from "react-app/src/styles/helpers";
import typography from "react-app/src/styles/theme/typography";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { getIsEmployee } from "react-app/src/utils/isEmployee/getIsEmployee";
import {
  getLocalStorageParsedItem,
  setLocalStorageItem,
  setStorageItem,
} from "react-app/src/utils/LocalStorage/local-storage";

const Content = styled.div`
  background-color: ${xxlTheme.colors.xxlBlack};
  color: ${xxlTheme.colors.xxlWhite};
  padding: ${xxlTheme.spaces.large};
  ${MQ("laptop")} {
    max-width: 480px;
    padding: ${xxlTheme.spaces.large};
    padding-bottom: ${xxlTheme.spaces.huge};
  }
`;

const DrawerHeading = styled.h3`
  font-family: ${typography.hugeBold.fontFamily};
  font-size: ${typography.hugeBold.fontSize}px;
  line-height: 28px;
  margin: 0;
  padding: ${xxlTheme.spaces.mini};
  display: flex;
  justify-content: flex-end;
`;

const Heading = ({ onClose }: { onClose: () => void }) => (
  <DrawerHeading>
    <CloseButton onClick={onClose}>
      <Icon color="white" size={22} name="CloseIconSlim" />
    </CloseButton>
  </DrawerHeading>
);

const MobileDrawer = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslations();
  return (
    <Drawer anchor="bottom" open={isOpen}>
      <Content>
        <Heading onClose={onClose} />
        <Icon size={64} name="Xxl" color="green" />
        <XxlStack py={xxlTheme.spaces.miniRegular} />
        <Text sizeCustom={24} color="white" typography="baseBold">
          {t("login.employee.discount.heading")}
        </Text>
        <XxlStack py={xxlTheme.spaces.regular} />
        <Text color="white" typography="base">
          {t("login.employee.discount.description.one")}
        </Text>
        <Text color="white" typography="base">
          {t("login.employee.discount.description.two")}
        </Text>
      </Content>
    </Drawer>
  );
};

const DesktopModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslations();
  return (
    <Dialog open={isOpen}>
      <Content>
        <Heading onClose={onClose} />
        <Icon size={64} name="Xxl" color="green" />
        <XxlStack pt={xxlTheme.spaces.miniRegular} />
        <Text sizeCustom={24} color="white" typography="baseBold">
          {t("login.employee.discount.heading")}
        </Text>
        <XxlStack py={xxlTheme.spaces.regular} />
        <Text color="white" typography="base">
          {t("login.employee.discount.description.one")}
        </Text>
        <Text color="white" typography="base">
          {t("login.employee.discount.description.two")}
        </Text>
      </Content>
    </Dialog>
  );
};

const EMPLOYEE_LOGIN_MODAL_DISMISSED_KEY = "employeeLoginModalDismissed";
const IS_EMPLOYEE_KEY = "isEmployee";

const useIsEmployee = (isLoggedIn: boolean) =>
  useQuery({
    queryKey: ["isEmployee", { isLoggedIn }],
    enabled: isLoggedIn,
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (accessToken === undefined) {
        return false;
      }
      const isEmployee = getIsEmployee(accessToken);
      setStorageItem(IS_EMPLOYEE_KEY, isEmployee.toString());
      return isEmployee;
    },
  });

export const EmployeeLoginWelcomeModal = ({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const { toggle_employee_discount } = useFeatureToggles(
    "toggle_employee_discount"
  );
  const { data, isError } = useIsEmployee(isLoggedIn);

  React.useLayoutEffect(() => {
    const isEmployeeFromLocalStorage = Boolean(
      getLocalStorageParsedItem(IS_EMPLOYEE_KEY)
    );
    const employeeLoginModalDismissed = Boolean(
      getLocalStorageParsedItem(EMPLOYEE_LOGIN_MODAL_DISMISSED_KEY)
    );
    if (employeeLoginModalDismissed) {
      setIsOpen(false);
    }
    if (isEmployeeFromLocalStorage && !employeeLoginModalDismissed) {
      setIsOpen(true);
    }
  }, [isLoggedIn, data]);

  const handleClose = () => {
    setIsOpen(false);
    setLocalStorageItem(EMPLOYEE_LOGIN_MODAL_DISMISSED_KEY, true);
  };

  if (isError) {
    return null;
  }

  if (toggle_employee_discount === false) {
    return null;
  }
  const Componet = isMobile ? MobileDrawer : DesktopModal;
  return <Componet onClose={handleClose} isOpen={isOpen} />;
};
