import type { GenericGraphQLError } from "react-app/src/components/Cart/Api/types";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useServiceDescription } from "../../../ConfiguratorForProductBundles/hooks/useServiceDescription/useServiceDescription";
import { GeneralErrorsList } from "../GeneralErrorsList";
import { ListWrapper } from "../ServiceProductsWrapper.styled";
import type { ServiceProductsProps } from "../types";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Overlay } from "@/react-components/Cart/Styles/ProductItem.styled";
import { XXLLoader } from "@/react-components/XXLLoader";
import { Text } from "@/react-components/Text";
import { DialogBox } from "@/react-components/DialogBox";
import { DialogFooter, ServiceProductsList } from "./styles";
import { useBreakpoint } from "@/react-hooks/useBreakpoint/useBreakpoint";
import { SanitizedHtmlComponent } from "@/react-components/Common";
import { hasValue } from "@xxl/common-utils";
import { ServiceProductsListItem } from "./ServiceProductsListItem";
import { SERVICE_ITEMS } from "./constants";
import type { TranslationKey } from "@/react-app/translations";
import { ButtonStyledAsLink } from "@/react-app/styled";
import { Transition } from "@/react-components/DialogBox/Transition";

const ServiceProduct = ({
  addToCartErrors,
  categoryId: category,
  closeModal,
  handleClickAddToCart,
  isAddingToCart,
  isModalOpen,
  serviceProduct: {
    brandName,
    code,
    name,
    priceData: {
      selling: { valueFormatted },
    },
  },
}: Omit<ServiceProductsProps, "serviceProducts"> & {
  addToCartErrors: GenericGraphQLError[];
  handleClickAddToCart: () => Promise<void>;
  isAddingToCart: boolean;
  serviceProduct: ServiceProductsProps["serviceProducts"][number];
}) => {
  const { t } = useTranslations();
  const isMobile = useBreakpoint() === "mobile";
  const { serviceDescription, serviceTitle } = useServiceDescription({
    category,
  });
  const onClick = () => void handleClickAddToCart();

  return (
    <DialogBox
      isDialogBoxOpen={isModalOpen}
      dialogBoxSize="xs"
      hasPadding={false}
      contentStyle={{ height: "100%" }}
      handleDialogBoxClosing={() => isAddingToCart === false && closeModal()}
      dialogBoxHeadline={t("services.popup.header.product.page")}
      transition={isMobile ? Transition : undefined}
    >
      <XxlStack
        sx={{
          height: "100%",
          maxWidth: isMobile ? "100%" : "495px",
        }}
      >
        <GeneralErrorsList errors={addToCartErrors} />
        <ListWrapper>
          {isAddingToCart && (
            <Overlay>
              <XXLLoader />
            </Overlay>
          )}
          <XxlStack px={"12px"} py={"24px"}>
            <Text typography="baseBold">
              {serviceTitle ?? t("services.popup.category.title.default")}
            </Text>
            {hasValue(serviceDescription) ? (
              SanitizedHtmlComponent({ text: serviceDescription })
            ) : (
              <Text as="p">
                {t("services.popup.category.description.default")}
              </Text>
            )}
            <ServiceProductsList>
              <ServiceProductsListItem
                brandName={brandName}
                code={code}
                name={name}
                price={valueFormatted}
                serviceTextList={
                  SERVICE_ITEMS[
                    code as keyof typeof SERVICE_ITEMS
                  ] as unknown as TranslationKey[]
                }
              >
                <XxlStack
                  gap={"20px"}
                  sx={{
                    display: !isMobile ? "flex" : "none",
                  }}
                  alignItems="center"
                >
                  <XxlButton
                    variant="secondary"
                    onClick={onClick}
                    disabled={isAddingToCart}
                    loading={isAddingToCart}
                    data-testid="add-to-cart-button"
                  >
                    {t("services.popup.add.service.label")}
                  </XxlButton>
                  <ButtonStyledAsLink
                    disabled={isAddingToCart}
                    onClick={closeModal}
                  >
                    <Text size="base">{t("sorts.close.button.text")}</Text>
                  </ButtonStyledAsLink>
                </XxlStack>
              </ServiceProductsListItem>
            </ServiceProductsList>
          </XxlStack>
        </ListWrapper>
        <DialogFooter>
          <XxlButton
            variant="secondary"
            onClick={onClick}
            disabled={isAddingToCart}
            loading={isAddingToCart}
            data-testid="add-to-cart-button"
          >
            {t("services.popup.add.service.label")}
          </XxlButton>
          <XxlButton
            variant="outlined"
            onClick={closeModal}
            disabled={isAddingToCart}
          >
            {t("sorts.close.button.text")}
          </XxlButton>
        </DialogFooter>
      </XxlStack>
    </DialogBox>
  );
};

export { ServiceProduct };
