import type { Ref } from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { VariantSelectDrawer } from "./VariantSelect/VariantSelectDrawer";
import { Message, StickyFormContent } from "./styles";
import type { TProductForm } from "./types";

const FormButton = ({
  onClick,
  isLoading = false,
}: {
  isLoading: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslations();
  return (
    <XxlButton disabled={isLoading} loading={isLoading} onClick={onClick}>
      {t("product.details.check.store.availability.button.label")}
      <Icon name="CaretRight" />
    </XxlButton>
  );
};

export const ProductFormOnlyAvailableInStore = ({
  formRef,
  isRefInView,
  product,
  isVariantSelectOpen,
  toggleVariantSelectDrawer,
  onVariantSelect,
}: TProductForm & {
  formRef: Ref<HTMLDivElement>;
  isRefInView: boolean;
}) => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const msgText = t({
    key: "product.details.only.available.in.store.product",
    messageArguments: [product.title],
  });

  const variantClickHandler = (variantCode: string) => {
    onVariantSelect({ action: "select-variant", variantCode });
    toggleVariantSelectDrawer();
  };

  const formContent = (
    <>
      <FormButton onClick={toggleVariantSelectDrawer} isLoading={false} />
      <Message text={msgText} />
    </>
  );

  return (
    <XxlStack ref={formRef} spacing={xxlTheme.spaces.micro}>
      <VariantSelectDrawer
        isLoading={false}
        product={product}
        onClose={toggleVariantSelectDrawer}
        isOpen={isVariantSelectOpen}
        heading={t("product.details.select.size")}
        onVariantClick={variantClickHandler}
      />
      {formContent}
      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        {formContent}
      </StickyFormContent>
    </XxlStack>
  );
};
