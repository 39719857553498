import type { TranslationOptions } from "@/react-app/utils/xxl-translate";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { CouponDataProps } from "../Api/types";

export type PromotionResultData = {
  description: Pick<TranslationOptions, "key" | "messageArguments">;
  promotion: {
    code: string;
  };
};

export const setPromotionsData = (
  coupons: (CouponDataProps | null)[] | undefined
): PromotionResultData[] | undefined => {
  if (coupons === undefined) {
    return undefined;
  }
  return coupons
    .filter(isNotNullOrUndefined)
    .map(({ code, couponFormattedAmount }) => ({
      description: isNotNullOrUndefined(couponFormattedAmount)
        ? {
            key: "checkout.coupon.applied.message",
            messageArguments: [String(couponFormattedAmount), code],
          }
        : {
            key: "checkout.coupon.applied.restricted.message",
            messageArguments: [code],
          },
      promotion: { code },
    }));
};
