import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface UsePortalProps {
  /**
   * ID of an existing element to serve as the root for the portal.
   *
   * Note: If the component needs access to any of our providers, the root element needs to be placed inside those providers.
   */
  rootElementId: string;
}

export const usePortal = ({ rootElementId }: UsePortalProps) => {
  const [content, setContent] = useState<React.ReactNode>(null);
  const portalContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    portalContainerRef.current = document.getElementById(rootElementId);

    if (portalContainerRef.current === null) {
      throw new Error(
        `No element found with id "${rootElementId}" to mount portal.`
      );
    }

    return () => {
      portalContainerRef.current = null;
      setContent(null);
    };
  }, [rootElementId]);

  const showPortal = useCallback((newContent: React.ReactNode) => {
    setContent(newContent);
  }, []);

  const hidePortal = useCallback(() => {
    setContent(null);
  }, []);

  return {
    hidePortal,
    portal:
      portalContainerRef.current !== null
        ? createPortal(content, portalContainerRef.current)
        : null,
    showPortal,
  };
};
