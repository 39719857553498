import type {
  AddBundledProductsToCartItemInput,
  AddBundleProductsToCartItemInput,
  AddBundleProductsToCartMutation,
  AddConfigurationsToCartItemInput,
} from "../../../generated/graphql-code-generator";
import { callGraphQL } from "../../../graphql/graphqlApi";
import { addBundleProductsToCartMutation } from "./graphqlQueries";
import type { AddBundleToCartResponse } from "./types";

const addBundleToCart = async (
  item: AddBundleProductsToCartItemInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddBundleToCartResponse> => {
  const queryResponse = await callGraphQL<AddBundleProductsToCartMutation>(
    {
      query: addBundleProductsToCartMutation,
      variables: {
        input: {
          cart: {
            items: [item],
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addBundleProductsToCart !== undefined
        ? "SUCCESS"
        : "ERROR",
    data: queryResponse,
  };
};

const addBundleWithEanToCart = async (
  ean: string,
  bundledProducts: AddBundledProductsToCartItemInput[],
  configurations: AddConfigurationsToCartItemInput[],
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  unit?: string
): Promise<AddBundleToCartResponse> =>
  addBundleToCart(
    {
      bundledProducts,
      configurations,
      ean,
      unit,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

const addBundleForProductConfiguratorToCart = async (
  templateId: string,
  bundledProducts: AddBundledProductsToCartItemInput[],
  configurations: AddConfigurationsToCartItemInput[],
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddBundleToCartResponse> =>
  addBundleToCart(
    {
      bundledProducts,
      configurations,
      templateId,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

export { addBundleForProductConfiguratorToCart, addBundleWithEanToCart };
