import type { Trackers } from "@/react-app/contexts/Tracking";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { getPreferredStoresCookie } from "@/react-app/utils/Cookie";
import {
  BOX_UNIT,
  PACKAGE_UNIT,
  PIECE_UNIT,
} from "@/react-components/Product/product-helper";
import { getCartDetailsCookie } from "@/react-utils/Cookie/XXLCookie";
import type { Store } from "react-app/src/utils/Stores/stores-helper";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type { CountdownTimerSettings } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type {
  AvailabilityData,
  ImageData,
  ProductData,
  StockStatusType,
  StoreAvailabilityData,
  VariantData,
} from "@xxl/product-search-api";
import { StockLevels, type CampaignCountdownProps } from "./types";

const PRIMARY_IMAGE_TYPE = "primary";
export const getPrimaryImg = (imgData: ImageData[]) =>
  imgData.find((i) => i.tags.includes(PRIMARY_IMAGE_TYPE));

export const getComponentSpacing = (isLaptopSize: boolean) =>
  isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular;

export const getCurrentStoreIdFromCookie = () =>
  getCartDetailsCookie()?.collectStore;

export const getPreferredStores = (
  stores: Store[],
  cncStoreId: string | undefined
) => {
  const preferredStoreIds = hasValue(cncStoreId)
    ? [cncStoreId]
    : getPreferredStoresCookie()?.ids ?? [];
  return Array.from(
    new Set(stores.filter(({ id }) => preferredStoreIds.includes(id)))
  );
};

export const getPreferredStoreIds = () => {
  const psCookie = getPreferredStoresCookie();
  if (psCookie === null) {
    return [];
  }
  return psCookie.ids;
};

export const createTimerProps = (
  settings: CountdownTimerSettings | null,
  fontColor?: string
): CampaignCountdownProps | null => {
  if (settings === null) {
    return null;
  }
  const { date, startDate, isOnlyHours } = settings;

  if (date === undefined) {
    log.error("Countdown property 'date' is undefined");
    return null;
  }

  return {
    convertDaysToHours: isOnlyHours ?? false,
    endDate: new Date(date),
    fontColor: fontColor ?? xxlTheme.colors.xxlWebBlack,
    startDate: startDate !== undefined ? new Date(startDate) : undefined,
  };
};

export const getSelectedOrFirstVariantSizeCode = (variants: VariantData[]) => {
  const selectedVariant = variants.filter(({ isSelected }) => isSelected);
  return selectedVariant[0]?.sizeCode ?? variants[0]?.sizeCode;
};

export const getSalesUnit = ({
  type,
  isAmmunition,
}: {
  isAmmunition: ProductData["isAmmunition"];
  type: ProductData["type"];
}) =>
  isAmmunition === true
    ? BOX_UNIT
    : ["PRODUCT_MULTIPACK", "BUNDLE_MULTIPACK"].includes(type)
      ? PACKAGE_UNIT
      : PIECE_UNIT;

export const isFrontendSalesUnit = (unit?: string): boolean =>
  unit !== undefined && [PIECE_UNIT, BOX_UNIT, PACKAGE_UNIT].includes(unit);

/**
 *
 * @param stockStatusList - A list of stock statuses `["OUTOFSTOCK", "INSTOCK", ...]`
 * @returns The highest stock status from a list of stock statuses. default return value is  `"OUTOFSTOCK"`
 */
export const getPrioritizedStockStatus = (
  stockStatusList: StockStatusType[]
) => {
  const stockStatusPriority = [
    StockLevels.IN_STOCK,
    StockLevels.LOW_STOCK,
    StockLevels.OUT_OF_STOCK,
  ] as const;

  return (
    stockStatusPriority.find((status) => stockStatusList.includes(status)) ??
    StockLevels.OUT_OF_STOCK
  );
};

export const getOnlineStockStatus = (a: VariantData["availability"]) =>
  a.find((item) => item.channel === "ONLINE")?.stockStatus;

export const getOnlineStockStatusOfVariants = (variants: VariantData[]) =>
  getPrioritizedStockStatus(
    variants
      .map(({ availability }) => getOnlineStockStatus(availability))
      .filter(isNotNullOrUndefined)
  );

const isStoreAvailabilityData = (
  a: AvailabilityData
): a is StoreAvailabilityData =>
  a.channel === "STORE" && a.key !== "CENTRAL_WAREHOUSE";

export const getStoresWithCollectableStockCount = (
  a: AvailabilityData[] = []
) =>
  a.reduce((acc, store) => {
    if (
      isStoreAvailabilityData(store) &&
      store.collectableStockStatus !== StockLevels.OUT_OF_STOCK
    ) {
      acc += 1;
    }
    return acc;
  }, 0);

const getHighestStockStatusByType =
  <T extends "collectableStockStatus" | "stockStatus">(stockStatusType: T) =>
  ({ availabilityData }: { availabilityData: AvailabilityData[] }) =>
    getPrioritizedStockStatus(
      availabilityData
        .filter(isStoreAvailabilityData)
        .map((availabilityDataItem) => availabilityDataItem[stockStatusType])
    );

export const getHighestCollectableStockStatus = getHighestStockStatusByType(
  "collectableStockStatus"
);

/**
 * Get the number of stores which have collectable stock, given a list of stores
 * @param storeIds to get data for
 * @param availabilityData for the product
 */
export const getStoresWithCollectableStockCountFromStores = (
  storeIds: string[],
  availabilityData: AvailabilityData[] = []
): number =>
  storeIds
    .map((storeId) => availabilityData.find(({ key }) => key === storeId))
    .filter(isNotNullOrUndefined)
    .filter(isStoreAvailabilityData)
    .filter(
      ({ collectableStockStatus }) =>
        collectableStockStatus !== StockLevels.OUT_OF_STOCK
    )
    .reduce((acc) => acc + 1, 0);

/**
 *
 * @param storeIds
 * @param availabilityData
 * @returns highest stock status from a list of store ids.
 */
export const getHighestCollectableStockStatusFromStores = (
  storeIds: string[],
  availabilityData: AvailabilityData[] = []
) => {
  const storeStockStatuses: StockStatusType[] = [];
  for (const id of storeIds) {
    const store = availabilityData.find((a) => a.key === id) ?? null;
    if (store === null || !isStoreAvailabilityData(store)) {
      continue;
    }
    storeStockStatuses.push(store.collectableStockStatus);
  }
  return getPrioritizedStockStatus(storeStockStatuses);
};

export const getInitCollectableStockStatus = (variants: VariantData[]) =>
  getPrioritizedStockStatus(
    variants
      .flatMap(({ availability }) =>
        availability.map((availabilityData) =>
          isStoreAvailabilityData(availabilityData)
            ? availabilityData.collectableStockStatus
            : undefined
        )
      )
      .filter(isNotNullOrUndefined)
  );

export type CncStockListItem = {
  storeId: string;
  name: string;
  storeStockStatus: StockStatusType;
  collectableStockStatus: StockStatusType;
};
export const getCncStockList = ({
  availabilityData,
  stores,
}: {
  availabilityData?: AvailabilityData[];
  stores: Store[];
}) => {
  if (availabilityData === undefined) {
    return [];
  }
  const resultsMap = new Map<string, CncStockListItem>();

  for (const store of stores) {
    const tempStore = availabilityData.find((a) => a.key === store.id);
    if (tempStore === undefined || tempStore.channel !== "STORE") {
      continue;
    }
    const isStoreEnabledForClickAndCollect =
      store.clickAndCollect?.enabled ?? false;
    const { collectableStockStatus, stockStatus: storeStockStatus } = tempStore;

    resultsMap.set(store.id, {
      storeId: store.id,
      name: store.name,
      storeStockStatus,
      collectableStockStatus: isStoreEnabledForClickAndCollect
        ? collectableStockStatus
        : StockLevels.OUT_OF_STOCK,
    });
  }

  return Array.from(resultsMap.values());
};

/**
 * Get highest stock for any size of each store
 */
const getStockByStoreForAnySize = (
  storeIds: string[],
  variants: { availability: AvailabilityData[] }[]
): StockStatusType[] =>
  storeIds.map((storeId) =>
    getPrioritizedStockStatus(
      variants
        .map(({ availability }) =>
          availability.find(({ key }) => key === storeId)
        )
        .filter(isNotNullOrUndefined)
        .filter(isStoreAvailabilityData)
        .map(({ collectableStockStatus }) => collectableStockStatus)
    )
  );

/**
 * Get the number of stores which have collectable stock, given a list of stores
 * @param storeIds to get data for
 * @param variants containing availability for the product
 */
export const getStoresWithCollectableStockCountForAnySize = (
  storeIds: string[],
  variants: { availability: AvailabilityData[] }[]
): number =>
  getStockByStoreForAnySize(storeIds, variants)
    .filter(
      (collectableStockStatus) =>
        collectableStockStatus !== StockLevels.OUT_OF_STOCK
    )
    .reduce((acc) => acc + 1, 0);

/**
 * Get the highest collectable stock of any size, given a list of stores
 * @param storeIds to get data for
 * @param variants containing availability for the product
 */
export const getHighestCollectableStockStatusForAnySize = (
  storeIds: string[],
  variants: { availability: AvailabilityData[] }[]
): StockStatusType =>
  getPrioritizedStockStatus(getStockByStoreForAnySize(storeIds, variants));
const getSalesChannelAvailabilityForElevate = (product: ProductData) => {
  if (product.isGraveyard) {
    return "Archived";
  }

  const selectedVariant =
    product.variants.find(({ isSelected }) => Boolean(isSelected)) ??
    product.variants.at(0);

  const isAvailableOnline = Boolean(
    selectedVariant?.availability.find(({ channel }) => channel === "ONLINE")
      ?.stockStatus !== "OUTOFSTOCK"
  );

  const isAvailableInStore = Boolean(
    selectedVariant?.availability.find(
      ({ channel, stockStatus }) =>
        channel === "STORE" && stockStatus !== "OUTOFSTOCK"
    )
  );

  if (!isAvailableInStore && isAvailableOnline) {
    return "Online only";
  }
  if (isAvailableInStore && !isAvailableOnline) {
    return `Store only ${
      product.isOnlyAvailableInStoreNoClickAndCollect === true
        ? "without"
        : "with"
    } Click and Collect`;
  }
  if (isAvailableInStore && isAvailableOnline) {
    return "Online and store";
  }
  return "Unknown";
};

type pushElevateProductViewEventProps = {
  trackers: Trackers;
  elevateProduct: ProductData;
  /**
   * Name of the product list that created the product URL.
   */
  listName: string;
};
export const pushElevateProductViewEvent = ({
  trackers,
  elevateProduct,
  listName,
}: pushElevateProductViewEventProps) => {
  const googleCategory = elevateProduct.categoryBreadcrumbs
    .map(({ name }) => name)
    .join(" / ");
  const brandName = elevateProduct.brand?.name ?? "";
  const productName = elevateProduct.title;
  const productCode = elevateProduct.code;
  const salesPrice = elevateProduct.price.selling.range.min.value;
  const productSize =
    elevateProduct.variants.find(({ isSelected }) => isSelected === true)
      ?.sizeCode ??
    elevateProduct.variants.at(0)?.sizeCode ??
    "";
  const salesChannelAvailability =
    getSalesChannelAvailabilityForElevate(elevateProduct);
  const product = {
    name: productName,
    id: productCode,
    price: salesPrice,
    brand: brandName,
    category: googleCategory,
    variant: productSize,
    salesChannelAvailability,
  };
  trackers.sendProductViewEvent({
    product,
    listName: listName,
  });
};
