import { PRODUCT_API_CLIENT_IDS, QUERY_KEYS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSession } from "@/react-app/hooks/useSession";
import { useSharedData } from "@/react-app/contexts/SharedData";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { isNotNullOrUndefined, isNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { BundleConfiguration } from "@xxl/product-search-api";
import { getPriceId } from "@/react-hooks/useElevateRequestData/useElevateRequestData";

type useElevateBundleQueryProps = {
  bundleConfiguration: BundleConfiguration[] | undefined;
};

export const useElevateBundleQuery = ({
  bundleConfiguration,
}: useElevateBundleQueryProps) => {
  const { productApi } = useApiClients();
  const {
    siteUid,
    isTeamsales,
    featureToggles: { toggle_force_member_price_display },
  } = useSharedData().data;
  const site = getSiteHost(legacySiteUidToSiteUid(siteUid));
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const priceId = getPriceId(
    isLoggedIn,
    isTeamsales,
    toggle_force_member_price_display
  );
  const bundleCode =
    bundleConfiguration?.[0]?.config[0]?.productGroupKey ?? null;

  const queryFn = async () => {
    if (bundleConfiguration === undefined || isNullOrUndefined(bundleCode)) {
      log.error("No bundle code provided. Cannot fetch data");
      return null;
    }

    try {
      const bundleResponse = await productApi.enrichBundleConfiguration({
        site,
        bundleCode,
        bundleConfiguration,
        priceId,
        clientId: PRODUCT_API_CLIENT_IDS.getBundles,
      });

      return bundleResponse[0].config[0].product;
    } catch (error) {
      throw new Error(`Failed to fetch product page data.`);
    }
  };

  return useQuery({
    queryKey: [QUERY_KEYS.ELEVATE_BUNDLE, { priceId, bundleCode }],
    queryFn,
    enabled: isNotNullOrUndefined(bundleCode),
  });
};
