import { isNotEmpty } from "@xxl/common-utils";
import type { PhotoshootModelData } from "@xxl/product-search-api";
import { motion } from "framer-motion";
import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "react-app/src/translations";
import type { SizeFitness } from "src/generated/graphql-code-generator";
import { useSizeRecommendationQuery } from "../../queries/useSizeRecommendationQuery";
import { SizeGuide } from "../../SizeGuide/SizeGuide";
import { SizeHeadingSection, SizeRecommendationSection } from "./styles";

const TRANSITION = { duration: 0.2, ease: [0.65, 0.05, 0.36, 1] };

const sizeFitnessTranslationKeyMap: Record<SizeFitness, TranslationKey> = {
  SMALLER_FIT: "product.details.customer.size.rating.small",
  BIGGER_FIT: "product.details.customer.size.rating.big",
  TRUE_TO_SIZE: "product.details.customer.size.rating.true",
};

type TSizeRating = {
  percentage: number;
  sizeFitness: SizeFitness;
}[];

const computeSizeRecommendation = (sr: TSizeRating) => {
  const { sizeFitness, percentage } = sr.reduce((acc, curr) => {
    if (curr.percentage > acc.percentage) {
      return curr;
    }
    return acc;
  });
  return {
    translationString: sizeFitnessTranslationKeyMap[sizeFitness],
    percentage,
  };
};

export const VariantRecommendationComponent = ({
  articleNumber,
  brandCode,
  categoryCodes,
  photoshoot,
}: {
  articleNumber: string;
  brandCode: string | undefined;
  categoryCodes: string[] | undefined;
  photoshoot: PhotoshootModelData | undefined;
}) => {
  const { t } = useTranslations();
  const { data: srData } = useSizeRecommendationQuery(articleNumber);
  const {
    translationString,
    percentage,
  }: { translationString?: TranslationKey; percentage?: number } = isNotEmpty(
    srData
  )
    ? computeSizeRecommendation(srData)
    : {};

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      transition={TRANSITION}
    >
      <SizeHeadingSection>
        {translationString !== undefined && percentage !== undefined && (
          <SizeRecommendationSection>
            <Icon name="Star" size={12} />
            <Text as="span" fontFamily="medium" size="small">
              {t({
                key: translationString,
                messageArguments: [percentage.toString()],
              })}
            </Text>
          </SizeRecommendationSection>
        )}
        <SizeGuide
          brandCode={brandCode}
          categoryCodes={categoryCodes}
          photoshoot={photoshoot}
          inDrawer={true}
        />
      </SizeHeadingSection>
    </motion.div>
  );
};
