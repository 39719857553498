import type { CartItemType } from "@/react-app/generated/graphql-code-generator";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import React, { useEffect, useState } from "react";
import { XXLLoader } from "../../XXLLoader";
import type { CartItem as CartItemData } from "../Api/types";
import { useCartContext } from "../CartState";
import { ListItem, Overlay } from "../Styles/ProductItem.styled";
import { CartItem } from "./CartItem";

type ProductItemProps = {
  item: CartItemData;
  onServiceEditClick: (args: {
    parentProductCode: string;
    parentItemId: {
      id: number;
      type: CartItemType;
    };
    serviceItemEan: string;
    serviceItemId: {
      id: number;
      type: CartItemType;
    };
  }) => void;
};

export const ProductItem: React.FunctionComponent<ProductItemProps> = ({
  item,
  onServiceEditClick,
}) => {
  const { state } = useCartContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [state]);

  return (
    <ListItem hasErrors={item.errors !== undefined && item.errors.length > 0}>
      {isLoading && (
        <Overlay>
          <XXLLoader />
        </Overlay>
      )}
      <CartItem item={item} onChange={setIsLoading} />
      {item.hasService === true &&
        isNotNullOrUndefined(item.productServices) && (
          <>
            {item.productServices.map((service) => (
              <CartItem
                key={service.entryNumber}
                item={service}
                onChange={setIsLoading}
                productQuantity={item.quantity}
                servicesQuantity={item.servicesQuantity}
                onServiceEditClick={({ serviceItemId, serviceItemEan }) =>
                  isNotNullOrUndefined(item.productCode) &&
                  isNotNullOrUndefined(item.itemId) &&
                  onServiceEditClick({
                    parentProductCode: item.productCode,
                    parentItemId: item.itemId,
                    serviceItemEan,
                    serviceItemId,
                  })
                }
              />
            ))}
          </>
        )}
    </ListItem>
  );
};
