import type { CartItem, CartResponseData, EgCartItem } from "../Api/types";
import { setEgCartItems } from "../Services/setCartItems";
import type { ToggleOfferInCartMutation } from "../../../generated/graphql-code-generator";

/**
 * The cart items from ToggleOfferInCartMutation
 */
export type PriceUpdateItem =
  ToggleOfferInCartMutation["toggleOfferInCart"]["items"][number];

/**
 * The nested cart items from ToggleOfferInCartMutation
 */
type PriceUpdateSubItem =
  | NonNullable<PriceUpdateItem["accessories"]>[number]
  | NonNullable<PriceUpdateItem["bundledProducts"]>[number]
  | NonNullable<PriceUpdateItem["serviceProducts"]>[number];

const updatePricesOnItems = (
  oldItems?: PriceUpdateSubItem[] | null,
  newPrices?: PriceUpdateSubItem[] | null
): void => {
  newPrices?.forEach(({ itemId, total, campaignInformation }) => {
    const oldItem = oldItems?.find(
      (item) => item.itemId.id === itemId.id && item.itemId.type === itemId.type
    );
    if (oldItem !== undefined) {
      oldItem.total = total;
      oldItem.campaignInformation = campaignInformation;
    }
  });
};

const updatePrices = (
  responseItems: EgCartItem[] = [],
  newPrices: PriceUpdateItem[] = []
): EgCartItem[] => {
  newPrices.forEach(
    ({
      itemId,
      total,
      campaignInformation,
      accessories,
      bundledProducts,
      serviceProducts,
    }) => {
      const oldItem = responseItems.find(
        (item) =>
          item.itemId.id === itemId.id && item.itemId.type === itemId.type
      );
      if (oldItem !== undefined) {
        oldItem.total = total;
        oldItem.campaignInformation = campaignInformation;
      }
      updatePricesOnItems(oldItem?.accessories, accessories);
      updatePricesOnItems(oldItem?.serviceProducts, serviceProducts);
      updatePricesOnItems(oldItem?.bundledProducts, bundledProducts);
    }
  );

  return [...responseItems];
};

export const updateCartItemsWithNewPrices = (
  cart: NonNullable<CartResponseData["data"]>["cart"] | undefined,
  newPrices: PriceUpdateItem[]
): CartItem[] => {
  const updatedItems = updatePrices(cart?.items, newPrices);
  return setEgCartItems(updatedItems, cart?.collectStore?.store.name);
};
