import type { PropsWithChildren } from "react";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { themeConfig } from "./mui-theme";

export const StyleProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const muiTheme = createTheme({
    ...themeConfig,
    ...{
      components: {
        ...themeConfig.components,
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: true,
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={muiTheme}>
      {Array.isArray(children) ? <>{children}</> : children}
    </ThemeProvider>
  );
};
