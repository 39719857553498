import { useMediaQuery } from "@mui/material";
import React from "react";
import { tabletAndDesktopMediaQuery } from "react-app/src/utils/xxl-screen";
import { List, UspWrapper } from "./UspBar.styled";
import { UspItem } from "./UspItem";

export const UspBar: React.FunctionComponent = () => {
  const isTabletOrDesktop = useMediaQuery(tabletAndDesktopMediaQuery);
  return isTabletOrDesktop ? (
    <UspWrapper>
      <List data-testid="service-usp-list">
        <UspItem index={1} />
        <UspItem index={2} />
        <UspItem index={3} />
      </List>
    </UspWrapper>
  ) : null;
};
