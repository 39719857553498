import { Drawer } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { hasValue } from "@xxl/common-utils";
import type { ProductTooltipContentData } from "@xxl/content-api";
import { compareSizes } from "@xxl/size-comparator-utils";
import { Field, Formik, type FieldProps } from "formik";
import React, { Fragment, useState } from "react";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";
import * as yup from "yup";
import { useTranslations } from "../../../../../contexts/Translations/TranslationsContext";
import { Button } from "../../../../../styled";
import { xxlTheme } from "../../../../../styles/xxl-theme";
import { SelectBox, Validity } from "../../../../Common/SelectBox";
import { XxlButton } from "../../../../Common/XxlButton/XxlButton";
import { ExpertReviewRadioButtons } from "../../../../ExpertReviewRadioButtons";
import {
  CloseIconStyled,
  FilterDrawerContainer,
  FilterDrawerFooter,
  FilterDrawerHeader,
  FilterDrawerHeadline,
  FilterDrawerSection,
  FilterDrawerTopWrapper,
  FilterDrawerWrapper,
} from "../../../../Filter/FilterDrawer/FilterDrawer.styled";
import type {
  CombinedConfigurationType,
  ProductConfiguration,
} from "../../ConfigurationModalWrapper";
import {
  ConfigurationFormDivider,
  ConfigurationFormFieldset,
  ConfigurationInputContainer,
  ErrorMessageWrapper,
  TooltipInfoContainer,
} from "./ConfigurationForm.styled";
import type { Configuration, ConfigurationSelection } from "./types";
import { SanitizedHtmlComponent } from "react-app/src/components/Common";

type ConfigurationOption = {
  key: string;
  name: string;
  value: ConfigurationSelection;
};

type ConfigurationFormProps = {
  configuration: ProductConfiguration[];
  isModalOpen: boolean;
  onModalClose: () => void;
  onReload?: () => void;
  onSubmit: (selectedConfigurations: Configuration) => Promise<void>;
};

type ConfigurationInputProps = {
  configurationInfo: ProductConfiguration;
  onClose: () => void;
  onSelectItem: (selection: ConfigurationSelection | null) => void;
  validity: Validity;
  errorMessage?: string;
};

const skiConfigName = "ski_config";

export const ConfigurationInput: React.FunctionComponent<
  ConfigurationInputProps
> = ({ errorMessage, configurationInfo, onClose, onSelectItem, validity }) => {
  const { t } = useTranslations();
  const { product, qualifier } = configurationInfo;

  if (product === undefined || qualifier === undefined) {
    return null;
  }

  const { baseProductName, sizeOptions } = product;

  if (baseProductName === undefined || sizeOptions === undefined) {
    return null;
  }

  const options: ConfigurationOption[] = sizeOptions
    .map((option) => ({
      key: option.code ?? "",
      name: option.size ?? "",
      value: {
        qualifier: configurationInfo.qualifier ?? "",
        value: option.code ?? "",
        ean: option.ean ?? null,
      },
    }))
    .sort(({ name: a }, { name: b }) => compareSizes(a, b));

  return (
    <ConfigurationInputContainer>
      <SelectBox
        errorMessage={errorMessage}
        id={qualifier}
        isMandatory={true}
        label={baseProductName}
        maxHeight={"170px"}
        onClose={onClose}
        onSelect={(selectedItem) => onSelectItem(selectedItem.value)}
        options={options}
        placeholder={t("product.details.select")}
        validity={validity}
      />
    </ConfigurationInputContainer>
  );
};

const createValidationSchema = (
  hasSkiSelector: boolean,
  requiredMessage: string
) => {
  const _skiSelector = {
    skiSelector: yup
      .boolean()
      .nullable()
      .default(null)
      .required(requiredMessage),
  };

  return yup.object({
    configurations: yup.array().of(
      yup.object().shape({
        qualifier: yup.string().required(),
        value: yup.string().nullable().required(requiredMessage),
      })
    ),
    ...(hasSkiSelector ? _skiSelector : null),
  });
};

const getSkiConfigCode = (
  configuration: CombinedConfigurationType[]
): { size?: string; ean?: string } => {
  const skiConfig = configuration.find(
    (config) => config.qualifier === skiConfigName
  );

  const sizeOptions = skiConfig?.product?.sizeOptions;
  return {
    size: sizeOptions?.[0]?.code,
    ean: sizeOptions?.[0]?.ean,
  };
};

export const ConfigurationForm = ({
  isModalOpen,
  onModalClose,
  onReload,
  onSubmit,
  configuration,
}: ConfigurationFormProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { t } = useTranslations();
  const { zIndex } = xxlTheme;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasSkiConfig = configuration.some(
    (config) => config.qualifier === skiConfigName
  );

  const skiConfig = configuration.filter(
    (config) => config.qualifier === skiConfigName
  );
  const skiConfigTooltip: ProductTooltipContentData | undefined =
    skiConfig[0]?.tooltip;

  const configurations = configuration.filter(
    (config) => config.qualifier !== skiConfigName
  );

  const skiConfigValue = hasSkiConfig ? getSkiConfigCode(configuration) : null;

  const formValues: Configuration = {
    configurations: configurations.map((config) => {
      return {
        qualifier: config.qualifier ?? "",
        value: null,
        ean: null,
      };
    }),
  };

  const configurationSchema = createValidationSchema(
    hasSkiConfig,
    t("form.field.required")
  );

  const checkValidity = (isTouched: boolean, hasError: boolean): Validity => {
    if (!isTouched) {
      return Validity.PENDING;
    }

    return hasError ? Validity.INVALID : Validity.VALID;
  };

  const handleSubmit = async (config: Configuration): Promise<void> => {
    const { skiSelector = false } = config;
    try {
      setIsSubmitting(true);
      await (skiSelector
        ? onSubmit({
            configurations: [
              ...config.configurations,
              ...[
                {
                  qualifier: skiConfigName,
                  value: skiConfigValue?.size ?? null,
                  ean: skiConfigValue?.ean ?? null,
                },
              ],
            ],
          })
        : onSubmit(config));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isModalOpen}
      onClose={onModalClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{ zIndex: zIndex.modal }}
    >
      <FilterDrawerWrapper style={{ height: "100dvh" }}>
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={configurationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({
            handleSubmit: _handleSubmit,
            isValid,
            setFieldValue,
            setFieldTouched,
            touched,
          }): JSX.Element => {
            return (
              <form onSubmit={_handleSubmit}>
                <FilterDrawerTopWrapper>
                  <FilterDrawerHeader>
                    <FilterDrawerHeadline>
                      {t("product.details.configure.product.modal.header")}
                    </FilterDrawerHeadline>
                    <CloseIconStyled onClick={onModalClose} />
                  </FilterDrawerHeader>
                  <FilterDrawerContainer>
                    <FilterDrawerSection>
                      {hasValue(configuration) ? (
                        <section>
                          <ConfigurationFormFieldset>
                            {configurations.map((configInfo, i) => {
                              const fieldName = `configurations[${i}].value`;

                              return (
                                <Fragment key={i}>
                                  <Field name={fieldName}>
                                    {({
                                      meta,
                                    }: FieldProps<ConfigurationSelection>): JSX.Element => {
                                      const isTouched = Boolean(
                                        touched.configurations !== undefined &&
                                          touched.configurations[i]?.value
                                      );
                                      const hasError = Boolean(meta.error);

                                      return (
                                        <ConfigurationInput
                                          configurationInfo={configInfo}
                                          errorMessage={
                                            hasError && isTouched
                                              ? meta.error
                                              : ""
                                          }
                                          onClose={(): void => {
                                            setFieldTouched(
                                              fieldName,
                                              true,
                                              true
                                            );
                                          }}
                                          onSelectItem={(selection): void => {
                                            setFieldValue(
                                              `configurations[${i}].ean`,
                                              selection?.ean,
                                              false
                                            );
                                            setFieldValue(
                                              fieldName,
                                              selection?.value
                                            );
                                          }}
                                          validity={checkValidity(
                                            Boolean(meta.touched || meta.value),
                                            hasError
                                          )}
                                        />
                                      );
                                    }}
                                  </Field>
                                  {typeof configInfo.tooltip
                                    ?.lightboxTooltipText === "string" && (
                                    <TooltipInfoContainer>
                                      <SanitizedHtmlComponent
                                        text={
                                          configInfo.tooltip.lightboxTooltipText
                                        }
                                      />
                                    </TooltipInfoContainer>
                                  )}
                                </Fragment>
                              );
                            })}
                          </ConfigurationFormFieldset>
                          <ConfigurationFormDivider />
                          {hasSkiConfig && (
                            <ExpertReviewRadioButtons
                              isRequired={true}
                              onCheckboxSelected={(allowChanges) =>
                                setFieldValue("skiSelector", allowChanges)
                              }
                            />
                          )}
                          {skiConfigTooltip?.lightboxTooltipText !==
                            undefined && (
                            <TooltipInfoContainer>
                              <SanitizedHtmlComponent
                                text={skiConfigTooltip.lightboxTooltipText}
                              />
                            </TooltipInfoContainer>
                          )}
                        </section>
                      ) : (
                        <ErrorMessageWrapper>
                          <p>
                            {t("product.details.configure.product.modal.error")}
                          </p>
                          <Button onClick={onReload}>
                            {t("product.details.configure.product.modal.retry")}
                          </Button>
                        </ErrorMessageWrapper>
                      )}
                    </FilterDrawerSection>
                  </FilterDrawerContainer>
                  <FilterDrawerFooter>
                    <XxlButton
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                      variant="primary"
                    >
                      {t("product.details.add.to.cart.label")}
                    </XxlButton>
                  </FilterDrawerFooter>
                </FilterDrawerTopWrapper>
              </form>
            );
          }}
        </Formik>
      </FilterDrawerWrapper>
    </Drawer>
  );
};
