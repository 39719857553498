import { callGraphQL } from "../../../graphql/graphqlApi";
import type { AddAccessoriesToCartResponse } from "./types";
import type {
  AddAccessoriesToCartItemsMutation,
  CartItemIdInput,
} from "../../../generated/graphql-code-generator";
import { addAccessoriesToCartMutation } from "./graphqlQueries";

const addAccessoriesToCart = async (
  items: { ean: string; parentId: CartItemIdInput; quantity: number }[],
  templateId: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddAccessoriesToCartResponse> => {
  const queryResponse = await callGraphQL<AddAccessoriesToCartItemsMutation>(
    {
      query: addAccessoriesToCartMutation,
      variables: {
        input: {
          cart: {
            items: items.map(({ ean, parentId, quantity }) => ({
              templateId,
              ean,
              parentId,
              quantity: { quantity },
            })),
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addAccessoriesToCartItems !== undefined
        ? "SUCCESS"
        : "ERROR",
    data: queryResponse,
  };
};

export { addAccessoriesToCart };
