import React from "react";
import {
  RewardsBannerSection,
  Wrapper,
  ButtonsWrapper,
  TextWrapper,
  Heading,
  Description,
} from "./RewardsBanner.styled";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { useSharedData } from "react-app/src/contexts/SharedData";
import * as XxlEvent from "react-app/src/utils/xxl-event";
import { useMediaQuery } from "@mui/material";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";
import { CSR } from "@/utils/client-side-rendering";

export const RewardsBanner = ({
  shouldHideBottomMargin,
}: {
  shouldHideBottomMargin: boolean;
}) => {
  const { t } = useTranslations();
  const {
    requestMapping: { reward },
  } = useSharedData().data;
  const isMobile = useMediaQuery(mobileMediaQuery);

  const openRewardsPageTrigger = () => {
    location.href = reward;
  };

  return (
    <CSR>
      <RewardsBannerSection shouldHideBottomMargin={shouldHideBottomMargin}>
        <Wrapper className="container">
          <TextWrapper>
            <Heading>{t("footer.reward.title")}</Heading>
            <Description>{t("footer.reward.subtitle")}</Description>
          </TextWrapper>
          <ButtonsWrapper>
            <XxlButton
              type="button"
              size={isMobile ? "large" : "small"}
              variant="rewardsBannerTransparent"
              onClick={() => XxlEvent.dispatchOpenLoginEvent()}
            >
              {t("footer.reward.button.login.title")}
            </XxlButton>
            <XxlButton
              type="button"
              size={isMobile ? "large" : "small"}
              variant="rewardsBannerSecondary"
              onClick={openRewardsPageTrigger}
            >
              {t("footer.reward.button.title")}
            </XxlButton>
          </ButtonsWrapper>
        </Wrapper>
      </RewardsBannerSection>
    </CSR>
  );
};
