import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { Text } from "@/react-components/Text";
import { useStoreDetailsQuery } from "../../../../queries/useStoreDetailsQuery";
import { StoreStockSelectedDetailsSection } from "../../../StoreStockSelectedDetailsSection";

export const StoreDetailsSectionComponent = ({
  storeId,
  isMobile,
}: {
  storeId: string;
  isMobile: boolean;
}) => {
  const { t } = useTranslations();
  const {
    data: storeDetails,
    isLoading: isLoadingQuery,
    isError,
  } = useStoreDetailsQuery(storeId);
  if (isError) {
    return <Text>{t("general.error")}</Text>;
  }
  return (
    <StoreStockSelectedDetailsSection
      store={storeDetails}
      isLoading={isLoadingQuery}
      isMobile={isMobile}
    />
  );
};
