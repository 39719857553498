import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { type Translation } from "@xxl/content-api";
import type { TranslationKey } from "react-app/src/translations";
import { getSiteUid } from "../environment-variables";
import {
  KeyvCacheNamespace,
  KeyvShortTtl,
} from "../server-side-cache/server-side-cache";
import * as fallbackTranslations from "./fallback.json";

interface TranslationOptions {
  /**
   * The translation key to resolve
   */
  key: TranslationKey;
  /**
   * Fallback value if no string found. Defaults to returning the passed key.
   */
  fallback?: string;
  /**
   * Values to replace {0} and {1} etc. with. Defaults to empty array.
   */
  messageArguments?: string[];
}

export const getTranslations = memoize(
  async () => {
    const {
      data: { result },
    } = await Apis.getInstance().contentApi.getTranslations(
      getSiteUid(),
      "web"
    );
    return (result ?? []).filter(
      ({ key, value }) => typeof key === "string" && typeof value === "string"
    );
  },
  new Keyv({ namespace: KeyvCacheNamespace.TRANSLATIONS_CONTENT }),
  KeyvShortTtl
);

const isSizeLabel = (
  key: TranslationKey
): key is `product.size.label.${string}` =>
  /^product\.size\.label\.\d+$/.test(key);

const getFromFallback = (key: TranslationKey): string => {
  if (isSizeLabel(key)) {
    return key;
  } else {
    return isNotNullOrUndefined(fallbackTranslations[key])
      ? fallbackTranslations[key]
      : key;
  }
};

/**
 * Server-side only translation function
 */
const translateMessage = (
  baseInput: TranslationOptions | TranslationKey,
  translations?: Translation[]
): string => {
  const input = typeof baseInput === "string" ? { key: baseInput } : baseInput;
  const { key, fallback, messageArguments = [] } = input;
  const translation =
    translations?.find(({ key: translationKey }) => key === translationKey)
      ?.value ??
    fallback ??
    getFromFallback(key);
  if (messageArguments.length > 0) {
    return translation.replace(
      /\{(\d+)\}/gu,
      (_, pos: string) => messageArguments[parseInt(pos, 10)] ?? ""
    );
  } else {
    return translation;
  }
};

export { translateMessage };
