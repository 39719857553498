import Image from "next/image";
import { imageLoader } from "../../../utils/image-loader/image-loader";
import type { NextImageProps } from "./NextImage.types";

export const NextImage = ({
  hotspot,
  padding,
  height,
  width,
  trim,
  getHeight,
  ...props
}: NextImageProps) => {
  return (
    <Image
      fill={false}
      data-private={true}
      loader={imageLoader({
        hotspot,
        getHeight,
        height,
        width,
        padding,
        trim,
      })}
      width={width}
      height={height}
      {...props}
    />
  );
};
