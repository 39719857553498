import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../src/config";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { spaces, colors, zIndex, typography } = xxlTheme;

export const DrawerContainer = styled.div<{ mobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 420px;
  ${({ mobile }) => mobile && "width: 100vw;"}
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${colors.xxlWebBlack};
  cursor: pointer;
  height: 24px;
  padding: 0;
  width: 24px;
`;

export const StickyHeading = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 ${spaces.large};
  box-shadow: 0 5px 5px -5px ${colors.xxlMediumGrey};
  background-color: ${colors.xxlWhite};

  h3: {
    margin: 0;
    padding: 0;
  }
  z-index: ${zIndex.modal};
`;

export const NonStickyHeading = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spaces.large} ${spaces.smallRegular};
`;

export const BodyContainer = styled.div<{ customPadding?: string }>`
  flex-grow: 1;
  padding: ${spaces.large} ${spaces.smallRegular};
  ${({ customPadding = "" }) =>
    customPadding !== "" && `padding: ${customPadding};`}

  @media (max-width: ${resolutionSizes.laptop}px) {
    padding: ${spaces.smallRegular};
    ${({ customPadding = "" }) =>
      customPadding !== "" && `padding: ${customPadding};`}
  }
`;

export const StickyFooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  padding: ${spaces.smallRegular};
  background-color: ${colors.xxlWhite};
  box-shadow: 0 -5px 5px -5px ${colors.xxlMediumGrey};
`;

export const DrawerHeading = styled.h3`
  font-family: ${typography.hugeBold.fontFamily};
  font-size: ${typography.hugeBold.fontSize}px;
  line-height: 28px;
  margin: 0;
  padding: ${spaces.large} 0;
`;
