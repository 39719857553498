import React from "react";
import type { GenericGraphQLError } from "react-app/src/components/Cart/Api/types";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { ErrorItem } from "./GeneralErrors.styled";

type GeneralErrorProps = {
  item: GenericGraphQLError;
};

enum CartModificationErrorsEnum {
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PRODUCTS_NOT_BUYABLE = "PRODUCTS_NOT_BUYABLE",
  CART_NOT_FOUND = "CART_NOT_FOUND",
  ERROR = "ERROR",
}

export const GeneralError: React.FunctionComponent<GeneralErrorProps> = ({
  item,
}) => {
  const { t } = useTranslations();

  const { errorType } = item;
  const errorText =
    errorType === CartModificationErrorsEnum.OUT_OF_STOCK
      ? t("services.popup.error.products.out.of.stock")
      : errorType === CartModificationErrorsEnum.PRODUCTS_NOT_BUYABLE
        ? t("services.popup.error.products.not.buyable")
        : errorType === CartModificationErrorsEnum.CART_NOT_FOUND
          ? t("services.popup.error.cart.not.found")
          : t("services.popup.error.general");

  return <ErrorItem>{errorText}</ErrorItem>;
};
