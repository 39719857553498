import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type { SiteIdentifier } from "@xxl/common-utils";
import { getEnvVar, isNotProd, isTrue } from "../utils/environment-variables";
import {
  KeyvCacheNamespace,
  KeyvShortTtl,
} from "./server-side-cache/server-side-cache";

interface GetSsmParametersInput {
  siteId: SiteIdentifier;
  parameters: {
    parameterName: string;
    fallback: string;
  }[];
}

interface getSsmParametersInternalInput {
  siteId: SiteIdentifier;
  names: string;
}

const getSsmParametersInternal = async ({
  siteId,
  names,
}: getSsmParametersInternalInput) => {
  try {
    const {
      data: { data },
    } = await Apis.getInstance().ssmApi.getParameters(names, siteId);
    return data;
  } catch (error) {
    if (isNotProd()) {
      throw Error(`Failed to get ${names ?? ""} from ssm-api`);
    }
    throw error as Error;
  }
};

const getSsmParametersCached = memoize(
  getSsmParametersInternal,
  new Keyv({ namespace: KeyvCacheNamespace.SSM_PARAMETERS }),
  {
    key: ({ names }: getSsmParametersInternalInput) => names,
    ...KeyvShortTtl,
  }
);

const getSsmParameters = async ({
  siteId,
  parameters,
}: GetSsmParametersInput): Promise<
  Record<(typeof parameters)[number]["parameterName"], string>
> => {
  const names = parameters
    .map((parameter) => parameter.parameterName)
    .filter((parameter) => parameter)
    .sort()
    .join(",");

  const parametersFromSsmApiResponse = await getSsmParametersCached({
    siteId,
    names,
  });
  const returnValueAsEntries: [string, string][] = [];
  for (const parameter of parameters) {
    const foundParameterFromSsmApiRespone = parametersFromSsmApiResponse.find(
      (parameterFromSsmApiResponse) =>
        parameterFromSsmApiResponse.queryName === parameter.parameterName
    );
    if (
      foundParameterFromSsmApiRespone !== undefined &&
      foundParameterFromSsmApiRespone.value !== null
    ) {
      returnValueAsEntries.push([
        parameter.parameterName,
        foundParameterFromSsmApiRespone.value,
      ]);
    } else {
      returnValueAsEntries.push([parameter.parameterName, parameter.fallback]);
    }
  }
  return Object.fromEntries(returnValueAsEntries);
};

const mapSsmParametersToBooleanToggles = <T extends Record<string, string>>(
  parameters: T
): Record<keyof T, boolean> =>
  Object.fromEntries(
    Object.entries(parameters).map(([key, value]) => [
      key,
      value.toLowerCase().trim() === true.toString(),
    ])
  ) as Record<keyof T, boolean>;

const getDynamicTogglesKeyValuePairs = async <
  InputBooleanToggles extends string,
  InputStringToggles extends string,
>({
  namesOfBooleanToggles,
  namesOfStringToggles,
  siteUid,
}: {
  siteUid: SiteIdentifier;
  namesOfBooleanToggles: Readonly<InputBooleanToggles[]>;
  namesOfStringToggles: Readonly<InputStringToggles[]>;
}): Promise<
  Required<Record<InputBooleanToggles, boolean>> &
    Required<Record<InputStringToggles, string>>
> => {
  const methodReturnAsEntries: (
    | [InputBooleanToggles, boolean]
    | [InputStringToggles, string]
  )[] = [];

  const ssmParameters = await getSsmParameters({
    siteId: siteUid,
    parameters: [
      ...namesOfBooleanToggles.map((parameter) => ({
        parameterName: parameter,
        fallback: false.toString(),
      })),
      ...namesOfStringToggles.map((parameter) => ({
        parameterName: parameter,
        fallback: "",
      })),
    ],
  });
  const booleanToggles: Record<InputBooleanToggles, boolean> =
    mapSsmParametersToBooleanToggles(
      Object.fromEntries(
        Object.entries(ssmParameters).filter(([key]) =>
          namesOfBooleanToggles.includes(key as InputBooleanToggles)
        )
      )
    );
  const stringToggles: Record<InputStringToggles, string> = Object.fromEntries(
    Object.entries(ssmParameters).filter(([key]) =>
      namesOfStringToggles.includes(key as InputStringToggles)
    )
  ) as Record<InputStringToggles, string>;
  methodReturnAsEntries.push(
    ...(Object.entries(booleanToggles) as [InputBooleanToggles, boolean][])
  );
  methodReturnAsEntries.push(
    ...(Object.entries(stringToggles) as [InputStringToggles, string][])
  );

  return Object.fromEntries(methodReturnAsEntries) as Required<
    Record<InputBooleanToggles, boolean>
  > &
    Required<Record<InputStringToggles, string>>;
};

const getSsmConfigParameters = async (siteId: SiteIdentifier) => {
  const getSsmParamtersResponse = await getSsmParameters({
    siteId,
    parameters: [
      {
        parameterName: "config.site.cookieVersion",
        fallback: getEnvVar("CONFIG_SITE_COOKIEVERSION"),
      },
      { parameterName: "config.site.giosgEnabled", fallback: "false" },
      { parameterName: "config.site.giosgId", fallback: "" },
      { parameterName: "config.site.logRocketApiId", fallback: "" },
      { parameterName: "config.site.serverGtmScriptUrl", fallback: "" },
      { parameterName: "config_ecoonline_api_key", fallback: "" },
      { parameterName: "config_ecoonline_url", fallback: "" },
    ],
  });

  return {
    cookieVersion: getSsmParamtersResponse["config.site.cookieVersion"],
    giosg: {
      giosgEnabled: isTrue(getSsmParamtersResponse["config.site.giosgEnabled"]),
      giosgId: getSsmParamtersResponse["config.site.giosgId"],
    },
    logRocketApiId: getSsmParamtersResponse["config.site.logRocketApiId"],
    serverGtmScriptUrl:
      getSsmParamtersResponse["config.site.serverGtmScriptUrl"],
    ecoOnlineUrl: getSsmParamtersResponse["config_ecoonline_url"],
    ecoOnlineApiKey: getSsmParamtersResponse["config_ecoonline_api_key"],
  };
};

export {
  getDynamicTogglesKeyValuePairs,
  getSsmConfigParameters,
  // eslint-disable-next-line import/no-unused-modules
  getSsmParameters,
};
