import type { AddConfigurableProductToCartEventData } from "@/react-app/global";
import {
  XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
  dispatchEvent,
} from "@/react-utils/xxl-event";
import type { ProductData, VariantData } from "@xxl/product-search-api";
import type { Configuration } from "./types";

export const configurationAtc = (
  configs: Configuration,
  p: ProductData,
  v: VariantData,
  apiKey: string,
  apiEndpoint: string
) => {
  const eventData: AddConfigurableProductToCartEventData = {
    additionalSales: p.additionalSales,
    parentEan: v.code,
    graphQLEndpointUrl: apiEndpoint,
    graphQLApiKey: apiKey,
    price: p.price.selling.range.min.value,
    categoryCode: [...p.categoryBreadcrumbs].pop()?.code ?? "",
    brandName: p.brand?.name ?? "",
    imageUrl: p.imageInfo.images[0].sources[0].url,
    name: p.title,
    salesPrice: p.price.selling.range.min.value,
    salesPriceFormatted: p.price.selling.range.min.formatted,
    sizeName: v.label,
    isClickAndCollect: false,
    configurations: configs.configurations.flatMap(({ ean }) =>
      ean === null ? [] : [{ ean }]
    ),
  };

  dispatchEvent(XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART, eventData);
  return Promise.resolve();
};
