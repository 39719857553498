import {
  SanityContent,
  type AutocompleteQuery,
  type ContentListSettingsSearch,
  type ElevateApi,
} from "@xxl/product-search-api";

export const CONTENT_NAMES = {
  [SanityContent.BRAND]: "brand",
  [SanityContent.CAMPAIGN]: "campaign",
  [SanityContent.CATEGORY]: "category",
  [SanityContent.FAQ]: "faq",
  [SanityContent.FAQ_CS]: "csQa",
  [SanityContent.GUIDE]: "guide",
  [SanityContent.STORE]: "store",
  products: "products",
} as const;

export type ContentName = (typeof CONTENT_NAMES)[keyof typeof CONTENT_NAMES];

const DEFAULT_LIMIT = 5;
/**
 * Since the FAQ hub search results will mostly contain QAs we want to display more than just the default limit
 */
const QA_DEFAULT_LIMIT = 3 * DEFAULT_LIMIT;

export const prepareContentLists = (
  limit: number = DEFAULT_LIMIT
): ContentListSettingsSearch[] => [
  {
    contentFilter: {
      type: [SanityContent.BRAND],
    },
    id: CONTENT_NAMES[SanityContent.BRAND],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.CATEGORY],
    },
    id: CONTENT_NAMES[SanityContent.CATEGORY],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.CAMPAIGN],
    },
    id: CONTENT_NAMES[SanityContent.CAMPAIGN],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.FAQ],
    },
    id: CONTENT_NAMES[SanityContent.FAQ],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.GUIDE],
    },
    id: CONTENT_NAMES[SanityContent.GUIDE],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.STORE],
    },
    id: CONTENT_NAMES[SanityContent.STORE],
    limit,
  },
];

export const getFaqContentLists = (
  qaLimit: number = QA_DEFAULT_LIMIT,
  faqLimit: number = DEFAULT_LIMIT
): ContentListSettingsSearch[] => [
  {
    contentFilter: {
      type: [SanityContent.FAQ_CS],
    },
    id: CONTENT_NAMES[SanityContent.FAQ_CS],
    limit: qaLimit,
  } as never,
  {
    contentFilter: {
      type: [SanityContent.FAQ],
    },
    id: CONTENT_NAMES[SanityContent.FAQ],
    limit: faqLimit,
  },
];

export const getAutoSuggestions = ({
  apiClient: {
    storefront: { autocomplete },
  },
  query,
  productRules,
  contentLists,
}: {
  apiClient: ElevateApi<unknown>;
  query: AutocompleteQuery;
  productRules?: string;
  contentLists?: ContentListSettingsSearch[];
}) => {
  return autocomplete(query, {
    productRules,
    contentLists: contentLists ?? prepareContentLists(DEFAULT_LIMIT),
  });
};

/**
 * Use this if you don't want elevate to return any products
 */
export const NO_PRODUCTS_RULE = 'rule incl product_key { "N/A" } ';
