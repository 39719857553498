import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export interface CartItemLoaderWrapperProps {
  "aria-label"?: string;
  isLoading: boolean;
}

export const CartItemLoaderWrapper = styled.div<CartItemLoaderWrapperProps>(
  ({ isLoading = false }) => css`
    display: ${isLoading ? "block" : "none"};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.2);

    div {
      box-sizing: border-box;
    }
  `
);

export const CartItemLoaderAnimation = styled.div(
  () => css`
    display: inline-block;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: currentColor;
      animation: cart-item-loader 1s steps(6, jump-none) infinite;
    }
    div:nth-of-type(1) {
      top: 32px;
      left: 8px;
      animation-delay: 0;
    }
    div:nth-of-type(2) {
      top: 32px;
      left: 32px;
      animation-delay: 0.333s;
    }
    div:nth-of-type(3) {
      top: 32px;
      left: 56px;
      animation-delay: 0.666s;
    }
    @keyframes cart-item-loader {
      0%,
      66%,
      100% {
        opacity: 0.5;
      }
      33% {
        opacity: 1;
      }
    }
    @media (prefers-reduced-motion: reduce) {
      div {
        animation: none;
        display: none;
      }
    }
  `
);
