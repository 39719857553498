import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon";
import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { SECTION_NAMES } from "../constants";

const SEE_FULL_DESCRIPTION_LINK = `#${SECTION_NAMES.description}`;

type Props = {
  seeFullDescriptionRedirectUrl?: string;
};

export const SeeDescription = ({ seeFullDescriptionRedirectUrl }: Props) => {
  const { t } = useTranslations();

  return (
    <XxlStack mt="6px" direction="row">
      <a
        href={
          seeFullDescriptionRedirectUrl !== undefined
            ? seeFullDescriptionRedirectUrl
            : SEE_FULL_DESCRIPTION_LINK
        }
        style={{
          fontSize: `${xxlTheme.typography.mediumRegular.fontSize}px`,
          textDecoration: "underline",
        }}
      >
        <Text fontFamily="medium" as="span">
          {t("product.details.see.full")}
        </Text>
        <Icon
          name="CaretRight"
          size={xxlTheme.typography.mediumRegular.fontSize}
          transform={{ translateX: 0, translateY: 2 }}
        />
      </a>
    </XxlStack>
  );
};
