import { useEffect, useState } from "react";
import { SanitizedHtmlComponent } from "@/react-components/Common";

const ClientSideRenderHTML = ({ html }: { html: string }) => {
  const [clientSideRenderedHtml, setClientSideRenderedHtml] = useState("");
  useEffect(() => setClientSideRenderedHtml(html), [html]);
  return (
    <>
      <SanitizedHtmlComponent text={clientSideRenderedHtml} />
    </>
  );
};

export { ClientSideRenderHTML };
