import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { useBreakpoint } from "@/react-hooks/useBreakpoint/useBreakpoint";
import { Skeleton } from "@mui/material";
import { useStockStatusTranslation } from "../../hooks/useStockStatusTranslation";
import type { StockLevel } from "../../types";
import type { Store } from "react-app/src/utils/Stores/stores-helper";
import {
  StockStatusIndicator,
  StockStatusIndicatorWrapper,
  StyledText,
} from "./styles";
import { SanitizedHtmlComponent } from "@/react-components/Common";

const ID = "size-select-drawer-availability-online";

type Props = {
  storesWithCollectableStockCount: number;
  highestCollectableStockStatus: StockLevel;
  preferredStores: Store[];
  preferredStoreName: string;
  isMultiPreferredStores: boolean;
  isLoading: boolean;
};
export const CollectableStoreStockStatusComponent = ({
  preferredStoreName,
  isMultiPreferredStores,
  highestCollectableStockStatus,
  storesWithCollectableStockCount,
  isLoading,
}: Props) => {
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";

  const { storeFn } = useStockStatusTranslation();
  const ssText = storeFn({
    storesWithCollectableStockCount,
    storeName: preferredStoreName,
    isMultiPreferredStores,
    highestCollectableStockStatus,
  });

  return (
    <XxlStack direction={"row"} alignItems={"center"}>
      {isLoading && <Skeleton />}
      <StockStatusIndicatorWrapper>
        <StockStatusIndicator stockStatus={highestCollectableStockStatus} />
        <StyledText typography={isMobile ? "small" : "base"} data-testid={ID}>
          <SanitizedHtmlComponent text={ssText} />
        </StyledText>
      </StockStatusIndicatorWrapper>
    </XxlStack>
  );
};
