// eslint-disable-next-line import/no-extraneous-dependencies
import type { Dispatch } from "react";
import type {
  GenericGraphQLError,
  OutOfStockInCartError,
  UpdateQuantityGraphQLError,
} from "react-app/src/components/Cart/Api/types";
import type {
  AddBundleProductsToCartMutation,
  AddCollectableProductsToCartMutation,
  AddProductsToCartMutation,
  CartQuery,
} from "../../generated/graphql-code-generator";
import { CustomerType } from "../../generated/graphql-code-generator";
import type { GraphQLError } from "../../graphql/graphqlApi";
import { getCartDetailsCookie } from "../../utils/Cookie";
import type { Action } from "./CartState";

const setMiniCartCountClientSide = (dispatch: Dispatch<Action>) => {
  const cookieValue = getCartDetailsCookie() ?? {};
  const { totalItems = 0 } = cookieValue;
  dispatch({
    payload: {
      count: totalItems,
    },
    type: "SET_CART_COUNT",
  });
};

function getCartBuyableForCnC4GoodsOnlyInStore(cartData?: CartQuery): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    cartData?.cart?.collectStore?.error?.some(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (error) => error?.type === "NOT_BUYABLE"
    ) ?? false
  );
}

const isOutOfStockError = (
  error: GenericGraphQLError
): error is OutOfStockInCartError => error.errorType === "OUT_OF_STOCK";

const hasOutOfStockError = (errors: GenericGraphQLError[] = []) =>
  errors.some(isOutOfStockError);

const getDefaultCustomerType = (isTeamsalesAdmin?: boolean): CustomerType =>
  isTeamsalesAdmin === true ? CustomerType.ORGANIZATION : CustomerType.PERSON;

const getTranslationKeyForErrorMessage = (
  errors:
    | GraphQLError<
        | AddProductsToCartMutation
        | AddCollectableProductsToCartMutation
        | AddBundleProductsToCartMutation,
        unknown
      >[]
    | null
) => {
  if (errors === null) {
    return "product.details.add.to.cart.failure";
  }

  const hasInvalidQuantityErrors = errors.some(
    (err) =>
      "errorType" in err &&
      (err as UpdateQuantityGraphQLError).errorType === "INVALID_QUANTITY"
  );

  if (
    hasOutOfStockError(errors as GenericGraphQLError[]) ||
    hasInvalidQuantityErrors
  ) {
    return "product.details.add.to.cart.not.enough.stock";
  }

  return "product.details.add.to.cart.failure";
};

export {
  getCartBuyableForCnC4GoodsOnlyInStore,
  getDefaultCustomerType,
  getTranslationKeyForErrorMessage,
  hasOutOfStockError,
  isOutOfStockError,
  setMiniCartCountClientSide,
};
