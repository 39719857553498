import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { SECTION_NAMES } from "../constants";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery/useXxlMediaQuery";
import { XxlStack } from "react-app/src/components/Common/XxlStack/XxlStack";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { Slider } from "@/react-components/Common/Slider";

const BROWSE_BUTTON_HEIGHT = "42px";

const Heading = styled.p`
  padding: 0 0 ${xxlTheme.spaces.smallRegular};
  margin: 0;
  font-size: 24px;
  font-family: var(--font-family-bold);
`;

const BrowseLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${BROWSE_BUTTON_HEIGHT};
  padding: 0 ${xxlTheme.spaces.regular};
  white-space: nowrap;
  cursor: pointer;
  background-color: ${xxlTheme.colors.xxlLightGreen};
  ${xxlTheme.typography.mediumMedium};
`;

type BrowseProps = {
  hasDescription: boolean;
  hasClassifications: boolean;
  hasRelatedGuides: boolean;
  hasServicePromotions: boolean;
};

const getBrowseItems = ({
  hasClassifications,
  hasDescription,
  hasRelatedGuides,
  hasServicePromotions,
}: BrowseProps) => {
  const TABS = {
    description: {
      id: "description",
      name: "product.details.description.tab",
      link: `#${SECTION_NAMES.description}`,
    },
    servicePromotions: {
      id: "service-promotions",
      name: "product.details.servicepromotion.tab",
      link: `#${SECTION_NAMES.servicePromotions}`,
    },
    specification: {
      id: "specification",
      name: "product.details.specification.tab",
      link: `#${SECTION_NAMES.specifications}`,
    },
    "ratings-and-reviews": {
      id: "ratings-and-reviews",
      name: "product.details.ratings-and-reviews.tab",
      link: `#${SECTION_NAMES.reviews}`,
    },
    "related-guides": {
      id: "related-guides",
      name: "product.details.guides.tab",
      link: `#${SECTION_NAMES.relatedGuides}`,
    },
  } as const;

  const items = new Set();

  if (hasDescription) items.add(TABS.description);
  if (hasClassifications) items.add(TABS.specification);
  if (hasServicePromotions) items.add(TABS.servicePromotions);
  if (hasRelatedGuides) items.add(TABS["related-guides"]);
  items.add(TABS["ratings-and-reviews"]);

  return [...items] as (typeof TABS)[keyof typeof TABS][];
};

export const Browse = (props: BrowseProps) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const { t } = useTranslations();

  const browseItems = getBrowseItems({ ...props }).map((item) => (
    <ErrorBoundary key={item.id}>
      <BrowseLink href={item.link}>{t(item.name)}</BrowseLink>
    </ErrorBoundary>
  ));

  return (
    <>
      <Heading>{t("general.browse")}</Heading>
      {isLaptopSize ? (
        <XxlStack
          justifyContent="start"
          direction="row"
          spacing={xxlTheme.spaces.smallRegular}
        >
          {browseItems}
        </XxlStack>
      ) : (
        <Slider
          items={browseItems}
          slidesConfig={{ perView: 2.5, spacing: 12 }}
        />
      )}
    </>
  );
};
