import type { CategoryData, LongTailData } from "@xxl/frontend-api";
import { FacetTypeEnum } from "@xxl/search-api";
import isEmpty from "lodash/isEmpty";
import { isDistinctFacetParameter } from "./UrlPath";
import {
  BRAND_ATTRIBUTE_NAME_FOR_ELEVATE,
  BRAND_ATTRIBUTE_NAME_FOR_LOOP,
  BRAND_ATTRIBUTE_NAMES_FOR_SOLR,
  BRAND_FACET_ATTRIBUTE_NAME,
  CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_LOOP,
  CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_SOLR,
  CATEGORY_FACET_ATTRIBUTE_NAME,
  CENSHARE_USER_FACET_ATTRIBUTE_NAME,
  COLOR_FACET_ATTRIBUTE_NAME,
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  SIZE_FACET_ATTRIBUTE_NAME,
  STYLE_FACET_ATTRIBUTE_NAME,
  USER_FACET_ATTRIBUTE_NAME,
} from "./Constants";
import type {
  Facet,
  FacetList,
  ToggleFilter,
  UpdateRangeFilterPayload,
} from "./SearchState";
import type { LongTailTitleProps, LongTailTitleFacet } from "./LongTailTitle";
import { LongTailFacetType } from "./LongTailTitle";
import {
  CATEGORY_LEVEL_2,
  CATEGORY_LEVEL_3,
  FILTER_SEPARATOR,
} from "../../constants";
import type { DistinctFacetParameter, Filter } from "../../utils/data-types";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const mainFacetsOrder = [
  CATEGORY_FACET_ATTRIBUTE_NAME,
  SIZE_FACET_ATTRIBUTE_NAME,
  CENSHARE_USER_FACET_ATTRIBUTE_NAME,
  USER_FACET_ATTRIBUTE_NAME,
  BRAND_ATTRIBUTE_NAME_FOR_LOOP,
  ...BRAND_ATTRIBUTE_NAMES_FOR_SOLR,
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  STYLE_FACET_ATTRIBUTE_NAME,
];

type DeserializedFilter = {
  id: string;
  values: string[];
};
const deserializeFilter = (filter: string): DeserializedFilter => {
  const filterArray = filter.split(FILTER_SEPARATOR);
  return { id: filterArray[0], values: filterArray.slice(1) };
};

export function addDistinctFilter(
  selectedFilters: Filter[],
  payload: ToggleFilter
): Filter[] {
  const { attributeName, selected } = payload;
  const value = selected[0];
  const resultSelectedFilters = selectedFilters.filter(
    (filter) => filter.attributeName !== attributeName
  );
  const matchingFilter = selectedFilters
    .filter((filter) => filter.attributeName === attributeName)
    .pop();
  const selectedValues =
    isNotNullOrUndefined(matchingFilter) &&
    Array.isArray(matchingFilter.selected)
      ? [...matchingFilter.selected, value]
      : [value];

  const filter: DistinctFacetParameter = {
    attributeName,
    selected: selectedValues,
    type: FacetTypeEnum.distinct,
  };
  resultSelectedFilters.push(filter);
  return resultSelectedFilters.filter((filter) => filter.selected);
}

export const updateRangeFilter = (
  selectedFilters: Filter[],
  payload: UpdateRangeFilterPayload
): Filter[] => {
  const { attributeName, range } = payload;
  const updatedFilters = selectedFilters.filter(
    (filter) => filter.attributeName !== attributeName
  );
  updatedFilters.push({
    attributeName,
    selected: range,
    type: FacetTypeEnum.range,
  });

  return updatedFilters;
};

export function removeDistinctFilter(
  selectedFilters: Filter[],
  payload: ToggleFilter
): Filter[] {
  const { attributeName, selected } = payload;
  const resultSelectedFilters = selectedFilters
    .filter(isDistinctFacetParameter)
    .filter((filter) => {
      if (filter.selected === undefined) {
        return false;
      }
      const found = filter.selected.some((val) => selected.includes(val));
      return !(filter.attributeName === attributeName && found);
    });

  const matchingFilter = selectedFilters
    .filter((filter) => {
      if (!("selected" in filter)) {
        return false;
      }
      const getHasSelectedFilter = (): boolean =>
        (filter.selected as string[]).some((val) => selected.includes(val));
      return filter.attributeName === attributeName && getHasSelectedFilter();
    })
    .pop();

  if (isNotNullOrUndefined(matchingFilter)) {
    const selectedMatchingFilters: DistinctFacetParameter["selected"] =
      isNotNullOrUndefined(matchingFilter.selected) &&
      Array.isArray(matchingFilter.selected)
        ? matchingFilter.selected
            .map((filterValue) => filterValue)
            .filter((filterValue) => !selected.includes(filterValue))
        : [];
    const filter: DistinctFacetParameter = {
      attributeName,
      selected: [...selectedMatchingFilters],
      type: FacetTypeEnum.distinct,
    };
    resultSelectedFilters.push(filter);
  }

  return resultSelectedFilters.filter((filter) => {
    const distinctFilter: DistinctFacetParameter = filter;
    return (
      isNotNullOrUndefined(distinctFilter.selected) &&
      distinctFilter.selected.length > 0
    );
  });
}

export function toggleCategoryFilter(
  selectedFilters: Filter[],
  payload: string
): Filter[] {
  const { id, values } = deserializeFilter(payload);
  const [value] = values;
  const hasMatchingCategoryFilter = selectedFilters
    .map((filter) => filter as DistinctFacetParameter)
    .find((filter) => filter.selected?.includes(value));
  return hasMatchingCategoryFilter !== undefined
    ? []
    : [
        {
          attributeName: id,
          selected: [value],
          type: FacetTypeEnum.distinct,
        },
      ];
}

export function getSelectedFilterCount(selectedFilters: Filter[]): number {
  let totalFiltersSelectedCount = 0;

  selectedFilters.forEach((filter) => {
    const distinctFacetParam = filter as DistinctFacetParameter;
    if (isNotNullOrUndefined(distinctFacetParam.selected)) {
      totalFiltersSelectedCount += distinctFacetParam.selected.length;
    }
  });

  return totalFiltersSelectedCount;
}

type GetFilteredFacetsProps = {
  facets: FacetList;
  categoryLevel?: number;
  isCampaignPage: boolean;
  subCategories?: CategoryData[];
};

export const shouldShowAllFilters = (): boolean => true;

export const CATEGORY_LEVEL_2_AND_3_FACETS = [
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  BRAND_FACET_ATTRIBUTE_NAME,
  USER_FACET_ATTRIBUTE_NAME,
  CENSHARE_USER_FACET_ATTRIBUTE_NAME,
  SIZE_FACET_ATTRIBUTE_NAME,
];

export const shouldShowOnCategoryLevel2And3 = ({
  attributeName = "",
}: Facet): boolean =>
  !isEmpty(attributeName) &&
  CATEGORY_LEVEL_2_AND_3_FACETS.includes(attributeName);

export const CAMPAIGN_PAGE_FACETS = [
  BRAND_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  PRICE_FACET_ATTRIBUTE_NAME,
  SIZE_FACET_ATTRIBUTE_NAME,
  CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_SOLR,
  CAMPAIGN_LABELS_FACET_ATTRIBUTE_NAME_LOOP,
];

export const shouldShowOnCampaignPage = ({
  attributeName = "",
}: Facet): boolean =>
  !isEmpty(attributeName) && CAMPAIGN_PAGE_FACETS.includes(attributeName);

export const getFilteredFacets = ({
  categoryLevel,
  facets,
  isCampaignPage,
  subCategories,
}: GetFilteredFacetsProps): FacetList => {
  const isCategoryLevel2or3 =
    categoryLevel === CATEGORY_LEVEL_2 || categoryLevel === CATEGORY_LEVEL_3;
  const hasSubCategories = !isEmpty(subCategories);
  return facets.filter(
    isCategoryLevel2or3 && hasSubCategories
      ? shouldShowOnCategoryLevel2And3
      : isCampaignPage
        ? shouldShowOnCampaignPage
        : shouldShowAllFilters
  );
};

const isDistinct = (filter: Filter): filter is DistinctFacetParameter => {
  return Array.isArray(filter.selected);
};

const getUniqueFilterValue = (
  selectedFilters: Filter[],
  filterNames: string[]
): string | null => {
  const value = selectedFilters
    .filter(isDistinct)
    .filter(
      ({ attributeName, selected }) =>
        filterNames.includes(attributeName) &&
        isNotNullOrUndefined(selected) &&
        selected.length === 1
    )
    .map((filter) => filter.selected?.[0]);
  return value.length > 0 ? value.toString() : null;
};

export const createLongTailPart = (
  type: LongTailFacetType,
  value: string | null
): LongTailTitleFacet | null => (value !== null ? { value, type } : null);

export const getLongTailBrandFilterValue = (
  selectedFilters: Filter[]
): string | null => {
  return getUniqueFilterValue(selectedFilters, [
    BRAND_ATTRIBUTE_NAME_FOR_ELEVATE,
    BRAND_ATTRIBUTE_NAME_FOR_LOOP,
  ]);
};

export const getUniqueFilterValueCombinedWithFacetName = (
  filterNames: string[],
  selectedFilters: Filter[],
  longTailFacets?: LongTailData[]
): string | null => {
  const longTailFilter = longTailFacets?.find((facet) =>
    filterNames.includes(facet.id)
  );
  const value =
    longTailFilter !== undefined
      ? getUniqueFilterValue(selectedFilters, [longTailFilter.id])
      : getUniqueFilterValue(selectedFilters, filterNames);

  if (value === null) {
    return null;
  }

  if (longTailFilter !== undefined) {
    return `${longTailFilter.name} ${value}`;
  }

  return value;
};

export const getBrand = (
  selectedFilters: Filter[],
  previousTitle: undefined | null | LongTailTitleProps
): LongTailTitleFacet | null => {
  const newBrand = createLongTailPart(
    LongTailFacetType.brand,
    getLongTailBrandFilterValue(selectedFilters)
  );
  if (newBrand === null) {
    return null;
  }

  return previousTitle?.brand?.value === newBrand.value
    ? previousTitle.brand
    : newBrand;
};

export const createLongTailTitle = (
  selectedFilters: Filter[],
  longTailFilters: LongTailData[] | undefined,
  categoryData?: CategoryData,
  previousTitle?: LongTailTitleProps | null
): LongTailTitleProps | null => {
  const brand = getBrand(selectedFilters, previousTitle);
  const size = getUniqueFilterValueCombinedWithFacetName(
    [SIZE_FACET_ATTRIBUTE_NAME],
    selectedFilters,
    longTailFilters
  );
  const color = getUniqueFilterValueCombinedWithFacetName(
    [COLOR_FACET_ATTRIBUTE_NAME, STYLE_FACET_ATTRIBUTE_NAME],
    selectedFilters
  );

  if (brand !== null || color !== null || size !== null) {
    return {
      brand,
      size:
        size !== null ? createLongTailPart(LongTailFacetType.size, size) : null,
      color:
        color !== null
          ? createLongTailPart(LongTailFacetType.color, color)
          : null,
      category:
        categoryData?.name !== undefined
          ? createLongTailPart(LongTailFacetType.category, categoryData.name)
          : null,
    };
  }
  return null;
};

export const getSortedFacets = (facets: FacetList): Facet[] => {
  const mapOrder = (facets: Facet[], order: string[]) =>
    facets.sort((a, b) => {
      const A = a.attributeName,
        B = b.attributeName;

      return A !== undefined &&
        B !== undefined &&
        order.indexOf(A) > order.indexOf(B)
        ? 1
        : -1;
    });

  const sortByNameAsc = (a: Facet, b: Facet) =>
    a.name !== undefined && b.name !== undefined && a.name > b.name ? 1 : -1;

  const mainFacets = facets.filter(
    (item) =>
      item.attributeName !== undefined &&
      mainFacetsOrder.includes(item.attributeName)
  );

  const sortedMainFacets = mapOrder(mainFacets, mainFacetsOrder);

  const sortedFacetsRest = facets
    .filter(
      (item) =>
        item.attributeName !== undefined &&
        !mainFacetsOrder.includes(item.attributeName)
    )
    .sort(sortByNameAsc);

  return [...sortedMainFacets, ...sortedFacetsRest];
};
