import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "@/react-app/styled/Checkbox/Checkbox.styled";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { Loader } from "@xxl/icons";
import { useState, type ChangeEvent, type ChangeEventHandler } from "react";
import { getPreferredStoreIds } from "../../helpers";
import { StockLevels } from "../../types";
import { Dialog } from "../Dialog/Dialog";
import type { StoreListItem } from "../helper";
import { StoreList } from "./StoreList";
import {
  BodyContainer,
  Footer,
  FormWrapper,
  LoadingIconWrapper,
  StoreInput,
} from "./StoreStockDialogStyles";

type Props = {
  isDialogOpen: boolean;
  isLoading?: boolean;
  storeListItems: StoreListItem[];
  toggleDialog: () => void;
};

const StoreStockDialog = ({
  isDialogOpen,
  isLoading = false,
  storeListItems,
  toggleDialog,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const [onlyShowStoresWithStock, setOnlyShowStoresWithStock] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const filteredListItems = storeListItems.filter((store) => {
    if (
      onlyShowStoresWithStock &&
      store.stockStatus === StockLevels.OUT_OF_STOCK
    ) {
      return false;
    }
    if (filterValue.length === 0) {
      return true;
    }
    return store.name
      .toLocaleLowerCase()
      .includes(filterValue.toLocaleLowerCase());
  });

  const psIds = getPreferredStoreIds();
  const preferredStores = storeListItems.filter((store) =>
    psIds.includes(store.storeId)
  );
  const nonPreferredStores = filteredListItems.filter(
    (store) => !psIds.includes(store.storeId)
  );

  const handleFilterInStock = (event: ChangeEvent<HTMLInputElement>) => {
    setOnlyShowStoresWithStock(event.target.checked);
  };

  const onFilterInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(target.value);
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={toggleDialog}
      heading={t("product.details.storestock.status")}
      fullScreen={isMobile}
    >
      <BodyContainer>
        <FormWrapper>
          <StoreInput
            onChange={onFilterInputChange}
            value={filterValue}
            disabled={false}
            placeholder={t("storeselect.search.placeholder")}
          />
          <StyledCheckbox
            testId="store-stock-show-instock"
            handleChange={handleFilterInStock}
            label={t("product.details.storestock.showall")}
          />
        </FormWrapper>
        {isLoading && (
          <LoadingIconWrapper>
            <Loader />
          </LoadingIconWrapper>
        )}
        {preferredStores.length > 0 && (
          <>
            <XxlStack pt={"24px"} />
            <Text typography="baseBold">
              {t("storeselect.selected.stores.header")}
            </Text>
            <StoreList storeListItems={preferredStores} />
          </>
        )}
        {nonPreferredStores.length > 0 && (
          <>
            <XxlStack pt={"24px"} />
            <Text typography="baseBold">
              {t("storefinder.warehouses.all.stores.checkbox.label")}
            </Text>
            <StoreList storeListItems={nonPreferredStores} />
          </>
        )}
      </BodyContainer>
      <Footer>
        <XxlStack width={isMobile ? "auto" : "124px"}>
          <XxlButton
            size="large"
            variant="secondary"
            onClick={toggleDialog}
            data-testid="store-stock-action-btn"
          >
            {t("general.close")}
          </XxlButton>
        </XxlStack>
      </Footer>
    </Dialog>
  );
};

export { StoreStockDialog };
