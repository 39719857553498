import { Stack, useMediaQuery } from "@mui/material";
import type { RatingProps } from "@mui/material/Rating";
import MuiRating from "@mui/material/Rating";
import SvgIcon from "@mui/material/SvgIcon";
import { hasValue } from "@xxl/common-utils";
import { color } from "@xxl/theme";
import type { ReactNode } from "react";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { mobileAndTabletMediaQuery } from "../../../utils/xxl-screen";
import {
  InfoBox,
  RatingContainer,
  RatingNumber,
  ReviewersLink,
  StarRatingContainer,
  StarRatingLink,
} from "./StarRating.styled";

const MINIMUN_DISPLAY_THRESHOLD = 75;

type Props = {
  fullWidth?: boolean;
  onClick?: () => void;
  recommendationPercentage?: number | null;
  showRatingNumber?: boolean;
} & RatingProps;

const GetStarRatingContainer = ({
  children,
  onClick,
}: {
  onClick?: () => void;
  children: ReactNode[];
}) =>
  onClick !== undefined ? (
    <StarRatingLink onClick={onClick} href="#" role="button">
      {children}
    </StarRatingLink>
  ) : (
    <StarRatingContainer>{children}</StarRatingContainer>
  );

const Star = ({ "data-testid": dataTestId }: { "data-testid": string }) => (
  <SvgIcon fontSize="inherit" data-testid={dataTestId}>
    <path d="M 12.000001,17.620911 19.111717,22.59497 16.390332,14.560638 23.50205,9.6981028 H 14.780046 L 12.000001,1.4050303 9.2199543,9.6969875 H 0.49795028 L 7.6096675,14.561753 4.8882825,22.59497 Z" />
  </SvgIcon>
);

const StarRating = ({
  onClick,
  fullWidth = false,
  showRatingNumber = true,
  recommendationPercentage,
  ...props
}: Props) => {
  const isMobile = useMediaQuery(mobileAndTabletMediaQuery);
  const { t } = useTranslations();

  return (
    <RatingContainer>
      <GetStarRatingContainer onClick={onClick}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          data-testid="rating"
          width={fullWidth ? "100%" : "auto"}
        >
          <MuiRating
            icon={<Star data-testid="StarIconFilled" />}
            emptyIcon={<Star data-testid="StarIconGrey" />}
            {...props}
          />
        </Stack>
        {showRatingNumber && (
          <RatingNumber isLink={onClick !== undefined}>
            {props.value}/5
          </RatingNumber>
        )}
      </GetStarRatingContainer>
      {hasValue(recommendationPercentage) &&
        recommendationPercentage >= MINIMUN_DISPLAY_THRESHOLD && (
          <InfoBox backgroundColor={color.lightAmber.hex} isMobile={isMobile}>
            <span>
              {`${stringFormat(
                t("product.ratings-and-reviews.summary.percent.of"),
                recommendationPercentage.toString()
              )} `}
              <ReviewersLink onClick={onClick} isLink={onClick !== undefined}>
                {t("product.ratings-and-reviews.summary.reviewers")}
              </ReviewersLink>
              {` ${t("product.ratings-and-reviews.summary.recommend.this.product")}`}
            </span>
          </InfoBox>
        )}
    </RatingContainer>
  );
};

export { StarRating };
