import type { ProductCardDataV2 } from "@/react-utils/ProductData/product-card-data-helper";

// https://xxlsports.atlassian.net/browse/XD-14831
// facet types that we don't want to show in the UI.
export const UNSUPPORTED_FACET_TYPES = ["CHECKBOX"] as const;

// https://xxlsports.atlassian.net/browse/XD-15417
// facet names that we don't want to show in the UI.
export const UNSUPPORTED_FACET_IDS = ["department"] as const;

export const EMPTY_LIST_OF_PRODUCTS: ProductCardDataV2[] = []; // Products will be fetched client side
export const MINIMUM_NUMBER_OF_PRODUCTS_WHEN_CALLING_ELEVATE = 1;

export const ARTICLE_NUMBERS_SEARCH_REGEX = /^\d{7}([+,\s]\d{7})*$/;
