export const SECTION_NAMES = {
  description: "product-description-section",
  relatedGuides: "related-guides-section",
  reviews: "review-section",
  servicePromotions: "service-promotions",
  specifications: "product-specification-section",
} as const;

export const IMAGE_SIZE = {
  DESKTOP_FULLSCREEN: 1000,
  MOBILE_FULLSCREEN: 500,
  DESKTOP: 500,
  MOBILE: 400,
  THUMBNAIL: 98,
  STYLE: 50,
} as const;

export const PRODUCT_COUNT = 8;
export const PRODUCT_LIST_NAMES = {
  ACCESSORIES_PDP: "accessories-pdp",
  FREQUENTLY_BOUGHT_TOGETHER_PDP: "frequently-bought-together-pdp",
  UPSALE_PDP: "upsale-pdp",
  RECENTLY_VIEWED: "recently-viewed-pdp",
};

export const NUMBER_OF_REVIEWS_TO_DISPLAY = 3;
export const REVIEWS_FETCH_LIMIT = 50;

export const MAX_ITEM_QUANTITY = 10_000;

export const MIN_IMAGES_FOR_DESKTOP_THUMBNAILS = 5;
export const INITIAL_IMAGE_SLIDE_DESKTOP = 0;
export const INITIAL_IMAGE_SLIDE_MOBILE = 0;
export const WALLEY_ID = "walley-payment-widget";
export const PRODUCT_RECOMMENDATION_STRATEGY_FOR_GRAVEYARD_PRODUCTS =
  "bestseller";
