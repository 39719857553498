import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type { Image, Link } from "@xxl/content-api";
import { getSiteUid } from "../environment-variables";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

type HeaderContent = {
  campaignHubLink: string | null;
  headerLinks: Link[] | null;
  headerLogo: Image | null;
};

const getHeaderContentInternal = async (): Promise<HeaderContent> => {
  const {
    data: { result: [header] = [] },
  } = await Apis.getInstance().contentApi.getHeader(getSiteUid(), "header");
  const {
    campaignHubLink,
    headerlinks: headerLinks,
    logo: headerLogo,
  } = {
    campaignHubLink: null,
    headerlinks: null,
    logo: null,
    ...header,
  };

  return {
    campaignHubLink,
    headerLinks,
    headerLogo,
  };
};

const getHeaderContent = memoize(
  getHeaderContentInternal,
  new Keyv({ namespace: KeyvCacheNamespace.HEADER_CONTENT }),
  KeyvDefaultTtl
);

export { getHeaderContent };
export type { HeaderContent };
