import styled from "@emotion/styled/macro";
import { MQ } from "react-app/src/styles/helpers";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
export const ListItem = styled.li`
  margin-bottom: 11px;

  ${MQ("tablet")} {
    margin-bottom: 11px;
  }

  a {
    color: ${colorsAsCssVariable.xxlGrey};
    font-size: 15px;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      color: ${colorsAsCssVariable.xxlGreen};
    }

    ${MQ("tablet")} {
      color: ${colorsAsCssVariable.xxlWhite};
    }
  }
`;
