import { getDiscountCategoryForCategoryData } from "@/utils/category-data-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { type Translation } from "@xxl/content-api";
import { type CategoryData } from "@xxl/pim-api";
import { type ProductDiscountCount } from "@xxl/product-search-api";

export const getCategoryData = (
  categoryCode: string | undefined,
  allCategories: CategoryData[],
  translations: Translation[],
  discountedCategories: ProductDiscountCount[]
): CategoryData | undefined => {
  if (categoryCode === undefined || allCategories.length === 0) {
    return;
  }

  const categoryData = allCategories.find((item) => item.code === categoryCode);

  if (categoryData === undefined) {
    return;
  }

  const { breadcrumbs, categoryLevel, pageTitle, url } = categoryData;
  const discountedCategoriesLevelTwo: {
    categoryCode: string;
    discountCount: number;
  }[] = [];
  discountedCategories.forEach((item) => {
    discountedCategoriesLevelTwo.push(...item.level2Categories);
  });
  const discountedCategoryLevelOne = discountedCategories.find(
    (item) => item.categoryCode === categoryCode
  );
  const discountedCategoryLevelTwo = discountedCategoriesLevelTwo.find(
    (item) => item.categoryCode === categoryCode
  );
  const discountedCategoryData =
    discountedCategoryLevelOne !== undefined
      ? discountedCategoryLevelOne
      : discountedCategoryLevelTwo !== undefined
        ? discountedCategoryLevelTwo
        : {
            categoryCode,
            discountCount: 0,
          };
  const discountedCategoryProductCount = discountedCategoryData.discountCount;
  const discountCategory = getDiscountCategoryForCategoryData(
    categoryData,
    translations,
    discountedCategoryProductCount
  );
  if (
    isNotNullOrUndefined(discountCategory) &&
    isNotNullOrUndefined(categoryData.subCategories) &&
    categoryData.subCategories.every(
      ({ code }) => code !== discountCategory.code
    )
  ) {
    categoryData.subCategories.push(discountCategory);
  }

  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ name, url }) => ({ name, url }));

  return {
    code: categoryCode,
    ...(url !== undefined && { url }),
    ...(categoryLevel !== undefined && { categoryLevel }),
    subCategories: subCategories,
    categoryLevel,
    ...(pageTitle !== undefined && { pageTitle }),
    ...(breadcrumbs !== undefined && { breadcrumbs }),
  };
};
