import { SALES_METHODS } from "@/react-hooks/useProductData/constants";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { ProductFormComponent } from "./ProductForm";
import { ProductFormNotSellable } from "./ProductFormNotSellable/ProductFormNotSellable";
import { ProductFormOnlyAvailableInStore } from "./ProductFormOnlyAvailableInStore";
import { ProductFormPreSale } from "./ProductFormPreSale";
import type { TProductForm } from "./types";

export const ProductFormContainer = (props: TProductForm) => {
  const ref = useRef(null);
  const isRefInView = useInView(ref, {
    margin: "-100px",
  });
  const { product } = props;

  if (product.salesMethodCode === SALES_METHODS.PRE_SALE) {
    return (
      <ProductFormPreSale btnRef={ref} isRefInView={isRefInView} {...props} />
    );
  }

  if (product.isGraveyard) {
    return (
      <ProductFormNotSellable
        btnRef={ref}
        isRefInView={isRefInView}
        productName={product.title}
      />
    );
  }

  if (product.isOnlyAvailableInStoreNoClickAndCollect === true) {
    return (
      <ProductFormOnlyAvailableInStore
        formRef={ref}
        isRefInView={isRefInView}
        {...props}
      />
    );
  }

  return <ProductFormComponent {...props} />;
};
