import { log } from "@xxl/logging-utils";
import type { PriceType } from "@xxl/product-search-api";
import React from "react";
import { useSharedData } from "../../../contexts/SharedData";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { useSession } from "../../../hooks/useSession";
import type { ProductType } from "../../../utils/data-types";
import { getFrontendPriceDisplayData } from "../../../utils/PriceDisplay/price-display";
import type { GetFrontendPriceDisplayDataProps } from "../../../utils/PriceDisplay/types";
import type { PriceData } from "../../../utils/ProductData/product-card-data-helper";
import {
  getPriceDisplaysForPackages,
  isSoldInPackages,
} from "../product-helper";
import { PriceDescriptionWidget } from "../Product.styled";
import { PriceWithLabelsV1 } from "./PriceWithLabelsV1";
import { PriceWithLabelsV2 } from "./PriceWithLabelsV2";

export type PriceDataV2 = {
  selling: PriceData;
  alternate?: PriceData;
  disclaimer?: string;
  type?: PriceType;
};

type PriceWithLabelsProps =
  | {
      productType: ProductType;
      version: 1;
      priceDisplay?: GetFrontendPriceDisplayDataProps["priceDisplay"];
      productCode?: string;
      showPackagePrice?: boolean;
      selectedColumnsNumber: number;
      t: Translate;
      units?: {
        code?: string;
        visible?: boolean;
      }[];
      widget?: JSX.Element;
    }
  | {
      priceData: PriceDataV2;
      productType: ProductType;
      selectedColumnsNumber: number;
      hasRewardPrices?: boolean;
      version: 2;
    };

type TPriceWithLabels = PriceWithLabelsProps & { isCompact?: boolean };

const PriceWithLabels = (props: TPriceWithLabels) => {
  const {
    siteUid,
    featureToggles: { toggle_products_as_package_quantity },
  } = useSharedData().data;
  const {
    sessionState: { isLoggedIn },
  } = useSession();

  if (props.version === 2) {
    const { selectedColumnsNumber, priceData, productType } = props;
    return (
      <PriceWithLabelsV2
        selectedColumnsNumber={selectedColumnsNumber}
        priceData={priceData}
        productType={productType}
      />
    );
  }

  //props.version is 1
  const isRewardsProduct =
    props.priceDisplay !== undefined &&
    props.priceDisplay.invertedPrice !== undefined;
  const isCampaignProduct =
    props.priceDisplay !== undefined &&
    props.priceDisplay.previousPriceFormatted !== undefined &&
    props.priceDisplay.previousPriceFormatted.length > 0;

  const {
    productCode,
    productType,
    priceDisplay,
    showPackagePrice = false,
    selectedColumnsNumber,
    t,
    units,
    isCompact = false,
  } = props;
  const isMultiPackProduct = ["PRODUCT_MULTIPACK", "BUNDLE_MULTIPACK"].includes(
    productType
  );
  const showPackagePriceValues = showPackagePrice || isMultiPackProduct;
  const isPackageSell = isSoldInPackages(units) || isMultiPackProduct;
  const priceDisplayWithPackages =
    isPackageSell &&
    toggle_products_as_package_quantity &&
    showPackagePriceValues
      ? getPriceDisplaysForPackages(priceDisplay, units)
      : priceDisplay;

  if (priceDisplayWithPackages === undefined) {
    log.error(
      `Price display is undefined for product with code ${productCode !== undefined ? productCode : "undefined"}`
    );
    return null;
  }

  const frontendPriceDisplay = getFrontendPriceDisplayData({
    isLoggedIn,
    priceDisplay: priceDisplayWithPackages,
    siteUid,
    t,
  });

  return (
    <PriceWithLabelsV1
      isMultiPackProduct={isMultiPackProduct}
      priceDisplay={{
        hasLowerProductConfiguratorPriceThanSalesPrice:
          frontendPriceDisplay.hasLowerProductConfiguratorPriceThanSalesPrice,
        isDiscountedMainPrice: frontendPriceDisplay.isDiscountedMainPrice,
        isInvertedPrice: frontendPriceDisplay.isInvertedPrice,
        salesPrice: frontendPriceDisplay.salesPrice,
        otherPrice: frontendPriceDisplay.otherPrice,
        otherPriceDisclaimer: frontendPriceDisplay.otherPriceDisclaimer,
        salesPriceDisclaimer: frontendPriceDisplay.salesPriceDisclaimer,
        invertedPrice: priceDisplay?.invertedPrice,
        invertedPriceFormatted: priceDisplay?.invertedPriceFormatted,
        type: priceDisplay?.type,
      }}
      selectedColumnsNumber={selectedColumnsNumber}
      productCode={productCode}
      widget={
        isPackageSell &&
        toggle_products_as_package_quantity &&
        showPackagePriceValues ? (
          <PriceDescriptionWidget>
            {t("product.unit.per.box.description")}
          </PriceDescriptionWidget>
        ) : undefined
      }
      isCompact={isCompact}
      isRewardsProduct={isRewardsProduct || isCampaignProduct}
    />
  );
};

export { PriceWithLabels };
export type { PriceWithLabelsProps };
