import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { containerMaxWidthPx, xxlTheme } from "../../../styles/xxl-theme";
import { css } from "@emotion/react";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { MQ } from "../../../styles/helpers";

const { spaces } = xxlTheme;

export type ArrowsNavigationProps = {
  leftMargin?: number;
  rightMargin?: number;
};

export const INDICATOR_HEIGHT = spaces.micro;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${spaces.smallRegular} 0;
`;

export const Indicator = styled.button<{ isActive: boolean }>`
  width: 100%;
  padding: 0;
  height: ${INDICATOR_HEIGHT};
  border: none;
  background-color: ${({ isActive }) =>
    isActive ? color.webBlack.cssVariable : color.gray.cssVariable};
`;
export const Container = styled.div<{ containerPadding?: string }>`
  position: relative;
  padding: ${({ containerPadding }) => containerPadding ?? "0"};
`;

export const SliderList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const ListItem = styled.li`
  list-style: none;
`;

// Arrow Styles
export const ArrowButton = styled.button<{
  disabled: boolean;
}>`
  display: grid;
  place-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  padding: ${xxlTheme.spaces.line} ${xxlTheme.spaces.micro};
  color: ${color.white.cssVariable};
  width: 44px;
  height: 44px;

  position: absolute;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.6; 
      cursor: not-allowed;
  `}
`;

export const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${color.webBlack.cssVariable};
  display: grid;
  place-items: center;
`;
export const FadeWrapper = styled.div<{ fadeDirection: "left" | "right" }>`
  width: 100%;
  position: relative;

  &::after {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      ${({ fadeDirection }) => (fadeDirection === "left" ? "90deg" : "270deg")},
      white 60%,
      transparent 90%
    );
    content: "";
  }
`;

type ArrowsContainerData = {
  arrowsContainerProps?: ArrowsNavigationProps;
};

export const ArrowsContainer = styled.div<ArrowsContainerData>(
  ({ arrowsContainerProps }) => css`
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    left: 0;
    right: 0;

    ${MQ("tabletHorizontal")} {
      width: calc(100dvw - 2 * ${spaces.huge});
    }

    ${MQ("bigDesktop")} {
      width: ${containerMaxWidthPx};
    }

    ${isNotNullOrUndefined(arrowsContainerProps?.leftMargin) &&
    css`
      left: -${arrowsContainerProps.leftMargin}px;
      right: auto;
    `}

    ${isNotNullOrUndefined(arrowsContainerProps?.rightMargin) &&
    css`
      left: auto;
      right: -${arrowsContainerProps.rightMargin}px;
    `}
  `
);
