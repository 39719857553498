import { isNotNullOrUndefined } from "@xxl/common-utils";
import { isShootingEquipmentInCartItems } from "../Services/isShootingEquipmentInCartItems";
import { setEgCartItems } from "../Services/setCartItems";
import { setPromotionsData } from "../Services/setPromotionsData";
import type {
  CartItem,
  CartPaymentTotalsData,
  CartResponseData,
  DisplayCart,
} from "./types";

const getCartItemProductCodes = (cartItem: CartItem) =>
  [
    cartItem.productCode,
    ...(cartItem.productServices ?? []).map(({ productCode }) => productCode),
  ].filter(isNotNullOrUndefined);

export const CartController = (cartResponse: CartResponseData): DisplayCart => {
  const cartData = cartResponse.data?.cart;
  const collectStoreName = cartData?.collectStore?.store.name;

  const cartItems = setEgCartItems(cartData?.items, collectStoreName);
  const hasShootingEquipment = isShootingEquipmentInCartItems(cartData?.items);
  return {
    collectStoreName,
    items: cartItems,
    totalAmount: cartData?.totals.salesTotalAmount ?? 0,
    totalAmountExcludingShipping: cartData?.totals.salesTotalAmount ?? 0, // price is always excluding shipping when Klarna payment is set
    paymentTotalAmount: cartData?.totals.paymentTotals.totalAmount ?? 0,
    totalPrice: cartData?.totals.salesTotalFormattedPrice,
    totalItems: cartData?.totals.itemsCount ?? 0,
    priceWithoutDiscount:
      (cartData?.totals.salesTotalAmount ?? 0) +
      (cartData?.totals.cartDiscountTotal ?? 0),
    hasDiscounts: cartData?.hasDiscounts,
    totalDiscounts: cartData?.totals.cartDiscountTotalFormattedPrice,
    totalPriceAsInteger: cartData?.totals.totalPriceAsInteger,
    productCodes: cartItems.flatMap(getCartItemProductCodes),
    appliedCoupons: cartData?.coupons,
    appliedOrderPromotions: setPromotionsData(cartData?.coupons) ?? undefined,
    errors: cartResponse.errors?.map(({ message }) => message),
    personalOffers: cartData?.offers,
    giftCards: cartData?.totals.paymentTotals.giftCards ?? [],
    shippingTotalAmount: cartData?.totals.shippingTotalAmount,
    hasShootingEquipment,
  };
};

export const UpdateCartTotalsController = (
  totals: CartPaymentTotalsData,
  previousDisplayCartData: DisplayCart
): DisplayCart => {
  return {
    ...previousDisplayCartData,
    shippingTotalAmount: totals.shippingTotalAmount,
    paymentTotalAmount: totals.paymentTotals.totalAmount,
    giftCards: totals.paymentTotals.giftCards,
  };
};

export const getCartItemsCount = (cartResponse: CartResponseData): number =>
  cartResponse.data?.cart?.totals.itemsCount ?? 0;
