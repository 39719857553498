import { hasValue, isNotNullOrUndefined, type Site } from "@xxl/common-utils";
import { formatPrice } from "@xxl/price-formatting-utils";
import {
  type BaseProductData,
  type ProductPageResult,
} from "@xxl/product-search-api";

/**
 * This function addresses mismatches between the data schema and the live data from the search API
 *
 * Prepare base product data for the client.
 * This function is used to prepare the base product data for the client.
 * It removes the base color from the product data and converts the campaign start and end dates to Date objects.
 *
 * @param product - The base product data.
 * @returns The prepared base product data.
 */
export const getSerializableProductData = (baseProduct: BaseProductData) => ({
  ...baseProduct,
  products: baseProduct.products.map(({ baseColor, ...product }) => ({
    ...product,
    ...(hasValue(baseColor) && {
      baseColor,
    }),
    ...(isNotNullOrUndefined(product.campaign) && {
      campaign: {
        ...product.campaign,
        ...(isNotNullOrUndefined(product.campaign.start) && {
          start: JSON.parse(JSON.stringify(product.campaign.start)) as Date,
        }),
        ...(isNotNullOrUndefined(product.campaign.end) && {
          end: JSON.parse(JSON.stringify(product.campaign.start)) as Date,
        }),
      },
    }),
  })),
});

export type SerializableProductPageResult = Omit<
  ProductPageResult,
  "baseProduct"
> & {
  baseProduct: BaseProductData;
};
export const getSerializableProductPageResult = (
  productPageResult: ProductPageResult & {
    baseProduct: NonNullable<ProductPageResult["baseProduct"]>;
  }
) => ({
  ...productPageResult,
  baseProduct: getSerializableProductData(productPageResult.baseProduct),
});

type enhanceProductForMultipackProps = {
  baseProduct: BaseProductData;
  site: Site;
};
export const enhanceProductForMultipack = ({
  baseProduct,
  site,
}: enhanceProductForMultipackProps) => {
  const isMultipackProduct = baseProduct.products.some(
    (product) => product.isAmmunition === true
  );

  const productForMultipack =
    isMultipackProduct === true
      ? ({
          ...baseProduct,
          products: baseProduct.products.map((product) => {
            if (
              product.packageQuantity !== undefined &&
              product.packageQuantity > 1
            ) {
              const priceValue =
                product.price.selling.range.min.value * product.packageQuantity;
              const valueFormatted = formatPrice(priceValue, site);
              return {
                ...product,
                price: {
                  ...product.price,
                  selling: {
                    ...product.price.selling,
                    range: {
                      ...product.price.selling.range,
                      min: {
                        value: priceValue,
                        formatted: valueFormatted,
                      },
                    },
                  },
                },
              };
            } else {
              return product;
            }
          }),
        } as BaseProductData)
      : baseProduct;

  return productForMultipack;
};
