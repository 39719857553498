import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { ArrayOfBundleData } from "@xxl/pim-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useApiClients } from "../../contexts/ApiClients";
import { useSession } from "../../hooks/useSession";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import type { AddBundleProductsToCartMutation } from "../../generated/graphql-code-generator";
import type {
  AddBundleToCartEventData,
  AddConfigurableProductToCartEventData,
  BundleConfigurationData,
  CartEventData,
  EcomSiteUidLegacy,
  XXLAddToCartErrorPayload,
} from "../../global";
import type { GraphQLError } from "../../graphql/graphqlApi";
import { useAddToCart } from "../../hooks/useAddToCart/useAddToCart";
import { useClient } from "../../hooks/useClient/useClient";
import { windowAccess } from "../../utils/Window";
import * as XxlEvent from "../../utils/xxl-event";
import { showStickyHeader } from "../../utils/xxl-toggle-sticky-header";
import { CART_PAYMENT_STATUS } from "../PaymentStatus/payment-status-helper";
import { checkIfAbandonedCartMode } from "../Reward/Login/Login.helper";
import { LoginModalWrapper } from "../Reward/Login/LoginModalWrapper";
import { SignupModalWrapper } from "../Reward/SignUp/SignupModalWrapper";
import { XXLLoader } from "../XXLLoader";
import {
  addConfigurableProductToCart,
  ERROR_RESPONSE_STATUS,
  getCart,
  SUCCESS_RESPONSE_STATUS,
} from "./Api/CartAPI";
import { addBundleWithEanToCart } from "./Api/CartBundleAPI";
import type {
  DisplayCart,
  GenericGraphQLError,
  UpdateQuantityGraphQLError,
} from "./Api/types";
import {
  getTranslationKeyForErrorMessage,
  hasOutOfStockError,
} from "./cart-page-helper";
import { CartContent } from "./CartContent";
import {
  ADD_TO_CART,
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  CART_EDITION_START,
  CART_EDITION_STOP,
  CART_REQUEST_SUCCESS,
  ENABLE_CART_API_REQUEST,
  HAS_MINI_CART,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  SET_CART_COUNT,
  UPDATE_CART_CONTENT,
  UPDATE_CART_COUNT,
  useCartContext,
} from "./CartState";
import { CollectWarningOnPage } from "./Components/CollectWarningOnPage";
import { WalleyCheckoutEvents } from "./constants";
import { useSetCartCountEventListener } from "./hooks/useSetCartCountEventListener";
import { MiniCartContent } from "./MiniCartContent";
import { initializeCheckout } from "./Services/checkout";
import {
  setBundledProducts,
  setBundledProductsConfigurations,
  SINGLE_QUANTITY_NUMBER,
} from "./Services/setBundledProducts";
import { setBundleProductsWithPrint } from "./Services/setBundleWithPrint";
import { handleTrackingCartUpdatedInCheckout } from "./Services/tracking";
import { updateCheckoutSnippet } from "./Services/updateCartQuantity";

const PREVENT_INITIAL_LOAD_CALL_TIMEOUT = 1000;

type CartProps = {
  isMiniCart: boolean;
  isTeamAdmin?: string;
  isTeamsalesAdmin?: string;
  disableCheckoutCoupons: boolean;
};

export const Cart: React.FunctionComponent<CartProps> = ({
  isMiniCart,
  isTeamAdmin,
  isTeamsalesAdmin,
  disableCheckoutCoupons,
}) => {
  const { state, dispatch } = useCartContext();

  const {
    isReactApp,
    data: { configuration, pageType, siteUid },
  } = useSharedData();
  const isClient = useClient();
  const trackers = useTracking();
  const { pimApi } = useApiClients();
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const [hideCartContent, setHideCartContent] = useState(false);
  const [initializedCheckout, setInitializedCheckout] = useState(false);
  const [pimConfigurations, setPimConfigurations] =
    useState<ArrayOfBundleData>();
  const [isInitialCall, setIsInitialCall] = useState(true);
  const [updateCheckoutTracking, setUpdateCheckoutTracking] = useState(false);
  const { handleAdditionalSales, CrossSalesComponent, ServicesComponent } =
    useAddToCart();
  const { updateCartContent, miniCartCounter, isOnlyMiniCartCount } = state;
  const isTeamAdminBoolean = isTeamAdmin === "true";

  useEffect(() => {
    if (isMiniCart) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get(CART_PAYMENT_STATUS.parameter);

    if (
      paymentStatus === CART_PAYMENT_STATUS.value.cancelled ||
      paymentStatus === CART_PAYMENT_STATUS.value.error
    ) {
      return;
    }
  }, [isMiniCart]);

  useEffect(() => {
    const isAbandonedCartMode = checkIfAbandonedCartMode();
    setHideCartContent(isAbandonedCartMode && !isLoggedIn && !isMiniCart);
  }, [isLoggedIn, isMiniCart]);

  const getConfiguration = useCallback(
    async (siteId: EcomSiteUidLegacy): Promise<ArrayOfBundleData> => {
      try {
        const bundleCode = windowAccess()._sharedData.bundleCode ?? "";
        if (bundleCode === "") {
          throw new Error(
            "Can't request bundle configuration, bundleCode is missing"
          );
        }

        const pimDataResult = await pimApi.getBundlesV2(siteId, bundleCode);

        const configurations = pimDataResult.data;

        setPimConfigurations(configurations);
        return configurations;
      } catch (err) {
        log.error("Cannot get pim data", err);
        return [];
      }
    },
    [pimApi]
  );

  const handleGraphQLErrors = (
    hasOutOfStockErrors: boolean,
    errors:
      | GraphQLError<AddBundleProductsToCartMutation, unknown>[]
      | GraphQLError<AddBundleProductsToCartMutation, unknown>[]
      | undefined,
    hasInvalidQuantityErrors = false
  ): void => {
    const nonGenericError = hasOutOfStockErrors || hasInvalidQuantityErrors;

    dispatch({
      type: SET_CART_COUNT,
      payload: { count: miniCartCounter ?? 0 },
    });
    dispatch({ type: CART_EDITION_STOP });
    const errorWrappers = document.querySelectorAll(
      ".js-product__error-message"
    );
    const errorTextStock = document.querySelectorAll(
      ".js-product__error-no-stock"
    );
    const errorTextInvalidQuantity = document.querySelectorAll(
      ".js-product__error-invalid-quantity"
    );
    const errorTextGeneric = document.querySelectorAll(
      ".js-product__error-failure"
    );
    const addToCartButton = document.querySelector(".js-cart__add");
    errorWrappers.forEach((item) => {
      item.classList.add("product__error-message--visible");
    });
    errorTextStock.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        hasOutOfStockErrors
      );
    });
    errorTextInvalidQuantity.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        hasInvalidQuantityErrors
      );
    });
    errorTextGeneric.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        !nonGenericError
      );
    });
    addToCartButton?.classList.toggle(
      "product__button-disable",
      nonGenericError
    );

    if (hasValue(errors)) {
      XxlEvent.dispatchEvent<XXLAddToCartErrorPayload>(
        XxlEvent.XXL_ADD_TO_CART_ERROR,
        {
          errorTranslationKey: getTranslationKeyForErrorMessage(errors),
        }
      );
    }
  };

  const clearErrors = (): void => {
    const errorWrappers = document.querySelectorAll(
      ".js-product__error-message"
    );
    const errorTextStock = document.querySelectorAll(
      ".js-product__error-no-stock"
    );
    const errorTextGeneric = document.querySelectorAll(
      ".js-product__error-failure"
    );
    errorWrappers.forEach((item) => {
      item.classList.remove("product__error-message--visible");
    });
    errorTextStock.forEach((item) => {
      item.classList.remove("product__error-message-text--visible");
    });
    errorTextGeneric.forEach((item) => {
      item.classList.remove("product__error-message-text--visible");
    });
  };

  useEffect(() => {
    const onCartCountChange = (event: CustomEvent<CartEventData>) => {
      const { count } = event.detail;
      if (count === miniCartCounter) {
        return;
      }

      dispatch({
        type: CART_EDITION_START,
      });

      if (count === undefined) {
        if (isOnlyMiniCartCount === true) {
          dispatch({
            type: ENABLE_CART_API_REQUEST,
          });
        }
        dispatch({
          type: UPDATE_CART_COUNT,
        });
      } else {
        dispatch({
          type: SET_CART_COUNT,
          payload: { count },
        });
        dispatch({
          type: CART_EDITION_STOP,
        });
      }
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_CART_UPDATE,
      onCartCountChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_CART_UPDATE,
        onCartCountChange
      );
    };
  }, [miniCartCounter, isOnlyMiniCartCount]);

  const _addBundleToCart = async (
    bundleConfigurationData: BundleConfigurationData,
    addToCartEvent: AddBundleToCartEventData,
    pimData?: ArrayOfBundleData
  ) => {
    clearErrors();
    const { $clickedButtonElement, isBundleWithPrintConfig, bundleName } =
      addToCartEvent;

    try {
      dispatch({
        type: CART_EDITION_START,
      });
      dispatch({
        type: "ADD_TO_CART",
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      showStickyHeader();

      windowAccess().Cart?.animateAddToCartLoading(true, $clickedButtonElement);
      if (isBundleWithPrintConfig) {
        const { members, bundleEan } = bundleConfigurationData;
        if (pimData !== undefined) {
          const bundledProducts = setBundleProductsWithPrint(members, pimData);

          const response = await addBundleWithEanToCart(
            bundleEan,
            bundledProducts,
            [],
            configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
            configuration.amplifyConfig.aws_appsync_apiKey
          );

          const { status, data } = response;

          if (
            status === SUCCESS_RESPONSE_STATUS &&
            data.data?.addBundleProductsToCart !== undefined
          ) {
            const { items } = data.data.addBundleProductsToCart;

            const sameEanBundlesArray = items.filter(
              (item) => item.ean === String(bundleEan)
            );
            const sameBundlesIds = sameEanBundlesArray.map(
              (item) => item.itemId.id
            );
            const highestBundleId = Math.max(...sameBundlesIds);
            const addedBundle = sameEanBundlesArray.find(
              ({ itemId }) => itemId.id === highestBundleId
            );

            if (addedBundle !== undefined) {
              window.Tracking.addToGiosgCart({
                productCode: window.BundleGlobal.bundleDataJSONConfig.bundle,
                productName: bundleName,
                quantity: addedBundle.quantity.quantity,
              });
              window.Eventstream.addBundleToCart({
                products: bundledProducts,
                bundle: bundleEan,
              });
            }

            dispatch({
              type: SET_CART_COUNT,
              payload: {
                count: data.data.addBundleProductsToCart.totals.itemsCount,
              },
            });
          } else if (status === ERROR_RESPONSE_STATUS) {
            const hasOutOfStockErrors =
              data.errors?.some(
                (err) =>
                  "errorType" in err &&
                  (err as UpdateQuantityGraphQLError).errorType ===
                    "OUT_OF_STOCK"
              ) ?? false;

            handleGraphQLErrors(hasOutOfStockErrors, data.errors);
          }
        } else {
          handleGraphQLErrors(false, []);
          await new Promise((resolve) => setTimeout(resolve, 100)); // can't dispatch CART_EDITION_START and CART_EDITION_STOP sequentially
        }
      } else {
        const {
          //bundle,
          bundleEan,
          groups,
        } = bundleConfigurationData;
        const bundledProducts = setBundledProducts(groups);
        const bundleConfigurations = setBundledProductsConfigurations(groups);
        const response = await addBundleWithEanToCart(
          bundleEan,
          bundledProducts,
          bundleConfigurations,
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey
        );
        const { status, data } = response;
        const itemsCount =
          data.data?.addBundleProductsToCart.totals.itemsCount ?? 0;
        const requestWasSuccessful =
          status === SUCCESS_RESPONSE_STATUS && itemsCount !== miniCartCounter;

        if (requestWasSuccessful) {
          const items = data.data?.addBundleProductsToCart.items;

          const sameEanBundlesArray =
            items?.filter((item) => item.ean === String(bundleEan)) ?? [];
          const sameBundlesIds = sameEanBundlesArray.map(
            (item) => item.itemId.id
          );
          const highestBundleId = Math.max(...sameBundlesIds);
          const addedBundle = sameEanBundlesArray.find(
            ({ itemId }) => itemId.id === highestBundleId
          );
          if (addedBundle !== undefined) {
            //setCartEntryItemId(addedBundle.itemId);
            window.Tracking.addToGiosgCart({
              productCode: window.BundleGlobal.bundleDataJSONConfig.bundle,
              productName: bundleName,
              quantity: SINGLE_QUANTITY_NUMBER,
            });
            window.Eventstream.addBundleToCart({
              products: bundledProducts,
              bundle: bundleEan,
            });
          }
          dispatch({
            type: SET_CART_COUNT,
            payload: { count: itemsCount },
          });
        } else if (status === ERROR_RESPONSE_STATUS && hasValue(data.errors)) {
          handleGraphQLErrors(
            hasOutOfStockError(data.errors as GenericGraphQLError[]),
            data.errors
          );
        } else {
          handleGraphQLErrors(false, []);
        }
      }
    } catch (err) {
      log.error("Cannot add bundle to cart", err);
    } finally {
      dispatch({
        type: CART_EDITION_STOP,
      });
      windowAccess().AnimateElement?.toggleAnimation({
        isActive: true,
        timeout: { set: false },
      });

      windowAccess().Cart?.animateAddToCartLoading(
        false,
        $clickedButtonElement
      );
    }
  };

  useEffect(() => {
    if (windowAccess()._sharedData.isBundleWithPrint === true) {
      void getConfiguration(siteUid);
    }
  }, [getConfiguration, siteUid]);

  useEffect(() => {
    const addBundleToCartChange = (
      event: CustomEvent<AddBundleToCartEventData>
    ) => {
      void _addBundleToCart(
        window.BundleGlobal.bundleDataJSONConfig,
        event.detail,
        pimConfigurations
      );
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_ADD_BUNDLE_TO_CART,
      addBundleToCartChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_ADD_BUNDLE_TO_CART,
        addBundleToCartChange
      );
    };
  }, [pimConfigurations]);

  const lockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_LOCK,
    });

  const unlockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_UNLOCK,
    });

  useEffect(() => {
    if (isWalley(state.paymentProvider)) {
      document.addEventListener(WalleyCheckoutEvents.locked, lockCart);
      document.addEventListener(WalleyCheckoutEvents.unlocked, unlockCart);
    }

    return () => {
      document.removeEventListener(WalleyCheckoutEvents.locked, lockCart);
      document.removeEventListener(WalleyCheckoutEvents.unlocked, unlockCart);
    };
  }, [state.paymentProvider]);

  const _addConfigurableProductToCart = async (
    event: AddConfigurableProductToCartEventData
  ) => {
    dispatch({
      type: ADD_TO_CART,
    });
    try {
      dispatch({
        type: CART_EDITION_START,
      });
      const response = await addConfigurableProductToCart(event);
      const {
        data: { data },
        status,
      } = response;
      const itemsCount =
        data?.addConfigurableProductsToCart.totals.itemsCount ?? 0;
      const { parentEan } = event;
      const addedItem = data?.addConfigurableProductsToCart.items.find(
        ({ ean }) => ean === parentEan
      );

      if (
        status === SUCCESS_RESPONSE_STATUS &&
        itemsCount !== miniCartCounter
      ) {
        dispatch({
          type: SET_CART_COUNT,
          payload: { count: itemsCount },
        });
      }

      const {
        additionalSales,
        brandName,
        categoryCode,
        imageUrl,
        name,
        salesPrice,
        salesPriceFormatted,
        sizeName,
        parentEan: ean,
      } = event;

      if (hasValue(addedItem?.itemId)) {
        void handleAdditionalSales({
          additionalSales,
          ean,
          categoryCode,
          cartEntryItemId: addedItem.itemId,
          clickAndCollectWarning: null,
          productData: {
            brand: brandName,
            imageUrl,
            name,
            salesPrice,
            salesPriceFormatted,
            size: sizeName,
          },
          variant: "addToCart",
        });
      }
    } catch (err) {
      log.error(
        `cannot add the configurable product ${JSON.stringify(event)} to cart`,
        err
      );
    } finally {
      dispatch({
        type: CART_EDITION_STOP,
      });
    }
  };

  useEffect(() => {
    const onAddConfigurableProductToCartChange = (
      event: CustomEvent<AddConfigurableProductToCartEventData>
    ) => {
      void _addConfigurableProductToCart(event.detail);
    };
    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
      onAddConfigurableProductToCartChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
        onAddConfigurableProductToCartChange
      );
    };
  }, []);

  useEffect(() => {
    // Sometimes `window.Checkout` is undefined on next-js-app client side. In this case it must be initialized again
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.Checkout === undefined) {
      window.Checkout = initializeCheckout(isLoggedIn);
      setInitializedCheckout(true);
    }
  }, []);

  useEffect(() => {
    if (initializedCheckout) {
      window.Checkout.initialize();
      setInitializedCheckout(false);
    }
  }, [initializedCheckout]);

  useEffect(() => {
    const _getCart = async (): Promise<void> => {
      try {
        const cartResponse = await getCart(
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey,
          pageType
        );
        if (cartResponse.status === SUCCESS_RESPONSE_STATUS && !isMiniCart) {
          setUpdateCheckoutTracking(true);
          if (
            (isKlarnaCheckoutSnippetLoaded() ||
              isWalley(state.paymentProvider)) &&
            (cartResponse.data.data?.cart?.items.length ?? 0) > 0 &&
            !isInitialCall
          ) {
            void updateCheckoutSnippet(
              dispatch,
              configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
              configuration.amplifyConfig.aws_appsync_apiKey,
              state.customerTypeOptions,
              state.customerType
            );
          }
        }

        dispatch({
          type: CART_REQUEST_SUCCESS,
          payload: { data: cartResponse.data },
        });
        if (isMiniCart) {
          dispatch({
            type: HAS_MINI_CART,
          });
        }
        dispatch({
          type: CART_EDITION_STOP,
        });
      } catch (err) {
        log.error("Cannot get cart data", err);
      }
    };
    if (updateCartContent === true && isOnlyMiniCartCount === false) {
      void _getCart();
    }
  }, [
    configuration.amplifyConfig.aws_appsync_apiKey,
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    isMiniCart,
    isOnlyMiniCartCount,
    updateCartContent,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialCall(false);
    }, PREVENT_INITIAL_LOAD_CALL_TIMEOUT);
  }, []);

  const getDisplayCartData = (
    cartData: DisplayCart | undefined
  ): DisplayCart | boolean => {
    if (cartData !== undefined) {
      return cartData;
    }
    return false;
  };

  const displayCart = useMemo(
    () => getDisplayCartData(state.displayCart),
    [state.displayCart]
  );

  useEffect(() => {
    if (
      typeof displayCart !== "boolean" &&
      !isMiniCart &&
      updateCheckoutTracking
    ) {
      setUpdateCheckoutTracking(false);
      handleTrackingCartUpdatedInCheckout(displayCart, trackers);
    }
  }, [displayCart]);

  useEffect(() => {
    if (isOnlyMiniCartCount === false) {
      dispatch({
        type: UPDATE_CART_CONTENT,
      });
    }
  }, [isOnlyMiniCartCount]);

  const cartPageRootElement = isClient
    ? document.getElementById("react-cart-page")
    : null;

  const productPageWarningsWrapper = isReactApp
    ? document.getElementById("react-warning-wrapper")
    : null;

  useEffect(() => {
    if (!isMiniCart) {
      dispatch({
        type: ENABLE_CART_API_REQUEST,
      });
    }
  }, [isMiniCart]);

  useSetCartCountEventListener(dispatch);

  return (
    <>
      {isMiniCart === true ? (
        <MiniCartContent isCartPage={false} />
      ) : (
        !hideCartContent && (
          <>
            <MiniCartContent isCartPage={true} />
            {cartPageRootElement !== null &&
              createPortal(
                <>
                  {state.isLoading === true ? (
                    <XXLLoader minHeight={"600px"} />
                  ) : (
                    <CartContent
                      isTeamAdmin={isTeamAdminBoolean}
                      isTeamsalesAdmin={isTeamsalesAdmin === "true"}
                      disableCheckoutCoupons={disableCheckoutCoupons}
                    />
                  )}
                </>,
                cartPageRootElement
              )}
          </>
        )
      )}
      {productPageWarningsWrapper !== null &&
        createPortal(<CollectWarningOnPage />, productPageWarningsWrapper)}
      {!isMiniCart && (
        <>
          <LoginModalWrapper
            isOpen={hideCartContent}
            onClose={() => setHideCartContent(false)}
          />
          <SignupModalWrapper />
        </>
      )}
      {CrossSalesComponent}
      {ServicesComponent}
    </>
  );
};
