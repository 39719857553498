import React from "react";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { InfoMessage as InfoMessageType } from "../../utils/apis/content-info-messages";
import { createUrl } from "../../components/InfoMessage/info-message-helper";
import { Fallback } from "./Fallback";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { hasValue } from "@xxl/common-utils";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";

type InfoMessageProps = {
  message: InfoMessageType | null;
};

const InfoMessage: React.FunctionComponent<InfoMessageProps> = ({
  message,
}) => {
  const { t } = useTranslations();
  const {
    requestMapping: { guides, faq },
  } = useSharedData().data;

  if (message === null) {
    return <Fallback />;
  }
  const {
    linkText,
    linkType,
    message: text,
    type,
    url,
    disclaimerInformation,
  } = message;
  const href = createUrl(url, linkType, guides, faq);
  const modifier = `--${type}`;

  return (
    <ErrorBoundary>
      <section className="info-messages">
        <p
          data-testid={"info-message-text"}
          className={`info-messages__message info-messages__message${modifier}`}
        >
          <span className="container" data-testid="product-info-message">
            {text} <a href={href}>{linkText}</a>
          </span>
          {hasValue(disclaimerInformation) && (
            <DisclaimerInformation
              buttonText={t("general.close")}
              title={t("general.information")}
              iconSize={16}
              color={disclaimerInformation.buttonColor}
              style={{
                display: "inline-block",
                marginLeft: xxlTheme.spaces.micro,
              }}
            >
              {disclaimerInformation.description}
            </DisclaimerInformation>
          )}
        </p>
      </section>
    </ErrorBoundary>
  );
};

export { InfoMessage };
