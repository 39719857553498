import React, { type ReactElement, forwardRef } from "react";
import { Slide } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = "Transition";

export { Transition };
